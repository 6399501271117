import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const SuccessText = styled.div`
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`
