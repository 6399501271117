import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Alert from 'src/Components/Alert'
import { BonusStatus } from 'src/Types'

import { AlertText, AlertTitle, BonusAlertWrapper } from './styles'

export const BonusAlert: FunctionComponent = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()

  const bonusStatus = search?.get('status')

  const isAlertShowing = bonusStatus === BonusStatus.INCREASED

  if (!isAlertShowing) {
    return null
  }

  return (
    <BonusAlertWrapper>
      <Alert color='success' icon unclosable noGap>
        <AlertText>
          <AlertTitle>
            {t('campaignsPage.congratulations')}
            &nbsp;<span>$20</span>&nbsp;
            {t('campaignsPage.areSuccessfullyAdded')}
          </AlertTitle>
        </AlertText>
      </Alert>
    </BonusAlertWrapper>
  )
}
