import React, { FunctionComponent } from 'react'

import { CloseIconGrey, SoundCampaignLogo } from 'src/Assets/Svg'

import { useCreateCampaignLayout } from '../../useCreateCampaignLayout'

import { LogoHeader, TotalCloseButton } from './styles'

export const CreateCampaignHeader: FunctionComponent = () => {
  const { totalCloseHandler } = useCreateCampaignLayout()

  return (
    <LogoHeader id='scroll'>
      <SoundCampaignLogo />
      <TotalCloseButton onClick={totalCloseHandler}>
        <CloseIconGrey />
      </TotalCloseButton>
    </LogoHeader>
  )
}
