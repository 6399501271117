import React, { useEffect } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SpotifyIcon from 'mdi-react/SpotifyIcon'

import logo from 'src/Assets/Png/logo.png'
import { APP } from 'src/Configs/App'
import {
  locationEvent,
  curatorRegistrationStartEvent,
} from 'src/Helpers/TagManager'

import {
  Accent,
  BoldText,
  Card,
  Condition,
  Container,
  Head,
  Logo,
  SpotifyLink,
  TextCenter,
  Wrapper,
} from './styles'

const START_INDEX = 8

const SignUp: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const role = location.pathname.slice(START_INDEX)

  useEffect(() => {
    locationEvent()

    if (role.includes('curator')) {
      curatorRegistrationStartEvent()
    }
  }, [role])

  return (
    <Wrapper>
      <Container>
        <Logo>
          <img alt='logo' src={logo} />
        </Logo>
        <Card>
          <Head>
            <h3>
              {t('signup.become')} <Accent>{t('sidebar.curator')}</Accent>
            </h3>
          </Head>
          {role.includes('curator') && (
            <>
              <TextCenter>
                <p>{t('signup.wePay')}</p>
              </TextCenter>
              <TextCenter>
                <BoldText>{t('signup.condition')}</BoldText>
              </TextCenter>
            </>
          )}
          <SpotifyLink href={APP.SPOTIFY_AUTH_API}>
            <SpotifyIcon />
            &nbsp;
            {t('signup.signUpWithSpotify')}
          </SpotifyLink>
          <span>
            <Condition>{t('signup.permission')}</Condition>

            {role.includes('curator') && (
              <Condition>{t('signup.agree')}</Condition>
            )}
          </span>
          <TextCenter>
            <Link to='/'>{t('signup.alreadyHaveAccount')}</Link>
          </TextCenter>
        </Card>
      </Container>
    </Wrapper>
  )
}

export default SignUp
