import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import ReactStars from 'react-rating-stars-component'

import { useAppSelector } from 'src/Hooks/redux'
import { colors } from 'src/Styled/variables'
import { getVideo } from 'src/Redux/campaignVideo-process/video-process/selectors'

import { RateFeedBackDate } from './RateFeedBackDate'

import {
  CommentTitle,
  RateContainer,
  RateDateWrapper,
  RateWrapper,
  StyledReactStars,
} from './styles'

export const RateFeedback: FC = () => {
  const { t } = useTranslation()
  const video = useAppSelector(getVideo)

  return (
    <RateWrapper>
      <RateContainer>
        <RateDateWrapper>
          <RateFeedBackDate />
        </RateDateWrapper>
        <StyledReactStars>
          <ReactStars
            count={5}
            edit={false}
            value={video?.stars}
            size={18}
            color={colors.greyTextBlackBg}
            activeColor={colors.messegesYellow1}
          />
        </StyledReactStars>
      </RateContainer>
      {Boolean(video?.feedback) && (
        <div>
          <CommentTitle>{t('campaignResultsPage.comment')}</CommentTitle>
          <div>{video?.feedback}</div>
        </div>
      )}
    </RateWrapper>
  )
}
