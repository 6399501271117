import { useCallback } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import {
  setTikTokTrackFromSearchList,
  setTiktokTrackToInitialState,
  setTiktokTracksToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { CampaignPlatformTypeEnum, TikTokTrack, Track } from 'src/Types'
import { usePageViewEvent } from 'src/Hooks/Analytics/useTrackEvents'
import {
  checkTrackToCreateCampaign,
  getSpotifyTracks,
} from 'src/Redux/spotifyTracks-process/api-actions'
import {
  getTikTokTrack,
  searchTikTokTrackByName,
} from 'src/Redux/tiktokTrack-process/api-actions'
import { getIsTikTokTrackUrl } from 'src/Constants/functions'
import {
  DEBOUNCE_TIMER_1000,
  DEBOUNCE_TIMER_300,
} from 'src/Constants/constants'
import {
  getAllSearchErrorSelector,
  getIsLoadingTikTok,
  getTikTokTrackSelector,
  getTikTokTracksSelector,
} from 'src/Redux/tiktokTrack-process/selectors'
import {
  getIsLoadingSpotifyTracks,
  getTracks,
} from 'src/Redux/spotifyTracks-process/selectors'
import { setSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice'
import { getSpotifyTrack } from 'src/Redux/track-process/api-actions'
import { getSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { setTrackToInitialState } from 'src/Redux/track-process'
import { SearchTrackFormDataProps } from 'src/Components/SearchForm/TrackSearchAuth'

interface UseSearchTrackResponse {
  onClearTracks: () => void
  onCheckTrack: (url: string) => void
  onTrackSearchHandler: ({ searchInput }: SearchTrackFormDataProps) => void
  tracks: TikTokTrack[] | Track[]
  hasSelectedTrack: boolean
  error?: string
  loading: boolean
}

export const useSearchTrack = (
  platform?: CampaignPlatformTypeEnum,
): UseSearchTrackResponse => {
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getSpotifyToken)
  const tikTokTracks = useAppSelector(getTikTokTracksSelector)
  const spotifyTracks = useAppSelector(getTracks)
  const searchError = useAppSelector(getAllSearchErrorSelector)
  const isLoadingTikTok = useAppSelector(getIsLoadingTikTok)
  const isLoadingSpotify = useAppSelector(getIsLoadingSpotifyTracks)
  const tikTokTrack = useAppSelector(getTikTokTrackSelector)
  const spotifyTrack = useAppSelector(getSpotifyTrackSelector)

  const {
    enteredTrackUrlEventHandler,
    startedEnterTrackEventHandler,
    trackAddedEventHandler,
  } = usePageViewEvent()

  const onClearTracks = useCallback((): void => {
    dispatch(setTracksToInitialState())
    dispatch(setTiktokTracksToInitialState())
    dispatch(setTrackToInitialState())
    dispatch(setTiktokTrackToInitialState())
  }, [dispatch])

  const searchTracks = ({
    searchInput: searchInputValue,
  }: SearchTrackFormDataProps): void => {
    if (
      platform === CampaignPlatformTypeEnum.SPOTIFY &&
      accessToken &&
      searchInputValue
    ) {
      void dispatch(
        getSpotifyTracks({ search: searchInputValue, token: accessToken }),
      )
      return
    }

    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      if (!getIsTikTokTrackUrl(searchInputValue)) {
        void dispatch(searchTikTokTrackByName({ searchText: searchInputValue }))
      }

      if (getIsTikTokTrackUrl(searchInputValue)) {
        void dispatch(getTikTokTrack({ search: searchInputValue }))
      }
    }
  }
  const onTrackSearchHandler = useDebouncedCallback(
    ({ searchInput }: SearchTrackFormDataProps) => {
      if (!searchInput) {
        onClearTracks()
        return
      }
      platform && searchTracks({ searchInput })

      if (getIsTikTokTrackUrl(searchInput)) {
        enteredTrackUrlEventHandler()
      } else {
        startedEnterTrackEventHandler()
      }
    },
    DEBOUNCE_TIMER_1000,
  )

  const onCheckTrack = useDebouncedCallback(async (url: string) => {
    const isSpotifyPlatform =
      platform === CampaignPlatformTypeEnum.SPOTIFY && accessToken
    const isTikTokPlatform = platform === CampaignPlatformTypeEnum.TIKTOK

    if (isSpotifyPlatform) {
      try {
        await dispatch(checkTrackToCreateCampaign({ trackURL: url })).unwrap()
        const res = await dispatch(
          getSpotifyTrack({ url, token: accessToken }),
        ).unwrap()
        trackAddedEventHandler({
          artistName: res?.artists[0]?.name,
          platformType: CampaignPlatformTypeEnum.SPOTIFY,
          trackDuration: res?.duration_ms,
          trackId: res?.id,
          trackName: res?.name,
        })
        dispatch(setTracksToInitialState())
        dispatch(setSchedulePlatform(null))
      } catch {
        void dispatch(getSpotifyAccessToken(false))
      }
    }

    if (isTikTokPlatform) {
      void dispatch(getTikTokTrack({ search: url }))
        .unwrap()
        .then((payload) => {
          trackAddedEventHandler({
            artistName: payload.trackAuthorName,
            platformType: CampaignPlatformTypeEnum.TIKTOK,
            trackDuration: payload.trackDuration,
            trackId: payload.trackTiktokId,
            trackName: payload.trackTitle,
          })
          dispatch(setTikTokTrackFromSearchList(payload))
          dispatch(setSchedulePlatform(null))
          dispatch(setTiktokTracksToInitialState())
          return payload
        })
    }
  }, DEBOUNCE_TIMER_300)

  const tracks =
    platform === CampaignPlatformTypeEnum.SPOTIFY ? spotifyTracks : tikTokTracks
  const hasSelectedTrack = Boolean(spotifyTrack || tikTokTrack)

  return {
    hasSelectedTrack,
    onClearTracks,
    onCheckTrack,
    onTrackSearchHandler,
    error: searchError || '',
    tracks: tracks || [],
    loading: isLoadingTikTok || isLoadingSpotify,
  }
}
