import { useCallback } from 'react'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { paymentCompletedEvent, purchaseEvent } from 'src/Helpers/TagManager'
import {
  getGtmPaymentPlatformsInfo,
  sendPaymentCompletedPlatformEvent,
} from 'src/Redux/googleAnalytics-process/api-actions'
import { GetGtmInfoInterface } from 'src/Types'
import { paymentEventCompletedGA4 } from 'src/Redux/ga4-process/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getIsFirstCampaign, getUserPhone,
  getUserSelector,
} from "src/Redux/auth-process/userSlice/selectors";
import { getSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { PaymentMethodEnum } from 'src/Constants/enums'

import { useCustomSearchParams } from './useCustomSearchParams'

interface PaymentCompletedEvents {
  handlePaymentCompletedEvents: (params: { platformName: string }) => void
}

export const usePaymentCompletedEvents = (): PaymentCompletedEvents => {
  const dispatch = useAppDispatch()
  const param = useParams()
  const plan = useAppSelector(getSubscriptionSelector)
  const campaignId = Number(param.number) || 0
  const isFirstCampaign = useSelector(getIsFirstCampaign)
  const user = useSelector(getUserSelector)
  const phone = useSelector(getUserPhone)
  const paymentMethod = useSelector(getPaymentMethod)
  const { search } = useCustomSearchParams()
  const startDate = search.get('startDate')
  const paypalBillingAgreement = search.get('autopay')

  const handlePaymentCompletedEvents = useCallback(
    async (params: { platformName: string }): Promise<void> => {
      await dispatch(
        getGtmPaymentPlatformsInfo({
          campaignId,
          platform: params.platformName,
        }),
      )
        .unwrap()
        .then((response: GetGtmInfoInterface) => {
          const payload = response
          paymentCompletedEvent({
            campaignId,
            paymentMethod:
              paypalBillingAgreement === 'true'
                ? PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT
                : paymentMethod,
            genresList: payload.genresList.join(', ') || '',
            languagesList: payload.languagesList.join(', ') || '',
            userId: user.id,
            userRole: user.role,
            email: user.email,
            phone: phone || '',
            campaignNumber: payload.campaignNumber,
            platformName: params.platformName,
            campaignType: payload.campaignType,
            campaignValue: payload.resultPrice,
            paymentAmount: payload.value,
            transactionId: payload.transactionId,
            isAnyLanguage:
              Boolean(payload.languagesList.join(', ').includes('Any')) ||
              false,
            subscriptionPlan: plan?.package?.name || '',
          })

          purchaseEvent({
            user,
            isFirstCampaign,
            campaignId,
            transactionTotal: payload.value,
            price: payload.resultPrice,
            campaignPlatform: params.platformName,
          })

          void dispatch(
            sendPaymentCompletedPlatformEvent({
              campaignId,
              platform: params.platformName,
              transaction: {
                price: payload.value,
                start_date: startDate,
              },
              url: window.location.href,
            }),
          )

          void dispatch(
            paymentEventCompletedGA4({
              campaignId,
              campaignPlatform: params.platformName,
            }),
          )
          return null
        })
    },
    [
      campaignId,
      dispatch,
      isFirstCampaign,
      paymentMethod,
      paypalBillingAgreement,
      plan?.package?.name,
      startDate,
      user,
    ],
  )

  return {
    handlePaymentCompletedEvents,
  }
}
