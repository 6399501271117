import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { capitalize } from 'src/Helpers/layoutHelpers'
import { ANY_LANGUAGE_ID, NO_VOCAL } from 'src/Constants/constants'

import { RootState } from '..'

import { LanguageState } from '.'

const getState = (state: RootState): LanguageState => state.languages

export const getLanguages = createDraftSafeSelector(
  getState,
  ({ languages }) => languages,
)

export const getExistingPlaylistsLanguages = createDraftSafeSelector(
  getState,
  ({ existing_playlists_languages }) => [
    {
      label: 'Most popular',
      options: (existing_playlists_languages?.popular || []).map((item) => ({
        value: item.id,
        label: capitalize(item.language_name || ''),
      })),
    },
    {
      label: 'A-Z',
      options: (existing_playlists_languages?.all || [])
        .map((item) => ({
          value: item.id,
          label: capitalize(item.language_name || ''),
        }))
        .filter((item) => item.value !== ANY_LANGUAGE_ID),
    },
  ],
)

export const getFilteredAndSortedLanguages = createDraftSafeSelector(
  getState,
  ({ languages }) => {
    const languagesFiltered = languages.filter((el) => el.id !== 1)
    const elementsToMove = languagesFiltered.filter(
      (el) => el.id === NO_VOCAL || el.id === ANY_LANGUAGE_ID,
    )
    const elementsRest = languagesFiltered.filter(
      (el) => el.id !== NO_VOCAL && el.id !== ANY_LANGUAGE_ID,
    )
    return [...elementsToMove, ...elementsRest]
  },
)
