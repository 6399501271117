import TagManager from 'react-gtm-module'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const creatorReviewAvailableSongs = ({
  userID,
  reviewId,
  campaignId,
  trackId,
}: {
  userID: number | string
  reviewId: number | string
  campaignId?: number | string
  trackId?: number | string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      userID,
      event: EventTypeEnum.CreatorReviewAvailableSongs,
      event_name: EventNameEnum.CreatorReviewAvailableSongs,
      'account-type': 'tiktok_creator',
      'campaign-platform': 'tiktok',
      is_feed: false,
      'review-id': reviewId,
      'campaign-id': campaignId,
      'track-id': trackId,
    },
  })
}

export const creatorAcceptedTrack = ({
  userID,
  reviewId,
  campaignId,
  trackId,
  amountPaid,
}: {
  userID: number | string
  reviewId: number | string
  campaignId?: number | string
  trackId?: number | string
  amountPaid?: number | string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      userID,
      event: EventTypeEnum.CreatorAcceptedTrack,
      event_name: EventNameEnum.CreatorAcceptedTrack,
      'account-type': 'tiktok_creator',
      'campaign-platform': 'tiktok',
      is_feed: false,
      'review-id': reviewId,
      'campaign-id': campaignId,
      'track-id': trackId,
      amount_paid: amountPaid,
    },
  })
}

export const creatorCancelledTask = ({
  userID,
  reviewId,
  campaignId,
  trackId,
  amountPaid,
}: {
  userID: number | string
  reviewId: number | string
  campaignId?: number | string
  trackId?: number | string
  amountPaid?: number | string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      userID,
      event: EventTypeEnum.CreatorCancelledTask,
      event_name: EventNameEnum.CreatorCancelledTask,
      'account-type': 'tiktok_creator',
      'campaign-platform': 'tiktok',
      is_feed: false,
      'review-id': reviewId,
      'campaign-id': campaignId,
      'track-id': trackId,
      amount_paid: amountPaid,
    },
  })
}

export const creatorUploadedVideo = ({
  userID,
  reviewId,
  campaignId,
  trackId,
  amountPaid,
}: {
  userID: number | string
  reviewId: number | string
  campaignId?: number | string
  trackId?: number | string
  amountPaid?: number | string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      userID,
      event: EventTypeEnum.CreatorUploadedVideo,
      event_name: EventNameEnum.CreatorUploadedVideo,
      'account-type': 'tiktok_creator',
      'campaign-platform': 'tiktok',
      is_feed: false,
      'review-id': reviewId,
      'campaign-id': campaignId,
      'track-id': trackId,
      amount_paid: amountPaid,
    },
  })
}

export const creatorPayoutRequested = ({
  userID,
  payoutAmount,
  payoutRequestId,
}: {
  userID: number | string
  payoutAmount?: number | string
  payoutRequestId?: number | string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CreatorPayoutRequested,
      event_name: EventNameEnum.CreatorPayoutRequested,
      'account-type': 'creator',
      userID,
      ...(payoutAmount ? { payout_amount: payoutAmount } : {}),
      ...(payoutRequestId ? { payout_request_id: payoutRequestId } : {}),
    },
  })
}
