import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

interface ReviewedTrackCommentProps {
  comment?: string
}

const StyledReviewedTrackComment = styled.div`
  overflow-wrap: break-word;
`
const TrackDetailsTitle = styled.div`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
`
const TrackDetailsRowInfo = styled.div<{ color?: string }>`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`
export const ReviewedTrackComment: FC<ReviewedTrackCommentProps> = ({
  comment,
}) => {
  const { t } = useTranslation()
  return (
    <StyledReviewedTrackComment>
      <TrackDetailsTitle>{t('reviewsPage.curatorReview')}</TrackDetailsTitle>
      <TrackDetailsRowInfo>{comment}</TrackDetailsRowInfo>
    </StyledReviewedTrackComment>
  )
}
