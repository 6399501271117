import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { CuratorTracksState } from '.'

const getState = (state: RootState): CuratorTracksState => state.curatorTracks

export const getAwaitingReviewTracks = createDraftSafeSelector(
  getState,
  ({ awaitingReviewTracks }) => awaitingReviewTracks,
)
export const getAwaitingReviewTracksCount = createDraftSafeSelector(
  getState,
  ({ awaitingReviewTracksCount }) => awaitingReviewTracksCount,
)
export const getSelectedReviewValues = createDraftSafeSelector(
  getState,
  ({ selectedReviewValues }) => selectedReviewValues,
)
