import { FunctionComponent, ReactElement, useEffect } from 'react'

import { useSelector } from 'react-redux'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import {
  getCreatorTransactions,
  getTransactionsDetails,
} from 'src/Redux/transactions-process/api-actions'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'
import { useAppDispatch } from 'src/Hooks/redux'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

import { InfluencerBalanceTitle } from './InfluencerBalanceTitle'
import { InfluencerBalanceTopBlock } from './InfluencerBalanceTopBlock'
import { InfluencerBalanceTable } from './InfluencerBalanceTable'
import { InfluencerBlockedError } from './InfluencerBlockedError/InfluencerBlockedError'

import { Container, PageContainer } from './styles'

const InfluencerBalancePage: FunctionComponent = (): ReactElement => {
  const dispatch = useAppDispatch()

  const user = useSelector(getUser)

  useEffect(() => {
    if (user) {
      pageViewEvent(user, pageInfo.balance)
      locationEvent()
    }
  }, [user])

  useEffect(() => {
    void dispatch(getCreatorTransactions(1))
    void dispatch(getTransactionsDetails())
  }, [dispatch])

  return (
    <PageContainer>
      <Container>
        <InfluencerBalanceTitle />
        <InfluencerBlockedError />

        <InfluencerBalanceTopBlock />

        <InfluencerBalanceTable />
      </Container>
    </PageContainer>
  )
}
export default InfluencerBalancePage
