import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const TrackAlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`
export const TrackAlertDescription = styled.div``

export const ContactSupportBtn = styled.button`
  color: ${colors.mainText} !important;
  border-bottom: 1px dashed ${colors.mainText};
  text-decoration: none;
  text-align: center;
  background: transparent;
  cursor: pointer;
  padding: 0;
  height: 20px;

  &:hover {
    color: ${colors.mainText};
  }
`
export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 25px;

  @media ${device.mobileMin} {
    flex-direction: row;
    row-gap: unset;
    align-items: center;
    justify-content: space-between;
  }
`
export const AlertTitle = styled.div`
  line-height: ${variables.lineHeight150per};
  font-weight: ${variables.fontWeight600};
`
