import React, { FunctionComponent } from 'react'

import Danger from 'src/Assets/Svg/Danger.svg?react'

import { SearchingTrackDropdownProps } from './SearchingTrackDropdownProps'

import { WrapperRelative, SearchingRelative } from './styles'
import { CampaignPlatformTypeEnum } from 'src/Types'

export const SearchingTrackDropdown: FunctionComponent<
  SearchingTrackDropdownProps
> = ({ text, error, platform }) => (
  <WrapperRelative
    error={error}
    platform={platform}
    isAbsolutePosition={Boolean(platform === CampaignPlatformTypeEnum.SPOTIFY)}
  >
    {error && <Danger />}

    <SearchingRelative
      error={error}
      platform={platform}
      isAbsolutePosition={Boolean(
        platform === CampaignPlatformTypeEnum.SPOTIFY,
      )}
    >
      {text}
    </SearchingRelative>
  </WrapperRelative>
)
