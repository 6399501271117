import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatDate } from 'src/Constants/formatDate'
import { calculateDaysLeft } from 'src/Constants/functions'
import dayjs from 'src/Helpers/dayjs'
import { colors } from 'src/Styled/variables'
import { getTiktokCampaignDetails } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'

import { DetailsCampaignStatus } from '../DetailsCampaignStatus'

import {
  TikTokDetailsCampaignStatusWrapper,
  StyledProgress,
  DaysInfo,
  DateInfo,
  TikTokDetailsCampaignStatusContainer,
} from './styles'

const HUNDRED_PERCENT = 100
const MIN_DAYS_LEFT = 4

export const CAMPAIGN_PERIOD = 14
export const DetailsProgress: FC = () => {
  const { t } = useTranslation()
  const campaignDetails = useSelector(getTiktokCampaignDetails)
  const daysLeftMemo = useMemo(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const days = calculateDaysLeft(campaignDetails?.endDate || '')

    const daysLeftText =
      days === 1
        ? `${days} ${t('campaignsPage.dayLeft')}`
        : `${days} ${t('campaignsPage.daysLeft')}`

    const percentageGraph = (days * HUNDRED_PERCENT) / CAMPAIGN_PERIOD
    const displayedDate = dayjs(campaignDetails?.startDate)
      .tz(timezone)
      .format(formatDate.formatDateMonthFull)

    return { daysLeftText, days, percentageGraph, displayedDate }
  }, [campaignDetails?.endDate, t, campaignDetails?.startDate])

  return (
    <TikTokDetailsCampaignStatusWrapper>
      <TikTokDetailsCampaignStatusContainer>
        <DetailsCampaignStatus campaignStatus={campaignDetails?.status || ''} />
        <DaysInfo>{daysLeftMemo.daysLeftText}</DaysInfo>
      </TikTokDetailsCampaignStatusContainer>

      <StyledProgress
        percent={daysLeftMemo.percentageGraph}
        steps={14}
        size={176}
        strokeWidth={4}
        showInfo={false}
        strokeColor={
          daysLeftMemo.days >= MIN_DAYS_LEFT
            ? colors.green
            : colors.messegesYellow1
        }
        trailColor={colors.greyTextBlackBg}
        status='active'
      />
      <DateInfo>{`${t('campaignResultsPage.startedAt')} ${
        daysLeftMemo.displayedDate
      } `}</DateInfo>
    </TikTokDetailsCampaignStatusWrapper>
  )
}
