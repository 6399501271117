import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  getAllLanguages,
  getLanguagesForExistingPlaylists,
} from './api-actions'

export interface LanguageItem {
  id: number
  language_name: string
  playlists_count?: number
}

export interface PlaylistsLanguages {
  popular: LanguageItem[]

  all: LanguageItem[]
}

export interface LanguageState {
  languages: Array<LanguageItem>
  existing_playlists_languages: PlaylistsLanguages
  loading: boolean
  error: ApiError | null
}

const initialState: LanguageState = {
  languages: [],
  existing_playlists_languages: {
    all: [],
    popular: [],
  },
  loading: false,
  error: null,
}

export const languagesProcess = createSlice({
  initialState,
  name: NameSpace.Languages,
  reducers: {},
  extraReducers: (builder) => {
    // getAllLanguages
    builder.addCase(getAllLanguages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllLanguages.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(getAllLanguages.fulfilled, (state, action) => {
      state.languages = action.payload
      state.error = null
      state.loading = false
    })

    // getLanguagesForExistingPlaylists
    builder.addCase(getLanguagesForExistingPlaylists.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getLanguagesForExistingPlaylists.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
    builder.addCase(
      getLanguagesForExistingPlaylists.fulfilled,
      (state, action) => {
        state.existing_playlists_languages = action.payload
        state.error = null
        state.loading = false
      },
    )
  },
})
