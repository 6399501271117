import { useExistingBillingAgreement } from 'src/Hooks/useExistingBillingAgreement'
import { useSidebarVisibility } from 'src/Hooks/useSidebarVisibility'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltipTimer'
import { useCheckoutTimerFeatureFlag } from 'src/Hooks/useCheckoutTimerFeratureFlag'

import { useScheduleInitApiCalls } from '../ScheduleSpotifyWrapper/helpers/useSpotifyScheduleInitApiCalls'

import { useCheckTikTokCampaignStatus } from './helpers/useCheckTikTokCampaignStatus'
import { useDefaultTikTokCampaignStartDate } from './helpers/useDefaultTikTokCampaignStartDate'
import { useBackFromPaymentPageTikTok } from './helpers/useBackFromPaymentPageTikTok'
import { useScheduleStartEvent } from './helpers/useScheduleStartEvent'

export const useScheduleTikTokCampaign = (): void => {
  useSidebarVisibility()
  useExistingBillingAgreement()
  useCheckTikTokCampaignStatus()
  useDefaultTikTokCampaignStartDate()
  useBackFromPaymentPageTikTok()
  useScheduleStartEvent()
  useScheduleInitApiCalls()

  // checkout timer experiment
  useBonusTooltipTimer()
  useCheckoutTimerFeatureFlag()
}
