import { FC } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import ReactLoading from 'react-loading'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { useAppSelector } from 'src/Hooks/redux'
import { colors } from 'src/Styled/variables'
import { campaignsList, details } from 'src/Router/routes'
import {
  getIsVideoLoading,
  getVideo,
} from 'src/Redux/campaignVideo-process/video-process/selectors'

import { TiktokReviewHeader } from '../TiktokReviewHeader'
import { TiktokReviewParams } from '../..'
import { RateContainer } from '../RateConteiner'
import { TiktokRateError } from '../TiktokRateError'
import { CreatorInfo } from '../CreatorInfo'
import { TikTokPlayer } from '../TiktokPlayer'

import {
  LoaderWrapper,
  TiktokReviewMobileWrapper,
  StyledBackground,
  CloseButton,
  RateWrapper,
  CreatorInfoWrapper,
} from './styles'

export const TiktokReviewMobile: FC = () => {
  const navigate = useNavigate()
  const { campaignID } = useParams<keyof TiktokReviewParams>()
  const isLoading = useAppSelector(getIsVideoLoading)
  const video = useAppSelector(getVideo)

  const handleBack = (): void => {
    if (campaignID) {
      navigate(`${campaignsList}${details}/${campaignID}`)
    }
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <ReactLoading
          color={colors.badgeGreen}
          width={'50px'}
          height={'50px'}
          type='spin'
          data-testid='loader'
        />
      </LoaderWrapper>
    )
  }
  return (
    <FullScreenModal showTopbar onCancel={handleBack} open prefixCls='genres'>
      <TiktokReviewMobileWrapper>
        <div>
          <TiktokReviewHeader />
          <CloseButton onClick={handleBack}>
            <CloseCross width='16px' height='16px' />
          </CloseButton>
        </div>
        <TiktokRateError />
        <RateWrapper>
          <RateContainer />
        </RateWrapper>
        <StyledBackground>
          <TikTokPlayer url={video?.videoLink || ''} />
        </StyledBackground>
        <CreatorInfoWrapper>
          <CreatorInfo />
        </CreatorInfoWrapper>
      </TiktokReviewMobileWrapper>
    </FullScreenModal>
  )
}
