import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { useAppSelector } from 'src/Hooks/redux'
import { ErrorCode } from 'src/Redux/types'
import { getVideoRateError } from 'src/Redux/campaignVideo-process/videoRate-process/selectors'
import { VideoStatus } from 'src/Constants/enums'
import { getVideo } from 'src/Redux/campaignVideo-process/video-process/selectors'

import { AlertTitle } from './styles'

export const TiktokRateError: FC = () => {
  const error = useAppSelector(getVideoRateError)
  const { t } = useTranslation()
  const video = useAppSelector(getVideo)

  if (video?.status === VideoStatus.DECLINED) {
    return (
      <Alert smallGap color='danger' icon unclosable>
        <AlertTitle>{t('campaignResultsPage.videoRejected')}</AlertTitle>
        <div>{t('campaignResultsPage.videoRejectedDesc')}</div>
      </Alert>
    )
  }

  if (error?.code === ErrorCode.Conflict) {
    return (
      <Alert smallGap color='danger' icon unclosable>
        <div>{t('campaignResultsPage.videoAlreadyRated')}</div>
      </Alert>
    )
  }
  if (error?.code === ErrorCode.InternalServerError) {
    return (
      <Alert smallGap color='danger' icon unclosable>
        <div>{t('campaignResultsPage.oops')}</div>
      </Alert>
    )
  }
  return null
}
