import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import { CuratorTrack, getCuratorTracks } from './api-actions'

export interface SelectedReviewValues {
  price?: number
  level?: number
}

export interface CuratorTracksState {
  awaitingReviewTracks: Array<CuratorTrack>
  awaitingReviewTracksCount: number
  selectedReviewValues: SelectedReviewValues | null

  loading: boolean
  error: ApiError | null
}

const initialState: CuratorTracksState = {
  awaitingReviewTracks: [],
  awaitingReviewTracksCount: 0,
  selectedReviewValues: null,

  loading: false,
  error: null,
}

export const curatorTracks = createSlice({
  initialState,
  name: NameSpace.CURATOR_TRACKS,
  reducers: {
    setReviewValues: (
      state,
      data: PayloadAction<SelectedReviewValues | null>,
    ): void => {
      state.selectedReviewValues = data ? data.payload : null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCuratorTracks.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCuratorTracks.fulfilled, (state, action) => {
      state.loading = false
      state.awaitingReviewTracks = action.payload.data
      state.awaitingReviewTracksCount = action.payload.meta.itemCount
    })
    builder.addCase(getCuratorTracks.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
  },
})

export const { setReviewValues } = curatorTracks.actions
