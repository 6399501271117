import { FC } from 'react'

import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import styled from 'styled-components'
import { DatePickerProps } from 'antd/lib'
import { variables } from 'src/Styled/variables'

const AntdDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)
type Props = DatePickerProps
const CustomScheduleDatePicker: FC<Props> = ({ className, ...rest }) => (
  <AntdDatePicker {...rest} popupClassName={className} />
)
export const ScheduleDatePicker = styled(CustomScheduleDatePicker)`
  z-index: 1000 !important;
  @media (max-width: 1279px) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .ant-picker-header {
    border-bottom: transparent;
    padding: 8px 16px 8px 8px;
  }

  .ant-picker-header-view {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto auto;
    button {
      font-size: ${variables.fontSize14};
      font-style: normal;
      font-weight: ${variables.fontWeight400};
      line-height: 150%;
    }
  }
  .ant-picker-body {
    padding-top: 0px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .ant-picker-cell {
    &.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    &.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
      display: none;
    }

    &.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover
      .ant-picker-cell-inner::after,
    &.ant-picker-cell-in-view.ant-picker-cell-in-range
      .ant-picker-cell-inner::after {
      display: none;
    }

    &:not(.ant-picker-cell-in-view) {
      display: none;
    }
  }
  .ant-picker-footer {
    border-top: transparent;
  }
  .ant-picker-cell {
    padding: 0;
  }
`
