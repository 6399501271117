import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

export const Message = styled.div`
  line-height: 28px;
  flex: 4 4;
  margin-right: 150px;
`
export const StyledDate = styled.p`
  position: absolute;
  bottom: -14px;
  margin-bottom: 20px;
  right: 16px;
  font-size: 14px;
  color: #787985;
  @media screen and (min-width: 992px) {
    right: 34px;
    margin-bottom: 0;
    bottom: 1px;
  }
`
export const Accent = styled.span`
  color: #1db954;
`
export const Name = styled.span`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: #1db954;
`
export const MessageHeader = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  display: block;
  a {
    color: #1db954;
  }
`
