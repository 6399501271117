import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { createBillingAgreement } from 'src/Redux/billingAgreement-process/create-billingAgreement/api-action'
import {
  getBillingAgreementIdSelector,
  hasBillingAgreementSelector,
} from 'src/Redux/billingAgreement-process/get-billingAgrreement'
import { getHasActiveSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { subscription } from 'src/Router/routes'
import { PaymentMethodEnum } from 'src/Constants/enums'

import { useCreateSubscription } from './useCreateSubscription'
import { useChangeSubscription } from './useChangeSubscription'

interface UsePaySubscription {
  onPayPaypal: () => void
  hasBillingAgreement: boolean
}

export const usePayPaypalSubscription = (): UsePaySubscription => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()
  const hasActiveSubscriptionSelector = useAppSelector(
    getHasActiveSubscriptionSelector,
  )
  const hasBillingAgreement = useAppSelector(hasBillingAgreementSelector)
  const userPaymentMethodId = useAppSelector(getBillingAgreementIdSelector)
  const packageId = Number(search.get('packageId'))
  const price = Number(search.get('price'))
  const cashback = Number(search.get('cashback'))
  const plan = search.get('plan')
  const autopay = search.get('autopay')

  const [create] = useCreateSubscription()
  const [change] = useChangeSubscription()

  useEffect(() => {
    if (autopay) {
      create(PaymentMethodEnum.PAYPAL, userPaymentMethodId)
    }
  }, [autopay, create, userPaymentMethodId])

  const onPayPaypal = (): void => {
    if (!packageId || !price || !plan) {
      return
    }
    if (!hasBillingAgreement) {
      void dispatch(
        createBillingAgreement({
          cancelUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&cashback=${cashback}&plan=${plan}&paymentType=${PaymentMethodEnum.PAYPAL}&error=save_token_error`,
          successUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&cashback=${cashback}&plan=${plan}&paymentType=${PaymentMethodEnum.PAYPAL}&autopay=true`,
        }),
      )
        .unwrap()
        .then((res) => {
          window.location.href = res.link
          return res
        })
        .catch(() => {
          navigate(
            `${subscription}/pay?packageId=${packageId}&plan=${plan}&price=${price}&paymentType=${PaymentMethodEnum.PAYPAL}&error=subscription_error`,
          )
        })
      return
    }
    if (hasActiveSubscriptionSelector) {
      change(PaymentMethodEnum.PAYPAL, userPaymentMethodId)
    } else {
      create(PaymentMethodEnum.PAYPAL, userPaymentMethodId)
    }
  }
  return { onPayPaypal, hasBillingAgreement }
}
