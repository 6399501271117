import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import LockIcon from 'src/Assets/Svg/lock.svg?react'
import Button from 'src/Components/Buttons/Button'
import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useCheckoutButton } from 'src/Hooks/useCheckoutButton'
import { getIsCreditsMethod } from 'src/Redux/payment-process/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { BonusTooltip } from 'src/Components/BonusTooltip'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'

import {
  BonusAlertWrapper,
  ButtonsBlockButtonsContainer,
  ContinueButtonWrapper,
  PromoCodeButton,
  SecureContainer,
  SecureLabel,
} from './styles'

export const ButtonsBlock: FC<{
  onClickPromoCode: () => void
  platformType: CampaignPlatformTypeEnum
}> = ({ onClickPromoCode, platformType }) => {
  const { t } = useTranslation()
  const isCreditsMethod = useAppSelector(getIsCreditsMethod)

  const { isLoading, buttonType, onClickContinue, text } =
    useCheckoutButton(platformType)

  const coupon = useAppSelector(getCoupon)
  const { tooltipValues, isNextTimeBonus } = useBonusTooltipTimer()
  const isNotMobile = useMediaQuery({ minWidth: 768 })

  return (
    <>
      {!isCreditsMethod && !coupon && (
        <PromoCodeButton onClick={onClickPromoCode}>
          +&nbsp;{t('scheduleCampaignPage.promocode')}
        </PromoCodeButton>
      )}

      <ButtonsBlockButtonsContainer>
        {/* Checkout timer experiment */}
        {isNextTimeBonus && (
          <BonusAlertWrapper>
            <BonusTooltip
              description={tooltipValues.description}
              position={'bottom-center'}
            />
          </BonusAlertWrapper>
        )}
        <ContinueButtonWrapper>
          <Button
            type={buttonType}
            loading={isLoading}
            onClick={onClickContinue}
          >
            {text}
          </Button>
          {isNotMobile && (
            <SecureContainer>
              <LockIcon width='16px' height='16px' />
              <SecureLabel>{t('scheduleCampaignPage.ssl')}</SecureLabel>
            </SecureContainer>
          )}
        </ContinueButtonWrapper>
      </ButtonsBlockButtonsContainer>
    </>
  )
}
