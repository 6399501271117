import React, { FC } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { FullScreenModal } from 'src/Components/FullScreenModal'
import { TextArea } from 'src/Containers/ui/TextArea'
import Button from 'src/Components/Buttons/Button'
import { MAX_COMMENT_LENGTH } from 'src/Constants/numeric'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { updateTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { mapScheduledSpotifyCampaignForUpdate } from 'src/Containers/ScheduleTiktokWrapper/helpers/mapScheduledSpotifyCampaignForUpdate'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { CommentFormValues, commentSchema } from './helpers'

import {
  SubPageTitle,
  FooterContainer,
  Container,
  Description,
  Example,
  ExampleTitle,
  ExampleText,
  TextareaContainer,
  TitleContainer,
  CommentModalWrapper,
  CloseButton,
} from './styles'

export const CommentModal: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const { setSearch } = useCustomSearchParams()
  const handleClose = (): void => {
    setSearch({ modal: null })
  }

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<CommentFormValues>({
    mode: 'onChange',
    values: { comment: scheduledCampaign.comment || '' },
    resolver: yupResolver(commentSchema),
  })

  const onSubmit = ({ comment }: CommentFormValues): void => {
    const mappedCampaign = mapScheduledSpotifyCampaignForUpdate({
      ...scheduledCampaign,
      comment,
    })

    void dispatch(updateTikTokCampaign(mappedCampaign))
      .unwrap()
      .then((res) => {
        handleClose()
        return res
      })
  }

  return (
    <FullScreenModal showTopbar open onCancel={handleClose} prefixCls='comment'>
      <CommentModalWrapper translate='no'>
        <TitleContainer>
          <SubPageTitle>
            {t('scheduleCampaignPage.commentToCreators')}
          </SubPageTitle>

          <CloseButton onClick={handleClose}>
            <CloseCross />
          </CloseButton>
        </TitleContainer>
        <Container onSubmit={handleSubmit(onSubmit)}>
          <Description>
            {t('scheduleCampaignPage.commentDescription')}
          </Description>
          <TextareaContainer>
            <Controller
              control={control}
              name='comment'
              render={({ field }) => (
                <TextArea
                  {...field}
                  role='textarea'
                  maxLength={MAX_COMMENT_LENGTH}
                  autoSize={{ minRows: 4 }}
                  showCount={{
                    formatter: (count) =>
                      count.value ? `${count.count}/${MAX_COMMENT_LENGTH}` : '',
                  }}
                  style={{
                    resize: 'none',
                    backgroundColor: 'transparent',
                    borderRadius: '4px',
                    border: '1px solid  #EEEEEE',
                    padding: '4px',
                    lineHeight: '150%',
                  }}
                  prefixCls='schedule-comment'
                />
              )}
            />
            <Example>
              <ExampleTitle>{t('scheduleCampaignPage.example')}</ExampleTitle>
              <ExampleText>
                {t('scheduleCampaignPage.exempleDescription')}
              </ExampleText>
            </Example>
          </TextareaContainer>
          <FooterContainer>
            <Button htmlType='submit' disabled={!isValid} type='green'>
              {t('scheduleCampaignPage.addComment')}
            </Button>
          </FooterContainer>
        </Container>
      </CommentModalWrapper>
    </FullScreenModal>
  )
}
