import TagManager from 'react-gtm-module'

import { User } from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const loginEvent = (user: Partial<User>): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.Login,
      userID: user.id,
      'User-first-name': user.first_name,
      'registration-date': user.createdAt,
      'account-type': user.role,
      'user-role': user.role,
    },
  })
}

export const logOutEvent = (user: User): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.LogOut,
      userID: user.id,
      'User-first-name': user.first_name,
      'registration-date': user.createdAt,
      'account-type': user.role,
      'user-role': user.role,
    },
  })
}

export const signupToLogin = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_name: EventNameEnum.LoginForm,
    },
  })
}
export const quickLogin = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_name: EventNameEnum.QuickLogin,
    },
  })
}
export const signupLoginError = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_name: EventNameEnum.SignupLoginError,
    },
  })
}
export const trackAddedExp = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_name: EventNameEnum.TrackAdded,
    },
  })
}
export const viewSignUpPopUpExp = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_name: EventNameEnum.ViewSignUpPopUp,
    },
  })
}
export const signupErrorToLogin = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_name: EventNameEnum.SignupLoginToError,
    },
  })
}

export const signUpEvent = (role: string): void => {
  const tagManagerLocationArgs = {
    dataLayer: {
      originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
    },
  }

  const tagManagerArgs = {
    dataLayer: {
      event: EventTypeEnum.PageView,
      pageTitle: 'Sign Up',
      pagePath: `https://app.soundcamps.com/signup-${role}`,
    },
  }

  TagManager.dataLayer(tagManagerLocationArgs)
  TagManager.dataLayer(tagManagerArgs)
}
