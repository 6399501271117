import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { useAppSelector } from 'src/Hooks/redux'
import { getBudgetRangeError } from 'src/Redux/schedule-process/budgetRangeSlice/selectors'
import { msgLowReachReceived } from 'src/Helpers/TagManager/ScheduleEvents'
import {
  getUserCampaignTypeSelector,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import {
  getAmountOfCampaigns,
  getCampaignToSchedule,
  getGenresListSelector,
  getLanguagesListSelector,
} from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'

export const useScheduleGenres = (): void => {
  const budgetRangeError = useAppSelector(getBudgetRangeError)
  const userId = useSelector(getUserId)
  const scheduledSpotifyCampaign = useSelector(getCampaignToSchedule)
  const genreList = useSelector(getGenresListSelector)
  const languageList = useSelector(getLanguagesListSelector)
  const campaignType = useSelector(getUserCampaignTypeSelector)
  const amountOfCampaigns = useSelector(getAmountOfCampaigns)

  useEffect(() => {
    if (budgetRangeError) {
      msgLowReachReceived({
        genreList,
        languageList,
        type: campaignType,
        campaignId: String(scheduledSpotifyCampaign.id),
        userId: String(userId),
        campaignNumber: amountOfCampaigns,
      })
    }
  }, [
    budgetRangeError,
    campaignType,
    scheduledSpotifyCampaign.genres,
    scheduledSpotifyCampaign.id,
    scheduledSpotifyCampaign.languages,
    userId,
    amountOfCampaigns,
    genreList,
    languageList,
  ])
}
