import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { Description, InfoWrapper, Title } from '../../styles'

export const FollowInfo: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <InfoWrapper>
      <Title>{t('soundsPage.followTask')}</Title>
      <Description>{t('soundsPage.showcaseText')}</Description>
    </InfoWrapper>
  )
}
