import { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  getIsPriceReceived,
  setScheduleStartEventSent,
} from 'src/Redux/analytics-process'
import { useAppDispatch } from 'src/Hooks/redux'
import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { getScheduleRedirectUrl } from 'src/Containers/App/helpers'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

export const useScheduleStartEvent = (): void => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const campaignId = Number(params.number)
  const { search } = useCustomSearchParams()
  const query = getScheduleRedirectUrl(search)
  const param = new URLSearchParams(query)
  const triggerSource = param.get('trigger_source')

  const { handleScheduleStartEvent } = useScheduleEvents()
  const campaignDetails = useSelector(getCampaignToSchedule)
  const isScheduleStartEventSent = useSelector(getIsPriceReceived)

  useEffect(() => {
    if (!campaignDetails) {
      return
    }

    if (
      (triggerSource === 'email-reminder' ||
        triggerSource === 'email-upsell1' ||
        triggerSource === 'SMS-reminder') &&
      !isScheduleStartEventSent
    ) {
      handleScheduleStartEvent({
        campaignType: campaignDetails.type || CampaignTypeEnum.BASIC,
        platformName: CampaignPlatformTypeEnum.SPOTIFY,
        campaignId: Number(campaignId),
        genreList:
          campaignDetails.genres?.map((genre) => genre.name).join('') || '',
        languageList:
          campaignDetails.languages
            ?.map((language) => language.name)
            .join('') || '',
      })
      dispatch(setScheduleStartEventSent(true))
    }
  }, [
    dispatch,
    campaignDetails,
    triggerSource,
    isScheduleStartEventSent,
    handleScheduleStartEvent,
    campaignId,
  ])
}
