import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const DateWrapper = styled.div`
  display: grid;
  max-width: 140px;
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`
export const CampaignType = styled.div`
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
  margin-bottom: 8px;
`
