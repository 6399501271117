import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const TiktokReviewMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
`
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`

export const StyledBackground = styled.div`
  background-color: ${colors.white};
  display: grid;
  justify-content: center;
  border-radius: 4px;
`

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0.5;
  transition: all 0.2s;
  border-radius: 4px;

  &:hover {
    background-color: rgba(204, 204, 204, 0.2);
    opacity: 1;
  }
  &:visited {
    background-color: rgba(204, 204, 204, 0.3);
  }
`

export const RateWrapper = styled.div`
  padding: 16px;
`

export const CreatorInfoWrapper = styled.div`
  padding: 16px;
`
