import { FC, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { colors, device, variables } from 'src/Styled/variables'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { CampaignPlatformTypeEnum, TikTokTrack, Track } from 'src/Types'

import { IconComponent } from '../IconComponent'
import { HowToGetYourTrackURLButton } from '../HowToGetYourTrackURLButton'

import { SearchTrackFormResult } from './SearchTrackFormResult'
import { SearchTrackFormMessages } from './SearchTrackFormMessages'
import { SearchFormInput } from './SearchFormInput'

interface SearchTrackFormProps {
  placeholderText: string
  loading?: boolean
  errorMessage?: string
  onSubmit: (data: SearchTrackFormDataProps) => void
  tracks: TikTokTrack[] | Track[]
  platform: CampaignPlatformTypeEnum
  onClearTracks: () => void
  onCheckTrack: (url: string) => void
  onClickBack?: () => void
  isFAQ?: boolean
  show?: boolean
}

export interface SearchTrackFormDataProps {
  searchInput: string
}

interface Props {
  isErrorOnMobile?: boolean
  isSame?: boolean
  isFAQ?: boolean
  show?: boolean
}

export const StyledTrackSearch = styled.div<Props>`
  background: ${colors.white};
  border-radius: 4px;
  padding: 0px;
  padding-bottom: ${(props: Props) => props.isSame && '72px'};
  @media ${device.mobileMin} {
    padding: 24px;
  }
  ${({ show, isFAQ }) => {
    if (show) {
      return css`
        @media ${device.mobileMax} {
          position: fixed;
          z-index: 1;
          overflow-y: auto;
          top: 0;
          left: 0;
          width: 100%;
          bottom: 0;
          right: 0;
        }
      `
    }
    return css`
      @media ${device.mobileMax} {
        padding: ${isFAQ ? '24px' : '0px'};
      }
    `
  }}
`

export const TrackSearchHeader = styled.div<{ show?: boolean }>`
  ${({ show }) =>
    !show
      ? css`
          display: grid;
          grid-template-columns: 1fr 16px;
        `
      : css`
          display: none;
        `}
  font-size: ${variables.fontSize14};

  @media ${device.mobileMin} {
    display: grid;
    grid-template-columns: 1fr 16px;
    align-items: center;
    font-weight: ${variables.fontWeight400};
    line-height: 150%;
    color: ${colors.mainText};
    margin-bottom: 8px;
    font-size: ${variables.fontSize14};
  }
`
export const SearchForm = styled.form<Props>`
  ${({ show }) => {
    if (show) {
      return css`
        -webkit-text-size-adjust: none;
        height: 100vh;
        position: relative;
        @media ${device.mobileMin} {
          height: auto;
        }
      `
    }
    return css``
  }}
`

export const HowToGetYourTrackURL = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  line-height: 150%;
  padding: 9.5px 0px;
  margin-top: 21px;
  @media ${device.mobileMin} {
    margin-top: 12px;
  }
`

export const TrackSearchAuth: FC<SearchTrackFormProps> = ({
  placeholderText,
  errorMessage,
  onSubmit,
  tracks,
  platform,
  onCheckTrack,
  onClearTracks,
  loading,
  onClickBack,
  isFAQ,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 567px)' })
  const [isShowFullscreenInput, setShowFullscreenInput] = useState(true)
  const { search, setSearch } = useCustomSearchParams()
  const urlTrackName = search.get('trackName')
  const { control, handleSubmit, setValue } = useForm<SearchTrackFormDataProps>(
    {
      mode: 'onChange',
    },
  )
  const clearInput = (): void => {
    setValue('searchInput', '')
  }

  useEffect(() => {
    if (urlTrackName) {
      setValue('searchInput', urlTrackName)
      void handleSubmit(onSubmit)().then((res) => {
        setSearch({
          trackName: null,
        })
        return res
      })
    }
  }, [handleSubmit, setValue, onSubmit, urlTrackName, setSearch])

  useEffect(() => {
    if (isFAQ && isMobile) {
      setShowFullscreenInput(false)
    }
  }, [isMobile, isFAQ])

  const handleFormClick = (): void => {
    if (isFAQ) {
      setShowFullscreenInput(true)
    }
  }

  return (
    <StyledTrackSearch
      onClick={handleFormClick}
      show={isShowFullscreenInput}
      isFAQ={isFAQ}
      isSame={Boolean(errorMessage)}
    >
      <>
        <TrackSearchHeader show={isShowFullscreenInput}>
          <div>{placeholderText}</div>
          <IconComponent platform={platform} />
        </TrackSearchHeader>

        <SearchForm
          show={isShowFullscreenInput}
          isErrorOnMobile={Boolean(errorMessage)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <>
            <SearchFormInput
              isFAQ={isShowFullscreenInput}
              placeholderText={isShowFullscreenInput ? placeholderText : ''}
              clearInput={clearInput}
              control={control}
              onClickBack={onClickBack}
              onInputChange={(value) => onSubmit({ searchInput: value })}
            />
            {!errorMessage && !loading && (
              <SearchTrackFormResult
                platform={platform}
                tracks={tracks}
                onCheckTrack={onCheckTrack}
                onClearTracks={onClearTracks}
              />
            )}
            <SearchTrackFormMessages
              error={errorMessage}
              loading={loading}
              platform={platform}
            />

            <HowToGetYourTrackURLButton platform={platform} />
          </>
        </SearchForm>
      </>
    </StyledTrackSearch>
  )
}
