import styled from 'styled-components'

import { device, colors } from 'src/Styled/variables'

export const CustomModalUserRegisterModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.greyBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  position: fixed;
  padding-left: 24px;
  padding-right: 24px;

  @media ${device.mobileMax} {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const RegisterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 25px;
`

export const LogoHeader = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 150.07px 1fr;
  justify-items: self-end;
  padding: 8px 16px;
  height: 60px;

  @media ${device.mobileMin} {
    display: grid;
  }
`
export const CloseButton = styled.button`
  padding: 12px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    height: 16px;
    width: 16px;
  }
`

export const TrackInfoAndFormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  width: 100%;
  gap: 24px;
  padding: 0;
  max-width: 800px;
  background-color: unset;

  @media ${device.mobileMax} {
    display: flex;
    flex-direction: column;
  }
`
