import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { tracksFeed } from 'src/Router/routes'
import { FeedDetails } from 'src/Types'

import { useGetTrackDetailsQuery } from '.'

type TractToReview = {
  data: FeedDetails | undefined
  isLoading: boolean
  isError: boolean
  campaignId?: string
}

export const useTrackToReview = (
  refetchOnMountOrArgChange?: boolean,
): TractToReview => {
  const navigate = useNavigate()
  const { campaignId } = useParams<{ campaignId: string }>()
  const { data, isError, isLoading } = useGetTrackDetailsQuery(
    String(campaignId),
    {
      refetchOnMountOrArgChange,
    },
  )

  useEffect(() => {
    if (isError || data?.review_status === 'reviewed') {
      navigate(tracksFeed)
    }
  }, [isError, data?.review_status, navigate])
  return { data, isError, isLoading, campaignId }
}
