import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { variables } from 'src/Styled/variables'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'

export const AlertSubTitle = styled.div``
export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const StyledAlert = styled(Alert)`
  margin-bottom: 0 !important;
  width: 100%;
`

interface ViewsAchievedAlertProps {
  currentViews: number
  minViews: number
  rewardDate: string
}
export const ViewsAchievedAlert: FC<ViewsAchievedAlertProps> = ({
  currentViews,
  minViews,
  rewardDate,
}) => {
  const { t } = useTranslation()

  const formattedMinViews = addSpaceToNumber(minViews, ',')
  const formattedCurrentViews = addSpaceToNumber(currentViews, ',')
  const formattedRewardDate = dayJs(rewardDate).format(formatDate.formatDate1)
  return (
    <StyledAlert color={'success'} icon unclosable={true}>
      <AlertTitle>{`${t(
        'soundsPage.viewsAchieved',
      )} ${formattedCurrentViews} / ${formattedMinViews}`}</AlertTitle>

      <AlertSubTitle>
        {t('soundsPage.viewsAchievedDescription')} ${formattedRewardDate}
      </AlertSubTitle>
    </StyledAlert>
  )
}
