import styled from 'styled-components'
import { Modal } from 'antd'

import { colors, device, variables } from 'src/Styled/variables'

export const StyledModal = styled(Modal)`
  top: 0;

  .ant-modal-content {
    border-radius: 16px;
    padding: 0;
    margin: 0 auto;
    width: 288px;

    @media ${device.tabletMin} {
      width: 600px;
    }
  }

  .ant-modal-header {
    padding: 16.5px 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 0;
  }

  .ant-modal-title {
    color: ${colors.mainText};
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
  }

  .ant-modal-close {
    right: 24px;
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  @media ${device.mobileMin} {
    top: 50px;
  }
  @media ${device.smallScreen} {
    width: 600px !important;
  }
`

export const HowToGetYourTrackURLModalWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 24px;
  @media ${device.tabletMin} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const HowToGetYourTrackURLBlock = styled.div`
  font-size: ${variables.fontSize12};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${colors.mainText};
`

export const HowToGetYourTrackURLImgWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  height: 372px;
  width: 172px;
  margin: 0 auto;
`

export const HowToGetYourTrackURLSubtitle = styled.div`
  font-weight: ${variables.fontWeight600};
  margin-bottom: 4px;
`

export const HowToGetYourTrackURLLists = styled.ul`
  margin-bottom: 16px;
  padding-left: 18px;
`
export const HighlightedPhone = styled.span`
  color: ${colors.badgeRed};
  font-weight: ${variables.fontWeight600};
`
