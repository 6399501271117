import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import {
  CanceledEvent,
  EventDetails,
  Meta,
  InfluencerEventStatus,
  InfluencerEvent,
  CampaignVideos,
} from 'src/Types'

import { ErrorCode, NameSpace } from '../types'

export interface GetTikTokEventData {
  data: Array<InfluencerEvent>
  meta: Meta
}

interface GetTikTokTrackRequest {
  pageNumber: number
  status: Array<InfluencerEventStatus>
  sortBy?: 'videoUploadDate.DESC'
}

// Tiktok Events = TikTok Tracks
export const getTikTokInfluencerEvents = createAsyncThunk<
  GetTikTokEventData,
  GetTikTokTrackRequest
>(
  `${NameSpace.Influencer}/getTikTokInfluencerEvents`,
  async ({ pageNumber, status, sortBy }, { rejectWithValue }) => {
    try {
      const statusesString = status
        .map((value) => `statuses=${encodeURIComponent(value)}`)
        .join('&')

      const sortByString = sortBy ? `&sortBy=${sortBy}` : ''

      const { data } = await api.get<GetTikTokEventData>(
        `${APP.TIKTOK_SERVER}/influencer/tiktok/events?&pageSize=7&pageNumber=${pageNumber}&${statusesString}${sortByString}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>

      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export interface EventsStatistics {
  new: number
  accepted: number
  approved_automatically: number
  approved_by_admin: number
  approved_by_artist: number
  awaiting_moderator_review: number
  awaiting_review: number
  campaign_stopped: number
  canceled: number
  canceled_by_artist: number
  declined: number
  submission_awaiting: number
  submission_rejected: number
}

export const getEventsStatistics = createAsyncThunk<EventsStatistics>(
  `${NameSpace.Influencer}/getEventsStatistics`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<EventsStatistics>(
        `${APP.TIKTOK_SERVER}/influencer/tiktok/events/statistics`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getInProgressTikTokInfluencerEvents = createAsyncThunk<
  GetTikTokEventData,
  { pageNumber: number }
>(
  `${NameSpace.Influencer}/getInProgressTikTokInfluencerEvents`,
  async ({ pageNumber }, { rejectWithValue, dispatch }) => {
    try {
      const inProgressStatuses = [InfluencerEventStatus.ACCEPTED]

      const eventsData = await dispatch(
        getTikTokInfluencerEvents({
          pageNumber,
          status: inProgressStatuses,
        }),
      )

      return unwrapResult(eventsData)
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>

      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getSubmittedTikTokInfluencerEvents = createAsyncThunk<
  GetTikTokEventData,
  { pageNumber: number }
>(
  `${NameSpace.Influencer}/getSubmittedTikTokInfluencerEvents`,
  async ({ pageNumber }, { rejectWithValue, dispatch }) => {
    try {
      const submittedStatuses = [
        InfluencerEventStatus.AWAITING_REVIEW,
        InfluencerEventStatus.AWAITING_MODERATOR_REVIEW,
        InfluencerEventStatus.APPROVED_BY_ARTIST,
        InfluencerEventStatus.APPROVED_BY_ADMIN,
        InfluencerEventStatus.APPROVED_AUTOMATICALLY,
        InfluencerEventStatus.DECLINED,
        InfluencerEventStatus.SUBMISSION_AWAITING,
        InfluencerEventStatus.SUBMISSION_REJECTED,
      ]

      const eventsData = await dispatch(
        getTikTokInfluencerEvents({
          pageNumber,
          status: submittedStatuses,
          sortBy: 'videoUploadDate.DESC',
        }),
      )
      return unwrapResult(eventsData)
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>

      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getNewTikTokInfluencerEvents = createAsyncThunk<
  GetTikTokEventData,
  { pageNumber: number }
>(
  `${NameSpace.Influencer}/getNewTikTokInfluencerEvents`,
  async ({ pageNumber }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetTikTokEventData>(
        `${APP.TIKTOK_SERVER}/influencer/tiktok/new-tracks?&pageSize=7&pageNumber=${pageNumber}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export interface EventsStatistics {
  new: number
  accepted: number
  approved_automatically: number
  approved_by_admin: number
  approved_by_artist: number
  awaiting_moderator_review: number
  awaiting_review: number
  campaign_stopped: number
  canceled: number
  canceled_by_artist: number
  declined: number
}

interface GetCampaignTikTokVideosData {
  data: CampaignVideos
  meta: unknown
}

export const getCampaignTikTokVideos = createAsyncThunk<
  GetCampaignTikTokVideosData,
  string
>(
  `${NameSpace.Influencer}/getCampaignTikTokVideos`,
  async (campaignId, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetCampaignTikTokVideosData>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${campaignId}/events`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getEventById = createAsyncThunk<EventDetails, number>(
  `${NameSpace.Influencer}/getEventById`,
  async (eventId, { rejectWithValue }) => {
    try {
      const { data } = await api.get<EventDetails>(
        `${APP.TIKTOK_SERVER}/influencer/tiktok/events/details/${eventId}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
interface UploadVideoData {
  campaignId: number
  paidAmount: number
  trackId: string
}
interface UploadVideoBody {
  videoTiktokUrl: string
  eventId: number
}
export const uploadVideo = createAsyncThunk<UploadVideoData, UploadVideoBody>(
  `${NameSpace.Influencer}/uploadVideo`,
  async ({ videoTiktokUrl, eventId }, { rejectWithValue }) => {
    try {
      const { data } = await api.put<UploadVideoData>(
        `${APP.TIKTOK_SERVER}/influencer/tiktok/upload-video/${eventId}`,
        {
          videoTiktokUrl,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
export const cancelEvent = createAsyncThunk<CanceledEvent, number>(
  `${NameSpace.Influencer}/cancelEvent`,
  async (eventId, { rejectWithValue }) => {
    try {
      const { data } = await api.post<CanceledEvent>(
        `${APP.TIKTOK_SERVER}/influencer/tiktok/cancel-event/${eventId}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        code: response?.status || ErrorCode.InternalServerError,
        message: response?.data.message,
      })
    }
  },
)

export const acceptTrack = createAsyncThunk<
  { eventId: number; amountPaid: number },
  number
>(
  `${NameSpace.Influencer}/acceptTrack`,
  async (campaignId, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.post<{ eventId: number; amountPaid: number }>(
        `${APP.TIKTOK_SERVER}/influencer/tiktok/accept-new-track/${campaignId}`,
      )
      void dispatch(getEventById(data.eventId))
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        code: response?.status || ErrorCode.InternalServerError,
        message: response?.data.message,
      })
    }
  },
)
