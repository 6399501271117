import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { TiktokCampaignState } from '.'

const getState = (state: RootState): TiktokCampaignState =>
  state.scheduling.tiktok

export const getScheduledTikTokCampaign = createDraftSafeSelector(
  getState,
  ({ campaign }) => campaign,
)

export const getIsErrorTikTokCampaign = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Failed,
)

export const getIsIdleTikTokCampaign = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Idle,
)
