import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'
import { RootState } from 'src/Redux'

import {
  resetPassword,
  sendEmailToConfirmAccount,
  sendEmailToResetPassword,
  sendQuickLoginLink,
} from './api-actions'

export interface EmailPasswordState {
  status: SliceStatus
  error: ApiError | null
}

const initialState: EmailPasswordState = {
  status: SliceStatus.Idle,
  error: null,
}

export const emailPasswordSlice = createSlice({
  initialState,
  name: NameSpace.EmailPassword,
  reducers: {},
  extraReducers(builder) {
    // sendEmailToResetPassword
    builder.addCase(sendEmailToResetPassword.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(sendEmailToResetPassword.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
    })
    builder.addCase(sendEmailToResetPassword.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // sendEmailToConfirmAccount
    builder.addCase(sendEmailToConfirmAccount.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(sendEmailToConfirmAccount.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
    })
    builder.addCase(sendEmailToConfirmAccount.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // sendQuickLoginLink
    builder.addCase(sendQuickLoginLink.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(sendQuickLoginLink.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
    })
    builder.addCase(sendQuickLoginLink.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // resetPassword
    builder.addCase(resetPassword.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
    })
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})

const getState = (state: RootState): EmailPasswordState =>
  state.authentication.emailPassword

export const getIsEmailPasswordLoading = createDraftSafeSelector(
  getState,
  (state) => state.status === SliceStatus.Loading,
)
