import { getCampaignToSchedulePrice } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import {
  getArtistBalance,
  getCountryName,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { getCurrency } from 'src/Redux/auth-process/currencySlice/selectors'
import { currencySymbolConverter } from 'src/Constants/functions'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { FIXED_BY } from 'src/Constants/constants'

import { usePayment } from './usePayment'
import { useAppSelector } from './redux'
import { useBonusTooltipTimer } from './useBonusTooltip'

interface UsePaymentAmountsWithCurrency {
  currencyDiscount: string
  campaignCurrencyBudget: string
  currencyVat: string
  currencyBalance: string
  currencyTotal: string
  isVATCountry: boolean
  dollarTotal?: number
}

export const usePaymentAmountsWithCurrency = (
  platformType: CampaignPlatformTypeEnum,
): UsePaymentAmountsWithCurrency => {
  const { calculatePaymentAmounts } = usePayment()

  const spotifyCampaignPrice = useAppSelector(getCampaignToSchedulePrice)
  const tikTokCampaignPrice = useAppSelector(getTiktokPrice)

  const price =
    platformType === CampaignPlatformTypeEnum.SPOTIFY
      ? spotifyCampaignPrice
      : tikTokCampaignPrice

  const country = useAppSelector(getCountryName)
  const coupon = useAppSelector(getCoupon)
  const balance = useAppSelector(getArtistBalance)
  const { name: currencyName, rate: currencyRate } = useAppSelector(getCurrency)
  const currencySymbol = currencySymbolConverter(currencyName)

  const currentPaymentAmounts = calculatePaymentAmounts({
    price,
    balance,
    country,
    coupon,
  })
  const { tooltipValues } = useBonusTooltipTimer()

  const isVATCountry = Boolean(currentPaymentAmounts?.VATAmount)

  const currencyDiscount = `${currencySymbol} ${Number(
    currentPaymentAmounts?.couponDiscountAmount
      ? currentPaymentAmounts?.couponDiscountAmount * currencyRate
      : 0,
  ).toFixed(FIXED_BY)}`

  const campaignCurrencyBudget = `${currencySymbol} ${
    price ? (price * currencyRate).toFixed(FIXED_BY) : 0
  }`

  const currencyBalance = `${currencySymbol} ${(currentPaymentAmounts?.balanceUseAmount
    ? (currentPaymentAmounts?.balanceUseAmount -
        tooltipValues.currentCampaignValue) *
      currencyRate
    : 0
  ).toFixed(FIXED_BY)}`

  const currencyVat = `${currencySymbol} ${Number(
    currentPaymentAmounts?.VATAmount
      ? (currentPaymentAmounts?.VATAmount * currencyRate).toFixed(FIXED_BY)
      : 0,
  )}`

  const currencyTotal = `${currencySymbol} ${
    currentPaymentAmounts?.total
      ? (currentPaymentAmounts?.total * currencyRate).toFixed(FIXED_BY)
      : (0).toFixed(FIXED_BY)
  }`

  return {
    currencyDiscount,
    campaignCurrencyBudget,
    currencyVat,
    currencyBalance,
    currencyTotal,
    isVATCountry,
    dollarTotal: currentPaymentAmounts?.total,
  }
}
