import { FC, useEffect } from 'react'

import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { Mobile } from 'src/Components/Responsive'
import RefreshIcon from 'src/Assets/Svg/refresh.svg?react'
import {
  getNewTikTokInfluencerEvents,
  getInProgressTikTokInfluencerEvents,
  getSubmittedTikTokInfluencerEvents,
} from 'src/Redux/influencer-process/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { colors } from 'src/Styled/variables'

import { TracksAlerts } from './components/TracksAlerts/TracksAlerts'
import { CreatorDetailsModal } from './components/CreatorDetailsModal'
import { TracksTable } from './components/TracksTable'
import { RoundBtn } from './components/TracksTable/styles'

import { TracksContainer, TracksTitle } from './styles'

const Tracks: FC = () => {
  const { t } = useTranslation()
  const user = useSelector(getUserSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (user) {
      locationEvent()
      pageViewEvent(user, pageInfo.creator)
    }
  }, [user])

  return (
    <TracksContainer>
      <Col lg={12} md={12} xl={12}>
        <Row justify='space-between'>
          <Col md={12}>
            <TracksTitle>{t('influencer.tracksPageTitle')}</TracksTitle>
          </Col>
          <Mobile>
            <Col md={12}>
              <RoundBtn
                onClick={() => {
                  void dispatch(getNewTikTokInfluencerEvents({ pageNumber: 1 }))
                  void dispatch(
                    getInProgressTikTokInfluencerEvents({ pageNumber: 1 }),
                  )
                  void dispatch(
                    getSubmittedTikTokInfluencerEvents({ pageNumber: 1 }),
                  )
                }}
              >
                <RefreshIcon
                  width={16}
                  height={16}
                  color={colors.greyTextBlackBg}
                />
              </RoundBtn>
            </Col>
          </Mobile>
        </Row>
      </Col>
      <TracksAlerts />
      <TracksTable />
      <CreatorDetailsModal />
    </TracksContainer>
  )
}

export default Tracks
