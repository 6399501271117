import { createAsyncThunk } from '@reduxjs/toolkit'

import { APP } from 'src/Configs/App'
import { NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

interface CreateBillingAgreementResponse {
  link: string
}

interface CreateBillingAgreementRequest {
  cancelUrl: string
  successUrl: string
}

export const createBillingAgreement = createAsyncThunk<
  CreateBillingAgreementResponse,
  CreateBillingAgreementRequest
>(
  `${NameSpace.CREATE_BILLING_AGREEMENT}/createBillingAgreement`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await api.post<CreateBillingAgreementResponse>(
        `${APP.TIKTOK_SERVER}/payment/paypal/billing-agreements/tokens`,
        { ...body },
      )
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
