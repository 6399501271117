import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import { PaymentMethodEnum } from 'src/Constants/enums'
import { subscriptionPaymentCompletedEvent } from 'src/Helpers/TagManager'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { changeSubscription } from 'src/Redux/subscription-process/subscription/api-action'
import { subscription } from 'src/Router/routes'

type onChangeSubscription = (paymentType: string, id?: number) => void

export const useChangeSubscription = (): [onChangeSubscription] => {
  const navigate = useNavigate()
  const userId = useAppSelector(getUserId)
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const packageId = Number(search.get('packageId'))
  const price = Number(search.get('price'))
  const cashback = Number(search.get('cashback'))
  const plan = search.get('plan')

  const handleChangeSubscription = useCallback(
    (paymentType: string, id?: number) => {
      if (!packageId || !price || !plan || !id) {
        return
      }
      void dispatch(
        changeSubscription({
          cashback,
          packageId,
          paymentType,
          userPaymentMethodId: id,
          paymentAmount: price,
          fullPaymentAmount: price,
          packageName: plan,
        }),
      )
        .unwrap()
        .then((res) => {
          navigate(`${subscription}/pay/success`)
          subscriptionPaymentCompletedEvent({
            userId,
            planName: plan,
            paymentMethod:
              paymentType === PaymentMethodEnum.PAYPAL
                ? 'PayPal_saved'
                : 'Stripe_saved',
          })
          return res
        })
        .catch(() => {
          navigate(
            `${subscription}/pay?packageId=${packageId}&plan=${plan}&price=${price}&paymentType=${paymentType}&error=subscription_error`,
          )
        })
    },
    [cashback, dispatch, navigate, packageId, plan, price, userId],
  )
  return [handleChangeSubscription]
}
