import { FunctionComponent } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
  RelaunchInvokePlacement,
  SpotifyCampaign,
} from 'src/Types/index'
import TiktokIcon from 'src/Assets/Svg/tiktok.svg?react'
import IconArrow from 'src/Assets/Svg/arrow.svg?react'
import { campaignsList, createCampaign } from 'src/Router/routes'
import { useAppDispatch } from 'src/Hooks/redux'
import { createRelaunchCampaign } from 'src/Redux/campaign-process/mainOldProcessSlice/api-actions'
import { updateScheduledCampaign } from 'src/Redux/schedule-process/spotifyCampaignSlice'
import { useRelaunchEvent } from 'src/Hooks/useRelaunchEvent'
import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'
import {
  hasPayedTikTokCampaigns,
  useMetadataQuery,
} from 'src/Redux/metadataApi'
import { useUpsellButtonEvent } from 'src/Hooks/useUpsellButtonEvent'

import RelaunchButtonBlock from '../RelaunchButtonBlock'

import {
  BackButton,
  PageTitle,
  PageTitleWrapper,
  PageTitleContainer,
  CreateCampaignButton,
} from './styles'

const CampaignResultsTitle: FunctionComponent = () => {
  const { t } = useTranslation()
  const { data } = useMetadataQuery()
  const isTiktokPayed = hasPayedTikTokCampaigns(data)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { onDetailsTikTokUpsellButtonClickEvent } = useUpsellButtonEvent()
  const { handleRelaunchEvent } = useRelaunchEvent()
  const { handleScheduleStartEvent } = useScheduleEvents()
  const { campaignDetails } = useSpotifyCampaignDetails()
  const handleGoBackToCampaignsList = (): void => {
    navigate(`${campaignsList}`)
  }
  const relaunchCampaignCallbackHandler = (res: SpotifyCampaign): void => {
    dispatch(updateScheduledCampaign(res))

    handleScheduleStartEvent({
      campaignType: res.type,
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      campaignId: res.id,
      genreList:
        res?.Track?.TrackGenres?.map((el) => el.Genre?.genre_name).join() || '',
      languageList:
        res?.Track?.TrackLanguages?.map(
          (el) => el.Language?.language_name,
        ).join() || '',
    })
    navigate(`/campaigns/schedule/${res.id}`)
  }

  const handleRelaunchCampaign = (campaignId: number | string): void => {
    const url = window.location.href

    void dispatch(
      createRelaunchCampaign({ campaignId: Number(campaignId), url }),
    )
      .unwrap()
      .then((response) => {
        relaunchCampaignCallbackHandler(response)

        handleRelaunchEvent({
          campaignId: response.id,
          placement: RelaunchInvokePlacement.DETAILS,
        })

        return response
      })
  }

  const relaunchBtnFlag =
    campaignDetails &&
    campaignDetails.type !== CampaignTypeEnum.RELAUNCHED &&
    [
      CampaignStatusEnum.STOPPED,
      CampaignStatusEnum.ENDED,
      CampaignStatusEnum.STOPPED_BY_ADMIN,
    ].includes(campaignDetails.status)

  const tiktokCreateLink = `${campaignsList}${createCampaign}/${
    CampaignPlatformTypeEnum.TIKTOK
  }?trackName=${encodeURIComponent(campaignDetails?.Track?.track_name || '')}`

  const showCreateTiktokButton =
    !isTiktokPayed && campaignDetails?.status === CampaignStatusEnum.ENDED
  return (
    <PageTitleWrapper>
      <PageTitleContainer>
        <BackButton
          data-testid={'back-button'}
          onClick={handleGoBackToCampaignsList}
        >
          <IconArrow />
        </BackButton>
        <PageTitle>{t('campaignResultsPage.pageTitle')}</PageTitle>
      </PageTitleContainer>
      {showCreateTiktokButton && (
        <Link
          to={tiktokCreateLink}
          onClick={onDetailsTikTokUpsellButtonClickEvent}
          target='_blank'
        >
          <CreateCampaignButton type='whiteWithGreenBorder'>
            <TiktokIcon width={16} height={16} />
            {t('campaignsPage.createTikTokCampaign')}
          </CreateCampaignButton>
        </Link>
      )}
      {!showCreateTiktokButton && (
        <RelaunchButtonBlock
          relaunchBtnFlag={relaunchBtnFlag}
          handleRelaunchCampaign={handleRelaunchCampaign}
          id={campaignDetails?.id}
        />
      )}
    </PageTitleWrapper>
  )
}

export default CampaignResultsTitle
