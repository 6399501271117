import { useEffect, FunctionComponent, ReactNode } from 'react'

import { useSelector } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'

import { getCookieByName } from 'src/Constants/functions'
import { APP } from 'src/Configs/App'
import { User } from 'src/Types'
import {
  getIsArtist,
  getIsAuth,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'
import { setGcidToUser } from 'src/Redux/auth-process/userSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'

import { IntercomSetup } from '../IntercomSetup'

const SLICE_NUMBER = 6

export interface MainWrapperProps {
  children: ReactNode
}

const MainWrapper: FunctionComponent<MainWrapperProps> = ({ children }) => {
  const dispatch = useAppDispatch()

  const isArtist = useSelector(getIsArtist)
  const user: User = useSelector(getUser)
  const isAuth: boolean = useSelector(getIsAuth)

  useEffect(() => {
    if (!user.gcid) {
      const adminItem = localStorage.getItem('admin')
      const isAdmin = adminItem ? Boolean(JSON.parse(adminItem)) : false
      const fullGcid = getCookieByName('_ga')
      const gcid = fullGcid ? fullGcid.slice(SLICE_NUMBER) : null

      if (!isAdmin && gcid && isAuth && isArtist !== null) {
        void dispatch(setGcidToUser({ gcid, isArtist }))
      }
    }
  }, [dispatch, isArtist, isAuth, user.gcid])

  return (
    <IntercomProvider appId={APP.INTERCOM_APP_ID} initializeDelay={1000}>
      <IntercomSetup name={user.first_name || 'there'} />
      <div>{children}</div>
    </IntercomProvider>
  )
}

export default MainWrapper
