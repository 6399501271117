import { FC } from 'react'

import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { colors, variables } from 'src/Styled/variables'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { subscription } from 'src/Router/routes'
import { changePlanEvent } from 'src/Helpers/TagManager'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'

const Wrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid ${colors.greyStroke};
  background-color: ${colors.greyBg};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const Title = styled.h3`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`
const Text = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
`

const LeftPriceDescription = styled(Text)`
  width: 96px;
  @media (min-width: 768px) {
    width: 184px;
  }
`
export const PlanToPay: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userId = useAppSelector(getUserId)
  const [search] = useSearchParams()
  const plan = search.get('plan') || ''

  const handlePlanChange = (): void => {
    navigate(subscription)
    changePlanEvent({ planName: plan, userId })
  }
  const title = `${t('getPremiumPage.chosenPlan')} ${plan}`
  const price = search.get('price') || '_'
  const pricePerMonth = `$${price} / ${t('getPremiumPage.month')}`
  const description = `${t('getPremiumPage.youWillBe')} $${price} ${t(
    'getPremiumPage.everyMonth',
  )}`

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{title}</Title>
        <DotedButton onClick={handlePlanChange} color={colors.mainText}>
          {t('getPremiumPage.changePlan')}
        </DotedButton>
      </TitleWrapper>
      <PriceWrapper>
        <LeftPriceDescription>
          {t('getPremiumPage.startingToday')}
        </LeftPriceDescription>
        <Text>{pricePerMonth}</Text>
      </PriceWrapper>
      <Text>{description}</Text>
    </Wrapper>
  )
}
