import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'
import { VideoStatus } from 'src/Constants/enums'

import { getVideoByEvent } from './api-actions'

export interface Video {
  createdAt: string
  feedback: string
  followerCount: number
  id: number
  likesCount: number
  nickname: string
  rate: number
  sharesCount: number
  status: VideoStatus
  stars: number
  tiktokCampaignId: number
  tiktokProfileUrl: string
  updatedAt: string
  videoUploadDate: string
  videoLink: string
  viewsCount: number
  userPhotoUrl: string
}

export interface IVideoProcess {
  error: ApiError | null
  status: SliceStatus
  video: Video | null
}

export const initialState: IVideoProcess = {
  error: null,
  video: null,
  status: SliceStatus.Idle,
}

export const videoProcess = createSlice({
  initialState,
  name: NameSpace.Video,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVideoByEvent.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getVideoByEvent.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.video = action.payload
      state.error = null
    })
    builder.addCase(getVideoByEvent.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
