import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../../types'
import { RootState } from '../..'
import { getVideoByEvent } from '../video-process/api-actions'

import { handleRateVideoEvents } from './helpers'

import { IResult } from '.'

interface IBody {
  id: number
  feedback: string
  stars: number
}

export const setTiktokCampaignEventRate = createAsyncThunk<IResult, IBody>(
  `${NameSpace.VideoRate}/setTiktokCampaignEventRate`,
  async (body, { rejectWithValue, dispatch, getState }) => {
    try {
      const { id, feedback, stars } = body
      const { data } = await api.put<IResult>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/rate-video/${id}`,
        { feedback, stars },
      )
      const state = getState() as RootState
      await dispatch(
        getVideoByEvent({
          eventId: id,
          campaignId: data.campaignId,
        }),
      )
      handleRateVideoEvents({ state, data, feedback, videoID: id })
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: String(response?.data?.message),
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
