import { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  getIsPriceReceived,
  setScheduleStartEventSent,
} from 'src/Redux/analytics-process'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { getScheduleRedirectUrl } from 'src/Containers/App/helpers'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

export const useScheduleStartEvent = (): void => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const campaignId = Number(params.number)
  const { search } = useCustomSearchParams()
  const query = getScheduleRedirectUrl(search)
  const param = new URLSearchParams(query)
  const triggerSource = param.get('trigger_source')

  const { handleScheduleStartEvent } = useScheduleEvents()
  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const isScheduleStartEventSent = useSelector(getIsPriceReceived)

  useEffect(() => {
    if (!scheduledTikTokCampaign) {
      return
    }

    if (
      (triggerSource === 'email-reminder' ||
        triggerSource === 'email-upsell1' ||
        triggerSource === 'SMS-reminder') &&
      !isScheduleStartEventSent
    ) {
      handleScheduleStartEvent({
        campaignType: scheduledTikTokCampaign.type || CampaignTypeEnum.BASIC,
        platformName: CampaignPlatformTypeEnum.TIKTOK,
        campaignId: Number(campaignId),
        genreList:
          scheduledTikTokCampaign.genres
            ?.map((genre) => genre.label)
            .join('') || '',
        languageList:
          scheduledTikTokCampaign.languages
            ?.map((language) => language.label)
            .join('') || '',
      })
      dispatch(setScheduleStartEventSent(true))
    }
  }, [
    campaignId,
    dispatch,
    handleScheduleStartEvent,
    isScheduleStartEventSent,
    scheduledTikTokCampaign,
    triggerSource,
  ])
}
