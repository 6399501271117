import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { Genre } from 'src/Types'
import { api } from 'src/Services/api'

import { NameSpace, ErrorCode } from '../types'

export const getAllGenres = createAsyncThunk<Array<Genre>>(
  `${NameSpace.Genres}/getAllGenresAction`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<Array<Genre>>(
        `${APP.SERVER}/campaign/genres`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
export const getAllCuratorGenres = createAsyncThunk<Array<Genre>>(
  `${NameSpace.Genres}/getAllCuratorsGenresAction`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<Array<Genre>>(
        `${APP.SERVER}/curator/genres`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export type AutoselectedGenres = {
  autoselectedGenres: Array<{ id: number; genreName: string }>
}
export const getAutoselectedSpotifyPlaylistGenres = createAsyncThunk<
  AutoselectedGenres,
  string
>(
  `${NameSpace.Genres}/getAutoselectedSpotifyPlaylistGenres`,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.get<AutoselectedGenres>(
        `${APP.SERVER}/curator/playlist-genres?spotifyPlaylistId=${id}`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
