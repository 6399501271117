import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { ArtistFeedback, ID } from 'src/Types'
import { offensiveValue, poorValue, thankValue } from 'src/Constants/constants'
import { Rating } from 'src/Components/Rating/Rating'

import { ReviewFeedBackForm } from '../ReviewFeedBackForm/ReviewFeedBackForm'
import {
  RespondedContainer,
  RespondedTitle,
  RespondedRating,
  RespondedFeedback,
} from '../styles'

interface Props {
  reviewFeedback?: ArtistFeedback
  curatorRating: number
  campaignID: number
  reviewId: ID
}

export const ReviewFeedback: FC<Props> = ({
  reviewFeedback,
  curatorRating,
  campaignID,
  reviewId,
}) => {
  const { t } = useTranslation()

  if (reviewFeedback) {
    const renderRespond = (): string => {
      switch (reviewFeedback) {
        case thankValue:
          return t('campaignResultsPage.thankYou')
        case offensiveValue:
          return t('campaignResultsPage.soundsOffensive')
        case poorValue:
          return t('campaignResultsPage.notUsefullFeedback')
        default:
          return t('campaignResultsPage.thankYou')
      }
    }
    return (
      <RespondedContainer>
        <RespondedTitle>{t('campaignResultsPage.yourResponse')}</RespondedTitle>
        <RespondedRating>
          <Rating rating={curatorRating} />
        </RespondedRating>
        <RespondedFeedback>{`${t(
          'campaignResultsPage.youSaid',
        )} "${renderRespond()}"`}</RespondedFeedback>
      </RespondedContainer>
    )
  }

  return <ReviewFeedBackForm reviewId={reviewId} campaignID={campaignID} />
}
