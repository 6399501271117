import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'

import { getArtistBalanceStats } from './api-actions'
export interface BalanceStats {
  currentBalance: number
  totalPaid: number
  totalUsed: number
}
export interface ArtistBalanceStatsState {
  artistBalanceStats: BalanceStats | null
  status: SliceStatus
  error: ApiError | null
}

const initialState: ArtistBalanceStatsState = {
  artistBalanceStats: null,
  status: SliceStatus.Idle,
  error: null,
}

export const artistBalanceStatsSlice = createSlice({
  initialState,
  name: NameSpace.ArtistBalanceStats,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArtistBalanceStats.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getArtistBalanceStats.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.artistBalanceStats = payload
    })
    builder.addCase(getArtistBalanceStats.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
