import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '../..'

import { IVideoRateProcess } from '.'

const getState = (state: RootState): IVideoRateProcess =>
  state.campaignVideo.rate

export const getVideoRateLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getVideoRateError = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)
