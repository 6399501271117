import React, { ReactElement } from 'react'

import { useNavigate } from 'react-router-dom'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { sounds } from 'src/Router/routes'

import { ConfirmRules } from '../ConfirmRules'
import { NewSoundDetails } from '../NewSoundDetails'
import { CloseCrossButton, Wrapper } from '../../styles'
import { SuccessAcceptModal } from '../../../SuccessAcceptModal'

export const NewSoundDetailsModal = (): ReactElement => {
  const { search } = useCustomSearchParams()
  const navigate = useNavigate()

  const isConfirmRulesState = search.get('confirmRules') === 'true'
  const soundStatus = search.get('soundStatus')

  const isSoundAccepted = soundStatus === 'accepted'

  const onClickCloseModal = (): void => {
    navigate(`${sounds}/new`)
  }

  return (
    <Wrapper>
      {!isConfirmRulesState && <NewSoundDetails />}
      {isConfirmRulesState && <ConfirmRules />}
      {isSoundAccepted && <SuccessAcceptModal />}

      <CloseCrossButton onClick={onClickCloseModal} />
    </Wrapper>
  )
}
