import { Tabs } from 'antd'
import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const TrackTableContainer = styled.div`
  width: 100%;
  padding-bottom: 30px;
  border-radius: 4px;
  position: relative;

  @media ${device.mobileMin} {
    background-color: ${colors.white};
    padding-bottom: 0px;
  }
  .ant-tabs-nav-more > span {
    display: none;
  }
  .ant-tabs-tab {
    padding: 11px 0px;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid ${colors.greyStroke};
  }
  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav-list {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
`

export const StyledTabs = styled(Tabs)`
  position: relative;
  padding-top: 16px;
  .ant-tabs-nav-list {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
  }
  padding-left: 0;
  @media ${device.mobileMin} {
    padding-left: 24px;
    padding-top: 21px;
    padding-right: 32px;
  }
  .ant-tabs-tab-btn {
    padding: 0px 16px 0 16px;
    @media ${device.smallScreen} {
      padding: 0px 24px 0 24px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
  .ant-tabs-ink-bar {
    background: ${colors.green};
    width: 116px;
    @media ${device.smallScreen} {
      width: 132px;
    }
  }
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 10px 0px 11px 0px;
    font-size: ${variables.fontSize14};
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    @media ${device.smallScreen} {
      padding: 11px 0px 11px 0px;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 42px;
    width: 90%;
    @media ${device.smallScreen} {
      margin-bottom: 21px;
    }
  }
`

export const StyledTabsItem = styled(Tabs.TabPane)`
  color: ${colors.mainText};
`

export const TabName = styled.span`
  color: ${colors.mainText};
  margin-right: 8px;
`

type NumberProps = {
  approved?: boolean
}
export const StyledNumber = styled.span<NumberProps>`
  color: ${(props) => (props.approved ? colors.green : colors.greyTextBlackBg)};
`

export const RoundBtn = styled.button`
  background: ${colors.white};
  border: 1px solid ${colors.greyStroke};
  box-sizing: border-box;
  border-radius: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media ${device.mobileMin} {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`
