import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { useOpenCampaignSchedule } from 'src/Hooks/useOpenCampaignSchedule'

import { ApprovedCampaignAlertProps } from './ApprovedCampaignAlertProps'

import { AlertButton, AlertText, AlertTitle } from './styles'

export const ApprovedCampaignAlert: FunctionComponent<
  ApprovedCampaignAlertProps
> = ({ campaign, handleAddCloseApprovedAlertIds, activeTab }) => {
  const { t } = useTranslation()

  const { handleScheduleCampaign } = useOpenCampaignSchedule({
    activeTab,
    campaign,
  })

  return (
    <Alert
      color='success'
      icon
      onClose={() => handleAddCloseApprovedAlertIds(Number(campaign.id))}
    >
      <AlertText>
        <AlertTitle>{t('campaignsPage.goodNews')}</AlertTitle>
        &quot;{campaign.trackTitle}&quot; {t('campaignsPage.isWaiting')}
      </AlertText>
      <AlertButton type='button' onClick={handleScheduleCampaign}>
        {t('campaignsPage.launchCampaign')}
      </AlertButton>
    </Alert>
  )
}
