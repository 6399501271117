import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

import { BalanceStats } from '.'

export const getArtistBalanceStats = createAsyncThunk<BalanceStats>(
  `${NameSpace.ArtistBalanceStats}/getArtistBalanceStats`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<BalanceStats>(
        `${APP.TIKTOK_SERVER}/artist/balance`,
      )

      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
