import { FunctionComponent } from 'react'

import { useNavigate } from 'react-router-dom'

import { activeTabStorage } from 'src/Constants/constants'
import { useWindowDimensions } from 'src/Helpers/useWindowDimensions'
import { campaignResults, campaignsList, details } from 'src/Router/routes'
import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { createRelaunchCampaign } from 'src/Redux/campaign-process/mainOldProcessSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import track from 'src/Assets/Png/track.png'
import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  RelaunchInvokePlacement,
  SpotifyCampaign,
  TiktokCampaignStatus,
} from 'src/Types/index'
import { updateScheduledCampaign } from 'src/Redux/schedule-process/spotifyCampaignSlice'
import { useRelaunchEvent } from 'src/Hooks/useRelaunchEvent'
import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { SmartImage } from 'src/Components/SmartImage'
import { useOpenCampaignSchedule } from 'src/Hooks/useOpenCampaignSchedule'

import { CampaignCardButton } from '../CampaignCardButton'

import { CampaignStatus } from './CampaignStatus'
import { CampaignPeriod } from './CampaignPeriod'
import { CampaignReviews } from './CampaignReviews'
import { CampaignCardProps } from './CampaignCardProps'

import {
  Artist,
  IconWrapper,
  ImgWrapper,
  Name,
  NamesWrapper,
  Status,
  StyledIcon,
  StyledItem,
  StyledLi,
} from './styles'

export const CampaignCard: FunctionComponent<CampaignCardProps> = ({
  campaign,
  activeTab,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useWindowDimensions()

  const { handleScheduleStartEvent } = useScheduleEvents()
  const { handleScheduleCampaign } = useOpenCampaignSchedule({
    activeTab,
    campaign,
  })

  const { handleRelaunchEvent } = useRelaunchEvent()

  const storeActiveTab = (): void => {
    window.sessionStorage.setItem(activeTabStorage, activeTab)
  }

  const relaunchCampaignCallbackHandler = (res: SpotifyCampaign): void => {
    dispatch(updateScheduledCampaign(res))

    handleScheduleStartEvent({
      campaignType: res.type,
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      campaignId: res.id,
      genreList: res?.Track?.TrackGenres?.map(
        (el) => el.Genre?.genre_name,
      ).join(),
      languageList: res?.Track?.TrackLanguages?.map(
        (el) => el.Language?.language_name,
      ).join(),
    })
    navigate(`/campaigns/schedule/${res.id}`)
  }

  const handleRelaunchCampaign = (campaignId: number | string): void => {
    storeActiveTab()
    const url = window.location.href

    void dispatch(
      createRelaunchCampaign({
        campaignId: Number(campaignId),
        url,
      }),
    )
      .unwrap()
      .then((res) => {
        relaunchCampaignCallbackHandler(res)

        handleRelaunchEvent({
          campaignId: res.id,
          placement: RelaunchInvokePlacement.LIST,
        })

        return res
      })
  }

  const handleViewResults = (): void => {
    storeActiveTab()
    campaign.platformType === CampaignPlatformTypeEnum.SPOTIFY
      ? navigate(`${campaignsList}${campaignResults}/${campaign.id}`)
      : navigate(`${campaignsList}${details}/${campaign.id}`)
    window.scrollTo(0, 0)
  }

  const withoutReviewsStatuses = [
    CampaignStatusEnum.PENDING_APPROVAL,
    TiktokCampaignStatus.AWAITING_REVIEW,
    CampaignStatusEnum.APPROVED,
    TiktokCampaignStatus.PENDING,
    CampaignStatusEnum.SCHEDULED,
  ]

  const isWithoutReviews = withoutReviewsStatuses.includes(campaign.status)

  return (
    <StyledLi data-testid={`${campaign.platformType}-campaign-card`}>
      <StyledIcon>
        <ImgWrapper>
          <SmartImage
            alt='trackCoverUrl'
            width='40'
            src={campaign.trackCoverUrl}
            fallbackSrc={track}
          />
        </ImgWrapper>

        {campaign.platformType === CampaignPlatformTypeEnum.SPOTIFY ? (
          <IconWrapper>
            <IconSpotify />
          </IconWrapper>
        ) : (
          <IconWrapper>
            <TikTokIcon />
          </IconWrapper>
        )}
      </StyledIcon>

      <NamesWrapper>
        <Name>{campaign.trackTitle}</Name>
        <Artist>{campaign.trackAuthorName}</Artist>
      </NamesWrapper>

      <div>
        <Status status={campaign.status}>
          <CampaignStatus campaign={campaign} />
        </Status>
        <CampaignPeriod campaign={campaign} />
      </div>

      <StyledItem withoutReviews={isWithoutReviews}>
        <Status status={campaign.status}>
          <CampaignReviews campaign={campaign} />
        </Status>
      </StyledItem>

      <CampaignCardButton
        campaign={campaign}
        onViewResults={handleViewResults}
        onScheduleCampaign={handleScheduleCampaign}
        onRelaunchCampaign={() => handleRelaunchCampaign(campaign.id)}
      />
    </StyledLi>
  )
}
