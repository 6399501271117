import { useEffect } from 'react'

import { setStartDate } from 'src/Redux/campaign-process/mainOldProcessSlice'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { getDefaultStartDate } from 'src/Constants/functions'

export const useDefaultSpotifyCampaignStartDate = (): void => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()

  const urlStartDate = search.get('startDate')
  const hasActiveOrCanceledSubscription = useAppSelector(
    getHasActiveOrCanceledSubscriptionSelector,
  )

  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)

  useEffect(() => {
    if (!spotifyCampaignDetails.start_date && !urlStartDate) {
      const startDate = getDefaultStartDate(hasActiveOrCanceledSubscription)
      dispatch(setStartDate(startDate))
    }
  }, [
    dispatch,
    spotifyCampaignDetails.start_date,
    urlStartDate,
    hasActiveOrCanceledSubscription,
  ])
}
