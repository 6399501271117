import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'

import { subscribeForGetPremium } from './api-actions'

export interface GetPremiumState {
  isAlertClosed: boolean
  getPremiumLoading: boolean
  getPremiumError: ApiError | null
}
const initialState: GetPremiumState = {
  isAlertClosed: false,
  getPremiumLoading: false,
  getPremiumError: null,
}

export const getPremium = createSlice({
  initialState,
  name: NameSpace.GET_PREMIUM,
  reducers: {
    setIsAlertClosed(state) {
      state.isAlertClosed = true
    },
  },
  extraReducers: (builder) => {
    // subscribeForGetPremium
    builder.addCase(subscribeForGetPremium.pending, (state) => {
      state.getPremiumLoading = true
    })
    builder.addCase(subscribeForGetPremium.fulfilled, (state) => {
      state.getPremiumLoading = false
      state.getPremiumError = null
      state.isAlertClosed = true
    })
    builder.addCase(subscribeForGetPremium.rejected, (state, action) => {
      state.getPremiumLoading = false
      state.getPremiumError = setApiError(action)
    })
  },
})

export const { setIsAlertClosed } = getPremium.actions
