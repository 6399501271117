import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'
import Alert from 'src/Components/Alert'
import { TiktokCampaignStatus } from 'src/Types'

export interface EngagementDataUpdatedAlertProps {
  dateInfo: string
  status?: TiktokCampaignStatus
}

export const EngagementDataUpdatedAlert: FunctionComponent<
  EngagementDataUpdatedAlertProps
> = ({ dateInfo, status }) => {
  const { t } = useTranslation()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const displayedDate = dayjs(dateInfo)
    .tz(timezone)
    .format(formatDate.formatDateWithTime2)

  const getText = (stat?: TiktokCampaignStatus): { title: string } => {
    switch (stat) {
      case TiktokCampaignStatus.RUNNING:
      case TiktokCampaignStatus.IN_PROCESS:
        return {
          title: t('campaignResultsPage.engagementDataIsAutomaticallyUpdated'),
        }

      case TiktokCampaignStatus.ENDED:
      case TiktokCampaignStatus.FINISHED:
        return {
          title: t('campaignResultsPage.engagementDataUpdatesWithin14Days'),
        }
      case TiktokCampaignStatus.CANCELED:
      case TiktokCampaignStatus.STOPPED:
        return { title: t('campaignResultsPage.engagementDataIsntUpdated') }
      default:
        return { title: t('campaignResultsPage.engagementDataIsntUpdated') }
    }
  }

  const { title } = getText(status)
  return (
    <Alert color='warning' noBorder icon unclosable middleGap>
      {title}
      {displayedDate}
    </Alert>
  )
}
