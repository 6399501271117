import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { useAppSelector } from 'src/Hooks/redux'
import { getCurrencyError } from 'src/Redux/auth-process/currencySlice/selectors'
import { getSpotifyCampaignError } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'

export const ScheduleAlerts: FC = () => {
  const { t } = useTranslation()
  const isCurrencyError = useAppSelector(getCurrencyError)
  const isSpotifyCampaignError = useAppSelector(getSpotifyCampaignError)
  if (isCurrencyError || isSpotifyCampaignError) {
    return (
      <Alert smallGap icon unclosable color='danger'>
        {t('PleaseTryAgainLater')}
      </Alert>
    )
  }
  return null
}
