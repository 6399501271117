import { FC } from 'react'

import { Skeleton } from 'antd'

const SKELETON_ARRAY = Array.from({ length: 6 }, (_, index) => index)
export const TiktokWidgetListLoading: FC = () => (
  <>
    {SKELETON_ARRAY.map((item) => (
      <Skeleton.Node
        key={item}
        active={true}
        style={{ height: '68px', width: '100%', borderRadius: '4px' }}
      >
        <div></div>
      </Skeleton.Node>
    ))}
  </>
)
