import { createAsyncThunk } from '@reduxjs/toolkit'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'

import { NameSpace } from '../types'
import { RootState } from '..'
import { getCountryName } from '../auth-process/userSlice/selectors'
import { getUserInfo } from '../auth-process/userSlice/api-actions'
import { setPaymentAlertError } from '../payment-process'

interface Campaign {
  type: CampaignPlatformTypeEnum
  amount: number
  startDate: string
  campaignId: number
}

interface CreditsPaymentData {
  campaigns: Campaign[]
}
interface CreditsPaymentResponse {
  succeeded: true
}

export const payByCredits = createAsyncThunk<
  CreditsPaymentResponse,
  CreditsPaymentData
>(
  `${NameSpace.CREDITS_PAYMENT}/payByCredits`,
  async ({ campaigns }, { rejectWithValue, getState, dispatch }) => {
    const state = getState() as RootState
    const country = getCountryName(state)
    try {
      const { data } = await api.post<CreditsPaymentResponse>(
        `${APP.TIKTOK_SERVER}/payment/balance-only/charge`,
        {
          campaigns,
          country,
        },
      )
      void dispatch(getUserInfo())
      return data
    } catch (error) {
      dispatch(setPaymentAlertError(PaymentErrors.DEFAULT))
      return rejectWithValue(error)
    }
  },
)
