import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CheckMark from 'src/Assets/Svg/checkMark.svg?react'
import { subscription } from 'src/Router/routes'
import { subscriptionSelectedEvent } from 'src/Helpers/TagManager'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'

import { SubTitle, Text } from '../../styles'
import { Plan } from '../../helpers'

import { PlanButton } from './PlanButton'
import { PlanNotification } from './PlanNotification'
import { BillingDate } from './BillingDate/BillingDate'
import { PlanPrice } from './PlanPrice/PlanPrice'

import {
  Container,
  InfoBlock,
  List,
  ListItem,
  Recommended,
  TitleWrapper,
  Wrapper,
} from './styles'

const PAY_STATUSES = ['upgrade', 'downgrade', 'default', 'canceled']

interface Props {
  isBig: boolean
  plan: Plan
}

export const PlanItem: FC<Props> = ({ plan, isBig }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userId = useAppSelector(getUserId)

  const { name, price, features, isRecommended, status, date, cashback } = plan

  const handleClick = (): void => {
    const query = `?price=${price}&packageId=${plan.value}&plan=${name}&cashback=${cashback}`
    if (PAY_STATUSES.includes(status)) {
      navigate(`${subscription}/pay${query}`)
      subscriptionSelectedEvent({ planName: name, userId })
    } else {
      navigate(`${subscription}/cancel${query}`)
    }
  }
  const isActive = status === 'active' || status === 'canceled'
  return (
    <Container isBig={isBig || isActive}>
      {isRecommended && status !== 'active' && (
        <Recommended>
          <span>{t('getPremiumPage.mostRecommended')}</span>
        </Recommended>
      )}
      {status === 'active' && (
        <Recommended>
          <span>{t('getPremiumPage.active')}</span>
        </Recommended>
      )}

      <InfoBlock>
        <TitleWrapper>
          <SubTitle>{name}</SubTitle>
        </TitleWrapper>

        <PlanPrice price={price} />

        <List>
          {features.map((feature) => (
            <ListItem key={feature}>
              <CheckMark />
              <Text>{feature}</Text>
            </ListItem>
          ))}
        </List>
      </InfoBlock>
      <BillingDate status={status} date={date} />
      <Wrapper>
        <PlanButton onClick={handleClick} status={status} />
        <PlanNotification status={status} date={date} />
      </Wrapper>
    </Container>
  )
}
