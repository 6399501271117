import { FunctionComponent } from 'react'

import { Route, Routes } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import { TiktokReview } from 'src/Containers/TiktokReview'
import CreateCampaignPage from 'src/Containers/CreateCampaignPage'
import ReferAndEarn from 'src/Containers/ReferAndEarn'
import ArtistBalancePage from 'src/Containers/ArtistBalancePage'
import ExpiredLinkPage from 'src/Containers/ui/ExpiredLinkPage'
import { Subscriptions } from 'src/Containers/Subscriptions'
import PaySubscription from 'src/Containers/Subscriptions/PaySubscription/PaySubscription'
import CancelSubscription from 'src/Containers/Subscriptions/CancelSubscription'
import { SuccessPaymentSubscription } from 'src/Containers/Subscriptions/SuccessPaymentSubscription'
import CampaignResults from 'src/Containers/CampaignResults'
import Details from 'src/Containers/Details'
import CampaignsList from 'src/Containers/CampaignsList'
import ScheduleSpotifyWrapper from 'src/Containers/ScheduleSpotifyWrapper'
import ScheduleTiktokWrapper from 'src/Containers/ScheduleTiktokWrapper'
import Settings from 'src/Containers/Settings'
import AdminPage from 'src/Containers/AdminPage'
import SearchNewCampaignByPlatform from 'src/Containers/SearchNewCampaignByPlatform'
import SuccessCreatedFirstCampaign from 'src/Containers/SuccessCreatedFirstCampaign'
import { FirstFeedback } from 'src/Containers/FirstFeedback'

import {
  campaignsList,
  referAndEarn,
  campaignResults,
  details,
  errors,
  scheduleCampaign,
  settings,
  createCampaign,
  admin,
  balance,
  scheduleCampaignTikTok,
  subscription,
  createdFirst,
  feedback,
} from './routes'
import { UserComponentsProps } from './props'

const ArtistComponents: FunctionComponent<UserComponentsProps> = ({
  email,
}) => (
  <Routes>
    <Route
      Component={CampaignResults}
      path={`${campaignsList}${campaignResults}`}
    >
      <Route path=':campaignID' Component={CampaignResults} />
    </Route>

    <Route path={`${campaignsList}${details}`}>
      <Route path=':campaignID' Component={Details} />
      <Route path=':campaignID/:videoID' Component={TiktokReview} />
    </Route>

    <Route Component={CampaignsList} path='/' />
    <Route Component={CampaignsList} path='/campaigns' />

    <Route Component={ReferAndEarn} path={referAndEarn} />

    <Route Component={ArtistBalancePage} path={balance} />

    <Route Component={Subscriptions} path={subscription}></Route>
    <Route path={`${subscription}/pay`} Component={PaySubscription} />
    <Route
      path={`${subscription}/pay/success`}
      Component={SuccessPaymentSubscription}
    />
    <Route path={`${subscription}/cancel`} Component={CancelSubscription} />

    <Route
      Component={ScheduleSpotifyWrapper}
      path={`${campaignsList}${scheduleCampaign}`}
    >
      <Route path=':number' Component={ScheduleSpotifyWrapper} />
    </Route>

    <Route
      Component={ScheduleTiktokWrapper}
      path={`${campaignsList}${scheduleCampaignTikTok}`}
    >
      <Route path=':number' Component={ScheduleTiktokWrapper} />
    </Route>

    <Route Component={Settings} path={settings}>
      <Route path=':page' Component={Settings} />
    </Route>

    <Route
      Component={CreateCampaignPage}
      path={`${campaignsList}${createCampaign}`}
    />
    <Route
      path={`${campaignsList}${createCampaign}/:platform`}
      Component={SearchNewCampaignByPlatform}
    />
    <Route
      path={`${createdFirst}/:campaignType`}
      Component={SuccessCreatedFirstCampaign}
    />
    <Route
      path={`${createdFirst}/:campaignType${feedback}`}
      Component={FirstFeedback}
    />

    <Route Component={ExpiredLinkPage} path={errors} />

    {APP.admin_email.includes(String(email)) && (
      <Route Component={AdminPage} path={admin} />
    )}

    <Route Component={CampaignsList} path='*' />
  </Routes>
)

export default ArtistComponents
