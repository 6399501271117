import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { BonusStatus } from 'src/Types/index'
import { useCreateCampaignLayout } from 'src/Components/CreateCampaignLayout/useCreateCampaignLayout'

import { BonusStatusText } from '../BonusStatusText'

import {
  BonusModal,
  BonusModalWrapper,
  SuccessButtonBlock,
  SuccessTitle,
} from './styles'

export const CampaignsListBonusAlert: FunctionComponent = () => {
  const { t } = useTranslation()
  const urlParams = new URLSearchParams(window.location.search)

  const bonusStatus = urlParams?.get('status')

  const { totalCloseHandler } = useCreateCampaignLayout()

  const isBonusOpened = bonusStatus === BonusStatus.OPENED

  if (!isBonusOpened) {
    return null
  }

  return (
    <BonusModal>
      <BonusModalWrapper>
        <SuccessTitle>{t('createCampaignPage.sorry')}</SuccessTitle>

        <BonusStatusText status={bonusStatus} />

        <SuccessButtonBlock>
          <Button type='white' onClick={totalCloseHandler}>
            {t('scheduleCampaignPage.goToCampaigns')}
          </Button>
        </SuccessButtonBlock>
      </BonusModalWrapper>
    </BonusModal>
  )
}
