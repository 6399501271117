import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const TextRed = styled.span`
  text-align: center;

  p {
    color: red;
    margin-bottom: 0;
  }
`
export const TextBlack = styled.span`
  text-align: center;

  p {
    color: black;
    margin-bottom: 20px;
  }
`
export const SpotifyLink = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: ${colors.green};
  color: ${colors.white};
  align-items: center;
  width: 100%;
  margin-right: 0;
  border-radius: 50px;
  margin-bottom: 10px;
  padding: 9px 39px;
  text-decoration: none;
  &:hover {
    color: ${colors.white};
  }

  .mdi-icon {
    height: 20px;
    width: 20px;
    fill: ${colors.white};
    margin: 0;
  }
`

export const AccountOr = styled.div`
  text-align: center;
  margin-bottom: 10px;
  position: relative;

  p {
    color: black !important;
  }

  &:before,
  &:after {
    content: '';
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`
export const Wrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`
export const FieldWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: auto;

  input {
    height: 43px;
    background-color: #f9f9f9;
    border: 2px solid #eaeaea !important;
    border-radius: 5px;
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    transition: border 0.3s;
    color: #646877;
    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
`
