import { useMemo, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'

import track from 'src/Assets/Png/track.png'
import { useGetRejectReasonsQuery } from 'src/Redux/RejectReasonApi'
import { useTrackToReview } from 'src/Redux/TrackFeedDetailsApi/useTrackToReview'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import {
  RejectOption,
  RejectOptionLabel,
  SelectOption,
  SelectOptionPercents,
  SelectOptionPlaylist,
  SelectOptionPlaylistCover,
  SelectOptionPlaylistInfo,
  SelectOptionPlaylistScore,
  SelectOptionPlaylistScoreLabel,
  SelectOptionPlaylistTitle,
} from './styles'

export interface PlaylistOrRejectOption {
  value: number
  type: string
  label: ReactNode
}

export const useSelectPlaylistOrRejectOptions = (
  noReject?: boolean,
): PlaylistOrRejectOption[] => {
  const { t } = useTranslation()
  const { data: reviewDetails } = useTrackToReview()
  const { data: rejectReasons } = useGetRejectReasonsQuery()

  const reviewPlaylistsOptions: PlaylistOrRejectOption[] = useMemo(() => {
    if (!reviewDetails?.matched_playlists) {
      return []
    }
    return reviewDetails.matched_playlists
      ?.slice()
      .sort((a, b) => b.matched_percent - a.matched_percent)
      .map((playlist) => ({
        value: playlist.id,
        type: 'add',
        label: (
          <SelectOption>
            <SelectOptionPlaylist>
              <SelectOptionPlaylistCover
                src={playlist.playlist_cover}
                fallbackSrc={track}
                alt='playlist_image'
              />
              <SelectOptionPlaylistInfo>
                <SelectOptionPlaylistTitle>
                  {playlist.playlist_name}
                </SelectOptionPlaylistTitle>
                <SelectOptionPlaylistScore>
                  <SelectOptionPlaylistScoreLabel>
                    {t('reviewsPage.score')}:
                  </SelectOptionPlaylistScoreLabel>
                  {addSpaceToNumber(playlist.score, ',')}
                </SelectOptionPlaylistScore>
              </SelectOptionPlaylistInfo>
            </SelectOptionPlaylist>
            <SelectOptionPercents
              id='SelectOptionPercents'
              percent={playlist.matched_percent}
            >
              {playlist.matched_percent}% {t('reviewsPage.match')}
            </SelectOptionPercents>
          </SelectOption>
        ),
      }))
  }, [reviewDetails, t])

  const reviewRejectOptions: PlaylistOrRejectOption[] = useMemo(() => {
    if (!rejectReasons?.length) {
      return []
    }
    return rejectReasons.map((reason) => ({
      value: reason[0],
      type: 'reject',
      label: (
        <RejectOption>
          <RejectOptionLabel>{reason[1]}</RejectOptionLabel>
        </RejectOption>
      ),
    }))
  }, [rejectReasons])

  return noReject
    ? reviewPlaylistsOptions
    : [...reviewPlaylistsOptions, ...reviewRejectOptions]
}
