import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

interface Props {
  isAbsolutePosition: boolean
}

export const WrapperRelative = styled.div<Props>`
  position: ${(props: Props) => props.isAbsolutePosition && 'absolute'};
  top: 60px;
  border-radius: 8px;
  width: 100%;
  background-color: ${colors.white};
  @media ${device.mobileMin} {
    top: 56px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }
`
