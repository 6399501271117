import { useState, memo, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import {
  getUserCampaignTypeSelector,
  getUserSelector,
} from 'src/Redux/auth-process/userSlice/selectors'
import {
  faqTiktokCreatingAnotherQuestionEvent,
  faqTiktokCreatingEvent,
} from 'src/Helpers/TagManager/FAQEvents'
import { getIsAuth } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { FAQItems } from './list'

import {
  FaqsHeader,
  FaqsList,
  FaqsItem,
  FaqsTitle,
  FaqsAnswersList,
  FaqsAnswersItem,
  FaqsText,
  StyledArrowTotalBtnDown,
  StyledDottedBtn,
  FaqsButton,
  StyledDottedLink,
} from './styles'

export interface FaqComponentProps {
  platform?: CampaignPlatformTypeEnum | null
  isTiktokTrackAdded?: boolean
}

export interface FaqListProps {
  id: number
  question: string
  answer?: string
  answer2?: string
  answer3?: string | string[]
  answer4?: string
  name: string
}

export const FaqComponent: React.FC<FaqComponentProps> = memo(
  ({ platform, isTiktokTrackAdded }) => {
    const { t } = useTranslation()
    const { show } = useIntercom()
    const { setSearch, search } = useCustomSearchParams()
    const isAuth = useSelector(getIsAuth)
    const [activeItemId, setActiveItemId] = useState<number | null>(null)
    const campaignType = useSelector(getUserCampaignTypeSelector)
    const user = useSelector(getUserSelector)

    const isHowGetTrackModalVisible =
      search.get('isHowGetTrackModalVisible') === 'true'

    const toggleActiveItem = useCallback(
      (item: FaqListProps): void => {
        if (activeItemId === item.id) {
          return setActiveItemId(null)
        }

        faqTiktokCreatingEvent({
          campaignType,
          userId: user?.id,
          buttonType: item.name,
        })
        setActiveItemId(item.id)
      },
      [activeItemId, campaignType, user?.id],
    )

    if (platform !== CampaignPlatformTypeEnum.TIKTOK || isTiktokTrackAdded) {
      return null
    }

    const handleOpenInstructions = (): void => {
      setSearch({
        isHowGetTrackModalVisible: isHowGetTrackModalVisible ? null : 'true',
      })
    }

    const content = FAQItems.map((item) => {
      const isActive = item.id === activeItemId

      return (
        <FaqsItem key={item.id} id={item.name} isActive={isActive}>
          <FaqsTitle onClick={() => toggleActiveItem(item)}>
            {t(item.question)}
            <StyledArrowTotalBtnDown
              active={isActive.toString()}
              width={12}
              height={12}
            />
          </FaqsTitle>

          {isActive && (
            <FaqsText>
              <FaqsAnswersList>
                <FaqsAnswersItem>{t(item.answer)}</FaqsAnswersItem>

                {item.answer2 && (
                  <FaqsAnswersItem>{t(item.answer2)}</FaqsAnswersItem>
                )}

                {item.answer3 && (
                  <FaqsAnswersItem>
                    {Array.isArray(item.answer3)
                      ? item.answer3.map((answerKey) => (
                          <span
                            key={answerKey}
                            onClick={handleOpenInstructions}
                          >
                            {t(answerKey)}
                          </span>
                        ))
                      : item.answer3 && <span>{t(item.answer3)}</span>}
                  </FaqsAnswersItem>
                )}

                {item.answer4 && (
                  <FaqsAnswersItem>{t(item.answer4)}</FaqsAnswersItem>
                )}
              </FaqsAnswersList>
            </FaqsText>
          )}
        </FaqsItem>
      )
    })

    const handleAnotherQuestionClick = (): void => {
      faqTiktokCreatingAnotherQuestionEvent({
        campaignType,
        userId: user?.id,
      })
      isAuth && show()
    }

    return (
      <>
        <FaqsHeader>{t('FAQs.faqs')}</FaqsHeader>
        <FaqsList>{content}</FaqsList>
        <FaqsButton>
          {isAuth ? (
            <StyledDottedBtn onClick={handleAnotherQuestionClick}>
              {t('FAQs.anotherQuestion')}
            </StyledDottedBtn>
          ) : (
            <StyledDottedLink
              to='https://help.soundcamps.com/en/collections/3958304-tiktok-campaigns'
              target='_blank'
              rel='noopener noreferrer'
              onClick={() =>
                faqTiktokCreatingAnotherQuestionEvent({
                  campaignType,
                  userId: user?.id,
                })
              }
            >
              {t('FAQs.anotherQuestion')}
            </StyledDottedLink>
          )}
        </FaqsButton>
      </>
    )
  },
)
