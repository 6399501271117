import { FC, useMemo } from 'react'

import { TFunction } from 'i18next'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import {
  getAllSearchErrorSelector,
  getTikTokTrackSelector,
} from 'src/Redux/tiktokTrack-process/selectors'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'

import { usePlatformModal } from '../../usePlatformModal'

import {
  ArrowBackButton,
  NewCampaigns,
  SubHeadForFirstCampaignSearch,
} from './styles'

interface SubHeaderProps {
  isVariantB?: boolean
  isFAQ?: boolean
}

const isSpotifyTitle = (
  hasTrack: boolean,
  searchPlatform?: CampaignPlatformTypeEnum,
): boolean =>
  searchPlatform === CampaignPlatformTypeEnum.SPOTIFY ||
  (hasTrack && !searchPlatform)

const isTiktokTitle = (
  hasTrack: boolean,
  searchPlatform?: CampaignPlatformTypeEnum,
): boolean =>
  searchPlatform === CampaignPlatformTypeEnum.TIKTOK ||
  (hasTrack && !searchPlatform)

const getTitle = (
  isVariantB: boolean | undefined,
  hasTrack: boolean,
  t: TFunction,
  isFAQ: boolean | undefined,
  searchPlatform?: CampaignPlatformTypeEnum,
): string => {
  if (isVariantB && hasTrack) {
    return t('createCampaignPage.promotion')
  }
  if (isVariantB) {
    return t('createCampaignPage.getHeard')
  }
  if (isSpotifyTitle(hasTrack, searchPlatform)) {
    return t('createCampaignPage.newSpotifyCampaign')
  }
  if (isTiktokTitle(hasTrack, searchPlatform) || isFAQ) {
    return t('createCampaignPage.addingTikTokCampaign')
  }
  return ''
}

export const FirstCampaignSubHeader: FC<SubHeaderProps> = ({
  isVariantB,
  isFAQ,
}) => {
  const { t } = useTranslation()
  const { arrowBackHandler } = usePlatformModal()
  const tiktokTrack = useSelector(getTikTokTrackSelector)
  const spotifyTrack = useSelector(getSpotifyTrackSelector)
  const searchPlatform = useSelector(getSchedulePlatform)
  const searchError = useSelector(getAllSearchErrorSelector)
  const { search } = useCustomSearchParams()
  const searchUrl = search.get('searchUrl')
  const navigate = useNavigate()

  const hasTrack = Boolean(spotifyTrack) || Boolean(tiktokTrack)

  const title = useMemo(
    () => getTitle(isVariantB, hasTrack, t, isFAQ, searchPlatform),
    [isVariantB, searchPlatform, hasTrack, t, isFAQ],
  )

  const handleClick = (): void => {
    if (searchUrl) {
      navigate(NAVIGATE_BACK_INDEX)
      return
    }

    arrowBackHandler()
  }

  return (
    <>
      <SubHeadForFirstCampaignSearch
        isVariantB={isVariantB}
        isFAQ={isFAQ}
        hasTrack={hasTrack}
        isHiddenOnMobile={Boolean(searchPlatform) && !searchError}
      >
        {(!isVariantB || hasTrack) && (
          <ArrowBackButton onClick={handleClick}>
            <ArrowIcon />
          </ArrowBackButton>
        )}
        <div>{title}</div>
      </SubHeadForFirstCampaignSearch>

      {tiktokTrack && spotifyTrack && !searchPlatform && (
        <NewCampaigns>{t('createCampaignPage.newCampaigns')}</NewCampaigns>
      )}
    </>
  )
}
