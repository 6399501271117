import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

import { ArtistBalanceStatsState } from '.'

const getState = (state: RootState): ArtistBalanceStatsState =>
  state.artistBalance.stats

export const getArtistBalanceStatsSelector = createDraftSafeSelector(
  getState,
  ({ artistBalanceStats }) => artistBalanceStats,
)
