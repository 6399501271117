import { FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import step1 from 'src/Assets/Png/step1.png'
import step2 from 'src/Assets/Png/step2.png'
import step3 from 'src/Assets/Png/step3.png'

import {
  StyledModal,
  HowToGetYourTrackURLModalWrapper,
  HowToGetYourTrackURLBlock,
  HowToGetYourTrackURLImgWrapper,
  HowToGetYourTrackURLLists,
  HowToGetYourTrackURLSubtitle,
  HighlightedPhone,
} from './styles'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'

export const HowToGetYourTrackURLModal: FunctionComponent =
  (): ReactElement | null => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { search } = useCustomSearchParams()

    const isHowGetTrackModalVisible =
      search.get('isHowGetTrackModalVisible') === 'true'

    const handleCloseHowGetTrackModal = (): void => {
      search.has('isHowGetTrackModalVisible') && navigate(NAVIGATE_BACK_INDEX)
    }

    if (!isHowGetTrackModalVisible) {
      return null
    }

    return (
      <StyledModal
        open={isHowGetTrackModalVisible}
        footer={null}
        title={t('howToGetYourTrackURL.howToGetYourTrackURL')}
        onCancel={handleCloseHowGetTrackModal}
        centered
      >
        <HowToGetYourTrackURLModalWrapper>
          <HowToGetYourTrackURLBlock>
            <HowToGetYourTrackURLSubtitle>
              {t('howToGetYourTrackURL.goToTheDiscoveryTab')}
            </HowToGetYourTrackURLSubtitle>
            <HowToGetYourTrackURLLists>
              <li>
                {t('howToGetYourTrackURL.openTikTokOnYourPhone')}&nbsp;
                <HighlightedPhone>
                  {t('howToGetYourTrackURL.phone')}&nbsp;
                </HighlightedPhone>
                {t('howToGetYourTrackURL.thisCannotBeDoneOnDesktop')}
              </li>
              <li> {t('howToGetYourTrackURL.tapTheDiscoveryTab')}</li>
            </HowToGetYourTrackURLLists>
            <HowToGetYourTrackURLImgWrapper>
              <img src={step1} height={'372px'} width={'172px'} alt='step1' />
            </HowToGetYourTrackURLImgWrapper>
          </HowToGetYourTrackURLBlock>
          <HowToGetYourTrackURLBlock>
            <HowToGetYourTrackURLSubtitle>
              {t('howToGetYourTrackURL.searchForYourTrack')}
            </HowToGetYourTrackURLSubtitle>
            <HowToGetYourTrackURLLists>
              <li> {t('howToGetYourTrackURL.typeTheTrackAndArtistName')}</li>
              <li> {t('howToGetYourTrackURL.goToTheSoundsTab')}</li>
              <li> {t('howToGetYourTrackURL.tapOnNameOfYourTrack')}</li>
            </HowToGetYourTrackURLLists>
            <HowToGetYourTrackURLImgWrapper>
              <img src={step2} height={'372px'} width={'172px'} alt='step2' />
            </HowToGetYourTrackURLImgWrapper>
          </HowToGetYourTrackURLBlock>
          <HowToGetYourTrackURLBlock>
            <HowToGetYourTrackURLSubtitle>
              {t('howToGetYourTrackURL.copyTheLink')}
            </HowToGetYourTrackURLSubtitle>
            <HowToGetYourTrackURLLists>
              <li> {t('howToGetYourTrackURL.tapTheShareButton')}</li>
              <li> {t('howToGetYourTrackURL.selectCopyLink')}</li>
              <li> {t('howToGetYourTrackURL.pasteThisLink')}</li>
            </HowToGetYourTrackURLLists>
            <HowToGetYourTrackURLImgWrapper>
              <img src={step3} height={'372px'} width={'172px'} alt='step3' />
            </HowToGetYourTrackURLImgWrapper>
          </HowToGetYourTrackURLBlock>
        </HowToGetYourTrackURLModalWrapper>
      </StyledModal>
    )
  }
