import { FC, Suspense } from 'react'

import { Route, Routes } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import SignUp from 'src/Containers/SignUp/SignUp'
import ForgotPassword from 'src/Containers/ForgotPasswordPage'
import ResetPassword from 'src/Containers/ResetPassword'
import ExpiredLinkPage from 'src/Containers/ui/ExpiredLinkPage'
import InternalServerError from 'src/Containers/ui/InternalServerError'
import Registration from 'src/Containers/Registration'
import ReferralLinkNotExist from 'src/Containers/ReferralLinkNotExist/index'
import LogIn from 'src/Containers/LogIn/Login'
import CreateCampaignWithoutSignup from 'src/Containers/CreateCampaignWithoutSignup'
import Rate from 'src/Containers/Rate'
import TiktokWidgetSearch from 'src/Containers/TiktokWidgetSearch'

import {
  signUpCurator,
  forgotPass,
  reset,
  create,
  spotify,
  errors,
  login,
  signUpCreator,
  internalServerError,
  rate,
  referralLinkNotExist,
  widgets,
  tiktokSearch,
} from './routes'

const PublicComponents: FC = () => (
  <Routes>
    <Route Component={LogIn} path='/' />
    <Route Component={LogIn} path={login} />
    <Route Component={SignUp} path={signUpCurator} />
    <Route Component={ForgotPassword} path={forgotPass} />
    <Route Component={ResetPassword} path={`${reset}/:uuid`} />
    <Route Component={ExpiredLinkPage} path={errors} />
    <Route Component={InternalServerError} path={internalServerError} />
    <Route
      element={
        <Suspense>
          <CreateCampaignWithoutSignup />
        </Suspense>
      }
      path={create}
    />
    <Route
      element={
        <Suspense>
          <TiktokWidgetSearch />
        </Suspense>
      }
      path={`${widgets}${tiktokSearch}`}
    />
    <Route Component={Registration} path={signUpCreator} />
    <Route Component={Rate} path={rate} />
    <Route Component={ReferralLinkNotExist} path={referralLinkNotExist} />
    <Route
      Component={() => {
        window.location.href = APP.SPOTIFY_AUTH_API
        return null
      }}
      path={spotify}
    />
    <Route Component={LogIn} path='*' />
  </Routes>
)

export default PublicComponents
