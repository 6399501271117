import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'src/Hooks/redux'
import { getIsBlockedCreatorSelector } from 'src/Redux/auth-process/userSlice/selectors'

import { TrackCover } from '../TrackCover'
import { EarnInfo } from '../EarnInfo'
import { SoundStatus } from '../../../SoundsList'
import { SoundListItemStatus } from '../SoundsListItemStatus'
import { DeadlineInfo } from '../DeadlineInfo'
import {
  MobileBlockWrapper,
  SoundsListItemContainer,
  StyledButton,
} from '../../styles'
import { TabKeysEnum } from '../../../../types'

type InProgressSoundsListItemProps = {
  id: number
  trackCoverUrl: string
  trackTitle: string
  trackAuthorName: string
  deadline: string
}

export const InProgressSoundsListItem: FunctionComponent<
  InProgressSoundsListItemProps
> = ({ id, trackCoverUrl, trackTitle, trackAuthorName, deadline }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isCreatorBlocked = useAppSelector(getIsBlockedCreatorSelector)

  const onDetailsClick = (): void => {
    navigate(`${TabKeysEnum.IN_PROGRESS}/${id}`)
  }

  return (
    <SoundsListItemContainer>
      <TrackCover
        trackAuthorName={trackAuthorName}
        trackCoverUrl={trackCoverUrl}
        trackTitle={trackTitle}
      />

      <DeadlineInfo deadline={deadline} />

      <EarnInfo />

      <SoundListItemStatus status={SoundStatus.IN_PROGRESS} />

      <MobileBlockWrapper>
        <StyledButton
          type={'green'}
          onClick={onDetailsClick}
          disabled={isCreatorBlocked}
        >
          {t('soundsPage.submit')}
        </StyledButton>
      </MobileBlockWrapper>
    </SoundsListItemContainer>
  )
}
