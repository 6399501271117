import TagManager from 'react-gtm-module'

import { User } from 'src/Types'

import { EventTypeEnum } from '../tagManagerEnums'

export const pageViewEvent = (
  user?: User | null,
  currentPageInfo?: { pageTitle: string; pagePath: string },
): void => {
  const tagManagerArgs = {
    dataLayer: {
      event: EventTypeEnum.PageView,
      pageTitle: currentPageInfo && currentPageInfo.pageTitle,
      pagePath: currentPageInfo && currentPageInfo.pagePath,
      ...(user
        ? { userID: user?.id || user?.influencerId || user?.curatorId }
        : {}),
    },
  }

  TagManager.dataLayer(tagManagerArgs)
}

export const locationEvent = (): void => {
  const tagManagerLocationArgs = {
    dataLayer: {
      originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
    },
  }
  TagManager.dataLayer(tagManagerLocationArgs)
}
