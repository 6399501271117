import React, { ReactElement, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'
import { Rule } from 'src/Components/Rule'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useAcceptSoundMutation } from 'src/Redux/creatorNewSoundsApi'

import { SomethingWentWrongAlert } from '../../../SomethingWentWrongAlert'
import { SoundStatus } from '../../../SoundsList'

import { greenRules, redRules } from './helper'

import {
  ActionsWrapper,
  ButtonsWrapper,
  Container,
  ContentWrapper,
  MainTitle,
  RulesList,
  StyledButton,
  StyledCheckbox,
} from './styles'

export const ConfirmRules = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { soundId } = useParams<{ soundId?: string }>()

  const [isRulesRead, setIsRulesRead] = useState(false)
  const { isError: isProblemsWithTikTokAlertVisible } =
    useGetMonetizationDataQuery()
  const { setSearch } = useCustomSearchParams()
  const [acceptSound, { isLoading, isError: isDefaultErrorVisible }] =
    useAcceptSoundMutation()

  const onCheckboxChange = (): void => {
    setIsRulesRead(!isRulesRead)
  }

  const onGoBackClick = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
  }

  // TODO DIMA add after BE
  const deadlineDate = new Date().toISOString()

  const onConfirmClick = (): void => {
    acceptSound({ soundId: Number(soundId) })
      .then((response) => {
        if ('data' in response) {
          setSearch({
            soundStatus: SoundStatus.IN_PROGRESS,
            eventId: String(response.data.eventId),
            deadline: deadlineDate,
          })
        }
        return true
      })
      .catch(() => false)
  }

  const checkboxTitle = t('soundsPage.confirmText')

  return (
    <Container>
      <MainTitle>{t('soundsPage.rulesTitle')}</MainTitle>
      {isDefaultErrorVisible && <SomethingWentWrongAlert />}
      {isProblemsWithTikTokAlertVisible && <ProblemsWithTikTokErrorAlert />}

      <ContentWrapper>
        <RulesList>
          {greenRules.map((rule) => (
            <Rule
              key={rule.title}
              title={rule.title}
              description={rule.description}
              type='green'
            />
          ))}
        </RulesList>

        <RulesList>
          {redRules.map((rule) => (
            <Rule
              key={rule.title}
              title={rule.title}
              description={rule.description}
              type='red'
            />
          ))}
        </RulesList>

        <ActionsWrapper>
          <StyledCheckbox
            onChange={onCheckboxChange}
            checked={isRulesRead}
            title={checkboxTitle}
          />

          <ButtonsWrapper>
            <DottedBtn
              content={t('soundsPage.goBack')}
              onClick={onGoBackClick}
            />
            <StyledButton
              type={'green'}
              disabled={!isRulesRead}
              onClick={onConfirmClick}
              loading={isLoading}
            >
              {t('soundsPage.confirm')}
            </StyledButton>
          </ButtonsWrapper>
        </ActionsWrapper>
      </ContentWrapper>
    </Container>
  )
}
