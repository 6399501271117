import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { FeedDetails } from 'src/Types'

interface SendFeedReviewBody {
  campaign_id: string
  position: number

  comment: string
  recommendations: string | null
  value: { id: number; type: string }
}

const trackFeedDetailsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrackDetails: builder.query<FeedDetails, string>({
      query: (campaignId: string) =>
        `${APP.SERVER}/review/feed-details/${campaignId}`,
    }),
    reserveReview: builder.mutation<string, string>({
      query: (campaignId: string) => ({
        url: `${APP.SERVER}/review/feed-reserve/${campaignId}`,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
    skipReview: builder.mutation<string, string>({
      query: (campaignId: string) => ({
        url: `${APP.SERVER}/review/feed-skip/${campaignId}`,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
    sendFeedReview: builder.mutation<string, SendFeedReviewBody>({
      query: (body) => ({
        body,
        url: `${APP.SERVER}/review/feed`,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTrackDetailsQuery,
  useReserveReviewMutation,
  useSkipReviewMutation,
  useSendFeedReviewMutation,
} = trackFeedDetailsApi
