import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CommonHint } from 'src/Components/CommonHint'
import { useAppSelector } from 'src/Hooks/redux'
import { getEstimatedTiktokVideos } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { colors } from 'src/Styled/variables'
import { PotentialVideos } from 'src/Containers/ui/PotentialVideos'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
const Title = styled.div`
  color: ${colors.mainText};
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`

const TiktokEstimationContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
`
const SubTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`
const Block = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
`

export const TiktokEstimation: FC = () => {
  const { t } = useTranslation()
  // const { maxViews, minViews } = useAppSelector(getEstimatedTiktokViews)
  const { estimatedMaxVideos, estimatedMinVideos } = useAppSelector(
    getEstimatedTiktokVideos,
  )
  return (
    <Wrapper>
      <Title>{t('scheduleCampaignPage.estimatedReach')}</Title>
      <TiktokEstimationContainer>
        {/* temporary hidden under the task DEV-6853 */}
        {/* <Block>
          <SubTitle>
            <div>{t('scheduleCampaignPage.numberOfPotentialViews')}</div>
            <CommonHint text={t('scheduleCampaignPage.potentialViewsHint')} />
          </SubTitle>
          <div>
            <StyledText>
              <PotentialViews minViews={minViews} maxViews={maxViews} />
            </StyledText>
          </div>
        </Block> */}
        <Block>
          <SubTitle>
            <div>{t('scheduleCampaignPage.potentialVideos')}</div>
            <CommonHint text={t('scheduleCampaignPage.potentialVideosHint2')} />
          </SubTitle>

          <PotentialVideos
            minVideos={estimatedMinVideos}
            maxVideos={estimatedMaxVideos}
          />
        </Block>
      </TiktokEstimationContainer>
    </Wrapper>
  )
}
