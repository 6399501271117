import { combineReducers } from '@reduxjs/toolkit'

import { budgetRangeSlice } from './budgetRangeSlice'
import { audienceTiktokSlice } from './audienceTiktokSlice'
import { couponSlice } from './couponSlice'
import { spotifyCampaignSlice } from './spotifyCampaignSlice'
import { tiktokCampaignSlice } from './tiktokCampaign'

export const scheduleProcess = combineReducers({
  budgetRange: budgetRangeSlice.reducer,
  audienceTiktok: audienceTiktokSlice.reducer,
  coupon: couponSlice.reducer,
  spotify: spotifyCampaignSlice.reducer,
  tiktok: tiktokCampaignSlice.reducer,
})
