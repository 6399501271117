import { useEffect } from 'react'

import { useGrowthBook } from '@growthbook/growthbook-react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { addFeatureForUser } from 'src/Redux/A-BFeatures-process/api-actions'
import {
  getFeaturesIsSucceeded,
  getIsFeatureValue,
} from 'src/Redux/A-BFeatures-process/selectors'
import { getIsNewUserForCheckoutTimerExp } from 'src/Redux/auth-process/userSlice/selectors'
import { getArtistBalanceDetails } from 'src/Redux/artistBalanceProcess/ArtistBalanceHistorySlice/api-actions'
import { getIsArtistNotFromInvitedLink } from 'src/Redux/artistBalanceProcess/ArtistBalanceHistorySlice/selectors'

import { useCustomSearchParams } from './useCustomSearchParams'
import { useBonusTooltipTimer } from './useBonusTooltip'

export const QUICK_CHECKOUT_FEATURE_NAME = 'quick_checkout'
export const EXPIRED_TIMER_FEATURE_VALUE = 4

export const useCheckoutTimerFeatureFlag = (): void => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const { dropCheckoutBonusFeatureForUser } = useBonusTooltipTimer()

  const paymentStatus = search.get('paymentStatus')

  const featuresIsSucceeded = useAppSelector(getFeaturesIsSucceeded)
  const isNewUser = useAppSelector(getIsNewUserForCheckoutTimerExp)
  const featureValue = useAppSelector(
    getIsFeatureValue(QUICK_CHECKOUT_FEATURE_NAME),
  )
  const isArtistNotFromInvitedLink = useAppSelector(
    getIsArtistNotFromInvitedLink,
  )

  const isNoFeature = featuresIsSucceeded && !featureValue && featureValue !== 0

  const isQuickCheckoutFeatureAvailable =
    (isNoFeature || featureValue === EXPIRED_TIMER_FEATURE_VALUE) &&
    isNewUser &&
    isArtistNotFromInvitedLink

  const growthBook = useGrowthBook()
  const isSuccessPageVisible = paymentStatus === 'success'

  useEffect(() => {
    if (isSuccessPageVisible) {
      dropCheckoutBonusFeatureForUser()
    }
  }, [isSuccessPageVisible, dropCheckoutBonusFeatureForUser])

  useEffect(() => {
    void dispatch(getArtistBalanceDetails())
  }, [dispatch])

  useEffect(() => {
    if (isQuickCheckoutFeatureAvailable) {
      const value = growthBook?.getFeatureValue('checkout_timer', 0)

      if (value) {
        void dispatch(
          addFeatureForUser({
            featureName: QUICK_CHECKOUT_FEATURE_NAME,
            variation: Number(value),
          }),
        )
      }
    }
  }, [dispatch, growthBook, isQuickCheckoutFeatureAvailable])
}
