import { useCallback, useEffect } from 'react'

import { useSelector } from 'react-redux'

import { setPaymentMethod } from 'src/Redux/payment-process'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { getIsEnoughBalance } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { getIsEnoughBalanceForTiktok } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getBillingAgreementEmailSelector } from 'src/Redux/billingAgreement-process/get-billingAgrreement'

import { useAppDispatch, useAppSelector } from './redux'

export const useExistingBillingAgreement = (): void => {
  const dispatch = useAppDispatch()

  const payPalTransactionEmail = useSelector(getBillingAgreementEmailSelector)
  const isEnoughBalance = useAppSelector(getIsEnoughBalance)
  const isEnoughBalanceForTiktok = useAppSelector(getIsEnoughBalanceForTiktok)
  const getDefaultValue = useCallback(() => {
    if (isEnoughBalance || isEnoughBalanceForTiktok) {
      dispatch(setPaymentMethod(PaymentMethodEnum.Credits))
      return
    }
    if (payPalTransactionEmail) {
      dispatch(setPaymentMethod(PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT))
      return
    }
    dispatch(setPaymentMethod(PaymentMethodEnum.STRIPE))
  }, [
    dispatch,
    isEnoughBalance,
    isEnoughBalanceForTiktok,
    payPalTransactionEmail,
  ])

  useEffect(() => {
    getDefaultValue()
  }, [getDefaultValue])
}
