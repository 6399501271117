import TagManager from 'react-gtm-module'

import { PlanTypes } from 'src/Redux/getPremiumProcess/api-actions'
import { ID } from 'src/Types'

import {
  EventNameEnum,
  EventTypeEnum,
  PaymentMethod,
  PremiumFeaturesButtonTypeEnum,
} from '../tagManagerEnums'

export const changePlanEvent = ({
  userId,
  planName,
}: {
  userId?: ID | null
  planName: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.ChangedPlan,
        subscription_plan: planName,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const getPremiumFeaturesEvent = ({
  userId,
  buttonType,
}: {
  userId?: ID | null
  buttonType?: PremiumFeaturesButtonTypeEnum
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.GetPremiumFeatures,
        'account-type': 'artist',
        button_type: buttonType,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const subscribeEvent = ({
  userId,
  planName,
}: {
  userId?: ID | null
  planName: PlanTypes
}): void => {
  let eventName = ''
  switch (planName) {
    case 'lite150':
      eventName = EventNameEnum.SubscribeStarter
      break
    case 'regular250':
      eventName = EventNameEnum.SubscribeBusiness
      break
    case 'super500':
      eventName = EventNameEnum.SubscribeProfessional
      break
    default:
      break
  }
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: eventName,
        'account-type': 'artist',
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const subscriptionConfirmedPaymentEvent = ({
  userId,
  planName,
  paymentMethod,
}: {
  userId?: ID | null
  planName: string
  paymentMethod: PaymentMethod
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.SubscriptionConfirmedPayment,
        subscription_plan: planName,
        payment_method: paymentMethod,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const subscriptionPaymentCompletedEvent = ({
  userId,
  planName,
  paymentMethod,
}: {
  userId?: ID | null
  planName: string
  paymentMethod: PaymentMethod
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.SubscriptionPaymentCompleted,
        subscription_plan: planName,
        payment_method: paymentMethod,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const subscriptionSelectedEvent = ({
  userId,
  planName,
}: {
  userId?: ID | null
  planName: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.SubscriptionSelected,
        subscription_plan: planName,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}
