import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { settings } from 'src/Router/routes'
import Alert from 'src/Components/Alert'
import { getIsEmailBounced } from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'

import { AlertText, DangerLink } from './styles'

export const EmailNotConfirmedAlert: FunctionComponent = () => {
  const { t } = useTranslation()
  const isEmailBounced = useAppSelector(getIsEmailBounced)

  if (!isEmailBounced) {
    return null
  }

  return (
    <Alert color='danger' icon>
      <div>
        <AlertText>
          {t('campaignsPage.bouncedEmail')}
          <br />
          {t('campaignsPage.bouncedEmailSecond')}&nbsp;
        </AlertText>
        <DangerLink to={`${settings}/email`}>
          {t('campaignsPage.clickHere')}
        </DangerLink>
      </div>
    </Alert>
  )
}
