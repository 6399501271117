import styled, { css } from 'styled-components'

import { colors, device } from 'src/Styled/variables'

interface Props {
  isHiddenOnMobile?: boolean
  isVariantB?: boolean
  isFAQ?: boolean
}

export const PlatformCardContainer = styled.div`
  display: grid;
  text-align: center;
  padding: 0px 16px;
  grid-auto-flow: dense;
  @media ${device.mobileMin} {
    padding: 0px;
  }
`

export const StyledPlatformModal = styled.div<{
  isVariantB?: boolean
  isFAQ?: boolean
}>`
  overflow-y: auto;
  background-color: ${colors.greyBlue};
  width: 100%;
  ${({ isVariantB, isFAQ }) => {
    if (!isVariantB || !isFAQ) {
      return css`
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000;
      `
    }
    return css``
  }}
`

export const ButtonWrapper = styled.div`
  width: 184px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 40px;
  @media ${device.mobileMin} {
    margin-bottom: 80px;
  }
`

export const InnerContainer = styled.div<Props>`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;

  @media ${device.mobileMin} {
    padding-right: 0px;
    padding-left: 0px;
    max-width: 800px;
  }
`
