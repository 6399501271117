import { FC, useEffect } from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

import { FullWideNotification } from 'src/Components/FullWideNotification'
import logo from 'src/Assets/Png/logo.png'
import { colors } from 'src/Styled/variables'
import { resetPassword } from 'src/Redux/auth-process/emailPasswordSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

import { locationEvent, pageViewEvent } from '../../Helpers/TagManager'

import ForgotPasswordForm from './components/ResetPasswordForm'

import { Card, Container, Head, Logo, TextCenter, Wrapper } from './styles'
const DELAY_3000 = 3000

const ResetPassword: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const dispatch = useAppDispatch()

  useEffect(() => {
    pageViewEvent(null, pageInfo.resetPassword)
    locationEvent()
  }, [])

  const { uuid } = useParams<{ uuid?: string }>()

  const handleSubmit = (formStates: {
    password: string
    confirm: string
  }): void => {
    const { password } = formStates
    if (password && uuid) {
      void dispatch(resetPassword({ password, uuid }))
        .unwrap()
        .then((res) => {
          void messageApi.open({
            content: (
              <FullWideNotification
                message={t('forgotPasswordPage.passwordChanged')}
              />
            ),
            duration: 3,
            style: { backgroundColor: colors.badgeGreen },
          })
          return res
        })
        .catch(() => {
          void messageApi.open({
            content: (
              <FullWideNotification
                message={t('forgotPasswordPage.linkExpired')}
              />
            ),
            duration: 3,
            style: { backgroundColor: colors.badgeRed },
          })
        })
        .finally(() => {
          setTimeout(() => {
            navigate('/')
          }, DELAY_3000)
        })
    }
  }

  return (
    <Wrapper>
      {contextHolder}
      <Container>
        <Logo>
          <img alt='logo' src={logo} />
        </Logo>
        <Card>
          <Head>
            <h3>{t('forgotPasswordPage.resetPassword')}</h3>
          </Head>
          <ForgotPasswordForm onSubmit={handleSubmit} />
          <TextCenter>
            <Link to='/'>{t('forgotPasswordPage.backToLogin')}</Link>
          </TextCenter>
        </Card>
      </Container>
    </Wrapper>
  )
}

export default ResetPassword
