import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const FormWrapper = styled.div`
  margin-left: 20px;
`

export const AlertWrapper = styled.div`
  padding: 20px 20px 0;
`
export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
`
export const FormGroupField = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  input {
    border: 1px solid #eee;
    border-radius: 8px;
    height: 47px;
    background-color: #fff;
    padding: 13px 16px;
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
`
export const Form = styled.form`
  max-width: 400px;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
`
export const FormGroup = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`
export const FormGroupLabel = styled.span`
  margin-bottom: 8px;
  display: inline-block;
  color: ${colors.mainText};
`

export const TabsWrapper = styled.div`
  margin-bottom: 10px;
`

export const CardBody = styled.div`
  background-color: white;
  padding: 24px;
`

export const SettingsPageHeader = styled.div`
  font-size: 18px;
`

export const SettingsHeader = styled.div`
  font-size: ${variables.fontSize18};
  line-height: 27px;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  margin-bottom: 22.5px;
  @media ${device.mobileMin} {
    margin-bottom: 30.5px;
  }
`

export const ConfirmationButtons = styled.div`
  display: flex;
  gap: 15px;
  padding: 20px 20px 0;
  margin-bottom: 40px;
  justify-content: flex-end;
  div {
    max-width: 30%;
  }
`
export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  > div {
    width: 30%;
  }
`
