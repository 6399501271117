import { useEffect, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import { useSelector } from 'react-redux'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getCurrencyTiktok } from 'src/Redux/auth-process/currencySlice/selectors'
import { getAudienceTiktok } from 'src/Redux/schedule-process/audienceTiktokSlice/api-actions'
import { DEBOUNCE_TIMER_1500 } from 'src/Constants/constants'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { APP } from 'src/Configs/App'
import { DIVIDE_BY_TWO } from 'src/Constants/numeric'
import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'

interface UseTiktokPrice {
  rate: number
  valueInput: number
  valueSlider: number
  currencySymbol: string
  setValueInput: (value: number) => void
  setValueSlider: (value: number) => void
  savePrice: (value: number) => void
}

const validateValue = (value: number): number => {
  if (value > APP.MAX_BUDGET_TIKTOK_CAMPAIGN) {
    return APP.MAX_BUDGET_TIKTOK_CAMPAIGN
  }
  if (value < APP.MIN_BUDGET_TIKTOK_CAMPAIGN) {
    return APP.MIN_BUDGET_TIKTOK_CAMPAIGN
  }
  return value
}

// This hook is used to get the price of the tiktok campaign
// and can be used only for budget slider
export const useTiktokPrice = (): UseTiktokPrice => {
  const dispatch = useAppDispatch()
  const { rate, currencySymbol } = useAppSelector(getCurrencyTiktok)
  const scheduledCampaign = useSelector(getScheduledTikTokCampaign)
  const price = useAppSelector(getTiktokPrice)

  const { handleBudgetEvents } = useScheduleEvents()

  const [valueInput, setValueInput] = useState<number>(0)
  const [valueSlider, setValueSlider] = useState<number>(0)

  const savePrice = useDebouncedCallback((value: number) => {
    void dispatch(getAudienceTiktok(validateValue(value)))
      .unwrap()
      .then((data) => {
        scheduledCampaign &&
          handleBudgetEvents({
            platformName: CampaignPlatformTypeEnum.TIKTOK,
            resultPrice: data.price,
          })

        setValueInput(Math.round(data.price * rate))
        setValueSlider(value)
        return data
      })
  }, DEBOUNCE_TIMER_1500)

  useEffect(() => {
    if (price) {
      setValueInput(Math.round(price * rate))
      setValueSlider(price)
      return
    }
    if (!price) {
      savePrice(
        (APP.MIN_BUDGET_TIKTOK_CAMPAIGN + APP.MAX_BUDGET_TIKTOK_CAMPAIGN) /
          DIVIDE_BY_TWO,
      )
    }
  }, [price, rate, savePrice])

  return {
    rate,
    currencySymbol,
    valueInput,
    valueSlider,
    setValueInput,
    setValueSlider,
    savePrice,
  }
}
