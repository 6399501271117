import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { PlatformModal } from 'src/Components/PlatformModal'
import { CampaignsListBonusAlert } from 'src/Containers/CampaignsList/components/CampaignsListBonusAlert'
import { useSimpleAddTrack } from 'src/Hooks/useSimpleAddTrack'
import { UserRegisterModal } from 'src/Containers/CreateCampaignWithoutSignup/components/UserRegisterModal'

import { BonusAlert } from '../BonusAlert'
import { WelcomeAlert } from '../WelcomeAlert'
import { PlatformModalWrapper } from '../PlatformModal/PlatformModalWrapper'

import { InfoForNotAuthUser } from './InfoForNotAuthUser'
import { CreateCampaignHeader } from './components/CreateCampaignHeader'
import { HelmetTitle } from './components/HelmetTitle'
import { StepsWrapper } from './components/StepsWrapper'
import { StartButtons } from './StartButtons'
import { useCreateCampaignLayout } from './useCreateCampaignLayout'

import {
  CreateFirstCampaignBlock,
  CreateCampaignBlockContainer,
  CreateCampaignContainer,
  CreateCampaignHead,
  CreateCampaignSubHead,
} from './styles'

export const CreateCampaignLayout: FunctionComponent = () => {
  const isVariantB = useSimpleAddTrack()

  const { t } = useTranslation()
  const { handleSpotifyToggle } = useCreateCampaignLayout()

  return (
    <CreateCampaignContainer>
      <StepsWrapper>
        <CreateFirstCampaignBlock>
          <CreateCampaignHeader />
          <CreateCampaignBlockContainer>
            <HelmetTitle />
            <BonusAlert />
            <WelcomeAlert />
            <CampaignsListBonusAlert />

            <CreateCampaignHead>
              {t('campaignsPage.createNewCampaign')}
            </CreateCampaignHead>
            <CreateCampaignSubHead>
              {t('campaignsPage.chooseTypeOfCampaignToStart')}
            </CreateCampaignSubHead>
            <StartButtons onClick={handleSpotifyToggle} />
            <InfoForNotAuthUser />
          </CreateCampaignBlockContainer>
        </CreateFirstCampaignBlock>
        <PlatformModalWrapper>
          <PlatformModal isVariantB={isVariantB} />
        </PlatformModalWrapper>

        <UserRegisterModal />
      </StepsWrapper>
    </CreateCampaignContainer>
  )
}

CreateCampaignLayout.displayName = 'CreateCampaignLayout'
