export const campaignsList = '/campaigns'
export const widgets = '/widgets'
export const tiktokSearch = '/tiktok-search'
export const createdFirst = '/created-first'
export const feedback = '/feedback'
export const referAndEarn = '/refer-and-earn'
export const createCampaign = '/new'
export const monetization = '/monetization'
export const spotify = '/spotify'
export const playlists = '/playlists'
export const scheduleCampaign = '/schedule'
export const scheduleCampaignTikTok = '/scheduleTikTok'
export const reviews = '/reviews'
export const tracks = '/tracks'
export const tracksFeed = '/tracksFeed'
export const reviewed = '/reviewed'
export const balance = '/balance'
export const subscription = '/subscription'
export const getPremium = '/getPremium'
export const root = '/'
export const login = '/login'
export const sounds = '/sounds'
export const signUpArtist = '/signup-artist'
export const signUpCurator = '/signup-curator'
export const forgotPass = '/forgot-password'
export const checkout = '/checkout'
export const campaignResults = '/view'
export const settings = '/settings'
export const rank = '/rank'
export const levels = '/levels'
export const reset = '/reset-password'
export const submitted = '/submitted'
export const details = '/details'
export const create = '/create'
export const review = '/review'
export const completed = '/completed'
export const admin = '/admin/management'
export const analytics = '/analytics'
export const artists = '/artists'
export const curators = '/curators'
export const errors = '/errors'
export const signUpCreator = '/signup-creator'
export const influencerBoard = '/influencer'
export const internalServerError = '/internal-server-error'
export const rate = '/rate'
export const referralLinkNotExist = '/referral-link-not-exist'
