import i18n from 'src/Localization/i18n'

export const infoBlocks = [
  {
    title: i18n.t('monetization.whatAreTheRequirements'),
    listItems: [
      i18n.t('monetization.follow'),
      i18n.t('monetization.ensureYourVideo'),
      i18n.t('monetization.attainAMinimum'),
      i18n.t('monetization.keepThePost'),
    ],
    link: {
      title: i18n.t('monetization.videoPolicies'),
      url: '',
    },
  },
  {
    title: i18n.t('monetization.whatAreTheRequirementsForProfile'),
    listItems: [
      i18n.t('monetization.ensureYourTikTok'),
      i18n.t('monetization.allowComments'),
      i18n.t('monetization.stayActive'),
    ],
    link: {
      title: i18n.t('monetization.profilePolicies'),
      url: '',
    },
  },
]
