import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../../types'

import { setTiktokCampaignEventRate } from './api-actions'

export interface IResult {
  campaignId: number
  paidAmount: number
  rateVideo: number
  trackId: string
}
export interface IVideoRateProcess {
  error: ApiError | null
  loading: boolean
  result?: IResult
}

const initialState: IVideoRateProcess = {
  error: null,
  loading: false,
}

export const videoRateProcess = createSlice({
  initialState,
  name: NameSpace.VideoRate,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setTiktokCampaignEventRate.pending, (state) => {
        state.loading = true
      })
      .addCase(setTiktokCampaignEventRate.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(setTiktokCampaignEventRate.rejected, (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      })
  },
})
