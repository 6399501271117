import { Tabs } from 'antd'
import styled from 'styled-components'

import { device, variables, colors } from 'src/Styled/variables'

export const SearchWrapper = styled.div`
  margin-left: auto;
  width: 200px;
`
export const CampaignNotFound = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
export const PaginationBottom = styled.div`
  margin-top: 20px;
`
export const StyledTabs = styled(Tabs)`
  position: relative;
  .ant-tabs-nav-list {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
  }
  padding-left: 0;
  .ant-tabs-tab-btn {
    padding: 0 24px 0 24px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
  .ant-tabs-ink-bar {
    background: ${colors.green};
    width: 116px;
    @media ${device.smallScreen} {
      width: 132px;
    }
  }
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 10px 0 11px 0;
    font-size: ${variables.fontSize14};
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    @media ${device.smallScreen} {
      padding: 11px 0 11px 0;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 12px;
    width: 90%;
    @media ${device.smallScreen} {
      margin-bottom: 12px;
    }
  }
`
export const Body = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 5px;
`

export const StyledTable = styled.div`
  .ant-spin .ant-spin-dot-item {
    background-color: ${colors.badgeGreen};
  }
`
