import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { variables, colors } from 'src/Styled/variables'

interface StyledComponentsProps {
  collapse?: boolean
  isGreen?: boolean
}

export const StyledNavLink = styled(NavLink)<{ $isGreen?: boolean }>`
  color: ${colors.greyTextWhiteBg};
  cursor: pointer;
  transition: all 0.2s ease-out;
  & svg {
    path {
      fill: ${({ $isGreen }) =>
        $isGreen ? colors.green : colors.greyTextWhiteBg};
    }
  }

  &:visited {
    color: ${colors.greyTextWhiteBg};
    & svg {
      path {
        fill: ${({ $isGreen }) =>
          $isGreen ? colors.green : colors.greyTextWhiteBg};
      }
    }
  }

  &.active {
    color: ${colors.mainText};
    background-color: ${colors.greyBg};
    li {
      div {
        color: ${colors.mainText};
      }
    }

    & svg {
      path {
        fill: ${({ $isGreen }) => ($isGreen ? colors.green : colors.mainText)};
      }
    }
  }
  &:hover {
    color: ${colors.mainText};
    background-color: ${colors.greyBg};

    & svg {
      path {
        fill: ${({ $isGreen }) => ($isGreen ? colors.green : colors.mainText)};
      }
    }
  }
`
export const SidebarLinkItem = styled.li<StyledComponentsProps>`
  height: 40px;
  transition: all 0.3s;
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: ${({ collapse }) => (!collapse ? '12px 16px' : '12px 20px')};
  &:hover {
    background-color: ${colors.greyBg};
  }
`

export const SidebarLinkText = styled.div<StyledComponentsProps>`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  position: relative;
  display: flex;
  left: 10px;
  width: ${({ collapse }) => (collapse ? '160px' : '0px')};
  overflow: hidden;
  color: ${({ isGreen }) => (isGreen ? colors.green : colors.greyTextWhiteBg)};

  opacity: ${({ collapse }) => (collapse ? '1' : '0')};
  transition: all 0.2s ease-out;
  white-space: nowrap;
  &:hover {
    color: ${({ isGreen }) => (isGreen ? colors.green : colors.mainText)};
  }
`

export const SidebarIconBlock = styled.div`
  margin-right: 6px;
  color: ${colors.greyTextWhiteBg};
  display: flex;
  flex-direction: row;
  svg {
    vertical-align: middle;
  }

  & a:visited {
    color: ${colors.greyTextWhiteBg};
  }
`

export const NewLabel = styled.span`
  margin-left: 9px;
  color: ${colors.green};
  font-size: ${variables.fontSize10};
  line-height: 21px;
  background: ${colors.green1Light};
  padding: 2px 8px;
  border-radius: 4px;
`
