import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignStatusEnum, TiktokCampaignStatus } from 'src/Types/index'
import Button from 'src/Components/Buttons/Button'
import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'

import { DetailsButton } from '../DetailsButton'

import { CampaignCardButtonProps } from './CampaignCardButtonProps'
import { CampaignCardEndedButton } from './CampaignCardEndedButton'

import { CardButtonWrapper, StyledBtn, Text } from './styles'

const PENDING_STATUSES = [
  CampaignStatusEnum.PENDING_APPROVAL,
  TiktokCampaignStatus.AWAITING_REVIEW,
]

const APPROVED_STATUSES = [
  CampaignStatusEnum.APPROVED,
  TiktokCampaignStatus.PENDING,
]
const RUNNING_STATUSES = [
  CampaignStatusEnum.RUNNING,
  TiktokCampaignStatus.IN_PROCESS,
]

const STOPPED_STATUSES = [
  TiktokCampaignStatus.STOPPED,
  CampaignStatusEnum.ENDED,
  CampaignStatusEnum.STOPPED,
  TiktokCampaignStatus.STOPPED_BY_ADMIN,
]

export const CampaignCardButton: FC<CampaignCardButtonProps> = ({
  campaign,
  onScheduleCampaign,
  onViewResults,
  onRelaunchCampaign,
}) => {
  const { t } = useTranslation()

  const { status, startDate, type, platformType, relaunched } = campaign
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (PENDING_STATUSES.includes(status)) {
    return <Text>{t('campaignsPage.teamWillReview')}</Text>
  }

  if (APPROVED_STATUSES.includes(status)) {
    return (
      <StyledBtn>
        <CardButtonWrapper>
          <Button type='green' onClick={onScheduleCampaign}>
            {t('campaignsPage.startCampaign')}
          </Button>
        </CardButtonWrapper>
      </StyledBtn>
    )
  }
  if (RUNNING_STATUSES.includes(status)) {
    return (
      <StyledBtn>
        <CardButtonWrapper>
          <DetailsButton onViewResults={onViewResults} />
        </CardButtonWrapper>
      </StyledBtn>
    )
  }

  if (STOPPED_STATUSES.includes(status)) {
    return (
      <CampaignCardEndedButton
        platformType={platformType}
        type={type}
        relaunched={relaunched}
        onRelaunchCampaign={onRelaunchCampaign}
        onViewResults={onViewResults}
      />
    )
  }
  if (status === CampaignStatusEnum.SCHEDULED) {
    return (
      <Text>
        {t('campaignsPage.campaignWillStart')}&nbsp;
        {dayjs(startDate).tz(timezone).format(formatDate.formatDateMonthFull)}
      </Text>
    )
  }
  if (status === CampaignStatusEnum.PAYMENT_PENDING) {
    return <>{t('campaignsPage.pendingPayment')}</>
  }

  return null
}
