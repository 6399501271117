import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const TrackListItemContainer = styled.div`
  padding-bottom: 2px;
  width: 100%;
  min-height: 130px;

  @media ${device.mobileMax} {
    height: auto;
  }
`

export const ContentContainer = styled.div`
  position: relative;
  padding: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  background: ${colors.greyBg};
  border-radius: 4px;

  @media ${device.mobileMax} {
    background: ${colors.white};
  }
`

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;

  @media ${device.mobileMax} {
    flex-direction: column;
  }
`

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.largeScreen} {
    width: 340px;
  }
`

export const TrackPreview = styled.div`
  position: relative;
  flex: 1;
  margin-right: 40px;
  padding: 4px;
  height: 88px;
  background-color: ${colors.white};
  border-radius: 14px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

  @media ${device.mobileMax} {
    margin-right: 0;
    margin-bottom: 16px;
    flex: none;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & > div {
    width: 90px;
  }

  @media ${device.mobileMax} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

export const ProgressBarContainer = styled.div``

export const LimitReachedPlaceholder = styled.span`
  width: 91px;
  font-size: ${variables.fontSize14};
  line-height: 150%;
  text-align: center;
  color: ${colors.messegesYellow1};
`
