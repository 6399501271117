import { FC, HTMLProps, MouseEvent } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import styled from 'styled-components'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'

interface CrossButtonProps extends HTMLProps<HTMLButtonElement> {
  loading?: boolean
}

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const CrossButton: FC<CrossButtonProps> = (props) => {
  const { onClick, className } = props

  const debouncedClick = useDebouncedCallback(
    (event) => {
      onClick?.(event as MouseEvent<HTMLButtonElement>)
    },
    0,
    { trailing: true },
  )

  return (
    <CloseButton onClick={debouncedClick} className={className}>
      <CloseCross />
    </CloseButton>
  )
}
