import { FC, useCallback, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { tracksFeed } from 'src/Router/routes'
import { colors, device } from 'src/Styled/variables'
import { SpotifyTrackPreview } from 'src/Components/SpotifyTrackPreview/SpotifyTrackPreview'
import { useSendReview } from 'src/Redux/TrackFeedDetailsApi/useSendReview'
import { useTrackToReview } from 'src/Redux/TrackFeedDetailsApi/useTrackToReview'
import { useGetFeedEvent } from 'src/Hooks/useGetFeedEvent'

import { LoadingSpinner } from '../ui'

import { TrackToReviewHeader } from './TrackToReviewHeader/TrackToReviewHeader'
import { TrackToReviewDetails } from './TrackToReviewDetails'
import { TrackToReviewForm } from './TrackToReviewForm'
import { ReviewFormValues } from './TrackToReviewForm/helpers'
import { StopTimerMessage } from './StopTimerMessage'
import { SuccessSendReview } from './SuccessSendReview'
import { TrackToReviewAlert } from './TrackToReviewAlert'

const ContentContainer = styled.div`
  position: relative;
  padding: 16px;
  background: ${colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${device.mobileMin} {
    padding: 0;
  }
`
const WrapperContent = styled.div`
  @media ${device.mobileMin} {
    padding: 65px 0;
    width: 600px;
  }
`
const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
`
const Wrapper = styled.div`
  @media ${device.mobileMin} {
    position: fixed;
    z-index: 1;
    top: 60px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
    overflow-y: scroll;
  }
`

const TrackToReview: FC = () => {
  const navigate = useNavigate()
  const {
    sendReview,
    isLoading: isSendLoading,
    isSuccess,
    isError,
  } = useSendReview()
  const { data, isLoading, campaignId } = useTrackToReview(true)
  const [hasStopTimer, setHasStopTimer] = useState<boolean>(false)
  useGetFeedEvent(data, campaignId)

  const handleClose = (): void => {
    navigate(tracksFeed)
  }
  const handleStopTimer = useCallback(() => {
    setHasStopTimer(true)
  }, [])

  const handleSkip = (): void => {
    navigate(`${tracksFeed}?skip=${campaignId || ''}`)
  }

  const handleSendReview = async (body: ReviewFormValues): Promise<void> => {
    await sendReview({
      ...body,
      id: campaignId,
      reward: data?.potential_reward,
      trackId: data?.track.id,
    })
  }

  const showFrom = !hasStopTimer

  const showWarningForm = !hasStopTimer && Boolean(data?.isRejectLastThree)

  return (
    <Wrapper>
      {isLoading && (
        <StyledLoadingSpinner fullscreen color='green' size='large' />
      )}
      <WrapperContent>
        <TrackToReviewHeader
          id={campaignId}
          onStopTimer={handleStopTimer}
          onClose={handleClose}
        />
        <TrackToReviewAlert isError={isError} />
        <ContentContainer>
          <SpotifyTrackPreview trackUrl={data?.track?.track_url} />
          <TrackToReviewDetails
            genres={data?.track?.genres}
            languages={data?.track?.languages}
            reward={data?.potential_reward}
            status={data?.review_status}
          />
          {showFrom && (
            <TrackToReviewForm
              showWarningForm={showWarningForm}
              isLoading={isSendLoading}
              reward={data?.potential_reward}
              trackId={data?.track?.id}
              id={campaignId}
              onSendReview={handleSendReview}
              onSkip={handleSkip}
            />
          )}
          {hasStopTimer && <StopTimerMessage onClick={handleSkip} />}
          <SuccessSendReview onClose={handleClose} isSuccess={isSuccess} />
        </ContentContainer>
      </WrapperContent>
    </Wrapper>
  )
}

export default TrackToReview
