import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { clearCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'

export const useIsCurrentSpotifyCampaign = (): void => {
  const dispatch = useAppDispatch()
  const params = useParams()

  const campaignId = Number(params.number)
  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)

  useEffect(() => {
    if (
      campaignId !== Number(spotifyCampaignDetails.id) &&
      Boolean(spotifyCampaignDetails.id)
    ) {
      dispatch(clearCampaignToSchedule())
    }
  }, [dispatch, spotifyCampaignDetails.id, campaignId])
}
