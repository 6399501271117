import { FC } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useGetTrackDetailsQuery } from 'src/Redux/TrackFeedDetailsApi'
import { tracksFeed } from 'src/Router/routes'
import { colors, device } from 'src/Styled/variables'
import { SpotifyTrackPreview } from 'src/Components/SpotifyTrackPreview/SpotifyTrackPreview'
import Button from 'src/Components/Buttons/Button'
import { useGetFeedEvent } from 'src/Hooks/useGetFeedEvent'

import { LoadingSpinner } from '../ui'

import { ReviewedTrackHeader } from './ReviewedTrackHeader'
import { ReviewedTrackError } from './ReviewedTrackError'
import { ReviewedTrackPlaylist } from './ReviewedTrackPlaylist'
import { ReviewedTrackComment } from './ReviewedTrackComment'
import { ReviewedTrackDetails } from './ReviewedTrackDetails'
import { ReviewedTrackArtistResponse } from './ReviewedTrackArtistResponse'

const ContentContainer = styled.div`
  position: relative;
  padding: 16px;
  background: ${colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${device.mobileMin} {
    padding: 0;
  }
`
const Wrapper = styled.div`
  @media ${device.mobileMin} {
    position: fixed;
    z-index: 1;
    top: 60px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
    overflow-y: scroll;
  }
`
const WrapperContent = styled.div`
  @media ${device.mobileMin} {
    padding: 65px 0;
    width: 600px;
  }
`
const StyledButton = styled(Button)`
  margin-top: 16px;

  @media ${device.mobileMin} {
    padding-bottom: 65px;
    margin: 0 auto;
    width: fit-content;
    button {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`
const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
`
const ReviewedTrack: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { campaignId } = useParams<{ campaignId: string }>()
  const { data, isError, isLoading } = useGetTrackDetailsQuery(
    String(campaignId),
  )
  useGetFeedEvent(data, campaignId)

  const handleClose = (): void => {
    navigate(tracksFeed)
  }

  return (
    <Wrapper>
      {isLoading && (
        <StyledLoadingSpinner fullscreen color='green' size='large' />
      )}
      <WrapperContent>
        <ReviewedTrackHeader onClose={handleClose} />
        <ContentContainer>
          <ReviewedTrackError isError={isError} />
          <SpotifyTrackPreview trackUrl={data?.track?.track_url} />
          <ReviewedTrackDetails
            rank={data?.rank}
            reward={data?.potential_reward}
            genres={data?.track.genres}
            languages={data?.track.languages}
            status={data?.review_status}
          />
          <ReviewedTrackComment comment={data?.comment} />
          <ReviewedTrackPlaylist playlist={data?.addedTo} />
          <ReviewedTrackArtistResponse
            reviewFeedback={data?.review_feedback}
            curatorRating={data?.curator_rating}
          />
          <StyledButton type='white' onClick={handleClose}>
            {t('reviewsPage.closeReview')}
          </StyledButton>
        </ContentContainer>
      </WrapperContent>
    </Wrapper>
  )
}
export default ReviewedTrack
