import styled from 'styled-components'
import { Tabs } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'

export const SettingsTitle = styled.h3`
  margin-bottom: 24px;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  @media ${device.mobileMin} {
    margin-top: 8px;
    line-height: 30px;
  }
`

export const SettingsContainer = styled.div`
  box-sizing: content-box;
  padding: 0;
  margin: 0 auto;
`

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const SettingsInnerContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  position: relative;
  background-color: ${colors.white};
  padding: 24px;

  @media ${device.mobileMin} {
    background-color: ${colors.white};
    padding-bottom: 0px;
    padding-right: 24px;
  }
  .ant-tabs-nav-more > span {
    display: none;
  }
  .ant-tabs-tab {
    padding: 11px 0;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid #eeeeee;
  }
  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav-list {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
`
export const StyledTabs = styled(Tabs)`
  position: relative;
  .ant-tabs-nav-list {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
  }
  padding-left: 0;
  /* @media ${device.mobileMin} {
    padding-left: 24px;
    padding-top: 21px;
  } */
  .ant-tabs-tab-btn {
    padding: 0 24px 0 24px;
    /* @media ${device.smallScreen} {
      padding: 0px 24px 0px 24px;
    } */
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
  .ant-tabs-ink-bar {
    background: ${colors.green};
    width: 116px;
    @media ${device.smallScreen} {
      width: 132px;
    }
  }
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 10px 0 11px 0;
    font-size: ${variables.fontSize14};
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    @media ${device.smallScreen} {
      padding: 11px 0 11px 0;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 12px;
    width: 90%;
    @media ${device.smallScreen} {
      margin-bottom: 12px;
    }
  }
`

export const StyledTabsItem = styled(Tabs.TabPane)`
  color: ${colors.mainText};
`

export const TabName = styled.span`
  color: ${colors.mainText};
  margin-right: 8px;
`
