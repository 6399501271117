import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Button from 'src/Components/Buttons/Button'
import CheckboxAntd from 'src/Containers/ui/CheckboxAntd'
import { DropdownMenu } from 'src/Components/DropdownMenu'
import { Desktop, Mobile } from 'src/Components/Responsive'
import FilterIcon from 'src/Assets/Svg/filter.svg?react'
import SearchIcon from 'src/Assets/Svg/search.svg?react'
import { CampaignPlatformTypeEnum, CampaignsFilters } from 'src/Types/index'
import { getIsTiktokAllowed } from 'src/Redux/auth-process/userSlice/selectors'
import PlusIcon from 'src/Assets/Svg/plus.svg?react'

import { ActionsBlockProps } from './ActionsBlockProps'

import {
  ApplyButton,
  BtnWithPlus,
  ButtonWrapper,
  Checkbox,
  CheckboxesContainer,
  FilterButton,
  FiltersContainer,
  FiltersLabel,
  FiltersMenu,
  inlineStyles,
  InputWrapper,
  RoundBtn,
  SearchIconWrapper,
  StyledInput,
  TableHeader,
} from './styles'

const ActionsBlock: React.FC<ActionsBlockProps> = ({
  checkAccessToken,
  activeSearch,
  filters,
  setFilters,
  setActiveSearch,
  handleFindCampaign,
}) => {
  const { t } = useTranslation()

  const isTiktokAllowed = useSelector(getIsTiktokAllowed)
  const [filtersState, setFiltersState] = useState<CampaignsFilters>(filters)

  const onChangeFiltersState = ({
    origin,
    value,
  }: {
    origin: CampaignPlatformTypeEnum
    value: boolean
  }): void => {
    if (origin === CampaignPlatformTypeEnum.SPOTIFY) {
      setFiltersState({
        ...filtersState,
        isSpotify: value,
      })
    }
    if (origin === CampaignPlatformTypeEnum.TIKTOK) {
      setFiltersState({
        ...filtersState,
        isTikTok: value,
      })
    }
  }

  const onApplyFilters = (): void => {
    setFilters(filtersState)
  }

  return (
    <TableHeader>
      <Desktop>
        <ButtonWrapper>
          <Button type='white' onClick={checkAccessToken}>
            <BtnWithPlus>
              <PlusIcon />

              <span>{t('campaignsPage.createNewCampaign')}</span>
            </BtnWithPlus>
          </Button>
        </ButtonWrapper>
      </Desktop>

      <Mobile>
        {activeSearch ? (
          <RoundBtn
            onClick={() => {
              setActiveSearch(false)
            }}
          >
            <PlusIcon />
          </RoundBtn>
        ) : (
          <ButtonWrapper>
            <Button type='white' onClick={checkAccessToken}>
              <BtnWithPlus>
                <PlusIcon />
                <span>{t('campaignsPage.createNewCampaign')}</span>
              </BtnWithPlus>
            </Button>
          </ButtonWrapper>
        )}
      </Mobile>

      <FiltersContainer>
        {isTiktokAllowed && (
          <DropdownMenu
            isRightOriented
            menuStyle={inlineStyles.dropdownMenu}
            renderContent={(toggleMenu) => (
              <FiltersMenu>
                <FiltersLabel>Platform</FiltersLabel>
                <CheckboxesContainer>
                  <Checkbox>
                    <CheckboxAntd
                      title='Spotify'
                      checked={filtersState.isSpotify}
                      onChange={(e) =>
                        onChangeFiltersState({
                          origin: CampaignPlatformTypeEnum.SPOTIFY,
                          value: e.target.checked,
                        })
                      }
                    />
                  </Checkbox>
                  <Checkbox>
                    <CheckboxAntd
                      title='TikTok'
                      checked={filtersState.isTikTok}
                      onChange={(e) =>
                        onChangeFiltersState({
                          origin: CampaignPlatformTypeEnum.TIKTOK,
                          value: e.target.checked,
                        })
                      }
                    />
                  </Checkbox>
                </CheckboxesContainer>
                <ApplyButton>
                  <Button
                    type='green'
                    disabled={
                      JSON.stringify(filters) === JSON.stringify(filtersState)
                    }
                    onClick={() => {
                      onApplyFilters()
                      toggleMenu()
                    }}
                  >
                    Apply
                  </Button>
                </ApplyButton>
              </FiltersMenu>
            )}
            renderTrigger={(isOpen) => (
              <FilterButton isFocused={isOpen}>
                <Desktop>Filters</Desktop>
                <FilterIcon width={'16px'} height={'16px'} />
              </FilterButton>
            )}
          />
        )}
        <InputWrapper
          activeSearch={activeSearch}
          onClick={() => setActiveSearch(true)}
        >
          <StyledInput
            placeholder={String(t('campaignsPage.searchCampaign'))}
            onChange={(e) => handleFindCampaign(e.target.value)}
            onFocus={() => setActiveSearch(true)}
            onBlur={() => setActiveSearch(false)}
          />
          {!activeSearch && (
            <SearchIconWrapper>
              <SearchIcon width={16} height={16} />
            </SearchIconWrapper>
          )}
        </InputWrapper>
      </FiltersContainer>
    </TableHeader>
  )
}

export default ActionsBlock
