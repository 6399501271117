import { FC, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'

import { getSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import {
  getAllSearchErrorSelector,
  getTikTokTrackSelector,
} from 'src/Redux/tiktokTrack-process/selectors'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { IconComponent } from '../IconComponent'
import { HowToGetYourTrackURLButton } from '../HowToGetYourTrackURLButton'

import { FormDataProps } from './TrackSearchProps'
import { useTrackSearchListeners } from './helpers/useTrackSearchListeners'
import { SearchDropdownMessages } from './components/SearchDropdownMessages'
import { DropdownResult } from './components/DropdownResult'
import { SearchInputBlock } from './components/SearchInputBlock'
import { useTrackSearch } from './helpers/useTrackSearch'
import { usePlaceholderText } from './helpers/usePlaceholderText'
import { useUpperTitleText } from './helpers/useUpperTitleText'

import { SearchForm, StyledTrackSearch, TrackSearchHeader } from './styles'

interface TrackSearchProps {
  isVariantB?: boolean
  isFAQ?: boolean
}

export const TrackSearch: FC<TrackSearchProps> = ({ isVariantB, isFAQ }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 567px)' })
  const [isShowFullscreenInput, setShowFullscreenInput] = useState(true)
  const { search } = useCustomSearchParams()
  const urlTrackName = search.get('trackName')
  const searchError = useSelector(getAllSearchErrorSelector)
  const platform = useSelector(getSchedulePlatform)
  const tiktokTrack = useSelector(getTikTokTrackSelector)
  const spotifyTrack = useSelector(getSpotifyTrackSelector)

  useTrackSearchListeners()

  const { trackSearchHandler } = useTrackSearch()
  const { upperTitleText } = useUpperTitleText(platform)
  const { placeholderText } = usePlaceholderText(
    platform,
    isShowFullscreenInput,
  )

  const { control, handleSubmit, setValue } = useForm<FormDataProps>({
    mode: 'onChange',
  })
  const [hasSubmitted, setHasSubmitted] = useState(false)

  useEffect(() => {
    if (urlTrackName && !hasSubmitted) {
      setValue('searchInput', urlTrackName)
      void handleSubmit(trackSearchHandler)()
      setHasSubmitted(true)
    }
  }, [urlTrackName, setValue, handleSubmit, trackSearchHandler, hasSubmitted])

  const clearInput = (): void => {
    setValue('searchInput', '')
  }

  const isTrackExist = Boolean(tiktokTrack || spotifyTrack)

  useEffect(() => {
    if ((isVariantB || isFAQ) && isMobile) {
      setShowFullscreenInput(false)
    }
  }, [isMobile, isVariantB, isFAQ])

  if (!platform || isTrackExist) {
    return null
  }

  const handleFormClick = (): void => {
    if (isVariantB || isFAQ) {
      setShowFullscreenInput(true)
    }
  }
  return (
    <StyledTrackSearch
      onClick={handleFormClick}
      show={isShowFullscreenInput}
      isVariantB={isVariantB}
      isFAQ={isFAQ}
      isErrorOnMobile={Boolean(searchError)}
      isSame={Boolean(searchError?.includes('The link'))}
    >
      <>
        <TrackSearchHeader show={isShowFullscreenInput}>
          <div>{upperTitleText}</div>
          <IconComponent platform={platform} />
        </TrackSearchHeader>

        <SearchForm
          show={isShowFullscreenInput}
          isErrorOnMobile={Boolean(searchError)}
          onSubmit={handleSubmit(trackSearchHandler)}
        >
          <>
            <SearchInputBlock
              placeholderText={placeholderText}
              clearInput={clearInput}
              control={control}
              isFAQ={isShowFullscreenInput}
            />
            <DropdownResult />
          </>

          <SearchDropdownMessages platform={platform} />

          <HowToGetYourTrackURLButton platform={platform} />
        </SearchForm>
      </>
    </StyledTrackSearch>
  )
}
TrackSearch.displayName = 'TrackSearch'
