import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'

import {
  MobileBlockWrapper,
  NormalText,
  SmallText,
  ValuesContainer,
} from '../../styles'

export const EarnInfo: FunctionComponent = () => {
  const { t } = useTranslation()

  const { data: monetizationData } = useGetMonetizationDataQuery()

  const formattedEarnUpToAmount = monetizationData
    ? `$${monetizationData.maxCreatorReward.toFixed(2)}`
    : t('soundsPage.noData')

  return (
    <MobileBlockWrapper>
      <ValuesContainer>
        <SmallText>{t('soundsPage.earnUpTo')}</SmallText>
        <NormalText>{formattedEarnUpToAmount}</NormalText>
      </ValuesContainer>
    </MobileBlockWrapper>
  )
}
