import { FC, useEffect, useMemo } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  getAllCampaignTikTokVideos,
  getAwaitingReviewCampaignTikTokVideos,
  getCampaignTikTokVideosStats,
} from 'src/Redux/campaignVideos-process/api-actions'
import { InfluencerEventStatus, TiktokCampaignStatus } from 'src/Types'
import SurveyForEndedCampaign from 'src/Components/SideBarSurveys/SurveyForEndedCampaign'
import { getCampaignTikTokDetails } from 'src/Redux/campaign-process/mainOldProcessSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import { getTiktokCampaignDetails } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import {
  getAwaitingVideos,
  getCampaignVideos,
  getVideosCountByStatuses,
} from 'src/Redux/campaignVideos-process/selectors'
import { getClosedTypeformIds } from 'src/Redux/typeform-process'

import { addSpaceToNumber } from '../../Helpers/layoutHelpers'
import { LoaderWrapper } from '../../Components/InfinityScrollWrapper/styled'
import InfinityLoader from '../../Components/InfinityLoader'

import { EmptyListItem } from './components/EmptyListItem'
import { VideoItem } from './components/VideoItem'
import { EngagementDataUpdatedAlert } from './components/EngagementDataUpdatedAlert'
import DetailsCard from './components/DetailsCard'
import DetailsHeader from './components/DetailsHeader'
import Block from './components/Block'

import {
  BlockOfTikTokCards,
  DetailsContainer,
  StyledList,
  StyledNumber,
  StyledTabs,
  TabName,
  Videos,
} from './styles'

const allTabVideoStatuses = [
  InfluencerEventStatus.AWAITING_REVIEW,
  InfluencerEventStatus.APPROVED_AUTOMATICALLY,
  InfluencerEventStatus.APPROVED_BY_ARTIST,
  InfluencerEventStatus.APPROVED_BY_ADMIN,
  InfluencerEventStatus.AWAITING_MODERATOR_REVIEW,
  InfluencerEventStatus.DECLINED,
]
const Details: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { campaignID } = useParams()

  const user = useSelector(getUserSelector)
  const campaignVideos = useSelector(getCampaignVideos)
  const awaitingVideos = useSelector(getAwaitingVideos)
  const campaignDetails = useSelector(getTiktokCampaignDetails)
  const closedTypeformIds = useSelector(getClosedTypeformIds)
  const allVideosCount = useSelector(
    getVideosCountByStatuses(allTabVideoStatuses),
  )
  const awaitingReviewVideosCount = useSelector(
    getVideosCountByStatuses([InfluencerEventStatus.AWAITING_REVIEW]),
  )

  useEffect(() => {
    if (campaignID) {
      void dispatch(
        getAllCampaignTikTokVideos({ campaignId: campaignID, pageNumber: 1 }),
      )
      void dispatch(getCampaignTikTokDetails({ tiktokCampaignId: campaignID }))
      void dispatch(getCampaignTikTokVideosStats({ campaignId: campaignID }))
      void dispatch(
        getAwaitingReviewCampaignTikTokVideos({
          campaignId: campaignID,
          pageNumber: 1,
        }),
      )
    }
  }, [campaignID, dispatch])

  const blocks = useMemo(
    () => [
      {
        title: t('campaignResultsPage.videoPublished'),
        value: campaignDetails?.stats?.totalVideos ?? 0,
      },
      {
        title: t('campaignResultsPage.totalViews'),
        value: campaignDetails?.stats?.totalViews
          ? addSpaceToNumber(campaignDetails?.stats?.totalViews, ',')
          : 0,
      },
      {
        title: t('campaignResultsPage.totalLikes'),
        value: campaignDetails?.stats?.totalLikes
          ? addSpaceToNumber(campaignDetails?.stats?.totalLikes, ',')
          : 0,
      },
      {
        title: t('campaignResultsPage.comments'),
        value: campaignDetails?.stats?.totalComments ?? 0,
      },
    ],
    [campaignDetails, t],
  )

  const tabs = useMemo(
    () => [
      {
        group: 'all',
        title: t('campaignResultsPage.all'),
        count: allVideosCount || 0,
      },
      {
        group: 'awaiting',
        title: t('campaignResultsPage.awaitingYourRating'),
        count: awaitingReviewVideosCount || 0,
      },
    ],
    [t, allVideosCount, awaitingReviewVideosCount],
  )

  const isAppropriateStatus = Boolean(
    campaignDetails?.status &&
      [
        TiktokCampaignStatus.ENDED,
        TiktokCampaignStatus.CANCELED,
        TiktokCampaignStatus.FINISHED,
        TiktokCampaignStatus.STOPPED,
      ].includes(campaignDetails?.status),
  )
  const isClosedCampaign = Boolean(
    closedTypeformIds.includes(String(campaignDetails?.id)),
  )

  const loadMoreAllVideos = (): void => {
    if (campaignID && campaignVideos.meta) {
      void dispatch(
        getAllCampaignTikTokVideos({
          campaignId: campaignID,
          pageNumber: Number(campaignVideos.meta.pageNumber) + 1,
        }),
      )
    }
  }

  const loadMoreAwaitingVideos = (): void => {
    if (campaignID && awaitingVideos.meta) {
      void dispatch(
        getAwaitingReviewCampaignTikTokVideos({
          campaignId: campaignID,
          pageNumber: awaitingVideos.meta.pageNumber + 1,
        }),
      )
    }
  }

  const items = tabs?.map((tab) => ({
    label: (
      <>
        <TabName>{tab.title}</TabName>
        <StyledNumber isGreen={tab.group === 'awaiting' && tab.count >= 1}>
          {tab.count}
        </StyledNumber>
      </>
    ),
    key: tab.group,
    children: (
      <StyledList>
        {tab.group === 'all' &&
          (campaignVideos.videos.length === 0 ? (
            <EmptyListItem />
          ) : (
            <InfiniteScroll
              dataLength={campaignVideos.videos.length}
              next={loadMoreAllVideos}
              hasMore={campaignVideos?.meta?.hasNextPage || false}
              loader={
                <LoaderWrapper>
                  <InfinityLoader isLoading={true} />
                </LoaderWrapper>
              }
            >
              {campaignVideos?.videos.map((item) => (
                <VideoItem key={item.id} video={item} />
              ))}
            </InfiniteScroll>
          ))}

        {tab.group === 'awaiting' &&
          (awaitingVideos.videos.length === 0 ? (
            <EmptyListItem />
          ) : (
            <InfiniteScroll
              dataLength={awaitingVideos.videos.length}
              next={loadMoreAwaitingVideos}
              hasMore={awaitingVideos?.meta?.hasNextPage || false}
              loader={
                <LoaderWrapper>
                  <InfinityLoader isLoading={true} />
                </LoaderWrapper>
              }
            >
              {awaitingVideos?.videos.map((item) => (
                <VideoItem key={item.id} video={item} />
              ))}
            </InfiniteScroll>
          ))}
      </StyledList>
    ),
  }))

  return (
    <DetailsContainer>
      {isAppropriateStatus && !isClosedCampaign && (
        <SurveyForEndedCampaign
          userEmail={user?.email}
          campaignId={`${campaignDetails?.id ?? ''}`}
          campaignType={`${campaignDetails?.type ?? ''}`}
          campaignName={`${campaignDetails?.track?.title ?? ''}`}
          isFeed={false}
          id='Dddzi36c'
          text={t('sidebar.shareFeedback')}
        />
      )}

      <DetailsHeader />
      <DetailsCard />

      <EngagementDataUpdatedAlert
        status={campaignDetails?.status || TiktokCampaignStatus.AWAITING_REVIEW}
        dateInfo={campaignDetails?.updatedAt || ''}
      />
      <BlockOfTikTokCards>
        {blocks.map((item) => (
          <Block key={item.title} title={item.title} value={item.value} />
        ))}
      </BlockOfTikTokCards>

      <Videos>
        <StyledTabs items={items} defaultActiveKey='all' />
      </Videos>
    </DetailsContainer>
  )
}

export default Details
