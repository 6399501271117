import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'

interface PageInfo {
  pageTitle: string
  pagePath: string
}

export const usePageViewEvent = (pageInfo: PageInfo): void => {
  const user = useSelector(getUserSelector)

  useEffect(() => {
    pageViewEvent(user, pageInfo)
    locationEvent()
  }, [pageInfo, user])
}
