import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import {
  getCheckoutTimer,
  updateCountdown,
} from 'src/Redux/checkoutCountdown-process'
import { addFeatureForUser } from 'src/Redux/A-BFeatures-process/api-actions'

import { useAppDispatch } from './redux'
import {
  EXPIRED_TIMER_FEATURE_VALUE,
  QUICK_CHECKOUT_FEATURE_NAME,
} from './useCheckoutTimerFeratureFlag'

const TIMEOUT = 1000

export const useBonusTooltipTimer = (): void => {
  const dispatch = useAppDispatch()

  const countdown = useSelector(getCheckoutTimer)

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 0) {
        void dispatch(
          addFeatureForUser({
            featureName: QUICK_CHECKOUT_FEATURE_NAME,
            variation: EXPIRED_TIMER_FEATURE_VALUE,
          }),
        )
        clearInterval(timer)
        dispatch(updateCountdown(null))
      } else {
        countdown && dispatch(updateCountdown(countdown - 1))
      }
    }, TIMEOUT)

    return () => clearInterval(timer)
  }, [countdown, dispatch])
}
