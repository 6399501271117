import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'
import { QUICK_CHECKOUT_FEATURE_NAME } from 'src/Hooks/useCheckoutTimerFeratureFlag'
import {
  BONUS_10,
  BONUS_20,
  VARIANT_2,
  VARIANT_3,
} from 'src/Hooks/useBonusTooltip'

import { AudienceTiktokState } from '.'

const getState = (state: RootState): AudienceTiktokState =>
  state.scheduling.audienceTiktok
export const getIsTiktokAudienceLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getIsReachSettingsError = createDraftSafeSelector(
  getState,
  ({ error }) => Boolean(error),
)

export const getEstimatedTiktokViews = createDraftSafeSelector(
  getState,
  ({ maxViews, minViews }) => ({ maxViews, minViews }),
)
export const getEstimatedTiktokVideos = createDraftSafeSelector(
  getState,
  ({ estimatedMinVideos, estimatedMaxVideos }) => ({
    estimatedMinVideos,
    estimatedMaxVideos,
  }),
)

export const getPotentialReach = createDraftSafeSelector(
  getState,
  ({ potentialMaxReach, potentialMinReach }) => ({
    potentialMaxReach,
    potentialMinReach,
  }),
)

export const getTiktokPrice = createDraftSafeSelector(
  getState,
  ({ price }) => price,
)

export const getIsEnoughBalanceForTiktok = createDraftSafeSelector(
  (state: RootState) => ({
    balance: Number(state?.authentication?.user?.user?.balance),
    price: getTiktokPrice(state),
    bonusVariation: state.aBFeatures.features?.find(
      (item) => item?.name === QUICK_CHECKOUT_FEATURE_NAME,
    )?.variation,
  }),
  ({ price, balance, bonusVariation }) => {
    let bonus = 0
    if (bonusVariation === VARIANT_2) {
      bonus = BONUS_20
    }
    if (bonusVariation === VARIANT_3) {
      bonus = BONUS_10
    }
    return balance + bonus >= price && price > 0
  },
)
