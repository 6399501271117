import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../types'

import { LanguageItem, PlaylistsLanguages } from './index'

export const getAllLanguages = createAsyncThunk<LanguageItem[]>(
  `${NameSpace.Languages}/getAllLanguages`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<LanguageItem[]>(
        `${APP.SERVER}/user/languages`,
      )
      return response.data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getLanguagesForExistingPlaylists =
  createAsyncThunk<PlaylistsLanguages>(
    `${NameSpace.Languages}/getLanguagesForExistingPlaylists`,
    async (_, { rejectWithValue }) => {
      try {
        const response = await api.get<PlaylistsLanguages>(
          `${APP.SERVER}/campaign/languages`,
        )
        return response.data
      } catch (error) {
        const { message, response } = error as AxiosError
        return rejectWithValue({
          message,
          code: response?.status || ErrorCode.InternalServerError,
        })
      }
    },
  )
