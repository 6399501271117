import { combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import { mainCampaignProcess } from './mainOldProcessSlice'

const campaigns = combineReducers({
  main: mainCampaignProcess.reducer,
})
const persistConfig = {
  storage,
  key: 'scheduleCampaigns',
  blacklist: ['main'],
}
export const campaignProcess = persistReducer(persistConfig, campaigns)
