import styled from 'styled-components'

export const Message = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: #787985;
  flex: 4 4;
`
export const Text = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  display: block;
  text-align: center;
`
