import { FunctionComponent, ReactElement, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { couponAppliedEvent } from 'src/Helpers/TagManager'
import ModalLayout from 'src/Components/ModalLayout'
import Button from 'src/Components/Buttons/Button'
import { checkCoupon } from 'src/Redux/schedule-process/couponSlice/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { ApiError } from 'src/Redux/types'
import { getIsCouponLoading } from 'src/Redux/schedule-process/couponSlice/selectors'
import { HUNDRED, HUNDREDTHS } from 'src/Constants/numeric'

import { FormDataProps, PromocodePopupProps } from './PromocodePopupProps'

import {
  GlobalStyled,
  PromocodeButtonsBlock,
  PromocodeErrorText,
  PromocodeForm,
  PromocodeInput,
} from './styles'

export const PromocodePopup: FunctionComponent<PromocodePopupProps> = ({
  price,
  campaignType,
  content,
  visible,
  width,
  setModalPromocode,
  handleClose,
}): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const isChecking = useAppSelector(getIsCouponLoading)
  const [couponErrorMessage, setCouponErrorMessage] = useState<string | null>(
    null,
  )

  const {
    register,
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
  } = useForm<FormDataProps>({
    criteriaMode: 'firstError',
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })

  const values = getValues()

  const watchCoupon = watch('coupon', '')

  const submitCouponHandler = (): void => {
    if (values.coupon.includes('%') || values.coupon.includes('?')) {
      setCouponErrorMessage(t('scheduleCampaignPage.couponDoesntExist'))
    } else {
      values.coupon &&
        void dispatch(checkCoupon(values.coupon))
          .unwrap()
          .then((res) => {
            const memoDiscount = (
              price *
              (res.coupon.discount / HUNDRED)
            ).toFixed(HUNDREDTHS)

            couponAppliedEvent(
              campaignType,
              res.coupon,
              memoDiscount ? Number(memoDiscount) : 0,
            )
            setModalPromocode(false)
            return res
          })
          .catch((error: ApiError) => {
            setCouponErrorMessage(error.message)
          })
    }
  }

  const handleSubmitPromocode = (): void => {
    submitCouponHandler()
  }

  const handleClearErrors = (): void => {
    clearErrors()
    setCouponErrorMessage(null)
  }

  const handleCancel = (): void => {
    handleClose()
    handleClearErrors()
    setValue('coupon', '')
    setModalPromocode(false)
  }

  const [placeholder, setPlaceholder] = useState('Enter a promo code')

  const handleFocus = (): void => {
    setPlaceholder('')
  }

  const handleBlur = (): void => {
    setPlaceholder('Enter a promo code')
  }

  return (
    <ModalLayout
      promocode
      width={width}
      content={content}
      visible={visible}
      handleClose={handleClose}
    >
      <GlobalStyled />
      <PromocodeForm onSubmit={handleSubmit(handleSubmitPromocode)}>
        <Controller
          name='coupon'
          control={control}
          defaultValue=''
          render={({ field: { onChange } }) => (
            <PromocodeInput
              {...register('coupon')}
              name='coupon'
              type='coupon'
              autoComplete='off'
              placeholder={placeholder}
              couponErrorMessage={Boolean(couponErrorMessage)}
              onChange={onChange}
              onFocus={() => {
                handleClearErrors()
                handleFocus()
              }}
              onBlur={handleBlur}
              onTouchStart={handleClearErrors}
            />
          )}
        />
        {couponErrorMessage ? (
          <PromocodeErrorText>{couponErrorMessage}</PromocodeErrorText>
        ) : null}
        <PromocodeButtonsBlock>
          <DottedBtn
            content={t('scheduleCampaignPage.cancel')}
            onClick={handleCancel}
          />

          <Button
            type='green'
            disabled={!watchCoupon?.length || isChecking}
            onClick={handleSubmitPromocode}
          >
            {t('scheduleCampaignPage.applyPromocode')}
          </Button>
        </PromocodeButtonsBlock>
      </PromocodeForm>
    </ModalLayout>
  )
}
