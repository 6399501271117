import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'

import Alert from 'src/Components/Alert'
import { ReviewSelectValue, ReviewType } from 'src/Types/index'
import { useTrackToReview } from 'src/Redux/TrackFeedDetailsApi/useTrackToReview'

import { SelectPosition } from '../SelectPosition'
import { SelectPlaylistOrReject } from '../SelectPlaylistOrReject'

import { WarningFormProps, WarningFormValues } from './props'

import {
  AlertText,
  AlertTitle,
  Button,
  ButtonGrey,
  ButtonsContainer,
  Content,
  FormContainer,
  SelectLabel,
  TextRow,
} from './styles'

export const WarningForm: FC<WarningFormProps> = ({
  setPlaylistFromWarning,
  setPositionFromWarning,
  setWarningFormVisible,
}) => {
  const { t } = useTranslation()
  const { data: reviewDetails } = useTrackToReview()

  const { handleSubmit, control, watch } = useForm<WarningFormValues>({
    mode: 'onChange',
  })

  const watchSelect: ReviewSelectValue = watch('reviewPlaylistSelect')
  const watchPosition: number = watch('reviewPlaylistPositionSelect')

  const onClose = (): void => {
    setWarningFormVisible(false)
  }

  const onSubmit = (values: WarningFormValues): void => {
    setPlaylistFromWarning(values.reviewPlaylistSelect)
    setPositionFromWarning(values.reviewPlaylistPositionSelect)
    onClose()
  }

  return (
    <Content>
      <Alert color='warning' icon unclosable rowDirection>
        <AlertText>
          <AlertTitle>{t('reviewsPage.threeReviewsWarningTitle')}</AlertTitle>
          <TextRow>{t('reviewsPage.threeReviewsWarningFeed')}</TextRow>
        </AlertText>
      </Alert>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <SelectLabel>{t('reviewsPage.selectLabel')}</SelectLabel>
        <Controller
          control={control}
          name='reviewPlaylistSelect'
          render={({ field: { onChange, value } }) => (
            <SelectPlaylistOrReject
              noReject
              value={value}
              onChange={onChange}
            />
          )}
        />
        {watchSelect?.type === ReviewType.add && (
          <Controller
            control={control}
            name='reviewPlaylistPositionSelect'
            render={({ field: { onChange, value } }) => (
              <SelectPosition
                value={value}
                amountPaid={Number(reviewDetails?.potential_reward)}
                trackId={Number(reviewDetails?.track.id)}
                reviewId={Number(reviewDetails?.id)}
                campaignId={Number(reviewDetails?.campaign_id)}
                onChange={onChange}
              />
            )}
          />
        )}
        <ButtonsContainer>
          <ButtonGrey onClick={onClose}>
            {t('reviewsPage.continueWithoutAdding')}
          </ButtonGrey>
          <Button type='submit' disabled={!watchSelect || !watchPosition}>
            {t('reviewsPage.addToPlaylist')}
          </Button>
        </ButtonsContainer>
      </FormContainer>
    </Content>
  )
}
