import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useLocation } from 'react-router-dom'

import { FullWideNotification } from 'src/Components/FullWideNotification'
import { landingPageUrl } from 'src/Constants/links'
import logo from 'src/Assets/Png/logo.png'
import CloseIconWhite from 'src/Assets/Svg/closeWhite.svg?react'
import CloseIconGreen from 'src/Assets/Svg/closeGreen.svg?react'
import { getAuthType } from 'src/Redux/auth-process/authTypeSlice/selectors'
import { setLoginTypeToInitialState } from 'src/Redux/auth-process/authTypeSlice'
import { colors } from 'src/Styled/variables'
import { useAppDispatch } from 'src/Hooks/redux'

import { MAP_LOGIN_FORM } from './helpers'
import { ChooseRoleModal } from './components/ChooseRoleModal'

import {
  Card,
  CloseButton,
  LoginContainer,
  Header,
  Logo,
  Wrapper,
} from './styles'

const DELAY = 3000

const LogIn: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authType = useSelector(getAuthType)
  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    if (location.search.includes('blacklist')) {
      navigate('/')
      void messageApi.open({
        content: (
          <FullWideNotification
            message={t('login.authErrorBlacklistRegistered')}
          />
        ),
        duration: 3,
        style: { backgroundColor: colors.badgeRed },
      })
    }
    return () => {
      dispatch(setLoginTypeToInitialState())
    }
  }, [location, messageApi, navigate, t, dispatch])

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>
    if (authType === 'userIsNotExist') {
      id = setTimeout(() => {
        dispatch(setLoginTypeToInitialState())
      }, DELAY)
    }
    return () => {
      clearTimeout(id)
    }
  }, [authType, dispatch])

  const onGoToLanding = (): void => {
    window.location.href = landingPageUrl
  }

  const isMobile = useMediaQuery({ maxWidth: 576 })
  return (
    <Wrapper>
      {contextHolder}
      <CloseButton data-testid='close-button' onClick={onGoToLanding}>
        {isMobile ? <CloseIconGreen /> : <CloseIconWhite />}
      </CloseButton>
      <ChooseRoleModal />
      <LoginContainer>
        <Logo>
          <img width={236} height={52} alt='logo' src={logo} />
        </Logo>
        <Card>
          <Header>{t('login.welcomeBack')}</Header>
          {MAP_LOGIN_FORM[authType] || null}
        </Card>
      </LoginContainer>
    </Wrapper>
  )
}
export default LogIn
