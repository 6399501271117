import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const CreatorInfoWrapper = styled.div`
  height: fit-content;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background-color: ${colors.white};

  @media (${device.mobileMax}) {
    width: 100%;
  }
`
export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  & > svg {
    margin-left: auto;
  }
`

export const UserPictureContainer = styled.div`
  img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
  }
`
export const CustomDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.white};
`
export const TopDetailsWrapper = styled.div`
  color: ${colors.mainText};
  display: grid;
  gap: 16px;
  background-color: ${colors.greyBg};
  padding: 16px;
  width: 100%;
`

export const ViewsWrapper = styled.div`
  color: ${colors.mainText};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 100%;
  margin-bottom: 16px;
  background-color: ${colors.greyBg};
  padding: 16px;
  @media ${device.mobileMax} {
    line-height: 150%;
  }
`
export const SubmissionTitle = styled.p`
  font-size: 10px;
`
export const StyledFollowers = styled.div`
  font-size: 10px;
`
export const SubmissionDate = styled.p`
  margin: 0;
`
