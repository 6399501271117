import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = null

export const redirectProcess = createSlice({
  initialState,
  name: 'REDIRECT',
  reducers: {
    redirectToRoute: (_, __: PayloadAction<string>): null => null,
  },
})

export const { redirectToRoute } = redirectProcess.actions
