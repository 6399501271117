import { Genre, TrackGenre, ID, TrackLanguage, Playlist } from 'src/Types/index'

type GenreOption = {
  id: ID | undefined
  genre_name?: string
}

export const getGenres = (
  arr: Array<Genre> | Array<TrackGenre>,
): GenreOption[] =>
  arr.map((el) => ({
    id: el.genre_id,
    genre_name: el.Genre?.genre_name,
  }))

type LanguageOption = {
  id?: number
  language_name?: string
}

export const getLanguages = (arr: Array<TrackLanguage>): LanguageOption[] =>
  arr.map((el) => ({
    id: el.language_id,
    language_name: el.Language?.language_name,
  }))

export type MoodOption = {
  id: number
  mood_name: string
}

export const removeDuplicatedPlaylists = (
  array: Playlist[],
): (Playlist | undefined)[] =>
  Array.from(
    new Set(
      array
        .map((el) => el.id)
        .map((id) => array.find((item) => item.id === id)),
    ),
  )

export const getPlaylistId = (playlistUrl?: string): string | null => {
  const SLICE_INDEX = 10
  const SUBSTRING_LENGTH = 22

  if (!playlistUrl) {
    return null
  }
  const index = playlistUrl.indexOf('playlists')
  return playlistUrl
    .substring(index)
    .slice(SLICE_INDEX)
    .substring(0, SUBSTRING_LENGTH)
}
