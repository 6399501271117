import axios, { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ErrorCode, NameSpace } from '../types'

import { Track } from '.'

interface SpotifyTrackBody {
  url: string
  token: string
}
export const getSpotifyTrack = createAsyncThunk<Track, SpotifyTrackBody>(
  `${NameSpace.Track}/getSpotifyTrack`,
  async ({ url, token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Track>(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
