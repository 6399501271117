import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  getAllSearchErrorSelector,
  getIsLoadingTikTok,
  getTikTokTracksSelector,
} from 'src/Redux/tiktokTrack-process/selectors'
import {
  getIsLoadingSpotifyTracks,
  getTracks,
} from 'src/Redux/spotifyTracks-process/selectors'
import { SearchingTrackDropdown } from 'src/Components/SearchingTrackDropdown'
import { CampaignPlatformTypeEnum } from 'src/Types'

interface SearchDropdownMessagesProps {
  platform?: CampaignPlatformTypeEnum
}

export const SearchDropdownMessages: FC<SearchDropdownMessagesProps> = ({
  platform,
}) => {
  const { t } = useTranslation()

  const tikTokTracks = useSelector(getTikTokTracksSelector)
  const spotifyTracks = useSelector(getTracks)
  const searchError = useSelector(getAllSearchErrorSelector)
  const isLoadingTikTok = useSelector(getIsLoadingTikTok)
  const isLoadingSpotify = useSelector(getIsLoadingSpotifyTracks)

  const isLoading =
    (!spotifyTracks && isLoadingSpotify) || (!tikTokTracks && isLoadingTikTok)

  const isError = !tikTokTracks && searchError

  return (
    <>
      {isLoading && (
        <SearchingTrackDropdown
          text={t('createCampaignPage.searchingTrack')}
          platform={platform}
        />
      )}

      {isError && (
        <SearchingTrackDropdown error text={searchError} platform={platform} />
      )}
    </>
  )
}
