const DEFAULT_HEIGHT = 1080
export const downloadReview = (
  canvas: HTMLCanvasElement,
  isFull: boolean,
): void => {
  const canvasWrapper = document.createElement('canvas')
  canvasWrapper.setAttribute('width', '1080')
  canvasWrapper.setAttribute(
    'height',
    isFull ? canvas.height.toString() : '1080',
  )
  const ctx = canvasWrapper.getContext('2d')
  if (!ctx) {
    return
  }
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    DEFAULT_HEIGHT,
    isFull ? canvas.height : DEFAULT_HEIGHT,
  )
  const image = canvasWrapper.toDataURL('image/jpeg')
  const link = document.createElement('a')
  link.download = 'review.jpg'
  link.href = image
  link.click()
  link.remove()
  canvasWrapper.remove()
}
