import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

interface Props {
  withborder?: string
  isfaq?: string
}

export const CommonInputWrapper = styled.div<Props>`
  width: 100%;

  .ant-input {
    outline: none !important;
    box-shadow: none !important;
    height: 48px;
    font-size: ${variables.fontSize14};
    border-radius: 8px;
    outline: none;
    padding: 16px;
    width: 100%;
    position: relative;
    background-color: ${colors.white};
    line-height: 21px;
    color: #999999;

    &:focus {
      background-color: ${colors.white};
      outline: none;
      color: ${colors.mainText};
      box-shadow: none;
    }

    &:active {
      background-color: ${colors.white};
      outline: none;
      color: ${colors.mainText};
    }

    @media (max-width: 576px) {
      padding: 0;
      margin: 0;
      &::placeholder {
        padding-left: 4px;
      }
      border: ${(props) =>
        props.withborder && props.isfaq === 'false'
          ? `1px solid ${colors.greyStroke}`
          : 'none !important'};
    }
  }
`
