import { useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'

import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { createCampaignStartEvent } from 'src/Helpers/TagManager'
import { getAmountOfCampaigns } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'

export const useCreateCampaignStartEvent = (): void => {
  const user = useSelector(getUserSelector)
  const amountOfCampaigns = useSelector(getAmountOfCampaigns)

  const campaignNumber = amountOfCampaigns + 1
  const isEventSentRef = useRef(false)
  useEffect(() => {
    if (!isEventSentRef.current && campaignNumber > 1) {
      createCampaignStartEvent(user, campaignNumber)
      isEventSentRef.current = true
    }
  }, [campaignNumber, user])
}
