import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  getIsFeatureValue,
  getIsMemoFeatureValue,
} from 'src/Redux/A-BFeatures-process/selectors'
import { getReferralCode } from 'src/Redux/referral-process/referralCode'
import {
  getHasOnePaidCampaignMetadata,
  getHasPaidCampaignMetadata,
  useMetadataQuery,
} from 'src/Redux/metadataApi'
import { addFeatureForUser } from 'src/Redux/A-BFeatures-process/api-actions'

import { memorizeFeature } from '../Redux/A-BFeatures-process'

import { useAppDispatch, useAppSelector } from './redux'
import { QUICK_CHECKOUT_FEATURE_NAME } from './useCheckoutTimerFeratureFlag'

export const VARIANT_3 = 3
export const VARIANT_2 = 2
const VARIANT_1 = 1

export const BONUS_10 = 10
export const BONUS_20 = 20

export const useBonusTooltipTimer = (): {
  isCurrentBonus: boolean
  isNextTimeBonus: boolean
  getTooltipValues: () => {
    value: number
    description: string
    currentCampaignValue: number
  }
  isBonus: boolean
  variant: number | null
  tooltipValues: {
    value: number
    description: string
    currentCampaignValue: number
  }
  isNextTimeBonusReceived: boolean
  dropCheckoutBonusFeatureForUser: () => void
} => {
  const { t } = useTranslation()
  const { data } = useMetadataQuery()
  const isFirstPaid = getHasPaidCampaignMetadata(data)
  const isOnePay = getHasOnePaidCampaignMetadata(data)
  const referralCode = useSelector(getReferralCode)
  const dispatch = useAppDispatch()

  const featureValue = useAppSelector(
    getIsFeatureValue(QUICK_CHECKOUT_FEATURE_NAME),
  )

  const memoFeatureValue = useAppSelector(
    getIsMemoFeatureValue(QUICK_CHECKOUT_FEATURE_NAME),
  )

  const mainConditions = !referralCode && !isFirstPaid
  const isCurrentBonus =
    (featureValue === VARIANT_2 || featureValue === VARIANT_3) && mainConditions
  const isNextTimeBonus = featureValue === 1 && mainConditions
  const isNextTimeBonusReceived = memoFeatureValue === 1 && isOnePay
  const isBonus = isCurrentBonus || isNextTimeBonus

  const getTooltipValues = useCallback((): {
    value: number
    description: string
    currentCampaignValue: number
  } => {
    const defaultTooltipValues = {
      value: 0,
      currentCampaignValue: 0,
      description: '',
    }

    if (!mainConditions || !featureValue) {
      return defaultTooltipValues
    }

    switch (featureValue) {
      case VARIANT_1:
        return {
          value: BONUS_20,
          currentCampaignValue: 0,
          description: t('scheduleCampaignPage.offerDescriptionBonus20Next'),
        }
      case VARIANT_2:
        return {
          value: BONUS_20,
          currentCampaignValue: BONUS_20,
          description: t('scheduleCampaignPage.offerDescriptionBonus20Current'),
        }
      case VARIANT_3:
        return {
          value: BONUS_10,
          currentCampaignValue: BONUS_10,
          description: t('scheduleCampaignPage.offerDescriptionBonus10Current'),
        }
      default:
        return defaultTooltipValues
    }
  }, [featureValue, mainConditions, t])

  const dropCheckoutBonusFeatureForUser = useCallback((): void => {
    if (featureValue && featureValue !== 0) {
      dispatch(
        memorizeFeature({
          name: QUICK_CHECKOUT_FEATURE_NAME,
          variation: featureValue,
        }),
      )

      void dispatch(
        addFeatureForUser({
          featureName: QUICK_CHECKOUT_FEATURE_NAME,
          variation: 0,
        }),
      )
    }
  }, [dispatch, featureValue])

  return {
    isCurrentBonus,
    isNextTimeBonus,
    isNextTimeBonusReceived,
    isBonus,
    getTooltipValues,
    dropCheckoutBonusFeatureForUser,
    variant: featureValue || null,
    tooltipValues: getTooltipValues(),
  }
}
