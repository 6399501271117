import React, { FC } from 'react'

import { ScheduleTabs } from 'src/Components/ScheduleTabs'
import SuccessPage from 'src/Components/SuccessPage'
import { useAppSelector } from 'src/Hooks/redux'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { TiktokSettings } from './components/TiktokSettings'
import { TiktokCheckout } from './components/TiktokCheckout'
import { useScheduleTikTokCampaign } from './useSchaduleTikTokCampaign'

export const ScheduleTiktok: FC = () => {
  useScheduleTikTokCampaign()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)

  return (
    <>
      <SuccessPage
        startDate={scheduledCampaign.startDate}
        platformName={CampaignPlatformTypeEnum.TIKTOK}
      />

      <ScheduleTabs>
        {/* first must be settings second checkout */}
        <TiktokSettings />
        <TiktokCheckout />
      </ScheduleTabs>
    </>
  )
}
