import { object, string as yupString } from 'yup'

import i18n from 'src/Localization/i18n'

const MIN_LENGTH = 2
const MAX_LENGTH = 30
const PLEASE_ENTER_FIELD = i18n.t(
  'createCampaignPage.pleaseEnterTheRequiredField',
)
export const registerSchema = object().shape({
  firstName: yupString()
    .min(
      MIN_LENGTH,
      String(i18n.t('createCampaignPage.firstNameMustBeAtLeast2Characters')),
    )
    .max(
      MAX_LENGTH,
      String(i18n.t('createCampaignPage.firstNameMustBeLessThan30')),
    )
    .required(PLEASE_ENTER_FIELD),
  lastName: yupString()
    .min(
      MIN_LENGTH,
      String(i18n.t('createCampaignPage.lastNameMustBeAtLeast2Characters')),
    )
    .max(
      MAX_LENGTH,
      String(i18n.t('createCampaignPage.lastNameMustBeLessThan30')),
    )
    .required(PLEASE_ENTER_FIELD),
  email: yupString()
    .email(String(i18n.t('createCampaignPage.invalidEmailAddress')))
    .required(PLEASE_ENTER_FIELD),
  role: yupString().required(PLEASE_ENTER_FIELD),
  phone_number: yupString().nullable(),
})
