import { createAsyncThunk } from '@reduxjs/toolkit'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { NameSpace } from '../../types'

interface GetBillingAgreementResponse {
  paypalEmail?: string
  id?: number
}

export const getBillingAgreement =
  createAsyncThunk<GetBillingAgreementResponse>(
    `${NameSpace.BILLING_AGREEMENT}/getBillingAgreement`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await api.get<GetBillingAgreementResponse>(
          `${APP.TIKTOK_SERVER}/payment/paypal/saved-billing-agreement`,
        )
        return data
      } catch (error) {
        return rejectWithValue(error)
      }
    },
  )
