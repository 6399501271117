import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0;
  @media ${device.mobileMin} {
    row-gap: 0;
  }
`

interface Props {
  isRulesPage?: boolean
}

export const ButtonsGroups = styled.div<Props>`
  margin-top: 40px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 24px;
  flex-direction: ${(props: Props) =>
    props.isRulesPage ? 'column' : 'column-reverse'};
  @media ${device.mobileMin} {
    flex-direction: row;
    justify-content: center;
    column-gap: 1rem;
    align-items: flex-start;
    flex-direction: ${(props: Props) =>
      props.isRulesPage ? 'row-reverse' : 'initial'};
  }
`

export const CloseWrapper = styled.div`
  width: fit-content;
  .ant-btn {
    padding: 0 40px;
  }
`

export const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  & > div {
    width: fit-content;
    .ant-btn {
      padding: 0 40px;
    }
  }
`

export const Success = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  row-gap: 16px;
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  line-height: 27px;
  align-items: center;
  justify-content: center;

  @media ${device.mobileMin} {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: ${variables.fontSize20};
    line-height: 30px;
    margin-bottom: 80px;
  }

  @media ${device.mobileMax} {
    span {
      max-width: 200px;
    }
  }
`

export const Notification = styled.div`
  margin-top: 24px;
  line-height: 21px;
  display: flex;
  align-items: flex-start;
  color: ${colors.messegesYellow1};
  column-gap: 0.5rem;
  text-align: left;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};

  > img {
    margin-top: 4px;
    width: fit-content;
  }
  @media ${device.mobileMin} {
    margin-top: 24px;
  }
`

export const CancelButtonWrapper = styled.div`
  .ant-btn {
    padding: 0 40px;
    width: fit-content;
  }

  @media ${device.mobileMax} {
    margin-top: 0;
  }
`
