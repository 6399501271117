import { FC } from 'react'

import { Popover, PopoverProps } from 'antd'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import LinkIcon from 'src/Assets/Svg/link-icon.svg?react'
import { soundLink } from 'src/Constants/links'
import { changeMobileSidebarVisibility } from 'src/Redux/sidebar-process'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { soundLinkClickEvent } from 'src/Helpers/TagManager'
import { getSidebar } from 'src/Redux/sidebar-process/selectors'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getAmountOfCampaigns } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { useHint } from 'src/Hooks/useHint'

import { SoundLinkHint } from '../SoundLinkHint'

import {
  SoundLinkItem,
  SoundIcon,
  SidebarSoundLinkText,
  White,
  Green,
} from './styles'

const StyledPopover = styled((props: PopoverProps) => (
  <Popover {...props} overlayClassName={props.className} />
))`
  .ant-popover-inner {
    padding: 10px 16px;
  }
  .ant-popover-content {
    left: 16px !important;
  }
  .ant-popover-arrow {
    left: 50px !important;
  }
`

export const SoundLink: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isArtist = useAppSelector(getIsArtist)
  const { collapse, show } = useAppSelector(getSidebar)
  const userId = useAppSelector(getUserId)
  const isTablet = useMediaQuery({ maxWidth: 1023 })

  const amountOfCampaigns = useSelector(getAmountOfCampaigns)
  const { isOpen, close } = useHint()

  if (!isArtist) {
    return null
  }
  const handleSoundLinkClickEvent = (): void => {
    soundLinkClickEvent({ userId, campaignNumber: amountOfCampaigns })
    isTablet && dispatch(changeMobileSidebarVisibility())
  }

  return (
    <StyledPopover
      overlayInnerStyle={{ backgroundColor: 'black' }}
      color='black'
      placement='top'
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      content={<SoundLinkHint onClick={close} />}
      trigger={'hover'}
      open={isOpen}
    >
      <SoundLinkItem
        collapse={collapse}
        isShownOnMobile={show}
        isMobile={isTablet}
        href={soundLink as string}
        target='_blank'
        onClick={handleSoundLinkClickEvent}
      >
        <SoundIcon>
          <LinkIcon />
        </SoundIcon>
        <SidebarSoundLinkText collapse={!collapse}>
          <White>{t('sidebar.sound')}</White>
          <Green>{t('sidebar.link')}</Green>
        </SidebarSoundLinkText>
      </SoundLinkItem>
    </StyledPopover>
  )
}
