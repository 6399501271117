import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { Pagination } from 'src/Types'

import { NameSpace, ErrorCode } from '../types'

export enum FilterDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface CuratorTrack {
  reward: number
  level: number
  campaign: {
    id: number
    startDate: string
    endDate: string
    trackName: string
    artistName: string
    trackUrl: string
    matchedGenres: Array<string>
    matchedLanguages: Array<string>
    matchedPlaylists: Array<string>
    matchedPercent: number
  }
}

interface GetCuratorTracksResponse {
  data: Array<CuratorTrack>
  meta: Pagination
}

export enum FilterType {
  date = 'date',
  genres = 'genres',
  playlists = 'playlists',
}

interface TracksBody {
  filterDirection: FilterDirection
  filterType: FilterType
}

export const getCuratorTracks = createAsyncThunk<
  GetCuratorTracksResponse,
  TracksBody
>(
  `${NameSpace.CURATOR_TRACKS}/getCuratorTracks`,
  async (
    { filterDirection = FilterDirection.ASC, filterType = FilterType.date },
    { rejectWithValue },
  ) => {
    try {
      const filterTypeValue =
        filterType === FilterType.date ? 'startDate' : filterType

      const { data } = await api.get<GetCuratorTracksResponse>(
        `${APP.TIKTOK_SERVER}/curators/available-reviews/?pageNumber=1&pageSize=10&sortBy=${filterTypeValue}.${filterDirection}`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
