import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const VideoItemCardContainer = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  background: ${colors.greyBg};
  padding: 24px;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: repeat(5, minmax(0, auto));
  gap: 10px;
  align-items: start;
  margin-bottom: 8px;
  @media ${device.smallScreen} {
    grid-template-columns: 40px 1fr 230px 150px 1fr 90px;
    grid-template-rows: none;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    margin-bottom: 2px;
  }
`
export const UserPicContainer = styled.div`
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: grid;
  grid-column-start: 1;
  grid-row-start: 1;
  justify-items: center;
  // align-items: self-start;
  justify-self: center;
  & img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  @media ${device.smallScreen} {
    grid-auto-flow: column;
    width: 40px;
    height: 40px;
    align-items: center;
  }
`

export const UserInfoWrapper = styled.div`
  display: grid;
  gap: 0px;
  grid-column-start: 2;
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};

  @media ${device.smallScreen} {
    grid-column-start: auto;
    padding-top: 4px;
  }
  & > span {
    margin-top: -4px;
    margin-bottom: 4px;
    font-size: ${variables.fontSize10};
    @media ${device.smallScreen} {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`
export const Name = styled.div`
  line-height: 150%;
`

export const UserParamWrapper = styled.div`
  display: grid;
  grid-column-start: 2;
  width: 100%;

  @media ${device.smallScreen} {
    grid-column-start: auto;
    text-align: center;
  }
  & > span {
    line-height: 150%;
  }
`

export const ViewsParamWrapper = styled.div`
  display: grid;
  grid-column-start: 2;
  width: 100%;
  padding-top: 6px;

  @media ${device.smallScreen} {
    grid-column-start: auto;
    padding-top: 0px;
  }
  & > span {
    line-height: 150%;
  }
`

export const ButtonWrapper = styled.div`
  //width: 144px;
  display: grid;
  grid-column-start: 2;
  padding-top: 2px;

  @media ${device.smallScreen} {
    grid-column-start: auto;
    width: 90px;
    padding-top: 0px;
  }
`
export const ParamTitle = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 150%;
`
export const StatusWrapper = styled.div`
  min-width: 72px;
  display: grid;
  grid-column-start: 2;
  flex-direction: column;
  padding-top: 10px;
  div {
    width: fit-content;
  }
  @media ${device.smallScreen} {
    grid-column-start: auto;
    padding-top: 4px;
  }
`
