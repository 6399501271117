import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const OrderedListContainer = styled.ul`
  list-style: none;
  padding: 0;
`

export const ImageWrapper = styled.div`
  width: 252px;
  height: 72px;
  margin-bottom: 16px;
`

export const HowSoundCampaignWorksList = styled.ul`
  list-style: none;
  padding: 0px;
`

export const OrderedItem = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 8px;
`

export const OrderedListNumber = styled.div`
  border: 1px solid ${colors.green};
  width: 24px;
  height: 24px;
  border-radius: 50px;
  color: ${colors.green};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize14};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OrderedListTitle = styled.div`
  color: ${colors.green};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize18};
  line-height: 150%;
  margin-bottom: 8px;
`

export const OrderedBlock = styled.div`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  align-items: center;
  grid-column: span 1;
  grid-column-start: 2;
`
export const OrderedText = styled.div`
  margin-bottom: 16px;
`
