import { useEffect, useState, FC, useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  getFeedTracks,
  getReviewedFeedTracks,
} from 'src/Redux/feed-process/api-actions'
import {
  getTotalAwaitingTracksSelector,
  getTotalReviewedTracksSelector,
} from 'src/Redux/feed-process/selectors'
import { FeedFilterType, FeedFilterDirection } from 'src/Types/index'
import { useAppDispatch } from 'src/Hooks/redux'
import { review, reviewed, tracksFeed } from 'src/Router/routes'

import SkipReviewModal from '../SkipReviewModal/SkipReviewModal'
import { ListFilter } from '../ListFilter'

import { Tabs, SkipMobileProps, ReviewMobileProps } from './props'
import { TabCount } from './TabCount'
import { AwaitingCampaignsList } from './AwaitingCampaignsList'
import { ReviewedCampaignsList } from './ReviewedCampaignsList'
import { TracksFeedAlert } from './TracksFeedAlert/TracksFeedAlert'

import { Container, TabTitles } from './styles'

export const TrackList: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const totalFeed = useSelector(getTotalAwaitingTracksSelector)
  const totalReviewed = useSelector(getTotalReviewedTracksSelector)
  const [currentTab, setCurrentTab] = useState<string>(Tabs.awaiting_review)

  const [filterType, setFilterType] = useState<FeedFilterType>(
    FeedFilterType.date,
  )
  const [filterDirection, setFilterDirection] = useState<FeedFilterDirection>(
    FeedFilterDirection.ASC,
  )

  const onActionCallback = useCallback(() => {
    window.scrollTo(0, 0)
    void dispatch(
      getFeedTracks({
        filterType,
        filterDirection,
      }),
    )
    void dispatch(
      getReviewedFeedTracks({
        page: 1,
      }),
    )
  }, [filterType, filterDirection, dispatch])

  const openReviewModal = ({
    isReviewed,
    campaign_id,
  }: ReviewMobileProps): void => {
    window.scrollTo(0, 0)
    if (isReviewed && campaign_id) {
      navigate(`${tracksFeed}/${campaign_id}${reviewed}`)
      return
    }
    navigate(`${tracksFeed}/${campaign_id}${review}`)
  }

  const openSkipModal = ({ campaign_id }: SkipMobileProps): void => {
    navigate(`${tracksFeed}?skip=${campaign_id}`)
  }

  useEffect(() => {
    onActionCallback()
  }, [onActionCallback])

  return (
    <>
      <TracksFeedAlert />
      <Container bottomPadding={currentTab === Tabs.reviewed}>
        <TabTitles
          items={[
            {
              label: (
                <TabCount
                  count={totalFeed}
                  title={t('influencer.awaitingReviewTab')}
                />
              ),
              key: Tabs.awaiting_review,
              children: (
                <AwaitingCampaignsList
                  openReviewModal={openReviewModal}
                  openSkipModal={openSkipModal}
                />
              ),
            },
            {
              label: (
                <TabCount
                  title={t('campaignResultsPage.reviewed')}
                  count={totalReviewed}
                />
              ),
              key: Tabs.reviewed,
              children: (
                <ReviewedCampaignsList openReviewModal={openReviewModal} />
              ),
            },
          ]}
          onChange={setCurrentTab}
        />
        <ListFilter
          setFilterType={setFilterType}
          setFilterDirection={setFilterDirection}
          filterType={filterType}
          filterDirection={filterDirection}
          isFilterVisible={currentTab === Tabs.awaiting_review}
        />
      </Container>
      <SkipReviewModal onReload={onActionCallback} />
    </>
  )
}
