import { Modal } from 'antd'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const SkipReviewModalWrapper = styled.div`
  padding: 24px;
`
export const SkipReviewModalHeader = styled.div`
  text-align: center;
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: normal;
  margin-bottom: 24px;
`
export const SkipReviewModalText = styled.div`
  text-align: left;
  margin-bottom: 24px;
  color: ${colors.mainText};
`
export const SkipReviewModalNote = styled.div`
  text-align: left;
`
export const GreenText = styled.span`
  color: ${colors.green};
`

export const ModalButtons = styled.div`
  margin: 24px auto 0;
  width: 276px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`
export const StyledModal = styled(Modal)`
  top: 10%;
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
  }
  .ant-modal-header {
    padding: 16.5px 24px;
    background-color: ${colors.green};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-title {
    color: ${colors.white};
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
