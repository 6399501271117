import { FC } from 'react'
import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'

interface IconComponentProps {
  platform: CampaignPlatformTypeEnum
  width?: number
  height?: number
}

export const IconComponent: FC<IconComponentProps> = ({
  platform,
  width = 16,
  height = 16,
}) => {
  return (
    <>
      {platform === CampaignPlatformTypeEnum.SPOTIFY ? (
        <IconSpotify width={width} height={width} />
      ) : (
        <TikTokIcon width={height} height={height} />
      )}
    </>
  )
}
