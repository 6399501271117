import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { GenresState } from 'src/Redux/genres-process/index'

import { RootState } from '..'

const getState = (state: RootState): GenresState => state.genres

export const getIsGenresLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getGenres = createDraftSafeSelector(
  getState,
  ({ genres }) => genres,
)
const SLICE_INDEX = 10
export const getAutoSelectedGenres = createDraftSafeSelector(
  getState,
  ({ autoselectedGenres }) =>
    autoselectedGenres
      .map((el) => ({
        id: el.id,
        genre_name: el.genreName,
      }))
      .slice(0, SLICE_INDEX),
)
