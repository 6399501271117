import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { ArtistBalanceItem } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { getArtistBalanceDetails } from './api-actions'

export interface ArtistBalanceHistoryState {
  artistBalanceHistory: Array<ArtistBalanceItem>
  status: SliceStatus
  error: ApiError | null
}

const initialState: ArtistBalanceHistoryState = {
  artistBalanceHistory: [],
  status: SliceStatus.Idle,
  error: null,
}

export const artistBalanceHistorySlice = createSlice({
  initialState,
  name: NameSpace.ArtistBalanceHistory,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArtistBalanceDetails.pending, (state) => {
        state.status = SliceStatus.Loading
      })
      .addCase(getArtistBalanceDetails.fulfilled, (state, action) => {
        state.status = SliceStatus.Succeeded
        state.artistBalanceHistory = action.payload
      })
      .addCase(getArtistBalanceDetails.rejected, (state, action) => {
        state.status = SliceStatus.Failed
        state.error = setApiError(action)
      })
  },
})
