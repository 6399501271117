import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum } from 'src/Types'

interface Props {
  error?: boolean
  platform: CampaignPlatformTypeEnum
  isAbsolutePosition: boolean
}

export const WrapperRelative = styled.div<Props>`
  position: ${(props: Props) => props.isAbsolutePosition && 'absolute'};
  z-index: 10;
  top: 52px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background-color: ${colors.white};
  gap: 8px;
  margin-top: ${(props: Props) =>
    props.platform === CampaignPlatformTypeEnum.TIKTOK && '8px'};

  @media ${device.mobileMax} {
    box-shadow: ${(props: Props) =>
      props.error ? 'none' : '0px 0px 6px rgba(0, 0, 0, 0.25)'};
    border: ${(props: Props) => (props.error ? 'none' : 'initial')};
  }
`

export const SearchingRelative = styled.div<Props>`
  border-radius: 8px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
  color: ${(props: Props) =>
    props.error ? colors.messegesRed : colors.mainText};
  background-color: ${colors.white};
`
