import { FunctionComponent, ReactElement, useEffect } from 'react'

import { ColumnsType } from 'antd/es/table'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'

import InfoCard from 'src/Components/InfoCard'
import { LoadingSpinner } from 'src/Containers/ui'
import { getArtistBalanceStats } from 'src/Redux/artistBalanceProcess/ArtistBalanceStatsSlice/api-actions'
import { getArtistBalanceStatsSelector } from 'src/Redux/artistBalanceProcess/ArtistBalanceStatsSlice/selectors'
import { useAppDispatch } from 'src/Hooks/redux'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { colors } from 'src/Styled/variables'
import {
  getArtistBalanceHistory,
  getArtistBalanceHistoryLoading,
} from 'src/Redux/artistBalanceProcess/ArtistBalanceHistorySlice/selectors'
import { getArtistBalanceDetails } from 'src/Redux/artistBalanceProcess/ArtistBalanceHistorySlice/api-actions'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { ArtistBalanceItem, BalanceTrack, TransactionTypeEnum } from 'src/Types'

import { MobileBalanceList } from './components/MobileBalanceList'
import { useArtistBalancePage } from './helpers'
import { BalanceSubscriptionInfo } from './components/BalanceSubscriptionInfo/BalanceSubscriptionInfo'

import {
  Amount,
  HeaderTitle,
  IconWrapper,
  InfoCardsWrapper,
  InfoCardWrapper,
  InfoText,
  MobileInfoText,
  PageContainer,
  PageContentContainer,
  PageSubTitle,
  TrackCard,
  TrackCardInfoWrapper,
} from './styles'

/**
 * Balance page. Artist side.
 */

const ArtistBalancePage: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const bonusText = t('balancePage.bonus')

  const { getIcon, getAmountValue, getTrackTitle } = useArtistBalancePage()

  const artistBalanceStats = useSelector(getArtistBalanceStatsSelector)
  const artistBalanceHistoryData = useSelector(getArtistBalanceHistory)
  const isBalanceHistoryLoading = useSelector(getArtistBalanceHistoryLoading)

  useEffect(() => {
    void dispatch(getArtistBalanceStats())
    void dispatch(getArtistBalanceDetails())
  }, [dispatch])

  const MAP: Record<TransactionTypeEnum, ReactElement> = {
    [TransactionTypeEnum.USED]: (
      <InfoText>{t('balancePage.creditsUsed')}</InfoText>
    ),
    [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: (
      <InfoText>{bonusText}</InfoText>
    ),
    [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: (
      <InfoText>{bonusText}</InfoText>
    ),
    [TransactionTypeEnum.SUBSCRIPTION]: (
      <InfoText>{t('balancePage.monthlyPayment')}</InfoText>
    ),
    [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: (
      <InfoText>{t('balancePage.monthlyPayment')}</InfoText>
    ),
    [TransactionTypeEnum.CASHBACK]: (
      <InfoText>{t('balancePage.cashback')}</InfoText>
    ),
    [TransactionTypeEnum.RECEIVED]: (
      <InfoText>{t('balancePage.creditReceived')}</InfoText>
    ),
    [TransactionTypeEnum.CHARGEBACK]: (
      <InfoText>{t('balancePage.chargeback')}</InfoText>
    ),
    [TransactionTypeEnum.PAYMENT]: (
      <InfoText>{t('balancePage.payment')}</InfoText>
    ),
    [TransactionTypeEnum.INVITED_BONUS]: (
      <InfoText>{t('balancePage.referralCredits')}</InfoText>
    ),
    [TransactionTypeEnum.REFERRAL_BONUS]: (
      <InfoText>{t('balancePage.referralCredits')}</InfoText>
    ),
    [TransactionTypeEnum.BONUS]: <InfoText>{bonusText}</InfoText>,
  }

  const columns: ColumnsType<ArtistBalanceItem> = [
    {
      dataIndex: 'date',
      key: 'date',
      width: '14%',
      title: (
        <div>
          <HeaderTitle>{t('balancePage.date')}</HeaderTitle>
        </div>
      ),
    },
    {
      title: <HeaderTitle>{t('balancePage.transaction')}</HeaderTitle>,
      dataIndex: 'transactionType',
      key: 'transactionType',
      width: '20%',
      render: (transactionType: TransactionTypeEnum) => {
        const element = MAP[transactionType]
        if (element) {
          return element
        }

        return <span />
      },
    },
    {
      title: <HeaderTitle>{t('balancePage.details')}</HeaderTitle>,
      dataIndex: ['track'],
      key: 'track',
      render: (track: BalanceTrack, value) => (
        <TrackCard>
          <IconWrapper>
            {getIcon({
              platformType: value.platformType,
              transactionType: value.transactionType,
            })}
          </IconWrapper>
          <TrackCardInfoWrapper>
            {getTrackTitle({
              track,
              packageName: value.packageName,
              platformType: value.platformType,
              transactionType: value.transactionType,
              referralData: value.referralData,
              invitedData: value.invitedData,
            })}
          </TrackCardInfoWrapper>
        </TrackCard>
      ),
      width: '52%',
    },
    {
      title: <HeaderTitle>{t('balancePage.amount')}</HeaderTitle>,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number, value) => (
        <Amount
          isRed={
            value.transactionType ===
            TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED
          }
        >
          {getAmountValue(value.transactionType, amount)}
        </Amount>
      ),
      width: '14%',
    },
  ]

  return (
    <PageContainer>
      <PageSubTitle>{t('balancePage.pageTitle')}</PageSubTitle>
      <BalanceSubscriptionInfo />
      <InfoCardsWrapper>
        <InfoCardWrapper>
          <InfoCard
            title='Current balance'
            value={`$ ${
              artistBalanceStats?.currentBalance
                ? addSpaceToNumber(
                    artistBalanceStats?.currentBalance,
                    ',',
                    true,
                  )
                : 0
            }`}
            type='green'
          />
        </InfoCardWrapper>
        <InfoCardWrapper>
          <InfoCard
            title='Total paid'
            value={`$ ${
              artistBalanceStats?.totalPaid
                ? addSpaceToNumber(artistBalanceStats?.totalPaid, ',')
                : 0
            }`}
            type='regular'
          />
        </InfoCardWrapper>
        <InfoCardWrapper>
          <InfoCard
            title='Total used'
            value={`$ ${
              artistBalanceStats?.totalUsed
                ? addSpaceToNumber(artistBalanceStats?.totalUsed, ',')
                : 0
            }`}
            type='regular'
          />
        </InfoCardWrapper>
      </InfoCardsWrapper>
      <Desktop>
        <PageContentContainer>
          <Table
            loading={{
              indicator: <LoadingSpinner color={colors.green} size='large' />,
              spinning: isBalanceHistoryLoading,
            }}
            rowKey='id'
            pagination={false}
            columns={columns}
            dataSource={artistBalanceHistoryData}
            locale={{
              emptyText: !isBalanceHistoryLoading && (
                <MobileInfoText>
                  {t('balancePage.YouHaveNoTransactions')}
                </MobileInfoText>
              ),
            }}
          />
        </PageContentContainer>
      </Desktop>
      <Mobile>
        <MobileBalanceList
          items={artistBalanceHistoryData}
          isLoading={isBalanceHistoryLoading}
        />
      </Mobile>
    </PageContainer>
  )
}

export default ArtistBalancePage
