import { FC } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Radio } from 'antd'
import styled from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'

import { colors, variables } from 'src/Styled/variables'
import { TextArea } from 'src/Containers/ui/TextArea'
import { MAX_COMMENT_LENGTH } from 'src/Constants/numeric'

import { CancelButtons } from '../CancelButtons'

import { CANCEL_PREMIUM_REASONS, cancelPremiumFormSchema } from './schema'

const StyledRadio = styled(Radio)`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  line-height: 150%;
  display: flex;
  padding: 9.5px 0px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  &.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #d9d9d9;
  }
  &.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.greyStroke};
    background-color: transparent;
    &::after {
      background-color: ${colors.green};
    }
  }
`
const Form = styled.form`
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

export interface CancelPremiumFormValues {
  reason: string
  reasonDetails?: string
}

interface CancelPremiumFormProps {
  onSave: (data: CancelPremiumFormValues) => void
  loading?: boolean
}
export const CancelSubscriptionForm: FC<CancelPremiumFormProps> = ({
  onSave,
  loading,
}) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<CancelPremiumFormValues>({
    resolver: yupResolver(cancelPremiumFormSchema),
  })

  const onSubmit = (data: CancelPremiumFormValues): void => {
    onSave(data)
  }
  const reasonDetailsShow =
    watch('reason') ===
    CANCEL_PREMIUM_REASONS[CANCEL_PREMIUM_REASONS.length - 1].value
  return (
    <Form>
      <Controller
        name='reason'
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <StyledRadioGroup ref={ref} value={value} onChange={onChange}>
            {CANCEL_PREMIUM_REASONS.map((reason) => (
              <StyledRadio key={reason.value} value={reason.value}>
                {t(reason.value)}
              </StyledRadio>
            ))}
          </StyledRadioGroup>
        )}
      />
      {reasonDetailsShow && (
        <Controller
          name='reasonDetails'
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextArea
              maxLength={MAX_COMMENT_LENGTH}
              autoSize={{ minRows: 4 }}
              showCount={{
                formatter: (count) =>
                  count.value ? `${count.count}/${MAX_COMMENT_LENGTH}` : '',
              }}
              prefixCls='cancel-premium'
              style={{ resize: 'none' }}
              rows={4}
              placeholder={t('getPremiumPage.specify') || ''}
              value={value}
              onChange={onChange}
            />
          )}
        />
      )}
      <CancelButtons
        loading={loading}
        onCancel={handleSubmit(onSubmit)}
        disabled={!isValid}
      />
    </Form>
  )
}
