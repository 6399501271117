import { useEffect } from 'react'

import { onGetFeedEvent } from 'src/Containers/TracksFeed/components/TrackList/helpers'
import { FeedDetails } from 'src/Types'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'

import { useAppSelector } from './redux'

export const useGetFeedEvent = (response?: FeedDetails, id?: string): void => {
  const userId = useAppSelector(getUserId)
  useEffect(() => {
    if (response && id && userId) {
      onGetFeedEvent(response, Number(id), userId)
    }
  }, [userId, response, id])
}
