import React, { FC, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'

import { Mobile } from 'src/Components/Responsive'
import {
  SpotifyCampaign,
  TiktokCampaign,
  CampaignStatusEnum,
  TiktokCampaignStatus,
} from 'src/Types/index'
import { compareCampaignsByDate } from 'src/Constants/functions'
import { CampaignsListPromoAlert } from 'src/Containers/Subscriptions/components/CampaignsListPromoAlert'

import { CampaignCard } from '../CampaignCard'

import { StyledList, StyledNoData, StyledNumber, TabName } from './styles'

interface ITab {
  key: string
  label: React.ReactNode
  children: React.ReactNode
}

const NoData: FC = () => <StyledNoData>No data available</StyledNoData>

const PENDING_STATUSES = [
  CampaignStatusEnum.PENDING_APPROVAL,
  TiktokCampaignStatus.AWAITING_REVIEW,
]

const APPROVED_STATUSES = [
  CampaignStatusEnum.APPROVED,
  TiktokCampaignStatus.PENDING,
]

const RUNNING_STATUSES = [
  CampaignStatusEnum.RUNNING,
  TiktokCampaignStatus.IN_PROCESS,
]

const STOPPED_STATUSSES = [
  TiktokCampaignStatus.STOPPED,
  CampaignStatusEnum.STOPPED,
  TiktokCampaignStatus.CANCELED_BY_ADMIN,
  TiktokCampaignStatus.STOPPED_BY_ADMIN,
]

const ENDED_STATUSES = [CampaignStatusEnum.ENDED, TiktokCampaignStatus.FINISHED]

export const useCampaignsTabs = (
  allCampaigns: (SpotifyCampaign | TiktokCampaign)[],
  activeTab: string,
  component: ReactNode,
): ITab[] => {
  const { t } = useTranslation()

  const sortedCampaigns = allCampaigns.slice().sort(compareCampaignsByDate)

  const allCampaignsList = sortedCampaigns.map((campaign) => (
    <CampaignCard campaign={campaign} key={campaign.id} activeTab={activeTab} />
  ))

  const allCampaignsListLength = allCampaignsList?.length

  const pendingApprovalList = sortedCampaigns
    ?.filter(({ status }) => PENDING_STATUSES.includes(status))
    .map((campaign) => (
      <CampaignCard
        campaign={campaign}
        key={campaign.id}
        activeTab={activeTab}
      />
    ))

  const pendingApprovalListLength = pendingApprovalList?.length

  const approvedList = sortedCampaigns
    ?.filter(({ status }) => APPROVED_STATUSES.includes(status))
    .map((campaign) => (
      <CampaignCard
        campaign={campaign}
        key={campaign.id}
        activeTab={activeTab}
      />
    ))

  const approvedListLength = approvedList?.length

  const runningList = sortedCampaigns
    ?.filter(({ status }) => RUNNING_STATUSES.includes(status))
    .map((campaign) => (
      <CampaignCard
        campaign={campaign}
        key={campaign.id}
        activeTab={activeTab}
      />
    ))

  const runningListLength = runningList?.length

  const finishedList = sortedCampaigns
    ?.filter(({ status }) => ENDED_STATUSES.includes(status))
    .map((campaign) => (
      <CampaignCard
        campaign={campaign}
        key={campaign.id}
        activeTab={activeTab}
      />
    ))

  const finishedListLength = finishedList?.length

  const stoppedList = sortedCampaigns
    ?.filter(({ status }) => STOPPED_STATUSSES.includes(status))
    .map((campaign) => (
      <CampaignCard
        campaign={campaign}
        key={campaign.id}
        activeTab={activeTab}
      />
    ))

  const stoppedListLength = stoppedList?.length
  return [
    {
      label: (
        <>
          <TabName>{t('campaignResultsPage.all')}</TabName>
          <StyledNumber>{allCampaignsListLength}</StyledNumber>
        </>
      ),
      key: '1',
      children: (
        <>
          <Mobile>{component}</Mobile>
          <CampaignsListPromoAlert />
          {allCampaignsList.length > 0 ? (
            <StyledList>{allCampaignsList}</StyledList>
          ) : (
            <NoData />
          )}
        </>
      ),
    },
    {
      label: (
        <>
          <TabName>{t('campaignsPage.pendingApproval')}</TabName>
          <StyledNumber>{pendingApprovalListLength}</StyledNumber>
        </>
      ),
      key: '2',
      children: (
        <>
          <Mobile>{component}</Mobile>
          <CampaignsListPromoAlert />
          {pendingApprovalList.length > 0 ? (
            <StyledList>{pendingApprovalList}</StyledList>
          ) : (
            <NoData />
          )}
        </>
      ),
    },
    {
      label: (
        <>
          <TabName>{t('campaignsPage.approved')}</TabName>
          <StyledNumber approved>{approvedListLength}</StyledNumber>
        </>
      ),
      key: '3',
      children: (
        <>
          <Mobile>{component}</Mobile>
          <CampaignsListPromoAlert />
          {approvedList.length > 0 ? (
            <StyledList>{approvedList}</StyledList>
          ) : (
            <NoData />
          )}
        </>
      ),
    },
    {
      label: (
        <>
          <TabName>{t('campaignsPage.running')}</TabName>
          <StyledNumber>{runningListLength}</StyledNumber>
        </>
      ),
      key: '4',
      children: (
        <>
          <Mobile>{component}</Mobile>
          <CampaignsListPromoAlert />
          {runningList.length > 0 ? (
            <StyledList>{runningList}</StyledList>
          ) : (
            <NoData />
          )}
        </>
      ),
    },
    {
      label: (
        <>
          <TabName>{t('campaignsPage.ended')}</TabName>
          <StyledNumber>{finishedListLength}</StyledNumber>
        </>
      ),
      key: '5',
      children: (
        <>
          <Mobile>{component}</Mobile>
          <CampaignsListPromoAlert />
          {finishedList.length > 0 ? (
            <StyledList>{finishedList}</StyledList>
          ) : (
            <NoData />
          )}
        </>
      ),
    },
    {
      label: (
        <>
          <TabName>{t('campaignsPage.stopped')}</TabName>
          <StyledNumber>{stoppedListLength}</StyledNumber>
        </>
      ),
      key: '6',
      children: (
        <>
          <Mobile>{component}</Mobile>
          <CampaignsListPromoAlert />
          {stoppedList.length > 0 ? (
            <StyledList>{stoppedList}</StyledList>
          ) : (
            <NoData />
          )}
        </>
      ),
    },
  ]
}
