import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'

import { orderPayout } from './api-actions'

export interface OrderPayoutState {
  status: SliceStatus
  error: ApiError | null
}

const initialState: OrderPayoutState = {
  status: SliceStatus.Idle,
  error: null,
}

export const orderPayoutSlice = createSlice({
  initialState,
  name: NameSpace.OrderPayout,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(orderPayout.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(orderPayout.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
      state.error = null
    })
    builder.addCase(orderPayout.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
