import { useState, FunctionComponent, useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  getCookieByName,
  getUtmParameters,
  handleDefinePlatform,
} from 'src/Constants/functions'
import { setTrackToInitialState } from 'src/Redux/track-process'
import {
  fistCampaignArtistDetailsEvent,
  pageViewEvent,
  phoneNumberAddedEvent,
  artistRegistrationCompleteEvent,
} from 'src/Helpers/TagManager'
import Logo from 'src/Assets/Svg/logoSoundCampaign.svg?react'
import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import {
  ANY_LANGUAGE_ID,
  GA_LENGTH,
  NAVIGATE_BACK_INDEX,
} from 'src/Constants/constants'
import { getTikTokTrackSelector } from 'src/Redux/tiktokTrack-process/selectors'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { createCampaignWithoutSignup } from 'src/Redux/auth-process/credentialsSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import { setTiktokTrackToInitialState } from 'src/Redux/tiktokTrack-process'
import { getReferralCode } from 'src/Redux/referral-process/referralCode'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { createdFirst } from 'src/Router/routes'
import { signupLoginError } from 'src/Helpers/TagManager/AuthEvents'

import UserRegisterForm from '../UserRegisterForm'
import { FormDataProps } from '../UserRegisterForm/UserRegisterFormProps'

import { spotifyEventsHandler, tiktokEventsHandler } from './helper'
import { RegisterTitle } from './RegisterTitle'

import {
  RegisterWrapper,
  CustomModalUserRegisterModal,
  LogoHeader,
  CloseButton,
  TrackInfoAndFormWrapper,
} from './styles'

const genreIds: number[] = []
const languageIds = [ANY_LANGUAGE_ID]

export const UserRegisterModal: FunctionComponent = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const tikTokTrack = useSelector(getTikTokTrackSelector)
  const spotifyTrack = useSelector(getSpotifyTrackSelector)
  const referralCode = useSelector(getReferralCode)

  const [emailExist, setEmailExist] = useState(false)

  const handleResetEmailExist = (): void => {
    setEmailExist(false)
  }

  const setGTMEventWhenEnterPhoneNumber = (): void => {
    phoneNumberAddedEvent()
  }

  const handleSubmit = useCallback(
    (data: FormDataProps, token: string): void => {
      const gcid = getCookieByName('_ga')?.slice(GA_LENGTH) || ''
      const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))
      const url = window.location.href

      fistCampaignArtistDetailsEvent()

      let tikToKCampaign

      if (tikTokTrack) {
        tikToKCampaign = {
          ...tikTokTrack,
          languages: languageIds,
          genres: genreIds,
        }
      }

      let spotifyCampaign

      if (spotifyTrack) {
        spotifyCampaign = {
          url,
          spotify_track: spotifyTrack,
          language_ids: languageIds,
          genre_ids: genreIds,
        }
      }

      void dispatch(
        createCampaignWithoutSignup({
          gcid,
          utmObj,
          artist: data,
          captchaToken: token,
          spotify_campaign: spotifyCampaign || null,
          tiktok_campaign: tikToKCampaign || null,
          referral_code: utmObj.ref || '',
          internal_referral_code: referralCode || '',
        }),
      )
        .unwrap()
        .then((res) => {
          res.tiktok_campaign
            ? tiktokEventsHandler({ res, dispatch })
            : spotifyEventsHandler({ res, dispatch })

          pageViewEvent(null, pageInfo.CreatedFirstCampaign)

          artistRegistrationCompleteEvent({
            platformName: handleDefinePlatform({
              isSpotifyTrack: Boolean(spotifyTrack),
              isTikTokTrack: Boolean(tikTokTrack),
            }),
            userId: res.user.id,
            userRole: res.user.role,
            spotifyCampaignId: res.spotify_campaign?.id,
            tiktokCampaignId: res.tiktok_campaign?.id,
            isFeed: Boolean(res.spotify_campaign?.is_feed) || false,
          })

          dispatch(setTrackToInitialState())
          dispatch(setTiktokTrackToInitialState())

          localStorage.removeItem('track')
          const campaignType = res.tiktok_campaign ? 'tiktok' : 'spotify'
          navigate(`${createdFirst}/${campaignType}`)
          return res
        })
        .catch(() => {
          signupLoginError()
          setEmailExist(true)
        })
    },
    [tikTokTrack, spotifyTrack, dispatch, referralCode, navigate],
  )

  const onBackClick = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
  }

  return (
    <CustomModalUserRegisterModal>
      <LogoHeader id='scroll'>
        <Logo height={32} />

        <CloseButton onClick={onBackClick}>
          <CloseCross />
        </CloseButton>
      </LogoHeader>
      <RegisterWrapper>
        <RegisterTitle />

        <TrackInfoAndFormWrapper>
          <UserRegisterForm
            emailExist={emailExist}
            setGTMEvent={setGTMEventWhenEnterPhoneNumber}
            handleResetEmailExist={handleResetEmailExist}
            onSubmit={handleSubmit}
          />
        </TrackInfoAndFormWrapper>
      </RegisterWrapper>
    </CustomModalUserRegisterModal>
  )
}
