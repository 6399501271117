import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const AlertProtectionWrapper = styled.div`
  position: absolute;
  bottom: -16px;
  left: 0;
  transform: translateY(100%);
  display: grid;
  grid-template-columns: 21px 1fr;
  gap: 17.5px;
  border-radius: 4px;
  width: 100%;
  font-size: ${variables.fontSize14};
  color: ${colors.greyTextWhiteBg};
`

export const AlertProtectionWrapperText = styled.div`
  display: grid;
  gap: 4px;
  line-height: 150%;
  > span:first-of-type {
    font-weight: ${variables.fontWeight600};
    line-height: 150%;
  }
`

export const SecurePaymentText = styled.span`
  font-size: ${variables.fontSize10};
  line-height: 15px;
  color: ${colors.greyTextWhiteBg};
`
