import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum } from 'src/Types'

interface UseUpperTitleText {
  upperTitleText: string
}

export const useUpperTitleText = (
  type?: CampaignPlatformTypeEnum | null,
): UseUpperTitleText => {
  const { t } = useTranslation()

  const getUpperTitleText = (): string => {
    if (type === CampaignPlatformTypeEnum.SPOTIFY) {
      return t('createCampaignPage.typeYourTrackNameOrInsertALinkSpotify')
    }
    if (type === CampaignPlatformTypeEnum.TIKTOK) {
      return t('createCampaignPage.typeYourTrackNameOrInsertALinkTiktok')
    }
    return ''
  }

  const upperTitleText = getUpperTitleText()

  return {
    upperTitleText,
  }
}
