import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import i18n from 'src/Localization/i18n'
import { BadgeType, Badge } from 'src/Components/Badge'

import { SoundStatus } from '../../../SoundsList'
import { MobileBlockWrapper } from '../../styles'

type SoundsListItemProps = {
  status: SoundStatus
}

export const StyledBadge = styled.div`
  display: flex;
  width: 93px;
  padding-right: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`

const getStatusValues = (
  soundStatus: SoundStatus,
): {
  title: string
  type: BadgeType
} => {
  switch (soundStatus) {
    case SoundStatus.NEW:
      return {
        title: i18n.t('soundsPage.newSound'),
        type: 'regular',
      }
    case SoundStatus.IN_PROGRESS:
      return {
        title: i18n.t('soundsPage.inProgressStatus'),
        type: 'warning',
      }
    case SoundStatus.MONITORING:
      return {
        title: i18n.t('soundsPage.monitoring'),
        type: 'disabled',
      }
    case SoundStatus.NOT_COMPLETED:
      return {
        title: i18n.t('soundsPage.notCompleted'),
        type: 'danger',
      }
    case SoundStatus.COMPLETED:
      return {
        title: i18n.t('soundsPage.completed'),
        type: 'regular',
      }
    default:
      return {
        title: 'Unknown status',
        type: 'regular',
      }
  }
}

export const SoundListItemStatus: FunctionComponent<SoundsListItemProps> = ({
  status,
}) => {
  const { title, type } = getStatusValues(status)

  return (
    <MobileBlockWrapper>
      <StyledBadge>
        <Badge type={type} title={title} />
      </StyledBadge>
    </MobileBlockWrapper>
  )
}
