import styled, { css } from 'styled-components'
import { PopupButton } from '@typeform/embed-react'

import { variables, colors, device } from 'src/Styled/variables'

interface StyledComponentsProps {
  collapse: boolean
}

export const GotItContainer = styled.div`
  margin: 0 auto;
`
export const SidebarContentWrapper = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);
  @media ${device.mobileMin} {
    overflow: hidden;
    height: calc(100vh - 60px);
  }
`

export const HintValidationPortal = styled.div`
  position: absolute;
  z-index: 1001;
  right: 0;
  top: 6%;
  border-radius: 4px;
  transform: translate(102%);
  padding: 26px 24px;
  color: ${colors.mainText};
  background: ${colors.white};
  font-size: ${variables.fontSize14};
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 320px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const SidebarAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 222px;
  padding-top: 40px;
`

export const SidebarImg = styled.img`
  border-radius: 50%;
  margin-bottom: 16px;
  min-height: 120px;
  width: 120px;
  object-fit: cover;
`

export const SidebarTitleName = styled.div`
  margin-bottom: 4px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 21px;
  color: ${colors.mainText};
  text-transform: capitalize;
`

export const SidebarTitleRole = styled.div`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  margin-bottom: 4px;
  color: ${colors.mainText};
  text-transform: capitalize;
`

export const ValidationBlock = styled.div`
  display: flex;
  align-items: center;
  z-index: 1000;
  position: relative;
`

export const ValidationText = styled.div`
  color: ${colors.messegesYellow1};
  margin-right: 8px;
`

export const ValidationInfoHint = styled.div`
  color: ${colors.messegesYellow1};
  margin-right: 8px;
`

export const SidebarLinkItem = styled.li`
  color: ${colors.greyStroke};
  height: 40px;
  transition: all 0.3s;
  cursor: pointer;
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  overflow: hidden;

  ${({ collapse }: { collapse: boolean }) =>
    collapse
      ? css`
          padding: 12px 20px;
        `
      : css`
          padding: 12px 16px;
        `};

  &:hover {
    background-color: ${colors.greyBg};
  }
`

export const StyledPopupButton = styled(PopupButton)`
  background-color: transparent;
  padding: 0;
  display: flex;
  cursor: pointer;
`

export const SidebarLinkText = styled.div<StyledComponentsProps>`
  position: relative;
  color: ${colors.greyTextWhiteBg};
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  display: flex;
  left: 10px;
  width: 160px;
  &:hover {
    color: ${colors.mainText};
  }
  opacity: ${({ collapse }) => (collapse ? '1' : '0')};
  transition: all 0.2s ease-out;
  overflow: hidden;
`

export const SidebarIconBlock = styled.div`
  margin-right: 6px;
  color: ${colors.greyTextWhiteBg};

  & a:visited {
    color: ${colors.greyTextWhiteBg};
  }

  & img {
    width: 16px;
    height: 16px;
  }
`
