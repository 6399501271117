import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const CreateCampaignHead = styled.div`
  padding-left: 0;
  padding-top: 0px;
  margin-bottom: 21px;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${colors.mainText};
  margin-top: 21px;

  @media ${device.mobileMin} {
    margin-top: 24px;
    font-size: ${variables.fontSize24};
    margin-bottom: 18px;
  }
`

export const CreateCampaignSubHead = styled.div`
  padding-left: 0;
  padding-top: 0px;
  margin-bottom: 24px;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight400};
  line-height: 27px;
  color: ${colors.mainText};
  cursor: pointer;
`

export const Text = styled.div`
  align-items: center;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
`

export const Hint = styled.div`
  align-items: center;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
  margin-bottom: 16px;
`

export const CreateCampaignContainer = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  background-color: #f3f4f7;
  height: 100vh;
`

export const CreateFirstCampaignBlock = styled.div`
  background-color: #f3f4f7;
  padding: 0px 16px;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 301;
  overflow-y: auto;

  @media ${device.mobileMin} {
    margin: 0;
    padding: 0px 40px;
    overflow-y: auto;
  }
`

export const LogoHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 150.07px 1fr;
  justify-items: self-end;
  height: 60px;

  @media ${device.mobileMin} {
    display: grid;
  }
`

export const CreateCampaignBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (min-width: 576px) {
    max-width: 1120px;
    margin: 0 auto;
  }
`

export const TextBlock = styled.div`
  height: 48px;
  padding-left: 66px;
  @media (max-width: 576px) {
    padding-left: 0;
  }
`

export const BtnReviewBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    width: 240px;
  }
`

export const PageSubHeaderWrapper = styled.div`
  margin-bottom: 0px;
`

export const PlatformIcon = styled.div`
  align-self: center;
  display: flex;
  & img {
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
`

export const Wrapper = styled.div`
  padding: 20px 24px 24px 24px;
  background-color: ${colors.white};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  column-gap: 24px;
  row-gap: 16px;

  @media (max-width: 576px) {
    row-gap: 16px;
  }
`

export const TopWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  column-gap: 8px;
`

export const BottomWrapper = styled.div`
  grid-column: span 2;

  @media ${device.mobileMin} {
    grid-column: span 1;
    grid-column-start: 2;
  }
`

export const ButtonWrapper = styled.div`
  width: 214px;
  margin: 0 auto;
  margin-bottom: 40px;
  @media ${device.mobileMin} {
    margin-bottom: 160px;
    margin-top: 64px;
  }
`

export const CreateCampaignBlockContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${device.mobileMin} {
    max-width: 800px;
  }
`
