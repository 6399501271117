import { FC } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import ReactLoading from 'react-loading'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { campaignsList, details } from 'src/Router/routes'
import { useAppSelector } from 'src/Hooks/redux'
import { colors } from 'src/Styled/variables'
import {
  getIsVideoLoading,
  getVideo,
} from 'src/Redux/campaignVideo-process/video-process/selectors'

import { RateContainer } from '../RateConteiner'
import { TiktokReviewHeader } from '../TiktokReviewHeader'
import { TikTokPlayer } from '../TiktokPlayer'
import { TiktokReviewParams } from '../..'
import { CreatorInfo } from '../CreatorInfo'
import { TiktokRateError } from '../TiktokRateError'

import {
  CloseButton,
  TiktokDetailsContainer,
  TiktokReviewDetailsContainer,
  LoaderWrapper,
  Wrapper,
} from './styles'

export const TiktokReviewDesktop: FC = () => {
  const navigate = useNavigate()
  const { campaignID } = useParams<keyof TiktokReviewParams>()
  const isLoading = useAppSelector(getIsVideoLoading)
  const video = useAppSelector(getVideo)

  const handleClose = (): void => {
    if (campaignID) {
      navigate(`${campaignsList}${details}/${campaignID}`)
    }
  }
  if (isLoading) {
    return (
      <LoaderWrapper>
        <ReactLoading
          color={colors.badgeGreen}
          width={'50px'}
          height={'50px'}
          type='spin'
        />
      </LoaderWrapper>
    )
  }
  return (
    <Wrapper>
      <TiktokDetailsContainer>
        <TiktokReviewHeader />
        <TiktokRateError />
        <RateContainer />
        <TiktokReviewDetailsContainer>
          <TikTokPlayer url={video?.videoLink || ''} />
          <CreatorInfo />
        </TiktokReviewDetailsContainer>
      </TiktokDetailsContainer>
      <CloseButton onClick={handleClose}>
        <CloseCross width='16px' height='16px' />
      </CloseButton>
    </Wrapper>
  )
}
