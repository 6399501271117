export const playlistRequirementsLink =
  'https://help.soundcamps.com/en/articles/4536944-terms-and-conditions-for-curators'

export const termsAndConditions =
  'https://help.soundcamps.com/en/articles/4536944-terms-and-conditions-for-curators'

export const landingPageUrl = 'https://soundcamps.com/'

export const feedFAQ =
  'https://help.soundcamps.com/en/articles/5355386-what-is-tracks-feed'

export const tikTokVideoPolicy =
  'https://help.soundcamps.com/en/articles/6959407-tiktok-video-policy'

export const meetConditionsLink =
  'https://help.soundcamps.com/en/articles/5541325-requirements-for-your-playlists'

export const soundLink =
  'https://soundcamps.com/spotify-pre-save/?trigger_source=sc-menu'

export const tikTokPromotionLink =
  'https://soundcamps.com/tiktok-music-promotion/'
