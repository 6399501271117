import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import { StatusBadge } from 'src/Containers/Details/components/StatusBadge'
import { VideoStatus } from 'src/Constants/enums'
import { getVideo } from 'src/Redux/campaignVideo-process/video-process/selectors'

import { TiktokDetailsHeader, TiktokDetailsTitle } from './styles'

export const TiktokReviewHeader: FC = () => {
  const { t } = useTranslation()
  const video = useAppSelector(getVideo)
  return (
    <TiktokDetailsHeader>
      <TiktokDetailsTitle>
        {t('campaignResultsPage.details')}
      </TiktokDetailsTitle>
      <StatusBadge
        videoStatus={video?.status || VideoStatus.APPROVED_AUTOMATICALLY}
      />
    </TiktokDetailsHeader>
  )
}
