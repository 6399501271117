import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

interface SearchProps {
  activeSearch?: boolean
}

export const TableHeader = styled.div`
  display: flex;
  padding: 0px 0px 24px 0px;
  justify-content: space-between;
  @media ${device.smallScreen} {
    padding: 24px 24px 20px 24px;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  width: ${(props: SearchProps) => (props.activeSearch ? '256px' : '40px')};
  @media ${device.smallScreen} {
    width: 256px;
  }
`

export const StyledInput = styled('input')`
  background: ${colors.errorBg};
  border: 1px solid ${colors.greyStroke};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0px 16px;
  height: 40px;
  width: 100%;
  outline: none;

  &::placeholder {
    color: transparent;
  }
  &:focus {
    color: ${colors.mainText};
    outline: none;
  }
  @media ${device.smallScreen} {
    &::placeholder {
      color: ${colors.greyTextWhiteBg};
    }
  }
`

export const SearchIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  background-color: ${colors.errorBg};
`

export const RoundBtn = styled.button`
  background: ${colors.white};
  border: 1px solid ${colors.mainText};
  box-sizing: border-box;
  border-radius: 24px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: grid;
  align-items: center;
  justify-content: center;
  @media ${device.mobileMax} {
    margin-right: 8px;
  }
`

export const BtnWithPlus = styled.div`
  display: flex;
  padding: 0 40px;
  gap: 8px;
  align-items: center;
  justify-items: center;
  svg {
    fill: ${colors.green};
  }
  img {
    max-width: 11px;
  }
  @media ${device.mobileMax} {
    padding: 0;
  }
`
export const ButtonWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  @media ${device.mobileMax} {
    margin-right: 8px;
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const FilterButton = styled.button<{ isFocused?: boolean }>`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93px;
  height: 40px;
  background-color: ${colors.greyBg};
  color: ${colors.greyTextWhiteBg};
  border: 1px solid ${colors.greyStroke};
  border-radius: 20px;
  gap: 9px;
  cursor: pointer;
  transition: 0.15s all linear;
  ${({ isFocused }) =>
    isFocused ? 'box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.15);' : ''}

  @media ${device.smallScreen} {
    width: 93px;
  }
  @media ${device.mobileMax} {
    margin-right: 8px;
    width: 40px;
  }
  & svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
  }
`

export const FiltersMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
`

export const FiltersLabel = styled.h5`
  font-size: ${variables.fontSize14};
  line-height: 1.5;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  margin-bottom: 16px;
`

export const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileMax} {
    flex-direction: row;
  }
`

export const ApplyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0 8px 0;
  div {
    width: 70%;
    @media ${device.mobileMax} {
      width: 50%;
    }
  }
`

export const Checkbox = styled.div`
  flex: 1;
`

export const inlineStyles = {
  dropdownMenu: {
    marginRight: 16,
  },
}
