import React, { FC } from 'react'

import { ScheduleTabs } from 'src/Components/ScheduleTabs'
import SuccessPage from 'src/Components/SuccessPage'
import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { SpotifySettings } from './components/SpotifySettings'
import { SpotifyCheckout } from './components/SpotifyCheckout'
import { useScheduleSpotifyCampaign } from './useSchaduleSpotifyCampaign'

export const ScheduleSpotify: FC = () => {
  useScheduleSpotifyCampaign()

  const campaignDetails = useAppSelector(getCampaignToSchedule)

  return (
    <>
      <SuccessPage
        startDate={campaignDetails.start_date}
        platformName={CampaignPlatformTypeEnum.SPOTIFY}
      />

      <ScheduleTabs>
        {/* first must be settings second checkout */}
        <SpotifySettings />
        <SpotifyCheckout />
      </ScheduleTabs>
    </>
  )
}
