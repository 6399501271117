import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import { NameSpace, SliceState, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'
import { RootState } from 'src/Redux'

import { createBillingAgreement } from './api-action'

const initialState: SliceState = {
  status: SliceStatus.Idle,
  error: null,
}

export const createBillingAgreementSlice = createSlice({
  initialState,
  name: NameSpace.CREATE_BILLING_AGREEMENT,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(createBillingAgreement.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(createBillingAgreement.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
      state.error = null
    })
    builder.addCase(createBillingAgreement.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})

const getState = (state: RootState): SliceState =>
  state.billingAgreement.createBillingAgreement

export const isCreateBillingAgreementLoadingSelector = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const errorCreateBillingAgreementSelector = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)
