import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { BudgetRange } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { getCampaignBudgetRange } from './api-actions'

export interface BudgetRangeState {
  budgetRange: BudgetRange | null
  error: ApiError | null
  status: SliceStatus
}

const initialState: BudgetRangeState = {
  budgetRange: null,
  error: null,
  status: SliceStatus.Idle,
}

export const budgetRangeSlice = createSlice({
  initialState,
  name: NameSpace.BudgetRange,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCampaignBudgetRange.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getCampaignBudgetRange.fulfilled, (state, { payload }) => {
      state.budgetRange = payload
      state.status = SliceStatus.Succeeded
      state.error = null
    })
    builder.addCase(getCampaignBudgetRange.rejected, (state, action) => {
      state.error = setApiError(action)
      state.status = SliceStatus.Failed
    })
  },
})
