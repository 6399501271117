import { useEffect } from 'react'

import { getFreeSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'
import { createFirstCampaignStartEvent } from 'src/Helpers/TagManager'
import { useAppDispatch } from 'src/Hooks/redux'
import { usePageViewEvent } from 'src/Hooks/Analytics/usePageViewEvent'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

export const useCreateCampaignWithoutSignupInit = (): void => {
  const dispatch = useAppDispatch()

  usePageViewEvent(pageInfo.createCampaignWithoutSignUp)

  useEffect(() => {
    createFirstCampaignStartEvent()

    void dispatch(getFreeSpotifyAccessToken())
      .unwrap()
      .catch((error: { message: string }) => {
        if (error) {
          void dispatch(getFreeSpotifyAccessToken())
        }
      })
  }, [dispatch])
}
