import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { NameSpace } from 'src/Redux/types'
import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
  SpotifyCampaign,
} from 'src/Types'

export interface SpotifyCampaignState {
  campaign: SpotifyCampaign
}

const initialState: SpotifyCampaignState = {
  campaign: {
    id: 0,
    platformType: CampaignPlatformTypeEnum.SPOTIFY,
    status: CampaignStatusEnum.SCHEDULED,
    type: CampaignTypeEnum.BASIC,
  },
}

export const spotifyCampaignSlice = createSlice({
  initialState,
  name: NameSpace.SpotifyCampaign,
  reducers: {
    updateScheduledCampaign: (
      state,
      { payload }: PayloadAction<SpotifyCampaign>,
    ) => {
      state.campaign = payload
    },
  },
})

export const { updateScheduledCampaign } = spotifyCampaignSlice.actions
