import styled from 'styled-components'

export const StyledHintSign = styled.div`
  width: 21px;
  height: 20px;
  background: #f9f9f9;
  border-radius: 50%;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  align-self: center;
`
export const StyledHintSignInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  align-self: center;

  & img {
    width: 7px;
    height: 14px;
  }
`
