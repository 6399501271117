import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

import {
  getInfluencerId,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'

export const useAnalyticsCookies = (): null => {
  const userId = useSelector(getUserId)
  const userCreatorId = useSelector(getInfluencerId)

  useEffect(() => {
    if (userId) {
      Cookies.set('sondcsrd', String(userId))
    }
    userCreatorId && Cookies.set('sondcsrd', String(userCreatorId))
  }, [userId, userCreatorId])

  return null
}
