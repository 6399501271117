import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { RootState } from '..'
import { NameSpace } from '../types'

interface AnalyticsState {
  isPriceReceived: boolean
  isScheduleStartEventSent: boolean
}

const initialState: AnalyticsState = {
  isPriceReceived: false,
  isScheduleStartEventSent: false,
}

export const analyticsProcess = createSlice({
  initialState,
  name: NameSpace.Analytics,
  reducers: {
    setPriceReceived: (state, { payload }: PayloadAction<boolean>) => {
      state.isPriceReceived = payload
    },
    setScheduleStartEventSent: (state, { payload }: PayloadAction<boolean>) => {
      state.isScheduleStartEventSent = payload
    },
  },
})

export const { setPriceReceived, setScheduleStartEventSent } =
  analyticsProcess.actions

const getState = (state: RootState): AnalyticsState => state.analytics

export const getIsPriceReceived = createDraftSafeSelector(
  getState,
  ({ isPriceReceived }) => isPriceReceived,
)

export const getScheduleStartEventSent = createDraftSafeSelector(
  getState,
  ({ isScheduleStartEventSent }) => isScheduleStartEventSent,
)
