import { forwardRef } from 'react'

import SelectType from 'react-select/dist/declarations/src/Select'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { CSSObject } from 'styled-components'

import AngryFaceGreyIcon from 'src/Assets/Svg/angry-face-grey.svg?react'
import DislikeGreyIcon from 'src/Assets/Svg/dislike-grey.svg?react'
import HeartGreyIcon from 'src/Assets/Svg/heart-grey.svg?react'
import { thankValue, offensiveValue, poorValue } from 'src/Constants/constants'

import { SelectResponseRatingProps, ResponseTextOption } from './props'

import {
  Select,
  SelectOption,
  SelectOptionIcon,
  SelectOptionText,
} from './styles'

const SelectResponseTextComponent = forwardRef<
  SelectType<ResponseTextOption>,
  SelectResponseRatingProps
>(({ onChange, value }, ref) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 576 })

  const reviewResponseTextOptions: Array<ResponseTextOption> = [
    {
      value: thankValue,
      label: (
        <SelectOption>
          <SelectOptionIcon>
            <HeartGreyIcon width={16} height={16} />
          </SelectOptionIcon>
          <SelectOptionText>
            {t('campaignResultsPage.thankYou')}
          </SelectOptionText>
        </SelectOption>
      ),
    },
    {
      value: offensiveValue,
      label: (
        <SelectOption>
          <SelectOptionIcon>
            <AngryFaceGreyIcon width={16} height={16} />
          </SelectOptionIcon>
          <SelectOptionText>
            {t('campaignResultsPage.soundsOffensive')}
          </SelectOptionText>
        </SelectOption>
      ),
    },
    {
      value: poorValue,
      label: (
        <SelectOption>
          <SelectOptionIcon>
            <DislikeGreyIcon width={16} height={16} />
          </SelectOptionIcon>
          <SelectOptionText>
            {t('campaignResultsPage.notUsefullFeedback')}
          </SelectOptionText>
        </SelectOption>
      ),
    },
  ]

  const customStyles: Record<string, (provided: CSSObject) => CSSObject> = {
    menu: (provided) => ({
      ...provided,
      width: '100vw',
    }),
  }

  return (
    <Select
      classNames={{
        dropdownIndicator: (state) =>
          state.selectProps.menuIsOpen
            ? 'custom-dropdown-indicator-open'
            : 'custom-dropdown-indicator',
      }}
      ref={ref}
      styles={isMobile ? customStyles : {}}
      fill
      isSearchable={false}
      options={reviewResponseTextOptions}
      placeholder={t('campaignResultsPage.respondToCurator')}
      classNamePrefix='review-response'
      value={reviewResponseTextOptions.find((c) => c.value === value)}
      onChange={(newValue: unknown) => {
        const val = newValue as ResponseTextOption
        onChange({ value: val.value })
      }}
    />
  )
})

SelectResponseTextComponent.displayName = 'SelectResponseTextComponent'

export default SelectResponseTextComponent
