import { FC, PropsWithChildren, useEffect } from 'react'

import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { useGrowthBook } from '@growthbook/growthbook-react'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { colors } from 'src/Styled/variables'
import { getTikTokTrackWithoutAuth } from 'src/Redux/tiktokTrack-process/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getIsLoadingTikTok } from 'src/Redux/tiktokTrack-process/selectors'
import { setTikTokTrackFromSearchList } from 'src/Redux/tiktokTrack-process'
import { viewSignUpPopUpExp } from 'src/Helpers/TagManager/AuthEvents'

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const PlatformModalWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { search, setSearch } = useCustomSearchParams()
  const dispatch = useAppDispatch()
  const growthBook = useGrowthBook()
  const searchUrl = search.get('searchUrl')
  const loading = useAppSelector(getIsLoadingTikTok)

  useEffect(() => {
    if (searchUrl) {
      void dispatch(
        getTikTokTrackWithoutAuth({
          searchText: searchUrl,
        }),
      )
        .unwrap()
        .then((payload) => {
          viewSignUpPopUpExp()
          dispatch(setTikTokTrackFromSearchList(payload))
          return payload
        })
    }
  }, [searchUrl, dispatch, setSearch, growthBook])

  if (loading && searchUrl) {
    return (
      <LoaderContainer>
        <ReactLoading height={44} color={colors.green} type='bars' />
      </LoaderContainer>
    )
  }
  return children
}
