import { FC, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getCampaigns } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import {
  getApprovedAlertsIds,
  setApprovedAlertsList,
} from 'src/Redux/alert-process'

import { ApprovedCampaignAlert } from '../../ApprovedCampaignAlert'

const APPROVED_STATUSES = ['pending', 'approved']

export const ApprovedAlerts: FC<{ activeTab: string }> = ({ activeTab }) => {
  const dispatch = useAppDispatch()
  const approvedIdsAlertArray = useAppSelector(getApprovedAlertsIds)
  const campaigns = useAppSelector(getCampaigns)

  const handleAddCloseApprovedAlertIds = useCallback(
    (alertId: number) => {
      const updatedAlertIds = [...approvedIdsAlertArray, alertId]
      dispatch(setApprovedAlertsList(updatedAlertIds))
    },
    [approvedIdsAlertArray, dispatch],
  )

  const campaign = campaigns?.find(
    ({ status, id }) =>
      APPROVED_STATUSES.includes(status) &&
      !approvedIdsAlertArray.includes(Number(id)),
  )

  if (!campaign) {
    return null
  }
  return (
    <ApprovedCampaignAlert
      campaign={campaign}
      key={campaign.id}
      handleAddCloseApprovedAlertIds={handleAddCloseApprovedAlertIds}
      activeTab={activeTab}
    />
  )
}
