import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Roles } from 'src/Constants/enums'
import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { getCurrencies } from 'src/Redux/wise-process/api-actions'
import { setRequirementsToInitialState } from 'src/Redux/wise-process'
import { useAppDispatch } from 'src/Hooks/redux'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

import { TabKeys, getTabs } from './halpers'
import { SettingsTabs } from './components/SettingsTabs'

import {
  Container,
  SettingsContainer,
  SettingsInnerContainer,
  StyledTabs,
  TabName,
  SettingsTitle,
} from './styles'

const Settings: FC = () => {
  const { page } = useParams()

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useSelector(getUserSelector)
  const isArtist = useSelector(getIsArtist)

  const [activeTab, setActiveTab] = useState<TabKeys>(
    isArtist ? TabKeys.password : TabKeys.payout,
  )

  useEffect(() => {
    user && pageViewEvent(user, pageInfo.settings)
    user && locationEvent()

    if (page === 'email') {
      setActiveTab(TabKeys.email)
    }
    if (page === 'payout') {
      setActiveTab(TabKeys.payout)
    }

    if (user?.role === Roles.curator || !isArtist) {
      void dispatch(getCurrencies())
    }

    return () => {
      dispatch(setRequirementsToInitialState())
    }
  }, [user, isArtist, page, dispatch])

  const tabItems = getTabs(Boolean(isArtist)).map((item) => ({
    label: <TabName>{t(item.title)}</TabName>,
    key: item.key,
    children: <SettingsTabs active={item.key} />,
  }))

  return (
    <SettingsContainer>
      <Container>
        <SettingsTitle>{t('settingsPage.pageTitle')}</SettingsTitle>
        <SettingsInnerContainer>
          <StyledTabs
            activeKey={activeTab}
            defaultActiveKey={isArtist ? TabKeys.password : TabKeys.payout}
            items={tabItems}
            onChange={(activeKey) => setActiveTab(activeKey as TabKeys)}
          />
        </SettingsInnerContainer>
      </Container>
    </SettingsContainer>
  )
}

export default Settings
