import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'
import { ArtistBalanceItem, TransactionTypeEnum } from 'src/Types'

import { ArtistBalanceItemResponse } from './api-actions'

export const transformBalanceDetailsResponse = (
  payload: ArtistBalanceItemResponse[],
): ArtistBalanceItem[] => {
  if (!payload) {
    return []
  }
  return payload.map((item, index) => {
    let transactionType: TransactionTypeEnum
    if (item.typeTransaction === 'refunded') {
      transactionType = TransactionTypeEnum.RECEIVED
    } else if (
      item.typeTransaction === 'payed' ||
      item.typeTransaction === 'completed'
    ) {
      transactionType = TransactionTypeEnum.PAYMENT
    } else if (item.typeTransaction === 'charged-back') {
      transactionType = TransactionTypeEnum.CHARGEBACK
    } else {
      transactionType = item.typeTransaction as TransactionTypeEnum
    }

    return {
      transactionType,
      id: `${item.campaignId || 0}${item.typeTransaction}${index}`,
      date: dayjs(item.createdAt).format(formatDate.formatDateFirst),
      createdAt: item.createdAt,
      track: {
        trackName: item.trackName,
        trackAuthorName: item.artistName,
      },
      amount: Math.abs(Number(item.amount)),
      packageName: item.packageName,
      platformType: item.platformType,
      referralData: item.referralData,
      invitedData: item.invitedData,
    }
  })
}
