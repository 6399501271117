import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { BadgeType, Badge } from 'src/Components/Badge'
import { VideoStatus } from 'src/Constants/enums'

interface StatusBadgeProps {
  videoStatus: VideoStatus
}

export const StatusBadge: FC<StatusBadgeProps> = (props) => {
  const { videoStatus } = props
  const { t } = useTranslation()

  const getVideoStatusBadge = (
    status: VideoStatus,
  ): { type: BadgeType; title: string } => {
    const notRatedTranslation = t('campaignResultsPage.notRated')

    switch (status) {
      case VideoStatus.AWAITING_REVIEW:
        return { type: 'regular', title: t('campaignResultsPage.newVideo') }
      case VideoStatus.APPROVED_AUTOMATICALLY:
        return { type: 'disabled', title: notRatedTranslation }
      case VideoStatus.DECLINED:
        return { type: 'danger', title: t('campaignResultsPage.rejected') }
      case VideoStatus.APPROVED_BY_ARTIST:
      case VideoStatus.APPROVED_BY_ADMIN:
      case VideoStatus.AWAITING_MODERATOR_REVIEW:
        return { type: 'disabled', title: t('campaignResultsPage.rated') }
      default:
        return {
          type: 'disabled',
          title: t('campaignResultsPage.unknownStatus'),
        }
    }
  }

  const { type, title } = getVideoStatusBadge(videoStatus)

  return <Badge type={type} title={title} />
}
