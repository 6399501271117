import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getAwaitingTracksSelector } from 'src/Redux/feed-process/selectors'

import { TrackListItem } from '../../TrackListItem'
import { ReviewMobileProps, SkipMobileProps } from '../props'
import { ListLimitMessage } from '../styles'
import { EmptyTrackList } from '../../EmptyTrackList'

interface Props {
  openReviewModal: (props: ReviewMobileProps) => void
  openSkipModal: (props: SkipMobileProps) => void
}

export const AwaitingCampaignsList: FC<Props> = (props) => {
  const awaitingCampaigns = useSelector(getAwaitingTracksSelector)
  const { openReviewModal, openSkipModal } = props
  const { t } = useTranslation()
  if (awaitingCampaigns.length) {
    return (
      <>
        {awaitingCampaigns.map((el) => {
          if (el.id) {
            return (
              <TrackListItem
                key={el.id}
                track={el}
                openReviewModal={openReviewModal}
                openSkipModal={openSkipModal}
              />
            )
          }
          return null
        })}
        <ListLimitMessage>{t('reviewsPage.yourListOfTracks')}</ListLimitMessage>
      </>
    )
  }
  return <EmptyTrackList>{t('reviewsPage.emptyAwaitingList')}</EmptyTrackList>
}
