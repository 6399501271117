import React, { FC } from 'react'

import TimeAgo from 'react-timeago'
import { useTranslation } from 'react-i18next'

import { NotificationType, UserNotification } from 'src/Types/index'

import { Accent, StyledDate, Message, MessageHeader, Name } from './styles'

export const OtherNotification: FC<UserNotification> = (props) => {
  const {
    type_id: type,
    extra_score,
    artist_name,
    track_name,
    created_at,
    playlist_name,
  } = props

  const { t } = useTranslation()

  const youReceivedText = t('notification.youReceived')
  const extraPointsText = t('notification.extraPoints')

  switch (type) {
    case NotificationType.STAY_IN_PLAYLIST:
      return (
        <Message>
          <MessageHeader>
            {youReceivedText}&nbsp;
            {extra_score > 0 ? (
              <Accent>+{extra_score}</Accent>
            ) : (
              <Accent>0</Accent>
            )}
            &nbsp;{extraPointsText}
            &nbsp;<Accent>{playlist_name}</Accent>
          </MessageHeader>
          <Name>{track_name} </Name>
          {t('notification.stayedInPlaylist')}
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </Message>
      )
    case NotificationType.STAY_ON_TOP:
      return (
        <Message>
          <MessageHeader>
            {youReceivedText}&nbsp;
            {extra_score > 0 ? (
              <Accent>+{extra_score}</Accent>
            ) : (
              <Accent>0</Accent>
            )}
            &nbsp;{extraPointsText}
            &nbsp;<Accent>{playlist_name}</Accent>
          </MessageHeader>
          <Name>{track_name} </Name>
          {t('notification.stayedOnTheTop')}
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </Message>
      )
    case NotificationType.POSITIVE_REVIEW:
      return (
        <Message>
          <MessageHeader>
            {youReceivedText}&nbsp;
            {extra_score > 0 ? (
              <Accent>+{extra_score}</Accent>
            ) : (
              <Accent>0</Accent>
            )}
            &nbsp;{extraPointsText}
            &nbsp;<Accent>{playlist_name}</Accent>
          </MessageHeader>
          <Name>{artist_name} </Name>
          {t('notification.positiveReview')}
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </Message>
      )
    case NotificationType.NEGATIVE_REVIEW:
      return (
        <Message>
          <MessageHeader>
            {t('notification.youLost')}&nbsp;
            <span style={{ color: 'rgb(255, 72, 97' }}>{extra_score}</span>
            &nbsp;{extraPointsText}
            &nbsp;<Accent>{playlist_name}</Accent>
          </MessageHeader>
          <Name>{artist_name} </Name>
          {t('notification.negativeReview')}
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </Message>
      )
    case NotificationType.SKIP_REVIEW:
      return (
        <Message>
          <MessageHeader>
            {youReceivedText}&nbsp;
            <Accent>+{extra_score}</Accent>
            &nbsp;{extraPointsText}
            &nbsp;<Accent>{playlist_name}</Accent>
          </MessageHeader>
          {t('notification.skipReview')}
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </Message>
      )
    default:
      return null
  }
}
