import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import TimeAgo from 'react-timeago'

import avatar from 'src/Assets/Png/ava.png'
import { SmartImage } from 'src/Components/SmartImage'

import {
  Wrapper,
  ImageWrapper,
  Message,
  HeaderMessage,
  TextName,
  DateWrapper,
} from './styles'

interface ArtistNotificationProps {
  photo: string
  playlistUrl: string
  playlistName: string
  firstName: string
  lastName: string
  date: string | Date
}
const SLICE_COUNT = -22
export const ArtistNotification: FC<ArtistNotificationProps> = (props) => {
  const { photo, playlistUrl, playlistName, firstName, lastName, date } = props
  const { t } = useTranslation()
  return (
    <Wrapper>
      <ImageWrapper>
        <SmartImage src={photo} alt='avatar' fallbackSrc={avatar} />
      </ImageWrapper>
      <Message>
        <HeaderMessage>
          {t('notification.trackAdded')}&nbsp;
          <a
            href={`https://open.spotify.com/playlist/${playlistUrl.slice(
              SLICE_COUNT,
            )}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            &nbsp;{playlistName}&nbsp;
          </a>
          <TextName>
            <span>{t('notification.by')} </span>
            <span>
              {firstName} {lastName}
            </span>
          </TextName>
          <DateWrapper>
            <TimeAgo date={date} />
          </DateWrapper>
        </HeaderMessage>
      </Message>
    </Wrapper>
  )
}
