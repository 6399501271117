import React, { ReactElement } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'
import { colors, variables } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { CrossButton } from 'src/Components/Buttons/CrossButton'
import { sounds } from 'src/Router/routes'

import { TabKeysEnum } from '../../types'

import { DateInfo } from './components/DateInfo'

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.white};
  padding: 124px 16px 40px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 560px;
  max-width: 560px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  text-align: center;
`
export const GoToSoundsButton = styled(Button)`
  width: 180px;
`
export const CloseCrossButton = styled(CrossButton)`
  position: absolute;
  top: 76px;
  right: 16px;
`

export const SuccessAcceptModal = (): ReactElement | null => {
  const { t } = useTranslation()
  const { search } = useCustomSearchParams()
  const navigate = useNavigate()
  const deadlineDate = search.get('deadline')
  const eventId = search.get('eventId')

  const submitVideoButtonClick = (): void => {
    if (eventId) {
      navigate(`${sounds}/${TabKeysEnum.IN_PROGRESS}/${eventId}`)
    } else {
      navigate(`${sounds}/${TabKeysEnum.IN_PROGRESS}`)
    }
  }

  const closeClick = (): void => {
    navigate(`${sounds}/${TabKeysEnum.IN_PROGRESS}`)
  }

  return (
    <Container>
      <ContentWrapper>
        <IconSuccessfully width={40} height={40} />

        <DescriptionWrapper>
          <Title>{t('soundsPage.soundAccepted')}</Title>
          <span>
            <span>{t('soundsPage.submitUpTo')}</span>
            <DateInfo date={deadlineDate} />
          </span>
        </DescriptionWrapper>

        <GoToSoundsButton type={'green'} onClick={submitVideoButtonClick}>
          {t('soundsPage.submitVideoButton')}
        </GoToSoundsButton>
      </ContentWrapper>

      <CloseCrossButton onClick={closeClick} />
    </Container>
  )
}
