import { handleTakeVideoId } from 'src/Helpers/handleTakeVideoId'
import {
  artistAcceptedVideo,
  artistRejectedVideo,
  artistReviewedVideo,
} from 'src/Helpers/TagManager'

import { RootState } from '../..'
import { getUserId } from '../../auth-process/userSlice/selectors'
import { getVideo } from '../video-process/selectors'

import { IResult } from '.'

interface IParams {
  state: RootState
  data: IResult
  videoID: number
  feedback: string
}
const MIN_RATE = 3
export const handleRateVideoEvents = (params: IParams): void => {
  const { state, data, videoID, feedback } = params
  const { rateVideo, trackId, campaignId, paidAmount } = data
  const userID = getUserId(state)
  const tiktokEventItem = getVideo(state)
  const videoId = handleTakeVideoId(tiktokEventItem?.videoLink || '')
  if (userID) {
    artistReviewedVideo({
      userID,
      campaignId,
      trackId,
      videoId,
      reviewId: videoID,
      rate: rateVideo,
      amountPaid: paidAmount,
    })
  }
  if (userID && rateVideo >= MIN_RATE) {
    artistAcceptedVideo({
      userID,
      videoId,
      trackId,
      campaignId,
      reviewId: videoID,
      rate: rateVideo,
      amountPaid: paidAmount,
    })
  } else {
    userID &&
      artistRejectedVideo({
        userID,
        campaignId,
        trackId,
        videoId,
        rate: rateVideo,
        rejectionReason: feedback,
        reviewId: videoID,
        amountPaid: paidAmount,
      })
  }
}
