import React, { useEffect, useState } from 'react'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import logo from 'src/Assets/Png/logo.png'
import { InfluencerRegisterEnum } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

import Status from './components/Status'
import { validateRegisterStatus } from './helpers/validateRegisterStatus'
import { LoginCreator } from './components/LoginCreator/LoginCreator'
import { LoginCreatorCompleted } from './components/LoginCreatorCompleted/LoginCreatorCompleted'
import { LoginCreatorConnected } from './components/LoginCreatorConnected/LoginCreatorConnected'
import { LoginCreatorFrom } from './components/LoginCreatorForm/LoginCreatorFrom'
import { LoginCreatorError } from './components/LoginCreatorError/LoginCreatorError'
import { LoginCreatorConnect } from './components/LoginCreatorConnect/LoginCreatorConnect'

import { Header, LoginFieldsWrapper, Logo, MainWrapper } from './styles'

export const translateInfluencer =
  'influencer.checkIfYouSelectedCorrectTikTokAccountAndPressContinue'

const Registration: React.FC = () => {
  const { search } = useCustomSearchParams()
  const status = search.get('status') || ''

  const [registerState, setRegisterState] = useState<InfluencerRegisterEnum>(
    validateRegisterStatus(status),
  )

  useEffect(() => {
    locationEvent()
    pageViewEvent(null, pageInfo.signupCreator)
  }, [])

  return (
    <MainWrapper>
      <Header>
        <Logo>
          <img alt='logo' src={logo} />
        </Logo>
      </Header>

      <LoginFieldsWrapper>
        <LoginCreator
          status={registerState}
          setRegisterState={setRegisterState}
        />
        <Status status={registerState} />
        <LoginCreatorConnect status={registerState} />
        <LoginCreatorError status={registerState} />
        <LoginCreatorConnected
          status={registerState}
          setRegisterState={setRegisterState}
        />
        <LoginCreatorFrom
          status={registerState}
          setRegisterState={setRegisterState}
        />
        <LoginCreatorCompleted status={registerState} />
      </LoginFieldsWrapper>
    </MainWrapper>
  )
}

export default Registration
