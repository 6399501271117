import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { getValueOrZero } from 'src/Constants/functions'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'

import {
  MobileBlockWrapper,
  NormalText,
  SmallText,
  ValuesContainer,
} from '../../styles'

type ViewsInfoProps = {
  currentViews: number
}

export const ViewsInfo: FunctionComponent<ViewsInfoProps> = ({
  currentViews,
}) => {
  const { t } = useTranslation()

  const { data: monetizationData } = useGetMonetizationDataQuery()

  const formattedMinViews = addSpaceToNumber(
    getValueOrZero(monetizationData?.minViews),
    ',',
  )

  const formattedCurrentViews = addSpaceToNumber(
    getValueOrZero(currentViews),
    ',',
  )

  const formattedViews = `${formattedMinViews} / ${formattedCurrentViews}`

  return (
    <MobileBlockWrapper>
      <ValuesContainer>
        <SmallText>{t('soundsPage.views')}</SmallText>
        <NormalText>{formattedViews}</NormalText>
      </ValuesContainer>
    </MobileBlockWrapper>
  )
}
