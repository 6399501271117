import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const Logo = styled.div`
  width: 250px;
  margin: 0 auto;
  padding: 20px;
`
export const Accent = styled.span`
  color: #1db954;
`
export const BoldText = styled.span`
  font-weight: 600;
`
export const Head = styled.div`
  margin-bottom: 20px;
  text-align: center;

  h3 {
    color: black !important;
    font-weight: 600;
    font-size: 24px;
  }
`

export const Card = styled.div`
  background-color: ${colors.white};
  margin: auto;
  padding: 35px 30px;
  max-width: 520px;
  width: 100%;
  border-radius: 5px;
  @media ${device.mobileMin} {
    padding: 50px 60px;
  }
`
export const Container = styled.div`
  border-radius: 5px;
  margin: auto;
  padding: 10px;
  width: 90%;
`
export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background-color: black;
  position: absolute;
`
export const Condition = styled.p`
  font-size: 12px;
  margin-bottom: 20px !important;
  text-align: center;
`

export const TextCenter = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  justify-content: space-around;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: black !important;

    .spotify-color {
      color: #1db954;
    }
  }
`
export const SpotifyLink = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: ${colors.green};
  color: ${colors.white};
  align-items: center;
  width: 100%;
  margin-right: 0;
  border-radius: 50px;
  margin-bottom: 10px;
  padding: 9px 39px;
  text-decoration: none;
  &:hover {
    color: ${colors.white};
  }

  .mdi-icon {
    height: 20px;
    width: 20px;
    fill: ${colors.white};
    margin: 0;
  }
`
