import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { ArtistCommentProps } from './types'

import {
  ExpectationsFromArtist,
  ExpectationsTitle,
  ExpectationsText,
} from './styles'

export const ArtistComment: FunctionComponent<ArtistCommentProps> = ({
  comment,
}) => {
  const { t } = useTranslation()

  if (!comment) {
    return null
  }

  return (
    <ExpectationsFromArtist>
      <ExpectationsTitle>{t('influencer.artistComment')}</ExpectationsTitle>
      <ExpectationsText>{comment}</ExpectationsText>
    </ExpectationsFromArtist>
  )
}
