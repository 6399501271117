import { FC, useMemo } from 'react'

import { colors } from 'src/Styled/variables'
import { numberFormatCommas } from 'src/Constants/functions'

import { MainValue, SubValue, ValueTooltip } from './styles'

interface IProgressTooltip {
  price?: number
  views: number
  hasLeftRadius?: boolean
  hasRightRadius?: boolean
  position: number
  completed?: boolean
}

export const ProgressTooltip: FC<IProgressTooltip> = ({
  price,
  views,
  hasLeftRadius,
  hasRightRadius,
  position,
  completed,
}) => {
  const valuePrice = `$${price || 0}`
  const valueViews = `${numberFormatCommas(views)} views`

  const tooltipBackgroundColor = useMemo(() => {
    if (completed) {
      return colors.greyStroke
    }
    if (hasLeftRadius) {
      return colors.greyStroke
    }
    return colors.progressActive
  }, [completed, hasLeftRadius])

  const valueColor = useMemo(() => {
    if (completed || hasLeftRadius) {
      return colors.mainText
    }
    return colors.white
  }, [completed, hasLeftRadius])
  return (
    <ValueTooltip
      left={hasLeftRadius}
      right={hasRightRadius}
      color={tooltipBackgroundColor}
      position={position}
    >
      <MainValue color={valueColor}>{valuePrice}</MainValue>
      <SubValue color={valueColor}>{valueViews}</SubValue>
    </ValueTooltip>
  )
}
