import React, { FunctionComponent } from 'react'

import { useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'

import { getSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getTracks } from 'src/Redux/spotifyTracks-process/selectors'
import { getTikTokTracksSelector } from 'src/Redux/tiktokTrack-process/selectors'

import { useTrackSearch } from '../../helpers/useTrackSearch'
import CheckTrackFromSearch from '../../../CheckTrackFromSearch'

import { WrapperRelative } from './styles'

export const DropdownResult: FunctionComponent = () => {
  const { debouncedOnCheckTrack, setTracksToInitialStateHandler } =
    useTrackSearch()

  const tikTokTracks = useSelector(getTikTokTracksSelector)
  const spotifyTracks = useSelector(getTracks)
  const platform = useSelector(getSchedulePlatform)

  const isTracksAvailable = (spotifyTracks || tikTokTracks) && platform

  const currentTracks =
    platform === CampaignPlatformTypeEnum.SPOTIFY ? spotifyTracks : tikTokTracks

  if (!isTracksAvailable) {
    return null
  }

  return (
    <OutsideClickHandler onOutsideClick={setTracksToInitialStateHandler}>
      <WrapperRelative
        isAbsolutePosition={Boolean(
          platform === CampaignPlatformTypeEnum.SPOTIFY,
        )}
      >
        <CheckTrackFromSearch
          onCheckTrack={debouncedOnCheckTrack}
          tracks={currentTracks}
          platform={platform}
        />
      </WrapperRelative>
    </OutsideClickHandler>
  )
}
