import styled from 'styled-components'

import { CustomScrollbarWrapper } from 'src/Components/CustomScrollbarWrapper'
import { device } from 'src/Styled/variables'

export const CollapseWrapper = styled.div`
  width: 290px;

  @media (min-width: 320px) {
    width: 295px;
  }

  @media (min-width: 360px) {
    width: 338px;
  }

  @media (min-width: 375px) {
    width: 353px;
  }

  @media (min-width: 411px) {
    width: 371px;
  }

  @media (min-width: 414px) {
    width: 374px;
  }

  @media (min-width: 640px) {
    width: 552px;
  }
`
export const ImageCurator = styled.div`
  max-width: 42px;
  padding-top: 3px;
  margin-right: 15px;
  flex: 1 1;

  img {
    border-radius: 50%;
  }
`
export const CollapseItemCurator = styled.div`
  padding: 9px 9px 16px 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;

  @media screen and (min-width: 960px) {
    padding: 9px 9px 3px 16px;
  }
`
export const NotificationsWrapper = styled(CustomScrollbarWrapper)<{
  isSmallNotificationsContainer: boolean
}>`
  height: ${({ isSmallNotificationsContainer }) =>
    isSmallNotificationsContainer ? 'fit-content' : '280px'}
  }
  @media ${device.mobileMax} {
    height: 50vh;
  }
`
