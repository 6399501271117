import { useEffect } from 'react'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'

export const useSoundsPageAnalytics = (): void => {
  const user = useAppSelector(getUserSelector)

  useEffect(() => {
    if (user) {
      locationEvent()
      pageViewEvent(user, pageInfo.creator)
    }
  }, [user])
}
