import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { CampaignPlatformTypeEnum } from 'src/Types/index'
import { chargeBillingAgreement } from 'src/Redux/paypalTransactions-process/api-actions'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import {
  getArtistBalance,
  getCountryName,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { setPaymentAlertError } from 'src/Redux/payment-process'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'
import { setIsProcessingPage } from 'src/Redux/paypalTransactions-process'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getDefaultStartDate } from 'src/Constants/functions'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { getBillingAgreementEmailSelector } from 'src/Redux/billingAgreement-process/get-billingAgrreement'

import { useAppDispatch, useAppSelector } from './redux'
import { usePayment } from './usePayment'
import { useCustomSearchParams } from './useCustomSearchParams'

interface UsePayPalTransactionPayment {
  pay: () => void
}

export const usePayPalTransactionPayment = (
  platformType: CampaignPlatformTypeEnum,
): UsePayPalTransactionPayment => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()

  const autopayField = Boolean(search.get('autopay'))

  const navigate = useNavigate()
  const params = useParams()
  const { calculatePaymentAmounts } = usePayment()

  const campaignId = Number(params.number)

  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const scheduledSpotifyCampaign = useAppSelector(getCampaignToSchedule)
  const country = useAppSelector(getCountryName)
  const coupon = useAppSelector(getCoupon)
  const balance = useAppSelector(getArtistBalance)
  const payPalTransactionEmail = useSelector(getBillingAgreementEmailSelector)
  const tikTokPrice = useSelector(getTiktokPrice)

  const isTikTokPlatform = platformType === CampaignPlatformTypeEnum.TIKTOK

  const currentPrice = isTikTokPlatform
    ? tikTokPrice
    : scheduledSpotifyCampaign?.price

  const defaultStartDate = getDefaultStartDate()

  const currentStartDate = isTikTokPlatform
    ? scheduledTikTokCampaign?.startDate?.toString() || defaultStartDate
    : scheduledSpotifyCampaign?.start_date?.toString() || defaultStartDate

  const currentPlatformCampaign = isTikTokPlatform
    ? scheduledTikTokCampaign
    : scheduledSpotifyCampaign

  const platformUrlPart = isTikTokPlatform ? 'scheduleTikTok' : 'schedule'

  const checkoutStepUrl = `/campaigns/${platformUrlPart}/${campaignId}?step=${
    ScheduleSteps.CHECKOUT
  }&paymentStatus=success&startDate=${encodeURIComponent(
    currentStartDate,
  )}&autopay=${autopayField}`

  const pay = useCallback((): void => {
    const currentPaymentAmounts = calculatePaymentAmounts({
      price: currentPrice,
      balance,
      coupon,
      country,
    })

    const genresIds = currentPlatformCampaign.genres.map((genre) =>
      isTikTokPlatform ? Number(genre.value) : Number(genre.id),
    )

    const languagesIds = currentPlatformCampaign.languages.map((language) =>
      isTikTokPlatform ? Number(language.value) : Number(language.id),
    )

    if (!currentPaymentAmounts || !payPalTransactionEmail) {
      return
    }

    const campaignBody = {
      amount: currentPaymentAmounts?.total,
      campaigns: [
        {
          type: platformType,
          genres: genresIds,
          languages: languagesIds,
          amount: currentPrice,
          startDate: currentStartDate,
          campaignId: Number(currentPlatformCampaign?.id),
        },
      ],
      country: country || '',
      couponValue: coupon?.value,
      usedBalance: currentPaymentAmounts.balanceUseAmount,
      paypalEmail: payPalTransactionEmail,
    }

    const onError = (error: Error): void => {
      if (error.message.includes('timeout')) {
        dispatch(setIsProcessingPage(true))
      } else {
        dispatch(setPaymentAlertError(PaymentErrors.DEFAULT))
      }
    }

    const onSuccess = (): void => {
      navigate(checkoutStepUrl)
    }

    void dispatch(chargeBillingAgreement(campaignBody))
      .unwrap()
      .then(onSuccess)
      .catch(onError)
  }, [
    calculatePaymentAmounts,
    currentPlatformCampaign.genres,
    currentPlatformCampaign.languages,
    currentPlatformCampaign?.id,
    currentPrice,
    balance,
    coupon,
    country,
    payPalTransactionEmail,
    platformType,
    currentStartDate,
    dispatch,
    isTikTokPlatform,
    navigate,
    checkoutStepUrl,
  ])

  return {
    pay,
  }
}
