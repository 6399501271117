import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const PlatformCardContainer = styled.div`
  background-color: ${colors.white};
  padding: 20px 25.5px 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  @media ${device.mobileMin} {
    padding: 20px 24px;
    margin-bottom: 8px;
  }
`
