import styled from 'styled-components'
import SelectField from 'react-select'

import { colors } from 'src/Styled/variables'

import { RatingOption } from './props'

export const Select = styled(SelectField<RatingOption>)<{ fill?: boolean }>`
  cursor: pointer;
  ${({ fill }) => (fill ? 'flex: 1;' : 'width: 144px;')}

  @media (max-width: 576px) {
    margin-right: 0;
    margin-bottom: 8px;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    .review-rating__menu {
      border-radius: 0;
      position: fixed;
      z-index: 1000;
      left: 0;
      top: calc(100% - 250px);
    }
  }

  .review-rating__control {
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid ${colors.greyStroke} !important;
    border-radius: 4px;
    cursor: pointer;
  }
  .review-rating__value-container {
    @media (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }
  .review-rating__single-value {
    display: flex;
    width: 100%;
    padding: 0 10px;

    @media (max-width: 576px) {
      width: 96px;
      padding: 0;
    }
  }
  .review-rating__input {
    display: flex;
    align-items: center;
  }
  .review-rating__dropdown-indicator {
    display: none;
  }
  .review-rating__indicator-separator {
    display: none;
  }
  .review-rating__control--is-focused {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) inset;
    border: 1px solid ${colors.greyTextBlackBg} !important;
  }
  .review-rating__menu {
    height: 250px;
    overflow: hidden;
  }
  .review-rating__menu-list {
    padding: 0 13px;
    border: none;
    padding-bottom: 14px;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }
  .review-rating__option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 13px 0;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.greyBlue};
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;

    &:last-child {
      border-bottom: none;
    }
  }
`

export const SelectRatingOption = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 576px) {
    width: 96px;
    justify-content: flex-start;
  }
`

export const StyledPlaceholder = styled.div`
  color: ${colors.mainText};
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 16px 1fr;
  column-gap: 4px;
  & img {
    display: grid;
    align-self: center;
  }
  > span {
    display: grid;
    align-self: center;
  }
`
export const StarWrapper = styled.div`
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
