import { combineReducers } from '@reduxjs/toolkit'

import { curatorBalanceHistorySlice } from './curatorBalanceHistorySlice'
import { orderPayoutSlice } from './orderPayoutSlice'

import { transactionsProcess } from '.'

export const transactions = combineReducers({
  transactions: transactionsProcess.reducer,
  curatorBalanceList: curatorBalanceHistorySlice.reducer,
  orderPayout: orderPayoutSlice.reducer,
})
