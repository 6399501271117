import { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { CloseIconGrey, SoundCampaignLogo } from 'src/Assets/Svg'
import { useCreateCampaignLayout } from 'src/Components/CreateCampaignLayout/useCreateCampaignLayout'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'

import { PlatformModalHeader, CloseButtonInPlatform } from './styles'

interface PlatformHeaderProps {
  isVariantB?: boolean
}

export const PlatformHeader: FC<PlatformHeaderProps> = ({ isVariantB }) => {
  const { totalCloseHandler } = useCreateCampaignLayout()
  const { search } = useCustomSearchParams()
  const searchUrl = search.get('searchUrl')
  const navigate = useNavigate()

  const handleClick = (): void => {
    if (searchUrl) {
      navigate(NAVIGATE_BACK_INDEX)
      return
    }

    totalCloseHandler(isVariantB)
  }
  return (
    <PlatformModalHeader>
      <SoundCampaignLogo />
      <CloseButtonInPlatform onClick={handleClick}>
        <CloseIconGrey />
      </CloseButtonInPlatform>
    </PlatformModalHeader>
  )
}
