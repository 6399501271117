import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'src/Helpers/dayjs'
import { TiktokCampaignStatus } from 'src/Types/index'
import { getTiktokCampaignDetails } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { formatDate } from 'src/Constants/formatDate'

import { DateInfo, TikTokDetailsCampaignStatus } from '../styles'

export const DetailsStatus: FC = () => {
  const { t } = useTranslation()
  const campaignDetails = useSelector(getTiktokCampaignDetails)

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const startDateFormatted = dayjs(campaignDetails?.startDate)
    .tz(timezone)
    .format(formatDate.formatDateMonthFull)
  const endDateFormatted = dayjs(campaignDetails?.endDate)
    .tz(timezone)
    .format(formatDate.formatDateMonthFull)

  const dateRange = `${startDateFormatted} - ${endDateFormatted}`

  const status =
    campaignDetails?.status === TiktokCampaignStatus.STOPPED ||
    campaignDetails?.status === TiktokCampaignStatus.STOPPED_BY_ADMIN ||
    campaignDetails?.status === TiktokCampaignStatus.CANCELED_BY_ADMIN
      ? t('campaignsPage.stopped')
      : t('campaignsPage.ended')

  return (
    <TikTokDetailsCampaignStatus>
      <TikTokDetailsCampaignStatus>{status}</TikTokDetailsCampaignStatus>

      <DateInfo>{dateRange}</DateInfo>
    </TikTokDetailsCampaignStatus>
  )
}
