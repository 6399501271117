import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { WhatArtistsThink } from 'src/Components/WhatArtistsThink'

import { ButtonWrapper } from './styles'

interface InfoForNotAuthUserProps {
  isVariantB?: boolean
}

export const InfoForNotAuthUser: FC<InfoForNotAuthUserProps> = ({
  isVariantB,
}) => {
  const { t } = useTranslation()

  const scrollToScrollElement = (): void => {
    const scrollElement = document.getElementById('scroll')

    if (scrollElement) {
      scrollElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }

  return (
    <>
      <WhatArtistsThink />
      {!isVariantB && (
        <ButtonWrapper>
          <Button type='green' onClick={scrollToScrollElement}>
            {t('campaignsPage.createNewCampaignBtn')}
          </Button>
        </ButtonWrapper>
      )}
    </>
  )
}

InfoForNotAuthUser.displayName = 'InfoForNotAuthUser'
