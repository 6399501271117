import styled, { createGlobalStyle, css } from 'styled-components'

import { device, colors, variables } from 'src/Styled/variables'

interface Props {
  isErrorOnMobile?: boolean
  isSame?: boolean
  show?: boolean
  isVariantB?: boolean
  isFAQ?: boolean
}

export const StyledTrackSearch = styled.div<Props>`
  background: ${colors.white};
  border-radius: 4px;
  padding: 0px;
  padding-bottom: ${(props: Props) => props.isSame && '72px'};
  @media ${device.mobileMin} {
    padding: 24px;
  }
  ${({ show, isVariantB, isFAQ }) => {
    if (show) {
      return css`
        @media ${device.mobileMax} {
          position: fixed;
          z-index: 1;
          overflow-y: auto;
          top: 0;
          left: 0;
          width: 100%;
          bottom: 0;
          right: 0;
        }
      `
    }
    return css`
      @media ${device.mobileMax} {
        padding: ${isVariantB || isFAQ ? '24px' : '0px'};
      }
    `
  }}
`

export const TrackSearchHeader = styled.div<{ show?: boolean }>`
  ${({ show }) =>
    !show
      ? css`
          display: grid;
          grid-template-columns: 1fr 16px;
        `
      : css`
          display: none;
        `}
  font-size: ${variables.fontSize14};

  @media ${device.mobileMin} {
    display: grid;
    grid-template-columns: 1fr 16px;
    align-items: center;
    font-weight: ${variables.fontWeight400};
    line-height: 150%;
    color: ${colors.mainText};
    margin-bottom: 8px;
    font-size: ${variables.fontSize14};
  }
`

export const SearchForm = styled.form<Props>`
  ${({ show }) => {
    if (show) {
      return css`
        -webkit-text-size-adjust: none;
        height: ${(props: Props) => (props.isErrorOnMobile ? 'auto' : '100vh')};
        position: relative;
        @media ${device.mobileMin} {
          height: auto;
        }
      `
    }
    return css``
  }}
`

export const BtnWrapper = styled.div<Props>`
  display: grid;
  justify-content: center;
  width: 40px;
  height: 40px;
  align-self: center;
  align-content: center;
  @media ${device.mobileMin} {
    display: none;
  }
`

export const BackButton = styled.img`
  cursor: pointer;
  width: 12px;
  height: 10px;
  &img {
    align-self: center;
  }
`

export const InputWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-column-gap: 8px;
  height: 58px;
  border-bottom: 1px solid #f3f4f7;
  padding: 8px;
  background-color: green;
  @media ${device.mobileMin} {
    grid-template-columns: none;
    border-bottom: none;
    padding: 0px;
    height: auto;
  }
`

export const GlobalStyled = createGlobalStyle<Props>`
  .ant-input {
    border: none !important;

    ${(props) =>
      !props.isFAQ &&
      css`
        border: 1px solid ${colors.greyStroke} !important;
      `}

    @media ${device.mobileMin} {
      border: 1px solid ${colors.greyStroke} !important;
    }
  }
`
