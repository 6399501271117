import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { getTiktokCampaignDetails } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { formattedNumber, getValueOrZero } from 'src/Constants/functions'
import { TiktokCampaignStatus } from 'src/Types/index'

import { DetailsProgress } from './DetailsProgress'
import { DetailsStatus } from './DetailsStatus/DetailsStatus'

import {
  StyledDetailsCard,
  StyledIcon,
  ImgWrapper,
  IconWrapper,
  NamesWrapper,
  Name,
  Artist,
  BudgetWrapper,
  Row,
  Key,
  Value,
} from './styles'

const ENDED_CAMPAIGNS_STASUSES = [
  TiktokCampaignStatus.ENDED,
  TiktokCampaignStatus.FINISHED,
  TiktokCampaignStatus.STOPPED,
  TiktokCampaignStatus.STOPPED_BY_ADMIN,
  TiktokCampaignStatus.CANCELED_BY_ADMIN,
]
const DetailsCard: FC = () => {
  const campaignDetails = useSelector(getTiktokCampaignDetails)
  const { t } = useTranslation()

  const isEnded =
    campaignDetails?.status &&
    ENDED_CAMPAIGNS_STASUSES.includes(campaignDetails?.status || '')

  const spent =
    getValueOrZero(campaignDetails?.amount) -
    getValueOrZero(campaignDetails?.currentAmount) -
    getValueOrZero(campaignDetails?.totalRefunded)

  return (
    <StyledDetailsCard>
      <StyledIcon>
        <ImgWrapper>
          <SmartImage
            alt='trackCoverUrl'
            src={campaignDetails?.track?.coverUrl}
            fallbackSrc={track}
          />
        </ImgWrapper>
        <IconWrapper>
          <TikTokIcon />
        </IconWrapper>
      </StyledIcon>

      <NamesWrapper>
        <Name>{campaignDetails?.track?.title}</Name>
        <Artist>{campaignDetails?.track?.authorName}</Artist>
      </NamesWrapper>

      <BudgetWrapper>
        <Row>
          <Key>{t('campaignResultsPage.budgetLabel')}:</Key>
          <Value>{formattedNumber(campaignDetails?.amount || 0)}</Value>
        </Row>

        <Row>
          <Key>{t('campaignResultsPage.spent')}:</Key>
          <Value>{formattedNumber(spent || 0)}</Value>
        </Row>
        {isEnded && (
          <Row>
            <Key>{t('campaignResultsPage.creditsLabel')}:</Key>

            <Value refund>
              {formattedNumber(campaignDetails?.totalRefunded || 0)}
            </Value>
          </Row>
        )}
      </BudgetWrapper>
      {isEnded ? <DetailsStatus /> : <DetailsProgress />}
    </StyledDetailsCard>
  )
}

export default DetailsCard
