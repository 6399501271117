import React, { FC, ReactNode, useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'

import { getSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { setSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice'
import { useAppDispatch } from 'src/Hooks/redux'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

interface StepsWrapperProps {
  children: [ReactNode, ReactNode, ReactNode]
}
export enum CreateCampaignSteps {
  PLATFORM_SELECT = 'platformSelect',
  PLATFORM_SEARCH = 'platformSearch',
  REGISTER = 'register',
}

interface Step {
  id: number
  query: { step?: CreateCampaignSteps }
}

const STEPS: Step[] = [
  {
    id: 1,
    query: { step: CreateCampaignSteps.PLATFORM_SELECT },
  },
  {
    id: 2,
    query: { step: CreateCampaignSteps.PLATFORM_SEARCH },
  },
  {
    id: 3,
    query: { step: CreateCampaignSteps.REGISTER },
  },
]
export const StepsWrapper: FC<StepsWrapperProps> = ({ children }) => {
  const { search, setSearch } = useCustomSearchParams()
  const step = search.get('step')
  const platform = search.get('platform') as CampaignPlatformTypeEnum
  const status = search.get('status')

  const dispatch = useAppDispatch()

  const searchPlatform = useSelector(getSchedulePlatform)

  const MAP_STEPS = useMemo(
    () => ({
      [CreateCampaignSteps.PLATFORM_SELECT]: children[0],
      [CreateCampaignSteps.PLATFORM_SEARCH]: children[1],
      [CreateCampaignSteps.REGISTER]: children[2],
    }),
    [children],
  )

  useEffect(() => {
    const isStepValid = Object.keys(MAP_STEPS).includes(
      step as CreateCampaignSteps,
    )

    const platformNotValid =
      step === CreateCampaignSteps.PLATFORM_SEARCH && !platform

    if (!step || !isStepValid || platformNotValid) {
      status
        ? setSearch({ ...STEPS[0].query, status })
        : setSearch({ ...STEPS[0].query })
    }
  }, [MAP_STEPS, platform, setSearch, status, step])

  useEffect(() => {
    const isSearchPlatform =
      step === CreateCampaignSteps.PLATFORM_SEARCH && platform

    if (
      (isSearchPlatform && !searchPlatform) ||
      (isSearchPlatform && searchPlatform !== platform)
    ) {
      dispatch(setSchedulePlatform(platform))
    }
  }, [dispatch, platform, searchPlatform, step])

  return (
    <div>
      {MAP_STEPS[search.get('step') as CreateCampaignSteps] || children[0]}
    </div>
  )
}
