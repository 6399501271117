import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors } from 'src/Styled/variables'
import { LoadingSVG } from 'src/Components/LoadingSVG'
import { kFormatter } from 'src/Constants/functions'
import { getIsTiktokAudienceLoading } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'

interface Props {
  minVideos?: number
  maxVideos?: number
}

const StyledNumbers = styled.div`
  color: ${colors.mainText};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
export const PotentialVideos: FC<Props> = ({ minVideos, maxVideos }) => {
  const { t } = useTranslation()
  const isUpdateCampaignLoading = useAppSelector(getIsTiktokAudienceLoading)

  if (
    minVideos &&
    maxVideos &&
    minVideos !== maxVideos &&
    !isUpdateCampaignLoading
  ) {
    return (
      <StyledNumbers>{`${kFormatter(minVideos)} - ${kFormatter(
        maxVideos,
      )}`}</StyledNumbers>
    )
  }
  if (
    minVideos &&
    maxVideos &&
    minVideos === maxVideos &&
    !isUpdateCampaignLoading
  ) {
    return (
      <StyledNumbers>
        {`${t('scheduleCampaignPage.upTo')} ${kFormatter(maxVideos)}`}
      </StyledNumbers>
    )
  }
  return <LoadingSVG />
}
