import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

export const RelativeWrapper = styled.div`
  position: relative;
`
export const TitleWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Title = styled.p`
  font-size: ${variables.fontSize14};
  line-height: 16px;
`
export const TopBarNewLabel = styled.div`
  position: absolute;
  right: 9px;
  top: 20px;

  & > div {
    position: relative;

    &:before {
      background-color: rgba(224, 83, 111, 0.2);
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: beforePulse 1.5s infinite;
    }

    &:after {
      height: 7px;
      width: 7px;
      background-color: #e0536f;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
`
export const TopBarButton = styled.button`
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;

  &:hover {
    background: rgba(77, 77, 77, 0.5);
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: white;
  }
`
