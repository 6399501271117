import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { ViewsProgressChart } from 'src/Components/ViewsProgressChart'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { BadgeType } from 'src/Components/Badge'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'

import { WarningModal } from '../../../WarningModal'
import { DetailsTitle } from '../DetailsTitle'
import { SomethingWentWrongAlert } from '../../../SomethingWentWrongAlert'
import { Player } from '../Player'
import { FollowInfo } from '../FollowInfo'
import { Description, InfoWrapper, Title } from '../Range'
import { MonitoringFailedAlert } from '../../../MonitoringFailedAlert'
import { ViewsNotAchievedAlert } from '../../../ViewsNotAchivedAlert'
import { TaskNotCompletedAlert } from '../../../TaskNotCompletedAlert'
import { Container, Content, StyledButton } from '../../styles'
import { ViewsAchievedAlert } from '../../../ViewsAchievedAlert'
import { TaskCompletedAlert } from '../../../TaskCompletedAlert'

export const SubmittedSoundDetails = (): ReactElement | null => {
  const { t } = useTranslation()

  const { search } = useCustomSearchParams()

  const { data } = useGetMonetizationDataQuery()

  if (!data) {
    return null
  }

  const { maxCreatorReward, minViews } = data

  const soundStatus = search.get('soundStatus')

  const isSoundNotAvailable = soundStatus === 'notAvailable'

  // TODO DIMA discuss on sync
  const isDefaultErrorAlert = false

  // TODO DIMA discuss on sync
  const isMonitoringFailedAlert = false

  // TODO DIMA discuss on sync
  const isTaskNotCompletedAlert = false

  // TODO DIMA discuss on sync
  const isViewsNotAchievedAlert = false

  // TODO DIMA discuss on sync
  const isViewsAchievedAlert = false

  // TODO DIMA discuss on sync
  const isTaskCompletedAlert = false

  const formattedMaxCreatorReward = `$${maxCreatorReward.toFixed(2)}`
  const monitoringSoundData = {
    trackAuthorName: 'trackAuthorName',
    trackPlayUrl: 'trackPlayUrl',
    trackTitle: 'trackTitle',
    trackCoverUrl: 'trackCoverUrl',
  }

  const titleBadge = {
    monitoring: {
      title: t('soundsPage.monitoring'),
      type: 'disabled' as BadgeType,
    },
    notCompleted: {
      title: t('soundsPage.notCompleted'),
      type: 'warning' as BadgeType,
    },
  }

  const currentViews = 2000
  const rewardDate = new Date().toISOString()

  // TODO DIMA discuss on sync
  const tikTokVideoUrl = '/'

  return (
    <Container>
      <DetailsTitle
        badgeTitle={titleBadge.monitoring.title}
        badgeType={titleBadge.monitoring.type}
        title={t('soundsPage.submittedModalTitle')}
      />

      {isDefaultErrorAlert && <SomethingWentWrongAlert />}
      {isMonitoringFailedAlert && <MonitoringFailedAlert />}
      {isViewsNotAchievedAlert && (
        <ViewsNotAchievedAlert
          minViews={minViews}
          currentViews={currentViews}
        />
      )}
      {isTaskNotCompletedAlert && <TaskNotCompletedAlert />}
      {isViewsAchievedAlert && (
        <ViewsAchievedAlert
          minViews={minViews}
          currentViews={currentViews}
          rewardDate={rewardDate}
        />
      )}
      {isTaskCompletedAlert && <TaskCompletedAlert />}

      <Content>
        {monitoringSoundData && (
          <Player
            trackAuthorName={monitoringSoundData.trackAuthorName}
            trackPlayUrl={monitoringSoundData.trackPlayUrl}
            trackTitle={monitoringSoundData.trackTitle}
            trackCoverUrl={monitoringSoundData.trackCoverUrl}
            // TODO DIMA add url
            trackUrl={'/'}
          />
        )}

        <FollowInfo />
        <InfoWrapper>
          {/* TODO DIMA add value after discuss */}
          <Title>{t('soundsPage.earnUpTo')} $6.79</Title>
          <Description>
            {t('soundsPage.rewardDescription', {
              formattedMaxCreatorReward,
            })}
          </Description>
        </InfoWrapper>
        {/* TODO DIMA add value after discuss */}
        <ViewsProgressChart
          min={{ price: 2, views: 1000 }}
          max={{ price: 5, views: 10222 }}
          value={{ price: 3, views: currentViews }}
        />

        <Link to={tikTokVideoUrl}>
          <StyledButton type={'whiteWithGreenBorder'}>
            <TikTokIcon width={14} height={16} />
            <span>{t('soundsPage.videoOnTikTok')}</span>
          </StyledButton>
        </Link>
      </Content>
      {isSoundNotAvailable && <WarningModal />}
    </Container>
  )
}
