import { FC, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import logo from 'src/Assets/Png/logo.png'
import LogoSmall from 'src/Assets/Svg/logoSmall.svg?react'
import { Mobile, Desktop } from 'src/Components/Responsive'
import { Roles } from 'src/Constants/enums'
import {
  getArtistNotifications,
  getCuratorNotifications,
} from 'src/Redux/notification-process/api-actions'
import { TestingMode } from 'src/Components/TestingMode'
import { useAppDispatch } from 'src/Hooks/redux'
import {
  getIsArtist,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getArtistBalance } from 'src/Redux/auth-process/userSlice/selectors'

import { TopBarAlert } from '../TopBarAlert'

import { TopBarSidebarButton } from './TopbarSidebarButton'
import TopBarProfile from './TopbarProfile'
import TopBarNotification from './TopbarNotification'
import TopBarBalance from './TopbarBalance'

import {
  TopBarContainer,
  TopBarLeft,
  TopBarLogo,
  TopBarRight,
  TopBarWrapper,
} from './styles'

const TopBar: FC = () => {
  const dispatch = useAppDispatch()

  const isArtist = useSelector(getIsArtist)
  const balance = useSelector(getArtistBalance)
  const userRole = useSelector(getUserRole)

  useEffect(() => {
    if (userRole === Roles.curator) {
      void dispatch(getCuratorNotifications())
    }
    if (isArtist) {
      void dispatch(getArtistNotifications())
    }
  }, [dispatch, isArtist, userRole])
  return (
    <TopBarContainer>
      <TopBarWrapper>
        <TopBarLeft>
          <TopBarSidebarButton />
          <TopBarLogo>
            <Link to='/'>
              <Desktop>
                <img alt='logo' src={logo} />
              </Desktop>
              <Mobile>
                <LogoSmall data-testid='logo' width='23px' />
              </Mobile>
            </Link>
          </TopBarLogo>
        </TopBarLeft>
        <TopBarRight>
          <TopBarBalance balance={balance} />
          <TopBarNotification />
          <TopBarProfile />
        </TopBarRight>
      </TopBarWrapper>
      <TestingMode />
      <Desktop>
        <TopBarAlert />
      </Desktop>
    </TopBarContainer>
  )
}

export default TopBar
