import styled from 'styled-components'
import Arrow from 'mdi-react/ChevronRightIcon'

import { colors } from 'src/Styled/variables'

export const WaveformContainer = styled.div`
  background-color: ${colors.white};
  padding: 16px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 96px 1fr;
  gap: 16px;
`

export const CoverActionBlock = styled.div`
  position: relative;
  border-radius: 4px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 96px;
    height: 96px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
  & img {
    border-radius: 4px;
    width: 96px;
    height: 96px;
  }
`

export const InfoActionBlockTop = styled.div`
  display: grid;
  grid-template-columns: 1fr 14.4px;
  gap: 16px;
  & svg {
    width: 14.4px;
    margin-top: -4px;
  }
  margin-bottom: 8px;
`
export const InfoActionBlockTopNames = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  text-align: left;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
`

export const InfoActionBlockTopTrack = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
`

export const StyledWaveform = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: transparent;
`

export const Wave = styled.div`
  height: 90px;
`
export const PlayButton = styled.button`
  display: grid;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;

  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
`

export const DurationDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
`

export const ControlsButtons = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`

export const ArrowRight = styled(Arrow)`
  height: 24px !important;
  width: 24px !important;
  background-color: transparent;
  border: none;
  outline: none;
`

export const ArrowLeft = styled(Arrow)`
  height: 24px !important;
  width: 24px !important;
  background-color: transparent;
  border: none;
  outline: none;
  transform: rotate(180deg);
`
