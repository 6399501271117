import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

import { getIsFeatureValue } from '../A-BFeatures-process/selectors'

import { GET_PREMIUM_FEATURE_NAME } from './api-actions'

import { GetPremiumState } from '.'

const getState = (state: RootState): GetPremiumState => state.getPremium

export const getIsSubscribedForGetPremium = getIsFeatureValue(
  GET_PREMIUM_FEATURE_NAME,
)

export const getIsGetPremiumLoading = createDraftSafeSelector(
  getState,
  ({ getPremiumLoading }) => getPremiumLoading,
)

export const getIsGetPremiumError = createDraftSafeSelector(
  getState,
  ({ getPremiumError }) => getPremiumError,
)

export const getIsAlertClosed = createDraftSafeSelector(
  getState,
  ({ isAlertClosed }) => isAlertClosed,
)
