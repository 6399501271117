import { FunctionComponent } from 'react'

import { BlockProps } from './BlockProps'

import { Card, Title, Value, SubTitle } from './styles'

const Block: FunctionComponent<BlockProps> = ({ title, value, subtitle }) => {
  const numberValue = Number(value)

  return (
    <Card>
      <Title>{title}</Title>
      <Value>{numberValue <= 0 ? '-' : value}</Value>
      <SubTitle>{subtitle}</SubTitle>
    </Card>
  )
}

export default Block
