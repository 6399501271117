import { FunctionComponent } from 'react'

import { AddedTrack } from 'src/Components/AddedTrack'
import { CampaignPlatformTypeEnum } from 'src/Types/index'

import { PlatformCardProps } from './PlatformCardProps'

import { PlatformCardContainer } from './styles'

export const PlatformCard: FunctionComponent<
  PlatformCardProps<CampaignPlatformTypeEnum>
> = ({ platform, changeTrackHandler, isVariantB }) => (
  <PlatformCardContainer>
    <AddedTrack
      isVariantB={isVariantB}
      platform={platform}
      changeTrackHandler={changeTrackHandler}
    />
  </PlatformCardContainer>
)
