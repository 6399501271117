import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { EIGHTY_PERCENT, FIFTY_PERCENT } from 'src/Constants/constants'
import { colors, variables } from 'src/Styled/variables'
import { FeedMatchedPlaylist } from 'src/Types'

const AddedToLabel = styled.span`
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 15px;
  color: ${colors.mainText};
`
const AddedTo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
`
const AddedToPlaylist = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const AddedToPlaylistCover = styled.img`
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
`

const AddedToPlaylistInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const AddedToPlaylistTitle = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`
const AddedToPlaylistScore = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`
const AddedToPlaylistScoreLabel = styled.span`
  margin-right: 4px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.greyTextWhiteBg};
`

const matchColor = (percent: number): string => {
  if (percent > EIGHTY_PERCENT) {
    return colors.green
  }
  if (percent > FIFTY_PERCENT) {
    return colors.messegesYellow1
  }
  return colors.messegesRed
}
const AddedToPercents = styled.span<{ percent: number }>`
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: ${({ percent }) => matchColor(percent)};
`

interface ReviewedTrackPlaylistProps {
  playlist?: FeedMatchedPlaylist
}
export const ReviewedTrackPlaylist: FC<ReviewedTrackPlaylistProps> = ({
  playlist,
}) => {
  const { t } = useTranslation()
  if (!playlist) {
    return null
  }
  return (
    <div>
      <AddedToLabel>{t('reviewsPage.addedToPlaylist')}</AddedToLabel>
      <AddedTo>
        <AddedToPlaylist>
          <AddedToPlaylistCover src={playlist.playlist_cover} />
          <AddedToPlaylistInfo>
            <AddedToPlaylistTitle>
              {playlist.playlist_name}
            </AddedToPlaylistTitle>
            <AddedToPlaylistScore>
              <AddedToPlaylistScoreLabel>
                {t('reviewsPage.score')}:
              </AddedToPlaylistScoreLabel>
              {playlist.score}
            </AddedToPlaylistScore>
          </AddedToPlaylistInfo>
        </AddedToPlaylist>
        <AddedToPercents percent={playlist.matched_percent}>
          {playlist.matched_percent}% {t('reviewsPage.match')}
        </AddedToPercents>
      </AddedTo>
    </div>
  )
}
