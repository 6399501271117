import React, { ReactElement, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'
import { useNewSoundByIdQuery } from 'src/Redux/creatorNewSoundsApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'
import { sounds } from 'src/Router/routes'
import { useLazyGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { DetailsTitle } from '../DetailsTitle'
import { Player } from '../Player'
import { FollowInfo } from '../FollowInfo'
import { Range } from '../Range'
import { Container, Content, StyledButton } from '../../styles'
import { TabKeysEnum } from '../../../../types'

const errorModalLink = `${sounds}/${TabKeysEnum.NEW}?errorModalVisible=true`

export const NewSoundDetails = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setSearch } = useCustomSearchParams()

  const creatorId = useAppSelector(getInfluencerId)
  const { soundId } = useParams<{ soundId?: string }>()

  const { data: newSoundData, error: getSoundError } = useNewSoundByIdQuery({
    creatorId,
    soundId: Number(soundId),
  })

  const [getMonetizationData, { isError: isRangeOutdated }] =
    useLazyGetMonetizationDataQuery()

  useEffect(() => {
    if (getSoundError) {
      navigate(errorModalLink)
    }
  }, [getSoundError, navigate])

  const onClickAcceptSound = (): void => {
    void getMonetizationData().then(({ error }) => {
      if (!error) {
        setSearch({
          confirmRules: 'true',
        })
      }

      return true
    })
  }

  return (
    <Container>
      <DetailsTitle
        badgeTitle={t('soundsPage.newSound')}
        badgeType={'regular'}
        title={t('soundsPage.detailsTitle')}
      />

      {isRangeOutdated && <ProblemsWithTikTokErrorAlert />}

      <Content>
        {newSoundData && (
          <Player
            trackAuthorName={newSoundData.trackAuthorName}
            trackPlayUrl={newSoundData.trackPlayUrl}
            trackTitle={newSoundData.trackTitle}
            trackCoverUrl={newSoundData.trackCoverUrl}
            // TODO DIMA add url
            trackUrl={'/'}
          />
        )}

        <FollowInfo />
        <Range isRangeOutdated={isRangeOutdated} />
      </Content>

      <StyledButton
        disabled={isRangeOutdated}
        type={'green'}
        onClick={onClickAcceptSound}
      >
        {t('soundsPage.acceptSound')}
      </StyledButton>
    </Container>
  )
}
