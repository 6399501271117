import { FC } from 'react'

import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

import { campaignsList, details } from 'src/Router/routes'
import { colors } from 'src/Styled/variables'

import { ContactButton, Wrapper } from './styles'

export const DETAILS_PATH_LENGTH = 4

export const TestingMode: FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { show } = useIntercom()

  // remove this after testing mode will be finished

  const showTiktokDetailsNotification =
    location.pathname.includes(`${campaignsList}${details}`) &&
    location.pathname.split('/').length === DETAILS_PATH_LENGTH

  if (showTiktokDetailsNotification) {
    return (
      <Wrapper>
        <span>{t('campaignsPage.testingModeTikTokCampaigns')}</span>
        <ContactButton color={colors.white} onClick={() => show()}>
          {t('campaignsPage.contactSupport')}
        </ContactButton>
      </Wrapper>
    )
  }
  return null
}
