import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors, variables } from 'src/Styled/variables'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'

const Bar = styled.div`
  position: relative;
  display: flex;
  height: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background-color: ${colors.green1Light};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 40px;
`

const LeftDivision = styled.div`
  position: absolute;
  height: 8px;
  width: 2px;
  background-color: ${colors.badgeGreen};
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const RightDivision = styled.div`
  position: absolute;
  height: 8px;
  width: 2px;
  background-color: ${colors.badgeGreen};
  right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Reward = styled.div`
  color: ${colors.mainText};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Views = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  text-wrap: nowrap;
`

export const DivisionInfo = styled.div`
  margin-top: 14px;
`

export const EarningRange: FC = () => {
  const { t } = useTranslation()

  const { data } = useGetMonetizationDataQuery()

  if (!data) {
    return null
  }

  const { minViews, maxViews, minCreatorReward, maxCreatorReward } = data

  const formattedMinViews = addSpaceToNumber(minViews, ',')
  const formattedMaxViews = addSpaceToNumber(maxViews, ',')
  const formattedMinCreatorReward = minCreatorReward.toFixed(2)
  const formattedMaxCreatorReward = maxCreatorReward.toFixed(2)

  return (
    <Bar>
      <RightDivision>
        <DivisionInfo>
          <Reward>${formattedMinCreatorReward}</Reward>
          <Views>
            {formattedMinViews} {t('monetization.views')}
          </Views>
        </DivisionInfo>
      </RightDivision>
      <LeftDivision>
        <DivisionInfo>
          <Reward>${formattedMaxCreatorReward}</Reward>
          <Views>
            {formattedMaxViews} {t('monetization.views')}
          </Views>
        </DivisionInfo>
      </LeftDivision>
    </Bar>
  )
}
