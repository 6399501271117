import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'
import { TransactionTypeEnum } from 'src/Types'

import { ArtistBalanceHistoryState } from '.'

const getState = (state: RootState): ArtistBalanceHistoryState =>
  state.artistBalance.details

const typeOrder: { [key in TransactionTypeEnum]: number } = {
  [TransactionTypeEnum.CASHBACK]: 1,
  [TransactionTypeEnum.PAYMENT]: 2,
  [TransactionTypeEnum.USED]: 3,
  [TransactionTypeEnum.RECEIVED]: 4,
  [TransactionTypeEnum.BONUS]: 5,
  [TransactionTypeEnum.CHARGEBACK]: 6,
  [TransactionTypeEnum.REFERRAL_BONUS]: 7,
  [TransactionTypeEnum.INVITED_BONUS]: 8,
  [TransactionTypeEnum.SUBSCRIPTION]: 9,
  [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: 10,
  [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: 11,
  [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: 12,
}

export const getArtistBalanceHistory = createDraftSafeSelector(
  getState,

  ({ artistBalanceHistory }) =>
    artistBalanceHistory
      .filter((transaction) => transaction.amount !== 0)
      .sort((a, b) => {
        const dateDifference =
          new Date(b.createdAt ?? '').getTime() -
          new Date(a.createdAt ?? '').getTime()
        if (dateDifference !== 0) {
          return dateDifference
        }

        return typeOrder[a.transactionType] - typeOrder[b.transactionType]
      }),
)

export const getArtistBalanceHistoryLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getIsArtistNotFromInvitedLink = createDraftSafeSelector(
  getState,
  ({ artistBalanceHistory, status }) => {
    if (status === SliceStatus.Idle || status === SliceStatus.Loading) {
      return false
    }

    return !(
      status === SliceStatus.Succeeded &&
      artistBalanceHistory.some(
        (item) => item.transactionType === 'invited_bonus',
      )
    )
  },
)
