import styled from 'styled-components'
export const SearchInputWrapper = styled.div`
  height: 56px;
  width: 100%;
  display: grid;
  grid-template-columns: 36px 1fr;
  border-bottom: 1px solid #f3f4f7;
  align-items: center;
`
export const SmallButton = styled.div`
  width: 44px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  img {
    height: 10px;
  }
`
