import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import {
  MobileBlockWrapper,
  NormalText,
  SmallText,
  ValuesContainer,
} from '../../styles'

export const RewardInfo: FunctionComponent<{ reward: number }> = ({
  reward,
}) => {
  const { t } = useTranslation()

  const formattedRewardAmount = `$${reward.toFixed(2)}`

  return (
    <MobileBlockWrapper>
      <ValuesContainer>
        <SmallText>{t('soundsPage.reward')}</SmallText>
        <NormalText>{formattedRewardAmount}</NormalText>
      </ValuesContainer>
    </MobileBlockWrapper>
  )
}
