import TagManager from 'react-gtm-module'

import { CampaignPlatformTypeEnum, CampaignTypeEnum, ID, User } from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'
import { campaignsPage, pageInfo } from '../pageInfo'

export const toggleActivatedEvent = ({
  isFirstCampaign,
  platformName,
  campaignNumber,
  userId,
}: {
  isFirstCampaign: boolean
  platformName: string
  campaignNumber?: number
  userId?: ID | null
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.ToggleActivated,
      event_name: EventNameEnum.ToggleActivated,
      'campaign-type':
        (isFirstCampaign && campaignNumber === 1) || !userId
          ? CampaignTypeEnum.FIRST
          : CampaignTypeEnum.BASIC,
      'campaign-platform': platformName,
      'track-platform': platformName,
      'campaign-number': campaignNumber || 1,
      ...(userId ? { userID: userId } : {}),
    },
  })
}

export const createdFirstCampaignEvent = (
  platformName: string,
  campaignInfo: {
    user: {
      id: string
      role: string
      email: string
      phone_number: string
    }
    spotify_campaign: {
      id: string
    }
    tiktok_campaign: {
      id: number | string
    }
  },
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CreatedFirstCampaign,
      event_name: EventNameEnum.CreatedFirstCampaign,
      'campaign-type': CampaignTypeEnum.FIRST,
      'campaign-platform': platformName,
      'campaign-number': '1',
      'campaign-id':
        platformName === CampaignPlatformTypeEnum.SPOTIFY
          ? campaignInfo.spotify_campaign.id
          : campaignInfo.tiktok_campaign.id,
      userID: campaignInfo.user.id,
      pageTitle: pageInfo.CreatedFirstCampaign.pageTitle,
      pagePath: pageInfo.CreatedFirstCampaign.pagePath,
      email: campaignInfo.user.email,
      phone: campaignInfo.user.phone_number,
    },
  })
}

export const createCampaignEvent = (
  userID: string,
  campaignId: string,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CreateCampaign,
      campaignID: campaignId,
      pageTitle: 'Created Campaign',
      pagePath: campaignsPage,
      userID,
    },
  })
}

export const createdCampaignEvent = ({
  isFirstCampaign,
  platformName,
  campaignId,
  trackId,
  userId,
  campaignNumber,
  danceRequired,
  is_feed,
}: {
  isFirstCampaign: boolean
  platformName: string
  campaignId: number | string
  trackId: number | string
  userId: string
  campaignNumber?: number
  danceRequired?: boolean
  is_feed?: boolean
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CreatedCampaign,
      event_name: EventNameEnum.CreatedCampaign,
      'campaign-type':
        (isFirstCampaign && campaignNumber === 1) || !userId
          ? CampaignTypeEnum.FIRST
          : CampaignTypeEnum.BASIC,
      'campaign-platform': platformName,
      'campaign-number': campaignNumber,
      'campaign-id': campaignId,
      ...(trackId ? { 'track-id': trackId } : {}),
      userID: userId,
      is_feed,
      ...(danceRequired ? { dance_required: danceRequired } : {}),
    },
  })
}

export const campaignSentToReviewEvent = ({
  campaignType,
  platformName,
  campaignNumber,
  userId,
}: {
  campaignType: CampaignTypeEnum
  platformName: string
  campaignNumber?: number | string
  userId?: ID | null
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CampaignSendToReview,
      event_name: EventNameEnum.CampaignSendToReview,
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'campaign-number': campaignNumber,
      ...(userId ? { userID: userId } : {}),
    },
  })
}

export const firstCampaignDetailsEvent = ({
  campaignPlatform,
  trackName,
  trackDuration,
  trackId,
  trackArtistName,
}: {
  campaignPlatform: CampaignPlatformTypeEnum
  trackName: string
  trackDuration: string | number
  trackId: string | number
  trackArtistName: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.FirstCampaignDetails,
      'campaign-type': CampaignTypeEnum.FIRST,
      'campaign-platform': campaignPlatform,
      'campaign-number': '1',
      'track-name': trackName,
      'track-length': trackDuration,
      'track-id': trackId,
      'track-artist-name': trackArtistName,
    },
  })
}

export const createFirstCampaignStartEvent = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CreateFirstCampaignStart,
    },
  })
}

export const createCampaignStartEvent = (
  user: User,
  campaignNumber: number,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.NcStart,
      'campaign-type': user.is_first_campaign
        ? CampaignTypeEnum.FIRST
        : CampaignTypeEnum.BASIC,
      'campaign-number': campaignNumber || 1,
      userID: user.id,
      'User-first-name': user.first_name,
      'registration-date': user.createdAt,
      'account-type': 'musician',
      'user-role': user.role,
    },
  })
}

export const campaignDetailsEvent = (
  campaignPlatform: string,
  campaignNumber: number,
  campaignType: string,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CampaignDetails,
      'campaign-type': campaignType,
      'campaign-platform': campaignPlatform,
      'campaign-number': campaignNumber,
    },
  })
}

export const fistCampaignArtistDetailsEvent = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      'campaign-type': CampaignTypeEnum.FIRST,
      event: EventTypeEnum.ArtistDetails,
    },
  })
}

export const relaunchCampaignListClickEvent = ({
  userId,
  campaignNumber,
  campaignId,
}: {
  userId?: ID | null
  campaignNumber?: number
  campaignId: string | number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.RelaunchCampaignListClick,
        'campaign-type': CampaignTypeEnum.RELAUNCHED,
        'campaign-platform': CampaignPlatformTypeEnum.SPOTIFY,
        campaign_number: campaignNumber || 1,
        'campaign-id': campaignId,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const relaunchCampaignInDetailsClickEvent = ({
  userId,
  campaignNumber,
  campaignId,
}: {
  userId?: ID | null
  campaignNumber?: number
  campaignId: string | number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.RelaunchDetailsClick,
        'campaign-type': CampaignTypeEnum.RELAUNCHED,
        'campaign-platform': CampaignPlatformTypeEnum.SPOTIFY,
        campaign_number: campaignNumber || 1,
        'campaign-id': campaignId,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}
