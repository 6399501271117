import { FC } from 'react'

import { ScheduleCampaignSummary } from 'src/Components/ScheduleCampaignSummary'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { PaymentMethods } from 'src/Components/PaymentMethods'
import PaymentErrorsAlert from 'src/Components/PaymentErrorsAlert'
import { getPaymentAlertError } from 'src/Redux/payment-process/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { getIsEnoughBalance } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { PaymentProcessingModal } from 'src/Components/PaymentProcessingModal'
import { getIsProcessingPage } from 'src/Redux/paypalTransactions-process/selectors'
import { useCheckSpotifyCheckoutStepIsAllowed } from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifyCheckout/helpers/useCheckSpotifyCheckoutStepIsAllowed'
import { CheckoutMobileFooter } from 'src/Components/CheckoutMobileFooter'
import { ProtectionAlertMobile } from 'src/Components/ProtectionAlertMobile'
import { usePaymentCancelledSpotifyEvents } from 'src/Hooks/usePaymentCancelledSpotifyEvents'
import { PaymentMethodsByStripeOnly } from 'src/Components/PaymentMethods/PaymentMethodsByStripeOnly'
import { RemovePaypalExperiment } from 'src/Hooks/Experiments/RemovePaypalExperiment'

import { SpotifyCheckoutContainer } from './styles'

export const SpotifyCheckout: FC = () => {
  useCheckSpotifyCheckoutStepIsAllowed()
  usePaymentCancelledSpotifyEvents()

  const paymentError = useAppSelector(getPaymentAlertError)
  const isProcessingPageVisible = useAppSelector(getIsProcessingPage)
  const isEnoughBalance = useAppSelector(getIsEnoughBalance)

  return (
    <SpotifyCheckoutContainer>
      {isProcessingPageVisible && <PaymentProcessingModal />}
      <div>
        {paymentError && <PaymentErrorsAlert name={paymentError} />}
        <RemovePaypalExperiment>
          <PaymentMethods
            isEnoughBalance={isEnoughBalance}
            currentPlatform={CampaignPlatformTypeEnum.SPOTIFY}
          />
          {/* must be second child */}
          <PaymentMethodsByStripeOnly isEnoughBalance={isEnoughBalance} />
        </RemovePaypalExperiment>
      </div>
      <ScheduleCampaignSummary
        platformName={CampaignPlatformTypeEnum.SPOTIFY}
      />
      <CheckoutMobileFooter platformType={CampaignPlatformTypeEnum.SPOTIFY} />
      <ProtectionAlertMobile platform={CampaignPlatformTypeEnum.SPOTIFY} />
    </SpotifyCheckoutContainer>
  )
}
