import { AnyAction, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { Track } from 'src/Types'
import { NO_TRACKS_WERE_FOUND } from 'src/Constants/constants'
import { APP } from 'src/Configs/App'
import { api } from 'src/Services/api'

import { ErrorCode, NameSpace } from '../types'
import { RootState } from '../index'

import {
  getErrorMessage,
  getIsInvalidSearchInputValue,
  getSpotifyApiUrl,
} from './utils'

import { setPlatformTypeStatus } from './index'

interface GetSpotifyTracksBody {
  search: string
  token: string
}

interface GetSpotifyTracksResponse {
  tracks: { items: Array<Track> }
}

type dispatchType = ThunkDispatch<unknown, unknown, AnyAction>

type GetSpotifyTracksError = {
  isKeyword: boolean
  isTrackId: boolean
  trackId: boolean
  keyword: boolean
  dispatch: dispatchType
}
const getSearchErrorByType = (props: GetSpotifyTracksError): string => {
  const { isKeyword, isTrackId, trackId, keyword, dispatch } = props

  if (isKeyword && !trackId) {
    dispatch(setPlatformTypeStatus({ keyword: true, trackId: false }))
    return 'tryUrl'
  }

  if (isTrackId && !keyword) {
    dispatch(setPlatformTypeStatus({ keyword: false, trackId: true }))
    return 'tryName'
  }

  if ((isTrackId && keyword) || (isKeyword && trackId)) {
    dispatch(setPlatformTypeStatus({ keyword: true, trackId: true }))
    return 'tryLater'
  }
  return ''
}

export const getSpotifyTracks = createAsyncThunk<
  GetSpotifyTracksResponse | Track,
  GetSpotifyTracksBody
>(
  `${NameSpace.SpotifyTracks}/getSpotifyTracks`,
  async ({ search, token }, { rejectWithValue, getState, dispatch }) => {
    const { url, type } = getSpotifyApiUrl(search)
    const { spotifyTracks } = getState() as RootState
    const { trackSearchByTypeStatus } = spotifyTracks
    const { keyword, trackId } = trackSearchByTypeStatus

    try {
      if (getIsInvalidSearchInputValue(search)) {
        throw new Error(NO_TRACKS_WERE_FOUND)
      }
      const { data } = await axios.get<GetSpotifyTracksResponse | Track>(url, {
        headers: { Authorization: `Bearer ${token}` },
      })

      if ('tracks' in data && data.tracks.items.length === 0) {
        throw new Error(NO_TRACKS_WERE_FOUND)
      }

      return data
    } catch (error) {
      const { response, message } = error as AxiosError<{
        message: string
      }>
      const isNotFound = response?.status === ErrorCode.NotFound
      const isKeyword = type === 'keyword' && isNotFound
      const isTrackId = type === 'trackId' && isNotFound
      return rejectWithValue({
        message: getErrorMessage({
          message,
          search,
          searchErrorByType: getSearchErrorByType({
            isKeyword,
            isTrackId,
            trackId,
            keyword,
            dispatch,
          }),
          code: response?.status,
        }),
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface CheckTrackBody {
  trackURL: string
}

interface CheckTrackResponse {
  errorMessage?: string
}

export const checkTrackToCreateCampaign = createAsyncThunk<
  CheckTrackResponse,
  CheckTrackBody
>(
  `${NameSpace.Campaign}/checkTrackToCreateCampaign`,
  async ({ trackURL }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<CheckTrackResponse>(
        `${APP.SERVER}/campaign/check-track`,
        {
          track_url: trackURL,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ errorMessage: string }>
      return rejectWithValue({
        message: response?.data?.errorMessage || 'something went wrong',
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
