import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'
import { TiktokCampaignStatus } from 'src/Types'

interface Props {
  status?: string
}

export const TikTokDetailsCampaignStatus = styled.div`
  color: ${(props: Props) =>
    props.status === TiktokCampaignStatus.RUNNING
      ? `${colors.mainText}`
      : `${colors.messegesRed}`};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`
