import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

export interface Feature {
  name: string
  variation: number
}

interface GetUserFeaturesResponse {
  features: Feature[]
}

export const getUserFeatures = createAsyncThunk<GetUserFeaturesResponse>(
  `${NameSpace.Features}/getUserFeatures`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetUserFeaturesResponse>(
        `${APP.TIKTOK_SERVER}/feature-flags/user-features`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>

      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface AddFeatureForUserBody {
  featureName: string
  variation: number
}

export const addFeatureForUser = createAsyncThunk<
  unknown,
  AddFeatureForUserBody
>(
  `${NameSpace.Features}/addFeatureForUser`,
  async (feature, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.put<unknown>(
        `${APP.TIKTOK_SERVER}/feature-flags/user-flag`,
        { ...feature },
      )
      void dispatch(getUserFeatures())
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>

      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
