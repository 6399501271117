import { useState, useEffect, FC } from 'react'

import { useIntercom } from 'react-use-intercom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { renderRole, removeCookiesHandler } from 'src/Constants/functions'
import { Roles } from 'src/Constants/enums'
import { getSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'
import ConnectToSpotifyModal from 'src/Containers/ui/ConnectToSpotifyModal'
import { DropdownMenu } from 'src/Components/DropdownMenu'
import avatar from 'src/Assets/Png/ava.png'
import IconSettings from 'src/Assets/Svg/settings.svg?react'
import IconLogout from 'src/Assets/Svg/logout.svg?react'
import { logOutEvent } from 'src/Helpers/TagManager'
import { settings } from 'src/Router/routes'
import { DropdownMenuItem } from 'src/Components/DropdownMenu/props'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { logOut } from 'src/Redux/auth-process/credentialsSlice'
import {
  getIsArtist,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import {
  getUser,
  getUserRoleToReflect,
} from 'src/Redux/auth-process/userSlice/selectors'
import { clearUserState } from 'src/Redux/auth-process/userSlice'
import { rtkApi } from 'src/Services/rtkApi'

import {
  Avatar,
  TopBarRole,
  Profile,
  AvatarName,
  TopBarName,
  AvatarImage,
} from './styles'

const TopBarProfile: FC = () => {
  const { t } = useTranslation()
  const { shutdown } = useIntercom()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useAppSelector(getUser)
  const userRole = useAppSelector(getUserRole)
  const artistRole = useAppSelector(getUserRoleToReflect)
  const isArtist = useAppSelector(getIsArtist)

  const {
    first_name: firstName,
    last_name: lastName,
    user_photo_url: url,
  } = user

  const [modal, setModal] = useState(false)

  const toggle = (): void => setModal(!modal)

  useEffect(() => {
    const adminItem = localStorage.getItem('admin')
    const isAdmin = adminItem ? Boolean(JSON.parse(adminItem)) : false

    if (userRole === Roles.influencer) {
      return
    }

    void dispatch(getSpotifyAccessToken(isAdmin))
      .unwrap()
      .catch((error) => {
        if (error && !isAdmin && !isArtist) {
          setModal(true)
        }
      })
  }, [])

  const handleLogOut = (): void => {
    shutdown()
    user && logOutEvent(user)
    dispatch(logOut())
    dispatch(clearUserState())
    navigate('/')
    removeCookiesHandler()
    dispatch(rtkApi.util.resetApiState())
    localStorage.removeItem('user')
  }

  const getDropdownItems = (callback: () => void): DropdownMenuItem[] => {
    const addCallback = (action: () => void) => () => {
      action && action()
      callback()
    }

    return [
      {
        icon: <IconSettings />,
        label: <span>{t('sidebar.settings')}</span>,
        isNav: true,
        navigateTo: settings,
        onClick: callback,
        key: 0,
      },
      {
        icon: <IconLogout />,
        label: <span>{t('sidebar.logout')}</span>,
        onClick: addCallback(handleLogOut),
        key: 1,
        navigateTo: '',
      },
    ]
  }

  return (
    <Profile>
      <ConnectToSpotifyModal {...{ modal, toggle }} />
      <DropdownMenu
        topSeparated
        getItems={getDropdownItems}
        renderTrigger={() => (
          <Avatar>
            <AvatarImage alt='avatar' src={url} fallbackSrc={avatar} />
            <TopBarName>
              <AvatarName data-cy='topbar__avatar-name-p'>
                {firstName} {lastName}
              </AvatarName>
              {userRole && (
                <TopBarRole>{renderRole(userRole, t, artistRole)}</TopBarRole>
              )}
            </TopBarName>
          </Avatar>
        )}
      />
    </Profile>
  )
}

export default TopBarProfile
