import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useFeatureValue } from '@growthbook/growthbook-react'

import { useAppSelector } from 'src/Hooks/redux'
import {
  BudgetSlider,
  SliderHandleStyles,
  SliderTrackStyles,
} from 'src/Containers/ui/BudgetSlider'
import { SPOTIFY_BUDGET_STEP } from 'src/Constants/constants'
import { ScheduleSubscription } from 'src/Components/ScheduleSubscription/ScheduleSubscription'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'

import { Title } from '../styles'
import { useCampaignPrice } from '../helpers'
import { SettingsContinueButton } from '../SettingsContinueButton'

import { SpotifyEstimation } from './SpotifyEstimation'

import {
  BudgetSliderSideContainer,
  BudgetSliderWrapper,
  SettingsBudgetContainer,
  SliderContainer,
  BudgetInput,
  BudgetContainer,
} from './styles'

export const SettingsBudget: FC = () => {
  const { t } = useTranslation()
  // need to be removed after the experiment will be finished
  const variantOfContinueButton = useFeatureValue('continue_button', 0)

  const {
    savePrice,
    rate,
    currencySymbol,
    min,
    max,
    valueInput,
    valueSlider,
    setValueInput,
    setValueSlider,
  } = useCampaignPrice()

  const handleSliderChange = (value: number): void => {
    setValueInput(Math.round(value * rate))
    setValueSlider(value)
    savePrice(value)
  }
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const substr = event.target.value.substring(
      currencySymbol.length,
      event.target.value.length,
    )
    const inputValue = Number(substr)
    if (isNaN(inputValue)) {
      return
    }
    setValueInput(inputValue)
    savePrice(Math.round(inputValue / rate))
  }

  const marks = {
    [min]: `${currencySymbol}${Math.round(min * rate).toLocaleString('en-US')}`,
    [max]: `${currencySymbol}${Math.round(max * rate).toLocaleString('en-US')}`,
  }

  const hasActivePlan = useAppSelector(
    getHasActiveOrCanceledSubscriptionSelector,
  )

  return (
    <SettingsBudgetContainer>
      <Title>{t('scheduleCampaignPage.SetUp')}</Title>
      <BudgetSliderWrapper>
        <SliderContainer hasActivePlan={hasActivePlan}>
          <BudgetSlider
            value={valueSlider}
            vertical
            marks={marks}
            tooltip={{ formatter: null }}
            step={SPOTIFY_BUDGET_STEP}
            max={max}
            min={min}
            styles={{
              track: SliderTrackStyles,
              handle: SliderHandleStyles,
            }}
            onChange={handleSliderChange}
          />
        </SliderContainer>
        <BudgetSliderSideContainer>
          <BudgetContainer>
            <BudgetInput
              role='budget-input'
              value={`${currencySymbol}${valueInput}`}
              onChange={handleInputChange}
            />
            <ScheduleSubscription symbol={currencySymbol} price={valueInput} />
          </BudgetContainer>
          <SpotifyEstimation />
        </BudgetSliderSideContainer>
      </BudgetSliderWrapper>
      {Boolean(variantOfContinueButton) && <SettingsContinueButton />}
    </SettingsBudgetContainer>
  )
}
