import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

import { Video } from '.'

interface GetVideoByEventBody {
  eventId: number
  campaignId: number
}

export const getVideoByEvent = createAsyncThunk<Video, GetVideoByEventBody>(
  `${NameSpace.Video}/getVideoByEvent`,
  async ({ eventId, campaignId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<Video>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${campaignId}/event/${eventId}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: String(response?.data?.message),
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
