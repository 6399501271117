import TagManager from 'react-gtm-module'

import { CampaignPlatformTypeEnum, ID } from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const reviewRequestSeenEvent = ({
  requestId,
  campaignID,
  platformName,
  userId,
  is_feed,
  reviewRequestType,
}: {
  requestId: number | string
  campaignID: number | string
  platformName: string
  userId?: number | string
  is_feed?: boolean | string
  reviewRequestType: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.ReviewRequestSeen,
      event_name: EventNameEnum.ReviewRequestSeen,
      'account-type': 'curator',
      ...(userId ? { userID: userId } : {}),
      'request-id': requestId,
      'campaign-platform': platformName,
      'campaign-id': campaignID,
      is_feed,
      ...(reviewRequestType ? { review_request_type: reviewRequestType } : {}),
    },
  })
}

export const reviewMadeEvent = (
  campaignID: number | string,
  isTrackAdded: boolean,
  userId: number | string,
  reviewId?: number | string,
  amount?: number,
  rejectionReasonId?: number | string | boolean,
  rejectionReason?: string,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.ReviewMade,
      event_name: EventNameEnum.ReviewMade,
      'account-type': 'curator',
      ...(userId ? { userID: userId } : {}),
      ...(reviewId ? { 'review-id': reviewId } : {}),
      'campaign-platform': CampaignPlatformTypeEnum.SPOTIFY,
      'campaign-id': campaignID,
      ...(amount ? { amount_paid: amount } : {}),
      ...(isTrackAdded ? { is_track_added: isTrackAdded } : {}),
      ...(rejectionReason ? { rejection_reason: rejectionReason } : {}),
      ...(rejectionReasonId ? { rejection_reason_id: rejectionReasonId } : {}),
      is_feed: true,
    },
  })
}

export const trackAddedToPlaylistEvent = (
  campaignID: number | string,
  userId: number | string,
  reviewId?: number,
  amount?: number,
  trackId?: number,
  positionInPlaylist?: number,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.TrackAddedToPlaylist,
      event_name: EventNameEnum.TrackAddedToPlaylist,
      'account-type': 'curator',
      ...(userId ? { userID: userId } : {}),
      ...(reviewId ? { 'review-id': reviewId } : {}),
      'campaign-platform': CampaignPlatformTypeEnum.SPOTIFY,
      'campaign-id': campaignID,
      amount_paid: amount,
      'track-id': trackId,
      is_feed: true,
      position_in_playlist: positionInPlaylist,
    },
  })
}

export const reviewSkippedEvent = (
  campaignID: number | string,
  reviewId: number | string,
  campaignType: string,
  amount: string | number,
  userId: number | string,
  isFeed?: boolean,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.ReviewSkipped,
      event_name: EventNameEnum.ReviewSkipped,
      'account-type': 'curator',
      ...(userId ? { userID: userId } : {}),
      ...(reviewId ? { 'review-id': reviewId } : {}),
      'campaign-platform': campaignType,
      'campaign-id': campaignID,
      amount_paid: amount,
      is_feed: isFeed,
    },
  })
}

export const playlistAddedEvent = (
  isValid: boolean,
  campaignType: string,
  playlistId?: string | number,
  userId?: number | string,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.PlaylistAdded,
      event_name: EventNameEnum.PlaylistAdded,
      'account-type': 'curator',
      ...(userId ? { userID: userId } : {}),
      is_valid: isValid,
      playlist_id: playlistId,
      'track-platform': campaignType,
    },
  })
}

export const reviewRequestSeenNotAvailableEvent = (
  campaignType: string,
  campaignId: number,
  userId: string,
  requestId: number | string,
  isFeed?: boolean,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.ReviewRequestSeenNotAvailable,
      event_name: EventNameEnum.ReviewRequestSeenNotAvailable,
      'account-type': 'curator',
      ...(userId ? { userID: userId } : {}),
      'request-id': requestId,
      'campaign-platform': campaignType,
      'campaign-id': campaignId,
      is_available: 'false',
      is_feed: isFeed,
    },
  })
}

export const curatorSelectedPositionAddTrackEvent = ({
  userId,
  amountPaid,
  trackId,
  reviewId,
  campaignId,
  positionInPlaylist,
}: {
  userId?: ID
  amountPaid: number
  trackId: number
  reviewId: number
  campaignId: number
  positionInPlaylist: number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.CuratorSelectedPositionAddTrack,
        is_feed: false,
        campaign_platform: CampaignPlatformTypeEnum.SPOTIFY,
        amount_paid: amountPaid,
        track_id: trackId,
        review_id: reviewId,
        campaign_id: campaignId,
        position_in_playlist: positionInPlaylist,
      },
      user_properties: {
        user_id: userId,
        account_type: 'curator',
      },
    },
  })
}
