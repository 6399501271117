import { FC } from 'react'

import styled from 'styled-components'

import DangerIcon from 'src/Assets/Svg/Danger.svg?react'

interface TiktokWidgetListErrorProps {
  error: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 16px;
`
const ERROR_COLOR = '#ec5765'

const ErrorText = styled.div`
  color: ${ERROR_COLOR};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const TiktokWidgetListError: FC<TiktokWidgetListErrorProps> = ({
  error,
}) => (
  <Wrapper>
    <DangerIcon width={'16px'} height={'16px'} />
    <ErrorText>{error}</ErrorText>
  </Wrapper>
)
