import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { setPaymentAlertError } from 'src/Redux/payment-process'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'
import {
  setCampaignBudget,
  setStartDate,
} from 'src/Redux/campaign-process/mainOldProcessSlice'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { roundPriceValue } from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifyCheckout/helpers'
import { checkCoupon } from 'src/Redux/schedule-process/couponSlice/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { couponCheckStatusIsSucceededSelector } from 'src/Redux/schedule-process/couponSlice/selectors'

export enum PaymentStatusEnum {
  SUCCESS = 'success',
  FAILURE = 'failure',
  ERROR = 'error',
}

export const useBackFromPaymentPageSpotify = (): void => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const couponCheckStatusIsSucceeded = useAppSelector(
    couponCheckStatusIsSucceededSelector,
  )

  const { search } = useCustomSearchParams()

  const urlStartDate = search.get('startDate')
  const errorType = search.get('errorType')
  const paymentStatus = search.get('paymentStatus')
  const urlBudget = search.get('budget')
  const baToken = search.get('ba_token')
  const coupon = search.get('coupon')
  const token = Boolean(search.get('token'))
  const priceFromQuery = String(search.get('price'))
  const saveTokenErrorCampaignPayment = String(search.get('error'))

  const campaignId = Number(params.number)

  const checkoutStepUrl = `/campaigns/schedule/${campaignId}?step=${ScheduleSteps.CHECKOUT}`

  const isBackFromPaymentPageWithoutError =
    paymentStatus === PaymentStatusEnum.FAILURE && urlStartDate

  const isBackFromPaymentPageWithError =
    paymentStatus === PaymentStatusEnum.ERROR && !baToken && urlStartDate
  useEffect(() => {
    if (isBackFromPaymentPageWithoutError || isBackFromPaymentPageWithError) {
      isBackFromPaymentPageWithError &&
        dispatch(
          setPaymentAlertError(
            (errorType as PaymentErrors) || PaymentErrors.DEFAULT,
          ),
        )
      const roundedValue = roundPriceValue(urlBudget || 0)

      dispatch(setStartDate(urlStartDate))
      dispatch(setCampaignBudget(roundedValue))

      coupon &&
        !couponCheckStatusIsSucceeded &&
        void dispatch(checkCoupon(coupon))

      navigate(checkoutStepUrl)

      paymentStatus === PaymentStatusEnum.FAILURE
        ? navigate(
            `${checkoutStepUrl}&payment=cancelled&price=${priceFromQuery}&token=${token}`,
          )
        : navigate(checkoutStepUrl)
    }
  }, [
    baToken,
    checkoutStepUrl,
    coupon,
    couponCheckStatusIsSucceeded,
    dispatch,
    errorType,
    isBackFromPaymentPageWithError,
    isBackFromPaymentPageWithoutError,
    navigate,
    paymentStatus,
    priceFromQuery,
    saveTokenErrorCampaignPayment,
    token,
    urlBudget,
    urlStartDate,
  ])
}
