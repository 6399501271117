import React from 'react'

import CheckMark from 'src/Assets/Svg/checkMark.svg?react'

import { StepItemProps } from './props'

import { Container, Title, StepIndicator } from './styles'

export const StepItem: React.FC<StepItemProps> = ({
  title,
  id,
  isCurrent,
  isPassed,
  onClick,
}) => (
  <Container isCurrent={isCurrent} isPassed={isPassed} onClick={onClick}>
    <StepIndicator isCurrent={isCurrent} isPassed={isPassed}>
      {isPassed ? <CheckMark width={10} height={6.5} /> : id}
    </StepIndicator>
    <Title isCurrent={isCurrent} isPassed={isPassed}>
      {title}
    </Title>
  </Container>
)
