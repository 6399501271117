import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { RootState } from 'src/Redux'

import { checkReferralCode } from './api-actions'

interface ReferralCode {
  referralCode: string
  referralName: string
  status: SliceStatus
  error: ApiError | null
}

const initialState: ReferralCode = {
  referralCode: '',
  referralName: '',
  status: SliceStatus.Idle,
  error: null,
}

export const referralCodeProcess = createSlice({
  initialState,
  name: NameSpace.REFERRAL_CODE,
  reducers: {},

  extraReducers(builder) {
    builder.addCase(checkReferralCode.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(checkReferralCode.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.referralName = action.payload.firstName
      state.referralCode = action.meta.arg
    })
    builder.addCase(checkReferralCode.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = action.payload as ApiError
    })
  },
})

const getState = (state: RootState): ReferralCode => state.referral.referralCode

export const getReferralCode = createDraftSafeSelector(
  getState,
  ({ referralCode }) => referralCode,
)

export const getReferralName = createDraftSafeSelector(
  getState,
  ({ referralName }) => referralName,
)

export const getReferralError = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Failed,
)
