import { useSelector } from 'react-redux'

import {
  firstCampaignDetailsEvent,
  viewSignUpPopUpEvent,
} from 'src/Helpers/TagManager'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { handleDefinePlatform } from 'src/Constants/functions'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getTikTokTrackSelector } from 'src/Redux/tiktokTrack-process/selectors'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import { setTrackToInitialState } from 'src/Redux/track-process'
import {
  setTiktokTracksToInitialState,
  setTiktokTrackToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { useAppDispatch } from 'src/Hooks/redux'
import { setSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { CreateCampaignSteps } from '../CreateCampaignLayout/components/StepsWrapper'

export const usePlatformModal = (): {
  triggerCampaignDetailsEvents: (platform: CampaignPlatformTypeEnum) => void
  handleSubmitCampaignWithoutSignup: () => void
  arrowBackHandler: () => void
} => {
  const dispatch = useAppDispatch()
  const { setSearch } = useCustomSearchParams()

  const userId = useSelector(getUserId)
  const tikTokTrack = useSelector(getTikTokTrackSelector)
  const spotifyTrack = useSelector(getSpotifyTrackSelector)

  const goToFormRegister = (): void => {
    if (tikTokTrack) {
      setSearch({
        step: CreateCampaignSteps.REGISTER,
        detailsTiktok: tikTokTrack.trackTitle,
      })
    }

    if (spotifyTrack) {
      setSearch({
        step: CreateCampaignSteps.REGISTER,
        detailsSpotify: spotifyTrack.name,
      })
    }
  }

  const handleSubmitCampaignWithoutSignup = (): void => {
    goToFormRegister()
    spotifyTrack &&
      !userId &&
      firstCampaignDetailsEvent({
        campaignPlatform: CampaignPlatformTypeEnum.SPOTIFY,
        trackName: spotifyTrack.name,
        trackDuration: spotifyTrack.duration_ms,
        trackId: spotifyTrack.id,
        trackArtistName: spotifyTrack.artists[0].name,
      })

    tikTokTrack &&
      !userId &&
      firstCampaignDetailsEvent({
        campaignPlatform: CampaignPlatformTypeEnum.TIKTOK,
        trackName: tikTokTrack.trackTitle,
        trackDuration: tikTokTrack.trackDuration,
        trackId: tikTokTrack.trackTiktokId,
        trackArtistName: tikTokTrack.trackAuthorName,
      })

    viewSignUpPopUpEvent({
      platformName: handleDefinePlatform({
        isSpotifyTrack: Boolean(spotifyTrack),
        isTikTokTrack: Boolean(tikTokTrack),
      }),
    })
  }

  const triggerCampaignDetailsEvents = (
    platform: CampaignPlatformTypeEnum,
  ): void => {
    if (
      platform === CampaignPlatformTypeEnum.SPOTIFY &&
      !userId &&
      spotifyTrack
    ) {
      firstCampaignDetailsEvent({
        campaignPlatform: CampaignPlatformTypeEnum.SPOTIFY,
        trackName: spotifyTrack.name,
        trackDuration: spotifyTrack.duration_ms,
        trackId: spotifyTrack.id,
        trackArtistName: spotifyTrack.artists[0].name,
      })
    }

    if (
      platform === CampaignPlatformTypeEnum.TIKTOK &&
      !userId &&
      tikTokTrack
    ) {
      firstCampaignDetailsEvent({
        campaignPlatform: CampaignPlatformTypeEnum.TIKTOK,
        trackName: tikTokTrack.trackTitle,
        trackDuration: tikTokTrack.trackDuration,
        trackId: tikTokTrack.trackTiktokId,
        trackArtistName: tikTokTrack.trackAuthorName,
      })
    }

    viewSignUpPopUpEvent({
      platformName: platform,
    })
  }

  const arrowBackHandler = (): void => {
    dispatch(setSchedulePlatform(null))
    dispatch(setTracksToInitialState())
    dispatch(setTiktokTracksToInitialState())
    dispatch(setTrackToInitialState())
    dispatch(setTiktokTrackToInitialState())

    setSearch({ step: CreateCampaignSteps.PLATFORM_SELECT })
  }

  return {
    handleSubmitCampaignWithoutSignup,
    arrowBackHandler,
    triggerCampaignDetailsEvents,
  }
}
