import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const Card = styled.div`
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 4px;
`

export const Title = styled.div`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
  margin-bottom: 16px;
`

export const Value = styled.div`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize20};
  line-height: 30px;
  color: ${colors.mainText};
`
export const SubTitle = styled.div`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.green};
`
