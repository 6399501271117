import { FunctionComponent, ReactNode } from 'react'

import spotifyIcon from 'src/Assets/Png/spotify-icon.png'
import { ALBUM_IMAGES } from 'src/Constants/constants'
import { CampaignPlatformTypeEnum, SpotifyTrack } from 'src/Types'

import { CheckTrackFromSearchProps } from './CheckTrackFromSearchProps'
import { SearchResultTiktokItem } from './SearchResultTiktokItem'

import {
  SearchResults,
  SearchResultsItem,
  SearchResultsImg,
  SearchResultsText,
  SearchResultsTitle,
  SearchResultsDescription,
  TracksWrapper,
} from './styles'

const MAX_DISPLAYED_TRACKS = 4

const CheckTrackFromSearch: FunctionComponent<CheckTrackFromSearchProps> = ({
  platform,
  onCheckTrack,
  tracks = [],
}) => {
  if (tracks && tracks.length === 0) {
    return null
  }

  const tracksToReflect = (): ReactNode => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      return ((tracks as SpotifyTrack[]) || []).map((item) => (
        <SearchResultsItem
          key={item.id}
          onClick={() => onCheckTrack(item.href)}
        >
          <SearchResultsImg
            src={item.album.images[ALBUM_IMAGES]?.url}
            fallbackSrc={spotifyIcon}
            alt={item.name}
            width='40'
          />
          <SearchResultsText>
            <SearchResultsTitle>{item && item.name}</SearchResultsTitle>
            <SearchResultsDescription>
              {item && item.artists[0].name}
            </SearchResultsDescription>
          </SearchResultsText>
        </SearchResultsItem>
      ))
    }

    return (
      <SearchResultTiktokItem tracks={tracks} onCheckTrack={onCheckTrack} />
    )
  }

  const isSmallTracksContainer = tracks?.length
    ? tracks?.length < MAX_DISPLAYED_TRACKS
    : false

  return (
    <SearchResults>
      <TracksWrapper
        isSmallTracksContainer={isSmallTracksContainer}
        platform={platform}
      >
        {tracksToReflect()}
      </TracksWrapper>
    </SearchResults>
  )
}

export default CheckTrackFromSearch
