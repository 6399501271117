import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { ArtistBalanceItem, CampaignPlatformTypeEnum } from 'src/Types'

import { transformBalanceDetailsResponse } from './helpers'
export interface ArtistBalanceItemResponse {
  amount: string
  createdAt: string
  trackName: string
  campaignId?: number
  typeTransaction: string
  packageName?: string
  artistName: string
  platformType: CampaignPlatformTypeEnum
  referralData?: {
    firstName: string
    trackName: string
  }
  invitedData?: {
    firstName: string
  }
}

export const getArtistBalanceDetails = createAsyncThunk<ArtistBalanceItem[]>(
  `${NameSpace.ArtistBalanceHistory}/getArtistBalanceDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<ArtistBalanceItemResponse[]>(
        `${APP.TIKTOK_SERVER}/artist/balance/details`,
      )

      return transformBalanceDetailsResponse(data)
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
