/**
 * Renders the statistics for streams and listeners in a campaign.
 *
 * @component
 * @param {number} streams - The number of streams.
 * @param {number} listeners - The number of listeners.
 * @param {string} noDataText - The text to display when no data is available.
 * @returns {JSX.Element} The rendered StreamsListenersStats component.
 */
import { FC } from 'react'

import { numberFormatCommas } from 'src/Constants/functions'

import { DataNotReceivedContainer, DataNotReceivedLabel } from '../../../styles'
import { ReviewSubSectionText } from '../styles'

interface StreamsListenersStatsProps {
  streams?: number
  listeners?: number
  noDataText: string
}

export const StreamsListenersStats: FC<StreamsListenersStatsProps> = ({
  streams,
  listeners,
  noDataText,
}) => {
  if (!streams && !listeners) {
    return (
      <DataNotReceivedContainer>
        <DataNotReceivedLabel>{noDataText}</DataNotReceivedLabel>
      </DataNotReceivedContainer>
    )
  }

  return (
    <ReviewSubSectionText>
      {`${numberFormatCommas(streams)} / 
        ${numberFormatCommas(listeners)}`}
    </ReviewSubSectionText>
  )
}
