import { FC, useState } from 'react'

import { useMediaQuery } from 'react-responsive'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import ReactLoading from 'react-loading'

import SelectResponseRatingComponent from 'src/Containers/CampaignResults/components/SelectResponseRating'
import Button from 'src/Components/Buttons/Button'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { setTiktokCampaignEventRate } from 'src/Redux/campaignVideo-process/videoRate-process/api-actions'
import { getVideoRateLoading } from 'src/Redux/campaignVideo-process/videoRate-process/selectors'
import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { getVideo } from 'src/Redux/campaignVideo-process/video-process/selectors'

import { MIN_POSITIVE_RATE, RateFormState, rateSchema } from './helpers'

import {
  ButtonWrapper,
  FormWrapper,
  RateFormHeader,
  StyledTextArea,
  DateWrapper,
  RateFormHeaderContainer,
} from './styles'

const RATE_TIME = 72

export const RateForm: FC = () => {
  const { t } = useTranslation()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const loading = useAppSelector(getVideoRateLoading)
  const dispatch = useAppDispatch()
  const video = useAppSelector(getVideo)
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(true)
  const ratedDate = dayjs(video?.updatedAt || '')
    .add(RATE_TIME, 'hour')
    .tz(timezone)
    .format(formatDate.formatDateFirst2)

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm<RateFormState>({
    resolver: yupResolver(rateSchema),
    mode: 'onChange',
  })

  const rateValue = watch('rate')
  const placeholder =
    rateValue && rateValue <= MIN_POSITIVE_RATE
      ? t('campaignResultsPage.pleaseLetUs')
      : t('campaignResultsPage.pleaseShare')

  const handleSubmit = (): void => {
    const state = getValues()
    if (video?.id) {
      void dispatch(
        setTiktokCampaignEventRate({
          id: video?.id,
          feedback: state.comment,
          stars: state.rate,
        }),
      )
    }
  }
  const isMobile = useMediaQuery({ maxWidth: 576 })

  const handleTextAreaClick = (): void => {
    setPlaceholderVisible(false)
  }

  return (
    <>
      <RateFormHeader>
        <RateFormHeaderContainer>
          <DateWrapper>
            {t('campaignResultsPage.rateThisVideoTill')}&nbsp;
            {ratedDate}
          </DateWrapper>
        </RateFormHeaderContainer>
        <SelectResponseRatingComponent
          text={t('campaignResultsPage.rateThisVideo')}
          value={rateValue}
          onChange={(rate) => setValue('rate', rate, { shouldValidate: true })}
        />
      </RateFormHeader>
      {Boolean(rateValue) && (
        <FormWrapper>
          <Controller
            name='comment'
            control={control}
            render={({ field }) => (
              <StyledTextArea
                {...field}
                showCount
                maxLength={500}
                style={{ height: isMobile ? '200px' : '110px', resize: 'none' }}
                placeholder={isPlaceholderVisible ? placeholder : ''}
                onClick={handleTextAreaClick}
              />
            )}
          />
          <ButtonWrapper>
            <Button onClick={handleSubmit} disabled={!isValid} type='green'>
              {loading ? (
                <ReactLoading width={'25px'} height={'25px'} type='spin' />
              ) : (
                t('reviewsPage.confirm')
              )}
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      )}
    </>
  )
}
