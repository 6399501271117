import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { IVideoProcess } from '.'

const getState = (state: RootState): IVideoProcess => state.campaignVideo.video

export const getIsVideoLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getVideo = createDraftSafeSelector(getState, ({ video }) => video)
