import {
  reviewRequestSeenEvent,
  reviewRequestSeenNotAvailableEvent,
  reviewSkippedEvent,
} from 'src/Helpers/TagManager'
import { ID, CampaignPlatformTypeEnum, FeedDetails } from 'src/Types/index'

export const onReviewSkippedEvent = (
  campaignId: string,
  userId?: ID | null,
): void => {
  if (campaignId && userId) {
    reviewSkippedEvent(
      campaignId,
      new Date().getTime(),
      CampaignPlatformTypeEnum.SPOTIFY,
      0,
      String(userId),
      true,
    )
  }
}

export const onGetFeedEvent = (
  response: FeedDetails,
  campaignID: number,
  userId: ID,
): void => {
  if (!response.isReserved) {
    reviewRequestSeenEvent({
      campaignID,
      userId: String(userId),
      requestId: new Date().getTime(),
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      is_feed: true,
      reviewRequestType: response.review_status,
    })
    return
  }
  reviewRequestSeenNotAvailableEvent(
    CampaignPlatformTypeEnum.SPOTIFY,
    campaignID,
    String(userId),
    new Date().getTime(),
    true,
  )
}
