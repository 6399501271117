import { configureStore, PreloadedState } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { rtkApi } from 'src/Services/rtkApi'

import rootReducer from './root-reducer'
import { redirect } from './middlewares/redirect'
import { sendTikTokTrackEvents } from './middlewares/sendTikTokTrackEvents'
import { sendSpotifyTrackEvents } from './middlewares/sendSpotifyTrackEvents'
import { enterTrackMiddleware } from './middlewares/enterTrackEvents'
import { sentToReviewMiddleware } from './middlewares/sendToReviewEvent'
import { paymentTransform } from './payment-process/paymentTransform'

const persistConfig = {
  storage,
  key: 'root',
  whitelist: [
    'sessionNotifications',
    'influencer',
    'alerts',
    'sourcebuster',
    'canceledAlerts',
    'getPremium',
    'sidebar',
    'typeform',
    'payment',
  ],
  transforms: [paymentTransform],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        rtkApi.middleware,
        redirect,
        sendTikTokTrackEvents,
        sendSpotifyTrackEvents,
        enterTrackMiddleware,
        sentToReviewMiddleware,
      ),
    preloadedState,
  })

  const persistor = persistStore(store)

  return { store, persistor }
}

export type RootState = ReturnType<typeof persistedReducer>
export type AppStore = ReturnType<typeof setupStore>
