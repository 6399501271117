import { FC } from 'react'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getFilteredAndSortedLanguages } from 'src/Redux/languages-process/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getPlaylistById } from 'src/Redux/playlists-process/selectors'
import { getIsGenresLoading } from 'src/Redux/genres-process/selectors'
import {
  changePlaylistInformation,
  getPlaylists,
} from 'src/Redux/playlists-process/api-actions'
import { Genre, Language } from 'src/Types'
import { ApiError } from 'src/Redux/types'
import { getCuratorRank } from 'src/Redux/auth-process/userSlice/api-actions'

import { useEditPlaylist } from './useEditPlaylist'

import PlaylistEditModal from '.'

export const PlaylistEditModalWrapper: FC = () => {
  const dispatch = useAppDispatch()
  const { search, setSearch } = useCustomSearchParams()
  const playlistId = Number(search.get('editPlaylist'))
  const isNew = search.get('new')
  const playlist = useAppSelector(getPlaylistById(playlistId))
  const isGenresLoading = useAppSelector(getIsGenresLoading)
  const languages = useAppSelector(getFilteredAndSortedLanguages)
  const editablePlaylist = useEditPlaylist()

  if (!playlist || !playlistId) {
    return null
  }
  const handleInformationSave = async (
    id: number,
    genres: Array<Genre>,
    playlistLanguages: Array<Language>,
    moods: Array<number>,
    callback: (error: ApiError | null) => void,
  ): Promise<void> => {
    try {
      await dispatch(
        changePlaylistInformation({
          playlistID: id,
          genres: genres.map((item) => item.id) as number[],
          languages: playlistLanguages.map((item) => item.id) as number[],
          moods,
        }),
      ).unwrap()
      void dispatch(getCuratorRank())
      void dispatch(getPlaylists())
      callback(null)
    } catch (error) {
      callback(error as ApiError)
    }
  }
  const handleClose = (): void => {
    setSearch({ editPlaylist: null, new: null })
  }
  return (
    <PlaylistEditModal
      handleSave={handleInformationSave}
      isOpen={Boolean(playlistId)}
      item={editablePlaylist}
      loading={isGenresLoading}
      toggle={handleClose}
      languages={languages}
      isNew={Boolean(isNew)}
    />
  )
}
