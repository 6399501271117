import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const ReviewsOrVideos = styled.div`
  text-transform: lowercase;
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
`

export const NewVideo = styled.div`
  padding: 4px;
  color: ${colors.green};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  background-color: ${colors.green1Light};
  max-width: 57px;
`
