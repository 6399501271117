import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const Wrapper = styled.div`
  padding: 9.5px 16px;
  font-size: 10px;
  gap: 2px;
  text-align: center;
  background-color: ${colors.green};
  color: ${colors.white};
  z-index: 0;
  line-height: 15px;
  @media ${device.mobileMax} {
    padding: 8px 24px;
  }
`

export const ContactButton = styled.span`
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
`
