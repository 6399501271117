import { FunctionComponent, useCallback, useMemo } from 'react'

import { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'src/Components/Buttons/Button'
import { SmartImage } from 'src/Components/SmartImage'
import { calculateDaysLeft } from 'src/Constants/functions'
import { StatusTextEnum, useStatusView } from 'src/Containers/Tracks/helpers'
import dayjs from 'src/Helpers/dayjs'
import { creatorReviewAvailableSongs } from 'src/Helpers/TagManager'
import { setID } from 'src/Redux/influencer-process'
import track from 'src/Assets/Png/track.png'
import { colors } from 'src/Styled/variables'
import { InfluencerEvent, InfluencerEventStatus } from 'src/Types/index'
import {
  getInfluencerId,
  getUser,
} from 'src/Redux/auth-process/userSlice/selectors'
import { formatDate } from 'src/Constants/formatDate'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { FIXED_BY } from 'src/Constants/constants'

import {
  ArtistName,
  CampaignProgress,
  DateProgress,
  DaysValue,
  DetailsButton,
  ListItemContainer,
  PaidContainer,
  Reward,
  Status,
  StatusWrapper,
  TrackCoverWrapper,
  TrackInfo,
  TrackName,
} from './styles'

type TracksListProps = {
  event: InfluencerEvent
  id: number
  onDetailsClick: (event: InfluencerEvent) => void
}
const PERIOD_TO_ACCEPT = 7
const PERIOD_OF_UPLOAD = 3
const PERIOD_OF_GREEN_STATUS = 5
const PERIOD_OF_YELLOW_STATUS = 2
const HUNDRED_PERCENT = 100

export const ListItem: FunctionComponent<TracksListProps> = ({
  id,
  event,
  onDetailsClick,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userId = useSelector(getInfluencerId)
  const creator = useSelector(getUser)

  const daysLeft = useMemo(() => {
    if (event.status === InfluencerEventStatus.NEW) {
      return calculateDaysLeft(new Date(event.deadlineToAccept))
    }
    if (event.status === InfluencerEventStatus.ACCEPTED) {
      return calculateDaysLeft(new Date(event.uploadVideoDeadline))
    }

    return 0
  }, [event])

  const { text: statusText, color } = useStatusView(event?.status)

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const startDateTz = dayjs(event?.startDate).tz(timezone)
  const createdDateTz = dayjs(event?.createdAt).tz(timezone)

  const getEndDateTz = (): Dayjs => {
    if (event?.status === InfluencerEventStatus.ACCEPTED) {
      return dayjs(event?.deadlineToLoadVideo).tz(timezone)
    }

    if (event?.status === InfluencerEventStatus.AWAITING_REVIEW) {
      return dayjs(event?.videoUploadDate)
        .tz(timezone)
        .add(PERIOD_OF_UPLOAD, 'd')
    }

    return dayjs(event?.deadlineToAccept).tz(timezone)
  }

  const campaignDaysLength = getEndDateTz().diff(startDateTz, 'days')
  const campaignVideoUploadDaysLength = getEndDateTz().diff(
    createdDateTz,
    'days',
  )

  const willBeReceivedData = getValueOrEmptyString(
    dayjs(event?.paymentWillBeReceived)
      .tz(timezone)
      .format(formatDate.formatDate1),
  )

  const handleDetailsClick = useCallback(() => {
    onDetailsClick(event)

    creatorReviewAvailableSongs({
      userID: userId,
      reviewId: event.id,
      campaignId: event.id,
      trackId: event.trackTiktokId,
    })
    dispatch(setID(id))
  }, [id, onDetailsClick, dispatch, userId, event])

  const campaignProgressPercentHandler = useCallback(
    (status: string) => {
      if (status === StatusTextEnum.IN_PROGRESS && daysLeft) {
        return (daysLeft / campaignVideoUploadDaysLength) * HUNDRED_PERCENT
      }

      if (status === StatusTextEnum.AWAITING_REVIEW && daysLeft) {
        return (daysLeft / PERIOD_OF_UPLOAD) * HUNDRED_PERCENT
      }
      return (daysLeft / campaignDaysLength) * HUNDRED_PERCENT
    },
    [daysLeft, campaignVideoUploadDaysLength, campaignDaysLength],
  )

  const stepsShowHandler = useCallback((status: string) => {
    if (status === StatusTextEnum.NEW_TRACK) {
      return PERIOD_TO_ACCEPT
    }

    if (
      status === StatusTextEnum.IN_PROGRESS ||
      status === StatusTextEnum.AWAITING_REVIEW
    ) {
      return PERIOD_OF_UPLOAD
    }

    return 0
  }, [])

  const strokeColorHandler = useCallback(
    (status: string, daysLeftCurrent: number) => {
      if (status === StatusTextEnum.NEW_TRACK) {
        return daysLeftCurrent >= PERIOD_OF_GREEN_STATUS
          ? colors.green
          : colors.messegesYellow1
      }

      if (
        status === StatusTextEnum.IN_PROGRESS ||
        status === StatusTextEnum.AWAITING_REVIEW
      ) {
        return daysLeftCurrent <= PERIOD_OF_YELLOW_STATUS
          ? colors.messegesYellow1
          : colors.green
      }

      return colors.mainText
    },
    [],
  )

  const isDayLeftAvailable = [
    StatusTextEnum.NEW_TRACK,
    StatusTextEnum.IN_PROGRESS,
    StatusTextEnum.AWAITING_REVIEW,
  ].includes(statusText as StatusTextEnum)

  const isAcceptedEvent = event.status === InfluencerEventStatus.ACCEPTED

  return (
    <ListItemContainer>
      <TrackCoverWrapper>
        <SmartImage
          src={event?.trackCoverUrl}
          alt='Track cover'
          fallbackSrc={track}
        />
      </TrackCoverWrapper>
      <TrackInfo status={statusText}>
        <TrackName>{getValueOrEmptyString(event?.trackTitle)}</TrackName>
        <ArtistName>{getValueOrEmptyString(event?.trackAuthorName)}</ArtistName>
      </TrackInfo>
      {isDayLeftAvailable && (
        <CampaignProgress>
          <DaysValue>{`${daysLeft} days left`}</DaysValue>
          <DateProgress
            percent={campaignProgressPercentHandler(statusText)}
            steps={stepsShowHandler(statusText)}
            strokeWidth={4}
            showInfo={false}
            strokeColor={strokeColorHandler(statusText, daysLeft)}
            trailColor={colors.greyTextBlackBg}
            status='active'
          />
        </CampaignProgress>
      )}
      <StatusWrapper>
        <Status $color={color} status={statusText}>
          <div>{statusText}</div>
        </Status>
        {Boolean(event?.reward) && (
          <Reward>{`$${event?.reward.toFixed(FIXED_BY)}`}</Reward>
        )}
        {statusText === StatusTextEnum.COMPLETE && (
          <div>
            {event?.isPaymentReceived ? (
              <PaidContainer>{t('influencer.rewardReceived')} </PaidContainer>
            ) : (
              <PaidContainer>
                {t('influencer.willBeReceived')}&nbsp;
                {event?.paymentWillBeReceived && willBeReceivedData}
              </PaidContainer>
            )}
          </div>
        )}
        {statusText === StatusTextEnum.CANCELED && (
          <PaidContainer>{t('influencer.rewardNotReceived')}</PaidContainer>
        )}
      </StatusWrapper>
      <DetailsButton>
        <Button
          type={isAcceptedEvent ? 'green' : 'whiteWithGreenBorder'}
          onClick={handleDetailsClick}
          disabled={isAcceptedEvent && creator.isBlocked}
        >
          {isAcceptedEvent ? t('influencer.upload') : t('influencer.details')}
        </Button>
      </DetailsButton>
    </ListItemContainer>
  )
}
