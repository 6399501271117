import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'
import Button from 'src/Components/Buttons/Button'
import { colors, variables } from 'src/Styled/variables'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import { Container, ListContainer } from '../../styles'
import { TextList } from '../TextList'
import { EarningRange } from '../EarningRange'

export const SubTitle = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const DescriptionText = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 150%;
`

export const StyledButton = styled(Button)`
  margin-top: 20px;
  align-self: center;
  width: 160px;
`
export const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
`
export const EarningBlock: FC = () => {
  const { t } = useTranslation()
  const { isError: isMonetizationError } = useGetMonetizationDataQuery()

  const { data } = useGetMonetizationDataQuery()

  const formattedMinViews = addSpaceToNumber(data?.minViews || 0, ',')
  const formattedMaxCreatorReward = `$${data?.maxCreatorReward.toFixed(2) || 0}`

  const listItems = [
    t('monetization.youPersonalized'),
    t('monetization.yourFinalRewardDetermined'),
  ]

  if (isMonetizationError) {
    return <ProblemsWithTikTokErrorAlert />
  }

  return (
    <Container greenBoarder>
      <ListContainer>
        <SubTitle>{t('monetization.howAreMyEarnings')}</SubTitle>
        <TextList listItems={listItems} />
      </ListContainer>

      <RangeContainer>
        <div>
          <SubTitle>{t('monetization.currentEarningRange')}</SubTitle>
          <DescriptionText>
            {t('monetization.startEarningDescription', {
              formattedMinViews,
              formattedMaxCreatorReward,
            })}
          </DescriptionText>
        </div>
        <EarningRange />
        <StyledButton type={'green'}>
          {t('monetization.startEarning')}
        </StyledButton>
      </RangeContainer>
    </Container>
  )
}
