import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { OrderPayoutState } from '.'

const getState = (state: RootState): OrderPayoutState =>
  state.transactions.orderPayout

export const getIsPayoutLoading = createDraftSafeSelector(
  getState,
  (state) => state.status === SliceStatus.Loading,
)

export const getPayoutError = createDraftSafeSelector(
  getState,
  (state) => state.error,
)
