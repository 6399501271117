import { FC, PropsWithChildren, useEffect } from 'react'

import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { getPlaylists } from 'src/Redux/playlists-process/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getHasPlaylists,
  getPlaylistsLoading,
} from 'src/Redux/playlists-process/selectors'

import { AddPlaylistMessage } from './AddPlaylistMessage'

export const EmptyListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`

export const EmptyListPlaceholder = styled.p`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`

export const EmptyTrackList: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const hasPlaylists = useAppSelector(getHasPlaylists)
  const loading = useAppSelector(getPlaylistsLoading)

  useEffect(() => {
    void dispatch(getPlaylists())
  }, [dispatch])

  if (!hasPlaylists && !loading) {
    return <AddPlaylistMessage />
  }
  return (
    <EmptyListContainer>
      <EmptyListPlaceholder>{children}</EmptyListPlaceholder>
    </EmptyListContainer>
  )
}
