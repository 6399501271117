import { useMemo } from 'react'

import { useSelector } from 'react-redux'

import { CampaignPlatformTypeEnum } from 'src/Types/index'
import { submitStripePayment } from 'src/Redux/payment-process/api-actions'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import {
  getArtistBalance,
  getCountryName,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { setPaymentAlertError } from 'src/Redux/payment-process'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getDefaultStartDate } from 'src/Constants/functions'

import { useAppDispatch, useAppSelector } from './redux'
import { usePayment } from './usePayment'

interface UseStripePayment {
  pay: () => void
}

const FIXED_BY = 2

export const useStripePayment = (
  platformType: CampaignPlatformTypeEnum,
): UseStripePayment => {
  const dispatch = useAppDispatch()
  const { calculatePaymentAmounts } = usePayment()

  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)
  const country = useAppSelector(getCountryName)
  const coupon = useAppSelector(getCoupon)
  const balance = useAppSelector(getArtistBalance)
  const scheduledCampaign = useSelector(getScheduledTikTokCampaign)
  const price = useAppSelector(getTiktokPrice)

  const campaignToPay = useMemo(() => {
    let campaign

    if (platformType === CampaignPlatformTypeEnum.SPOTIFY) {
      campaign = {
        price: spotifyCampaignDetails.price,
        genres: spotifyCampaignDetails.genres,
        languages: spotifyCampaignDetails.languages,
        id: spotifyCampaignDetails.id,
        startDate: spotifyCampaignDetails.start_date,
      }
    } else {
      campaign = {
        price,
        genres: scheduledCampaign.genres,
        languages: scheduledCampaign.languages,
        id: scheduledCampaign.id,
        startDate: scheduledCampaign.startDate,
      }
    }

    return campaign
  }, [platformType, price, scheduledCampaign, spotifyCampaignDetails])

  const pay = (): void => {
    const currentPaymentAmounts = calculatePaymentAmounts({
      price: campaignToPay.price,
      balance,
      coupon,
      country,
    })
    currentPaymentAmounts &&
      void dispatch(
        submitStripePayment({
          amount: currentPaymentAmounts.total,
          campaignValue: Number(campaignToPay.price.toFixed(FIXED_BY)),
          campaignId: Number(campaignToPay.id),
          startDate:
            campaignToPay.startDate?.toString() || getDefaultStartDate(),
          couponValue: coupon?.value,
          type: platformType,
          usedBalance: currentPaymentAmounts.balanceUseAmount,
          isMedianTest: true,
        }),
      )
        .unwrap()
        .then((res) => {
          window.location.href = res.link
          return res
        })
        .catch(() => {
          dispatch(setPaymentAlertError(PaymentErrors.DEFAULT))
        })
  }

  return {
    pay,
  }
}
