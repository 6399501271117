import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { Meta } from 'src/Types'
import { SoundStatus } from 'src/Containers/SoundsPage/components/SoundsList'

import { Sound } from '../types'

export interface SubmittedSound extends Sound {
  status: SoundStatus
  id: number
  currentViews: number
  reward: number
  trackTiktokId: string
}

interface SubmittedResponse {
  data: SubmittedSound[]
  meta: Meta
}

interface UploadVideoData {
  campaignId: number
  paidAmount: number
  trackId: string
}

interface UploadVideoBody {
  videoTiktokUrl: string
  eventId: number
}

const creatorSubmittedSoundsApi = rtkApi.injectEndpoints({
  endpoints(build) {
    const pageSize = 10

    const statuses = [
      'submission_awaiting',
      'submission_rejected',
      'approved_by_artist',
      'approved_automatically',
      'awaiting_moderator_review',
      'approved_by_admin',
      'declined',
      'submission_rejected',
    ]

    const statusesQuery = statuses
      .map((status) => `statuses=${status}`)
      .join('&')

    return {
      submittedSounds: build.query<SubmittedResponse, number>({
        query: (pageNumber) => ({
          url: `${APP.TIKTOK_SERVER}/creators/events?pageSize=${pageSize}&pageNumber=${pageNumber}&${statusesQuery}`,
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems) => ({
          data: [...currentCache.data, ...newItems.data],
          meta: newItems.meta,
        }),
      }),
      submitVideo: build.mutation<UploadVideoData, UploadVideoBody>({
        query: ({ videoTiktokUrl, eventId }) => ({
          url: `${APP.TIKTOK_SERVER}/influencer/tiktok/upload-video/${eventId}`,
          method: 'PUT',
          body: {
            videoTiktokUrl,
          },
        }),
      }),
    }
  },
})

export const selectSubmittedSoundsCount = ({
  data,
}: {
  data?: SubmittedResponse
}): { submittedSoundsCount: number } => ({
  submittedSoundsCount: data?.meta.itemCount || 0,
})

export const { useSubmittedSoundsQuery, useSubmitVideoMutation } =
  creatorSubmittedSoundsApi
