import React, { FC } from 'react'

import { t } from 'i18next'

import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'
import { useAppSelector } from 'src/Hooks/redux'
import { getVideo } from 'src/Redux/campaignVideo-process/video-process/selectors'
import { VideoStatus } from 'src/Constants/enums'

import { RateDateContainer, RateDate } from './styles'

const THREE_DAYS = 72

export const RateFeedBackDate: FC = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const video = useAppSelector(getVideo)

  if (video?.status !== VideoStatus.APPROVED_AUTOMATICALLY) {
    const ratedDate = dayjs(video?.videoUploadDate || '')
      .tz(timezone)
      .format(formatDate.formatDateFirst2)
    return (
      <RateDateContainer>
        <div>{t('campaignResultsPage.videoRatedOn')}</div>
        <RateDate>{ratedDate}</RateDate>
      </RateDateContainer>
    )
  }
  const approvedAtDate = dayjs(video?.videoUploadDate || '')
    .add(THREE_DAYS, 'hour')
    .tz(timezone)
    .format(formatDate.formatDateFirst2)

  return (
    <RateDateContainer>
      <div>{t('campaignResultsPage.ratingDeadlineWas')}</div>
      <RateDate>{approvedAtDate}</RateDate>
    </RateDateContainer>
  )
}
