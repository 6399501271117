import { FC } from 'react'

import styled from 'styled-components'

import { CampaignsFilters } from 'src/Types'

import { CreateCampaignButtons } from './CreateCampaignButtons'
import { PlatformFilter } from './PlatformFilter'
import { SearchCampaign } from './SearchCampaign'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
  @media screen and (min-width: 1080px) {
    padding: 24px 24px 0;
  }
`
const ActionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
export interface CampaignsListTableActionProps {
  filters: CampaignsFilters
  setFilters: (filters: CampaignsFilters) => void
  search: string
  setSearch: (value: string) => void
}

export const CampaignsListTableAction: FC<CampaignsListTableActionProps> = ({
  filters,
  search,
  setSearch,
  setFilters,
}) => (
  <Wrapper>
    <CreateCampaignButtons />
    <ActionContainer>
      <PlatformFilter filters={filters} setFilters={setFilters} />
      <SearchCampaign search={search} setSearch={setSearch} />
    </ActionContainer>
  </Wrapper>
)
