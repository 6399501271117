import { useCallback } from 'react'

import { useMediaQuery } from 'react-responsive'

import dayJs from 'src/Helpers/dayjs'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getSoundLinkHint, setSoundLinkHint } from 'src/Redux/alert-process'
import { getEndedCompaniesAfterDate } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { getSidebar } from 'src/Redux/sidebar-process/selectors'

interface Hint {
  isOpen: boolean
  close: () => void
  setIsOpen: () => void
}

export const useHint = (): Hint => {
  const dispatch = useAppDispatch()
  const sidebar = useAppSelector(getSidebar)
  const soundLinkHintObject = useAppSelector(getSoundLinkHint)
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const isThereEndedCompanies = useAppSelector(
    getEndedCompaniesAfterDate(soundLinkHintObject?.closedDate),
  )

  const close = useCallback(() => {
    dispatch(
      setSoundLinkHint({
        closedDate: dayJs().add(1, 'day').toISOString(),
        closedState: true,
      }),
    )
  }, [dispatch])

  const setIsOpen = useCallback(() => {
    dispatch(
      setSoundLinkHint({
        closedDate: dayJs().add(1, 'day').toISOString(),
        closedState: false,
      }),
    )
  }, [dispatch])

  const show =
    !soundLinkHintObject ||
    !soundLinkHintObject?.closedState ||
    isThereEndedCompanies

  const shouldBeOpen = show && (sidebar?.show ? isMobile : !isMobile)

  return {
    isOpen: shouldBeOpen,
    close,
    setIsOpen,
  }
}
