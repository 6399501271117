import { FC } from 'react'

import styled from 'styled-components'

import CompleteIcon from 'src/Assets/Svg/checkMark.svg?react'
import ActiveIcon from 'src/Assets/Svg/arrow-right.svg?react'

export enum StepButtonStatus {
  active = 'active',
  completed = 'completed',
  disabled = 'disabled',
}

interface StepButtonProps {
  status: StepButtonStatus
  onClick: () => void
  children: React.ReactNode
}

const MAP_STATUS_TO_ICON: { [key in StepButtonStatus]: FC | null } = {
  [StepButtonStatus.active]: ActiveIcon,
  [StepButtonStatus.completed]: CompleteIcon,
  [StepButtonStatus.disabled]: null,
}

const StyledButton = styled.button<StepButtonProps>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: start;
  padding-left: 32px;
  background-color: transparent;
  cursor: pointer;
  color: ${({ status }) =>
    status === StepButtonStatus.active ? '#1CB954' : '#646877'};
  position: relative;
`

const IconWrapper = styled.div<{ status: StepButtonStatus }>`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ScheduleTrackStepButton: FC<StepButtonProps> = ({
  status,
  onClick,
  children,
}) => {
  const Icon = MAP_STATUS_TO_ICON[status]
  return (
    <StyledButton status={status} onClick={onClick}>
      {Icon && (
        <IconWrapper status={status}>
          <Icon />
        </IconWrapper>
      )}
      {children}
    </StyledButton>
  )
}
