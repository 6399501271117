import { useNavigate } from 'react-router-dom'

import {
  CampaignPlatformTypeEnum,
  CampaignTypeEnum,
  SpotifyCampaign,
  TiktokCampaign,
} from 'src/Types/index'
import { setPlatform } from 'src/Redux/campaign-process/mainOldProcessSlice'
import { activeTabStorage } from 'src/Constants/constants'
import {
  campaignsList,
  scheduleCampaign,
  scheduleCampaignTikTok,
} from 'src/Router/routes'
import { getCampaignStatus } from 'src/Redux/campaign-process/mainOldProcessSlice/api-actions'

import { useAppDispatch } from './redux'
import { useScheduleEvents } from './useScheduleEvents'

interface UseOpenCampaignScheduleI {
  activeTab: string
  campaign: SpotifyCampaign | TiktokCampaign
}

export const useOpenCampaignSchedule = ({
  activeTab,
  campaign,
}: UseOpenCampaignScheduleI): {
  handleScheduleCampaign: () => void
} => {
  const { platformType } = campaign
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { handleScheduleStartEvent } = useScheduleEvents()

  const storeActiveTab = (): void => {
    window.sessionStorage.setItem(activeTabStorage, activeTab)
  }

  const handleSpotifyScheduleStartEvent = (campaignID: number): void => {
    void dispatch(getCampaignStatus({ campaignId: campaignID }))
      .unwrap()
      .then((response) => {
        handleScheduleStartEvent({
          campaignType: response.campaign.type,
          platformName: CampaignPlatformTypeEnum.SPOTIFY,
          campaignId: response.campaign.id,
          genreList:
            response.campaign?.genres
              .map((genre: { name: string }) => genre.name)
              .join() || '',
          languageList:
            response.campaign?.languages
              .map((language: { name: string }) => language.name)
              .join() || '',
        })
        return response
      })
      .catch(() => {
        navigate(campaignsList)
      })
  }

  const handleScheduleCampaign = (): void => {
    storeActiveTab()

    platformType && dispatch(setPlatform(platformType))

    platformType === CampaignPlatformTypeEnum.SPOTIFY &&
      handleSpotifyScheduleStartEvent(Number(campaign.id))

    platformType === CampaignPlatformTypeEnum.TIKTOK &&
      handleScheduleStartEvent({
        campaignType: campaign.type || CampaignTypeEnum.BASIC,
        platformName: CampaignPlatformTypeEnum.TIKTOK,
        campaignId: Number(campaign.id),
      })

    const schedule =
      platformType === CampaignPlatformTypeEnum.SPOTIFY
        ? scheduleCampaign
        : scheduleCampaignTikTok
    navigate(`${campaignsList}${schedule}/${campaign.id}`)

    window.scrollTo(0, 0)
  }

  return {
    handleScheduleCampaign,
  }
}
