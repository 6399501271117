import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

interface Props {
  promocode?: boolean
  header?: boolean
  hint?: boolean
}

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  pointer-events: auto;
`

export const ModalHeader = styled.div<Props>`
  background-color: ${(props: Props) =>
    props.promocode ? colors.white : colors.green};

  padding: ${(props: Props) =>
    props.promocode ? '24px 12px 24px 24px' : '16.5px 0 16.5px 24px'};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props: Props) =>
    props.promocode ? colors.mainText : colors.white};
  border-bottom: transparent;
`

export const ContentContainer = styled.div<Props>`
  background-color: ${colors.white};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: ${(props: Props) =>
    props.header && !props.hint ? '0px' : '8px'};
  border-top-right-radius: ${(props: Props) =>
    props.header && !props.hint ? '0px' : '8px'};
`
export const PromocodeButtonClose = styled.div`
  top: 16px;
`
export const ModalTitle = styled.span`
  font-size: ${variables.fontSize18};
  line-height: 27px;
  font-weight: ${variables.fontWeight400};
`
export const ModalCloseButton = styled.button<Props>`
  background-color: transparent;
  border: none;
  width: ${(props: Props) => (props.promocode ? '36px' : '60px')};
  display: ${(props: Props) => props.promocode && 'grid'};
  align-self: ${(props: Props) => props.promocode && 'start'};
  margin-top: ${(props: Props) => props.promocode && '-4px'};
`
