import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'
import { CampaignTypeEnum } from 'src/Types/index'

import { DateWrapper } from './styles'

interface CampaignStoppedProps {
  type: CampaignTypeEnum
  endDate?: string | Date
  startDate?: string | Date
}

export const CampaignStopped: FC<CampaignStoppedProps> = ({
  endDate,
  startDate,
}) => {
  const { t } = useTranslation()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return (
    <>
      {Boolean(startDate) && Boolean(endDate) ? (
        <DateWrapper>
          {dayjs(startDate).tz(timezone).format(formatDate.formatDateMonthFull)}
          &nbsp;-&nbsp;
          {dayjs(endDate).tz(timezone).format(formatDate.formatDateMonthFull)}
        </DateWrapper>
      ) : (
        <DateWrapper>{t('campaignsPage.stoppedByAdmin')}</DateWrapper>
      )}
    </>
  )
}
