import { createSlice } from '@reduxjs/toolkit'

import { CampaignVideos, InfluencerEventStatus, Meta } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'

import {
  getAllCampaignTikTokVideos,
  getCampaignTikTokVideosStats,
  getAwaitingReviewCampaignTikTokVideos,
} from './api-actions'

export interface CampaignVideosState {
  allVideos: {
    videos: CampaignVideos
    meta: Meta | null
  }
  awaitingReviewVideos: {
    videos: CampaignVideos
    meta: Meta | null
  }
  videosCount: Array<{
    status: InfluencerEventStatus
    count: number
  }>
  status: SliceStatus
  error: ApiError | null
}

const initialState: CampaignVideosState = {
  allVideos: {
    videos: [],
    meta: null,
  },
  awaitingReviewVideos: {
    videos: [],
    meta: null,
  },
  status: SliceStatus.Idle,
  error: null,
  videosCount: [],
}
export const campaignVideosProcess = createSlice({
  initialState,
  name: NameSpace.CampaignVideos,
  reducers: {},
  extraReducers: (builder) => {
    // getAllCampaignTikTokVideos
    builder.addCase(getAllCampaignTikTokVideos.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(
      getAllCampaignTikTokVideos.fulfilled,
      (state, { payload }) => {
        state.status = SliceStatus.Succeeded

        if (payload.meta.pageNumber === 1) {
          state.allVideos.videos = payload.data
          state.allVideos.meta = payload.meta
        } else {
          state.allVideos.videos = [...state.allVideos.videos, ...payload.data]
          state.allVideos.meta = payload.meta
        }
      },
    )
    builder.addCase(getAllCampaignTikTokVideos.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // getAwaitingReviewCampaignTikTokVideos
    builder.addCase(getAwaitingReviewCampaignTikTokVideos.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(
      getAwaitingReviewCampaignTikTokVideos.fulfilled,
      (state, { payload }) => {
        state.status = SliceStatus.Succeeded

        if (payload.meta.pageNumber === 1) {
          state.awaitingReviewVideos.videos = payload.data
          state.awaitingReviewVideos.meta = payload.meta
        } else {
          state.awaitingReviewVideos.videos = [
            ...state.awaitingReviewVideos.videos,
            ...payload.data,
          ]
          state.awaitingReviewVideos.meta = payload.meta
        }
      },
    )
    builder.addCase(
      getAwaitingReviewCampaignTikTokVideos.rejected,
      (state, action) => {
        state.status = SliceStatus.Failed
        state.error = setApiError(action)
      },
    )

    // getCampaignTikTokVideosStats
    builder.addCase(getCampaignTikTokVideosStats.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(
      getCampaignTikTokVideosStats.fulfilled,
      (state, { payload }) => {
        state.videosCount = payload.statuses
        state.status = SliceStatus.Succeeded
      },
    )
    builder.addCase(getCampaignTikTokVideosStats.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
