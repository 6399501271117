import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'
import { RootState } from '..'

import {
  SourceBusterCollectedData,
  collectDataFromSourceBuster,
  sendSourceBusterEvent,
} from './api-actions'

interface SourceBusterState {
  status: SliceStatus
  data: SourceBusterCollectedData | null
  error: ApiError | null
}

const initialState: SourceBusterState = {
  status: SliceStatus.Idle,
  error: null,
  data: null,
}

export const sourceBusterProcess = createSlice({
  initialState,
  name: NameSpace.SourceBusterEvent,
  reducers: {
    saveSourceBusterData(state) {
      state.data = collectDataFromSourceBuster()
    },
  },
  extraReducers(builder) {
    builder.addCase(sendSourceBusterEvent.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(sendSourceBusterEvent.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
      state.data = null
      state.error = null
    })
    builder.addCase(sendSourceBusterEvent.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
const getState = (state: RootState): SourceBusterState => state.sourcebuster

export const getSourceBusterData = createDraftSafeSelector(
  getState,
  (state) => state.data,
)

export const { saveSourceBusterData } = sourceBusterProcess.actions
