import { object as yupObject, string as yupString } from 'yup'

import i18n from 'src/Localization/i18n'

export interface FirstFeedbackFormValues {
  resource: string
  other?: string
}

interface Value {
  radioValue: string
  radioLabel: string
  inputPlaceholder?: string
  rows?: number
  commentLength?: number
}

interface FirstFeedbackFormList {
  label: string
  values: Value[]
}

export const OTHER_RESOURCES = 'Other resources - Other'
export const RECOMMENDATIONS_INSTAGRAM =
  'Influencers` recommendations - Instagram'
export const RECOMMENDATIONS_TIKTOK = 'Influencers` recommendations - TikTok'
export const RECOMMENDATIONS_YOUTUBE = 'Influencers` recommendations - YouTube'

const VALUES = [
  OTHER_RESOURCES,
  RECOMMENDATIONS_INSTAGRAM,
  RECOMMENDATIONS_TIKTOK,
  RECOMMENDATIONS_YOUTUBE,
]
const specifyRecommendations = i18n.t('feedBackPage.specifyOptional')
const specify = i18n.t('feedBackPage.specify')
export const FIRST_FEEDBACK_FORM_LIST: FirstFeedbackFormList[] = [
  {
    label: i18n.t('feedBackPage.advertising'),
    values: [
      {
        radioLabel: i18n.t('feedBackPage.instagram'),
        radioValue: 'Advertising - Instagram',
      },
      {
        radioLabel: i18n.t('feedBackPage.google'),
        radioValue: 'Advertising - Google',
      },
      {
        radioLabel: i18n.t('feedBackPage.facebook'),
        radioValue: 'Advertising - Facebook',
      },
    ],
  },
  {
    label: i18n.t('feedBackPage.googleSearch'),
    values: [
      {
        radioLabel: i18n.t('feedBackPage.sponsoredResults'),
        radioValue: 'Google search - Sponsored results',
      },
      {
        radioLabel: i18n.t('feedBackPage.nonSponsoredResults'),
        radioValue: 'Google search - Non-sponsored results',
      },
    ],
  },
  {
    label: i18n.t('feedBackPage.recommendations'),
    values: [
      {
        radioLabel: i18n.t('feedBackPage.instagram'),
        radioValue: RECOMMENDATIONS_INSTAGRAM,
        inputPlaceholder: specifyRecommendations,
        rows: 1,
        commentLength: 100,
      },
      {
        radioLabel: i18n.t('feedBackPage.tikTok'),
        radioValue: RECOMMENDATIONS_TIKTOK,
        inputPlaceholder: specifyRecommendations,
        rows: 1,
        commentLength: 100,
      },
      {
        radioLabel: i18n.t('feedBackPage.youTube'),
        radioValue: RECOMMENDATIONS_YOUTUBE,
        inputPlaceholder: specifyRecommendations,
        rows: 1,
        commentLength: 100,
      },
    ],
  },
  {
    label: i18n.t('feedBackPage.resources'),
    values: [
      {
        radioLabel: i18n.t('feedBackPage.reddit'),
        radioValue: 'Other resources - Reddit',
      },
      {
        radioLabel: i18n.t('feedBackPage.friend'),
        radioValue: 'Other resources - Friend / Word of mouth',
      },
      {
        radioLabel: 'Other',
        radioValue: OTHER_RESOURCES,
        inputPlaceholder: specify,
        rows: 4,
        commentLength: 500,
      },
    ],
  },
]

export const firstFeedbackFormSchema = yupObject().shape({
  resource: yupString().required(),
  other: yupString().when('resource', (value: string[], schema) => {
    if (VALUES.includes(value[0] || '')) {
      return schema.required('Please add specify where you heard about us')
    }
    return schema
  }),
})
