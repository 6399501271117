import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Button from 'src/Components/Buttons/Button'
import { PlatformCard } from 'src/Components/PlatformCard'
import { TrackSearch } from 'src/Components/TrackSearch'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import { CampaignPlatformTypeEnum } from 'src/Types/index'
import {
  getAllSearchErrorSelector,
  getTikTokTrackSelector,
} from 'src/Redux/tiktokTrack-process/selectors'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { setTrackToInitialState } from 'src/Redux/track-process'
import { useAppDispatch } from 'src/Hooks/redux'
import {
  setTiktokTracksToInitialState,
  setTiktokTrackToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { getSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { setSchedulePlatform } from 'src/Redux/campaign-process/mainOldProcessSlice'

import { InfoForNotAuthUser } from '../CreateCampaignLayout/InfoForNotAuthUser'
import { HowToGetYourTrackURLModal } from '../HowToGetYourTrackURLModal'
import { FaqComponent } from '../FaqComponent'

import { PlatformHeader } from './components/PlatformHeader'
import { FirstCampaignSubHeader } from './components/FirstCampaignSubHeader'
import { usePlatformModal } from './usePlatformModal'
import { HeaderComponentsBlock } from './components/HeaderComponentsBlock'

import {
  StyledPlatformModal,
  ButtonWrapper,
  InnerContainer,
  PlatformCardContainer,
} from './styles'

export interface PlatformModalProps {
  isVariantB?: boolean
}

export const PlatformModal: FC<PlatformModalProps> = ({ isVariantB }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const tiktokTrack = useSelector(getTikTokTrackSelector)
  const spotifyTrack = useSelector(getSpotifyTrackSelector)
  const searchPlatform = useSelector(getSchedulePlatform)
  const searchError = useSelector(getAllSearchErrorSelector)

  const { handleSubmitCampaignWithoutSignup } = usePlatformModal()

  const handleCreate = (): void => {
    handleSubmitCampaignWithoutSignup()
  }

  const setSearchPlatform = (platform: CampaignPlatformTypeEnum): void => {
    dispatch(setSchedulePlatform(platform))
  }
  const isHiddenOnMobile = Boolean(!searchError)
  const isSelectedTrack = Boolean(spotifyTrack || tiktokTrack)

  return (
    <StyledPlatformModal
      isVariantB={isVariantB}
      isFAQ={Boolean(searchPlatform === CampaignPlatformTypeEnum.TIKTOK)}
    >
      <HowToGetYourTrackURLModal />
      <PlatformHeader isVariantB={isVariantB} />

      <InnerContainer isHiddenOnMobile={isHiddenOnMobile}>
        <HeaderComponentsBlock isVariantB={isVariantB} />

        <FirstCampaignSubHeader
          isVariantB={isVariantB}
          isFAQ={Boolean(searchPlatform === CampaignPlatformTypeEnum.TIKTOK)}
        />
        <TrackSearch
          isVariantB={isVariantB}
          isFAQ={Boolean(searchPlatform === CampaignPlatformTypeEnum.TIKTOK)}
        />

        <PlatformCardContainer>
          {spotifyTrack && (
            <div
              style={{
                order:
                  searchPlatform === CampaignPlatformTypeEnum.SPOTIFY ? 0 : 1,
              }}
            >
              <PlatformCard
                isVariantB={isVariantB}
                platform={CampaignPlatformTypeEnum.SPOTIFY}
                changeTrackHandler={() => {
                  setSearchPlatform(CampaignPlatformTypeEnum.SPOTIFY)
                  dispatch(setTrackToInitialState())
                  dispatch(setTracksToInitialState())
                }}
              />
            </div>
          )}
          {tiktokTrack && (
            <PlatformCard
              isVariantB={isVariantB}
              platform={CampaignPlatformTypeEnum.TIKTOK}
              changeTrackHandler={() => {
                setSearchPlatform(CampaignPlatformTypeEnum.TIKTOK)
                dispatch(setTiktokTrackToInitialState())
                dispatch(setTiktokTracksToInitialState())
              }}
            />
          )}
        </PlatformCardContainer>

        {isSelectedTrack && (
          <ButtonWrapper>
            <Button type='green' onClick={handleCreate}>
              {isVariantB
                ? t('createCampaignPage.submitTrack')
                : t('createCampaignPage.createCampaign')}
            </Button>
          </ButtonWrapper>
        )}

        {isVariantB && !isSelectedTrack && (
          <InfoForNotAuthUser isVariantB={isVariantB} />
        )}

        <FaqComponent
          platform={searchPlatform}
          isTiktokTrackAdded={Boolean(tiktokTrack)}
        />
      </InnerContainer>
    </StyledPlatformModal>
  )
}
