import React, { FC } from 'react'

import styled from 'styled-components'

import GreenCheckmark from 'src/Assets/Svg/checkmark-green-round.svg?react'
import Danger from 'src/Assets/Svg/Danger.svg?react'
import { colors, variables } from 'src/Styled/variables'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

const Description = styled.p`
  margin: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  padding: 0 0 0 24px;
`

const Title = styled.h3<{ color: string }>`
  margin: 0;
  padding: 0;
  color: ${({ color }) => color};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight400};
`

interface RuleProps {
  title: string
  description: string
  type: 'green' | 'red'
}

export const Rule: FC<RuleProps> = ({ title, description, type }) => {
  const Icon = type === 'green' ? GreenCheckmark : Danger
  const titleColor = type === 'green' ? colors.green : colors.red

  return (
    <Container>
      <TitleWrapper>
        <Icon />
        <Title color={titleColor}>{title}</Title>
      </TitleWrapper>
      <Description>{description}</Description>
    </Container>
  )
}
