import { useEffect } from 'react'

import { useAppDispatch } from 'src/Hooks/redux'
import { getBillingAgreement } from 'src/Redux/billingAgreement-process/get-billingAgrreement/api-actions'

export const useScheduleInitApiCalls = (): void => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(getBillingAgreement())
  }, [dispatch])
}
