import {
  campaignDetailsEvent,
  createdCampaignEvent,
  createdFirstCampaignEvent,
} from 'src/Helpers/TagManager'
import {
  CampaignPlatformTypeEnum,
  CreateCampaignResponse,
} from 'src/Types/index'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import { createTiktokCampaignEvent } from 'src/Redux/googleAnalytics-process/api-actions'
import {
  setTiktokTracksToInitialState,
  setTiktokTrackToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { AppDispatch } from 'src/Redux/types'
export const spotifyEventsHandler = (params: {
  res: CreateCampaignResponse
  dispatch: AppDispatch
}): void => {
  createdFirstCampaignEvent(CampaignPlatformTypeEnum.SPOTIFY, params.res)

  campaignDetailsEvent(
    CampaignPlatformTypeEnum.SPOTIFY,
    1,
    params.res.spotify_campaign.type,
  )

  createdCampaignEvent({
    isFirstCampaign: true,
    platformName: CampaignPlatformTypeEnum.SPOTIFY,
    campaignId: params.res.spotify_campaign.id,
    trackId: params.res.spotify_campaign.Track.id,
    userId: params.res.user.id,
    campaignNumber: 1,
    danceRequired: false,
    is_feed: Boolean(params.res.spotify_campaign.is_feed),
  })

  params.dispatch(setTracksToInitialState())
}

export const tiktokEventsHandler = (params: {
  res: CreateCampaignResponse
  dispatch: AppDispatch
}): void => {
  createdFirstCampaignEvent(CampaignPlatformTypeEnum.TIKTOK, params.res)

  campaignDetailsEvent(
    CampaignPlatformTypeEnum.TIKTOK,
    1,
    params.res.tiktok_campaign.type,
  )

  createdCampaignEvent({
    isFirstCampaign: true,
    platformName: CampaignPlatformTypeEnum.TIKTOK,
    campaignId: params.res.tiktok_campaign.id,
    trackId: params.res.tiktok_campaign.id,
    userId: params.res.user.id,
    campaignNumber: 1,
    is_feed: false,
  })

  void params.dispatch(
    createTiktokCampaignEvent({
      campaignID: Number(params.res.tiktok_campaign.id),
      url: window.location.href,
      token: params.res.token,
    }),
  )

  params.dispatch(setTiktokTrackToInitialState())
  params.dispatch(setTiktokTracksToInitialState())
}
