import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  CampaignStatusResponse,
  CampaignTypeEnum,
  Genre,
  Language,
  Mood,
  SpotifyCampaign,
  TiktokCampaign,
  TiktokCampaignStatus,
} from 'src/Types'
import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { clientId, gaSession } from 'src/Redux/ga4-process/helpers'
import { MILLISECONDS_IN_SECONDS } from 'src/Constants/numeric'
import { getCookieByName, getUtmParameters } from 'src/Constants/functions'

import { ErrorCode, NameSpace } from '../../types'
import { mapSpotifyCampaign, mapTiktokCampaign } from '../helpers'
import { sendSourceBusterEvent } from '../../sourcebuster-process/api-actions'
import { getAccessToken } from '../../auth-process/credentialsSlice/selectors'
import { RootState } from '../../index'

interface CampaignStatusBody {
  campaignId: number
}

interface CreateRelaunchCampaignBody {
  campaignId: number
  url: string
}

interface AddNewSpotifyCampaignBody {
  artistName: string
  artistSpotifyId: string
  artistUrl: string
  genreIds: Array<number>
  languageIds: Array<number>
  moodIds: Array<number>
  trackSpotifyId: string
  trackImage: string
  trackLength: number
  trackName: string
  trackUrl: string
  pageUrl: string
}

interface AddNewSpotifyCampaignResponse {
  id: number
  is_feed: boolean
  type: CampaignTypeEnum
  Track: {
    track_id: number | string
    track_image: string
    track_name: string
    artist_name: string
  }
  start_date: string
  end_date: string
  status: CampaignStatusEnum
}

interface AddNewTikTokCampaignBody {
  trackUrl: string
  genres: Array<number>
  languages: Array<number>
  trackTiktokId: string
  trackTitle: string
  trackPlayUrl: string
  trackCoverUrl: string
  trackAuthorName: string
  initialVideoCount: number
  trackDuration: number
}

export interface TikTokCampaignsListResponse {
  campaigns: Array<{
    createdAt: string
    endDate: string
    genres: Array<{ id: number; genreName: string }>
    id: number
    influencersAcceptedAmount: number
    languages: Array<{ id: number; languageName: string }>
    startDate: string
    status: TiktokCampaignStatus
    submittedVideosAmount: number
    trackAuthorName: string
    trackCoverUrl: string
    trackPlayUrl: string
    trackTitle: string
    type: CampaignTypeEnum
    updatedAt: string
  }>
}

export type SpotifyCampaignsListResponse = Array<{
  id: number
  type: CampaignTypeEnum
  start_date: string
  end_date: string
  status: CampaignStatusEnum
  is_feed: boolean
  relaunched: boolean
  created_at: string
  reviewed_reviews: number
  all_review: number
  amount: string | null
  current_amount: string | null
  Track: {
    track_image: string
    track_name: string
    track_id: number
    artist_name: string
  }
}>

interface GetCampaignTikTokDetailsBody {
  tiktokCampaignId: string
}

export const getCampaignStatus = createAsyncThunk<
  CampaignStatusResponse,
  CampaignStatusBody
>(
  `${NameSpace.Campaign}/getCampaignStatus`,
  async ({ campaignId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<CampaignStatusResponse>(
        `${APP.SERVER}/campaign/status/${campaignId}`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const createRelaunchCampaign = createAsyncThunk<
  SpotifyCampaign,
  CreateRelaunchCampaignBody
>(
  `${NameSpace.Campaign}/createRelaunchCampaign`,
  async ({ campaignId, url }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<SpotifyCampaign>(
        `${APP.SERVER}/campaign/relaunch`,
        {
          campaign_id: campaignId,
          url,
        },
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const addNewSpotifyCampaign = createAsyncThunk<
  AddNewSpotifyCampaignResponse,
  AddNewSpotifyCampaignBody
>(
  `${NameSpace.Campaign}/addNewCampaign`,
  async (
    {
      artistName,
      artistSpotifyId,
      artistUrl,
      genreIds,
      languageIds,
      moodIds,
      trackSpotifyId,
      trackImage,
      trackLength,
      trackName,
      trackUrl,
      pageUrl,
    },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await api.post<AddNewSpotifyCampaignResponse>(
        `${APP.SERVER}/campaign`,
        {
          track_name: trackName,
          artist_name: artistName,
          track_url: trackUrl,
          track_image: trackImage,
          genre_ids: genreIds,
          artist_url: artistUrl,
          spotify_id: trackSpotifyId,
          artist_spotify_id: artistSpotifyId,
          track_length: trackLength,
          language_ids: languageIds,
          mood_ids: moodIds,
          url: pageUrl,
        },
      )

      const campaignId = Number(data.id)
      const token = getAccessToken(getState() as RootState)
      const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

      await dispatch(
        sendSourceBusterEvent({
          campaignId,
          token,
          campaignPlatform: CampaignPlatformTypeEnum.SPOTIFY,
          eventName: 'create_basic_campaign',
          gclid: utmObj.gclid || '',
        }),
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const addNewTikTokCampaign = createAsyncThunk<
  TiktokCampaign,
  AddNewTikTokCampaignBody
>(
  `${NameSpace.Campaign}/addNewTikTokCampaign`,
  async (
    {
      trackUrl,
      genres,
      languages,
      trackTiktokId,
      trackTitle,
      trackPlayUrl,
      trackCoverUrl,
      trackAuthorName,
      initialVideoCount,
      trackDuration,
    },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const headers = {
        'x-scga-session': gaSession,
        'x-scga-client-id': clientId,
        'x-scga-timestamp': Date.now() * MILLISECONDS_IN_SECONDS,
        'x-scga-page-title': window.location.origin,
      }
      const { data } = await api.post<TiktokCampaign>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns`,
        {
          trackUrl,
          genres,
          languages,
          trackTiktokId,
          trackTitle,
          trackPlayUrl,
          trackCoverUrl,
          trackAuthorName,
          initialVideoCount,
          trackDuration,
          isDanceRequested: false,
        },
        { headers },
      )

      const campaignId = Number(data.id)
      const token = getAccessToken(getState() as RootState)
      const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

      await dispatch(
        sendSourceBusterEvent({
          campaignId,
          token,
          campaignPlatform: CampaignPlatformTypeEnum.TIKTOK,
          eventName: 'create_basic_campaign',
          gclid: utmObj.gclid || '',
        }),
      )

      return data
    } catch (error) {
      const { message, response } = error as AxiosError

      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
export const getCampaignsList = createAsyncThunk<{
  campaigns: Array<TiktokCampaign | SpotifyCampaign>
  spotifyCampaigns: Array<SpotifyCampaign>
  tiktokCampaigns: Array<TiktokCampaign>
}>(`${NameSpace.Campaign}/getCampaignsList`, async (_, { rejectWithValue }) => {
  try {
    const { data: spotifyData } = await api.get<SpotifyCampaignsListResponse>(
      `${APP.SERVER}/campaign`,
    )
    const { data: tiktokData } = await api.get<TikTokCampaignsListResponse>(
      `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns`,
    )

    const spotifyCampaigns = mapSpotifyCampaign(spotifyData)
    const tiktokCampaigns = mapTiktokCampaign(tiktokData)

    const campaigns = [...spotifyCampaigns, ...tiktokCampaigns].sort((a, b) => {
      const dateA = new Date(String(a.createdAt))
      const dateB = new Date(String(b.createdAt))
      return dateB.getTime() - dateA.getTime()
    })

    return {
      campaigns,
      spotifyCampaigns,
      tiktokCampaigns,
    }
  } catch (error) {
    const { message, response } = error as AxiosError
    return rejectWithValue({
      message,
      code: response?.status || ErrorCode.InternalServerError,
    })
  }
})

export const getCampaignTikTokDetails = createAsyncThunk<
  TiktokCampaign,
  GetCampaignTikTokDetailsBody
>(
  `${NameSpace.Campaign}/getCampaignTikTokDetails`,
  async ({ tiktokCampaignId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TiktokCampaign>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${tiktokCampaignId}`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface ChangeCampaignBody {
  campaign_id: number | string
  genres_ids: Array<number>
  languages_ids: Array<number | undefined>
  moods_ids?: Array<number> | null
  max_price: number | null
}

interface ChangeCampaignResponse {
  curatorsCount: number
  playlistsCount: number
  price: number
  genres: Array<Genre>
  languages: Array<Language>
  moods: Array<Mood>
  minPlaylistsCount: number
  maxPlaylistsCount: number
  minCuratorsCount: number
  maxCuratorsCount: number
}

export const changeCampaign = createAsyncThunk<
  ChangeCampaignResponse,
  ChangeCampaignBody
>(
  `${NameSpace.Campaign}/changeCampaign`,
  async (
    { campaign_id, genres_ids, languages_ids, moods_ids, max_price },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.put<ChangeCampaignResponse>(
        `${APP.SERVER}/campaign/update-campaign`,
        {
          campaign_id,
          genres_ids,
          languages_ids,
          moods_ids,
          max_price,
        },
      )
      return { ...data, price: max_price || data.price }
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
