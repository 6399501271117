import { useCallback, useEffect, useMemo } from 'react'

import { useGrowthBook } from '@growthbook/growthbook-react'

import { getIsAuth } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { collectDataFromSourceBuster } from 'src/Redux/sourcebuster-process/api-actions'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { setPlatform } from 'src/Redux/campaign-process/mainOldProcessSlice'
import { CreateCampaignSteps } from 'src/Components/CreateCampaignLayout/components/StepsWrapper'

import { useAppDispatch, useAppSelector } from './redux'
import { useCustomSearchParams } from './useCustomSearchParams'

const simpleAddTrack = 'simple_add_track'
const TIKTOK_NAMINGS = ['tiktok', 'TikTok', 'Tiktok']

export const getPlatform = (): CampaignPlatformTypeEnum => {
  const sourcebusterData = collectDataFromSourceBuster()

  const hasTiktok = TIKTOK_NAMINGS.some((naming) =>
    sourcebusterData.lastUtmCampaign?.includes(naming),
  )
  if (hasTiktok) {
    return CampaignPlatformTypeEnum.TIKTOK
  }
  return CampaignPlatformTypeEnum.SPOTIFY
}

export const useSimpleAddTrack = (): boolean => {
  const isAuth = useAppSelector(getIsAuth)
  const { search, setSearch } = useCustomSearchParams()
  const dispatch = useAppDispatch()
  const growthBook = useGrowthBook()
  const platform = useMemo(() => getPlatform(), [])
  const isRegister = search.get('step') === CreateCampaignSteps.REGISTER

  const setPlatformBySourceBuster = useCallback(() => {
    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      dispatch(setPlatform(CampaignPlatformTypeEnum.TIKTOK))
    }
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      dispatch(setPlatform(CampaignPlatformTypeEnum.SPOTIFY))
    }
    setSearch({
      step: CreateCampaignSteps.PLATFORM_SEARCH,
      platform,
    })
  }, [dispatch, platform, setSearch])

  const isSimpleAddTrack = useMemo(() => {
    if (isAuth) {
      return false
    }
    return growthBook?.getFeatureValue(simpleAddTrack, false) || false
  }, [growthBook, isAuth])

  useEffect(() => {
    if (isSimpleAddTrack && !isRegister) {
      setPlatformBySourceBuster()
    }
  }, [isSimpleAddTrack, isRegister, setPlatformBySourceBuster])

  return isSimpleAddTrack
}
