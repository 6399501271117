import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const BonusAlertWrapper = styled.div`
  border: 1px solid ${colors.messegesGreen1};
  padding: 14px 16px;
  background-color: ${colors.white};
  border-radius: 4px;
  box-sizing: border-box;
  .ant-alert-with-description.ant-alert-no-icon {
    border: none;
  }
`

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 150%;
`
