import { createDraftSafeSelector } from '@reduxjs/toolkit'

import dayJs from 'src/Helpers/dayjs'
import { AppSelector } from 'src/Hooks/redux'
import { QUICK_CHECKOUT_FEATURE_NAME } from 'src/Hooks/useCheckoutTimerFeratureFlag'
import {
  BONUS_10,
  BONUS_20,
  VARIANT_2,
  VARIANT_3,
} from 'src/Hooks/useBonusTooltip'

import { RootState } from '../../index'

import { CampaignState } from '.'

const getState = (state: RootState): CampaignState => state.campaigns.main

export const getCampaignToSchedule = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) => campaignToSchedule?.campaign,
)
export const getCampaignToSchedulePrice = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) => campaignToSchedule?.campaign.price,
)
export const getIsEnoughBalance = createDraftSafeSelector(
  (state: RootState) => ({
    balance: Number(state.authentication.user.user.balance),
    price: Number(state.campaigns.main.campaignToSchedule?.campaign.price),
    bonusVariation: state.aBFeatures.features?.find(
      (item) => item?.name === QUICK_CHECKOUT_FEATURE_NAME,
    )?.variation,
  }),
  ({ balance, price, bonusVariation }) => {
    let bonus = 0
    if (bonusVariation === VARIANT_2) {
      bonus = BONUS_20
    }
    if (bonusVariation === VARIANT_3) {
      bonus = BONUS_10
    }

    return balance + bonus >= price && Boolean(price)
  },
)

export const getCampaigns = createDraftSafeSelector(
  getState,
  ({ campaigns }) => campaigns,
)

export const getEndedCompaniesAfterDate = (
  date: string = dayJs().toISOString(),
): AppSelector<boolean> =>
  createDraftSafeSelector(getCampaigns, (campaigns) => {
    const campaign = campaigns
      ?.filter((item) => item.status === 'ended' && item?.endDate)
      ?.find((item) => {
        const endDate = dayJs(item?.endDate)
        const closedDate = dayJs(date)
        return endDate.isAfter(closedDate)
      })
    return Boolean(campaign)
  })

export const getSpotifyCampaigns = createDraftSafeSelector(
  getState,
  ({ campaignsSpotify }) => campaignsSpotify,
)

export const getTiktokCampaigns = createDraftSafeSelector(
  getState,
  ({ campaignsTiktok }) => campaignsTiktok,
)

export const getCampaignsLoader = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getTiktokCampaignDetails = createDraftSafeSelector(
  getState,
  ({ detailsTiktok }) => detailsTiktok,
)

export const getAmountOfCampaigns = createDraftSafeSelector(
  getState,
  ({ amountOfCampaigns }) => amountOfCampaigns,
)

export const getPlatform = createDraftSafeSelector(
  getState,
  ({ platform }) => platform,
)

export const getSpotifyCampaignError = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)

export const getSchedulePlatform = createDraftSafeSelector(
  getState,
  ({ schedulePlatform }) => schedulePlatform,
)
export const getIsUpdateCampaignLoading = createDraftSafeSelector(
  getState,
  ({ updateCampaignLoading }) => updateCampaignLoading,
)

export const getGenresListSelector = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) =>
    campaignToSchedule.campaign.genres.map((genre) => genre.name).join(),
)

export const getLanguagesListSelector = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) =>
    campaignToSchedule.campaign.languages
      .map((language) => language.name)
      .join(),
)
