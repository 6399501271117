import { FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Alert from 'src/Components/Alert'
import { checkReferralCode } from 'src/Redux/referral-process/referralCode/api-actions'
import { referralLinkNotExist } from 'src/Router/routes'
import { useAppDispatch } from 'src/Hooks/redux'
import {
  getReferralError,
  getReferralName,
} from 'src/Redux/referral-process/referralCode'

import {
  AlertText,
  AlertAmount,
  AlertTitle,
  WelcomeAlertWrapper,
} from './styles'

export const WelcomeAlert: FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const urlParams = new URLSearchParams(window.location.search)

  const referralCode = urlParams?.get('referralCode')
  const referralError = useSelector(getReferralError)
  const referralName = useSelector(getReferralName)

  useEffect(() => {
    if (referralCode && !referralError) {
      dispatch(checkReferralCode(referralCode))
        .unwrap()
        .then((res) => {
          if (!res) {
            navigate(`${referralLinkNotExist}`)
          }
          return res
        })
        .catch(() => {
          navigate(`${referralLinkNotExist}`)
        })
    }
  }, [dispatch, navigate, referralCode, referralError])

  if (!referralCode && !referralName) {
    return null
  }

  return (
    <WelcomeAlertWrapper>
      <Alert color='success' icon unclosable noGap>
        <AlertText>
          <AlertTitle>{t('referAndEarn.welcomeToSoundCampaign')}</AlertTitle>
          <div>
            {referralName} &nbsp;
            {t('referAndEarn.hasInvitedYouToCreateAFirstCampaignForYourSong')}
            &nbsp;<AlertAmount>$30</AlertAmount>&nbsp;
            {t('referAndEarn.hasAlreadyBeenCreditedToYourBalance')}
          </div>
        </AlertText>
      </Alert>
    </WelcomeAlertWrapper>
  )
}
