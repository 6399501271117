import { FC } from 'react'

import { useAppSelector } from 'src/Hooks/redux'
import { VideoStatus } from 'src/Constants/enums'
import { getVideo } from 'src/Redux/campaignVideo-process/video-process/selectors'

import { RateForm } from './RateForm'
import { RateFeedback } from './RateFeedBack'

const ratedStatuses = [
  VideoStatus.APPROVED_BY_ARTIST,
  VideoStatus.APPROVED_BY_ADMIN,
  VideoStatus.APPROVED_AUTOMATICALLY,
  VideoStatus.AWAITING_MODERATOR_REVIEW,
  VideoStatus.DECLINED,
]

export const RateContainer: FC = () => {
  const video = useAppSelector(getVideo)

  if (video?.status && ratedStatuses.includes(video?.status)) {
    return <RateFeedback />
  }

  return <RateForm />
}
