import { FC, useEffect } from 'react'

import { useAppDispatch } from 'src/Hooks/redux'
import { getAllGenres } from 'src/Redux/genres-process/api-actions'
import { getLanguagesForExistingPlaylists } from 'src/Redux/languages-process/api-actions'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { LanguagesModal } from './LanguagesModal'
import { GenresModalSpotify } from './GenresModalSpotify'

export enum ModalType {
  Genres = 'genres',
  Languages = 'languages',
  Comment = 'comment',
}

export const ModalsContainer: FC = () => {
  const { search } = useCustomSearchParams()

  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(getAllGenres())
    void dispatch(getLanguagesForExistingPlaylists())
  }, [dispatch])

  if (search.get('modal') === ModalType.Genres) {
    return <GenresModalSpotify />
  }
  if (search.get('modal') === ModalType.Languages) {
    return <LanguagesModal />
  }
  return null
}
