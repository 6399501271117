import { FunctionComponent, useRef, useEffect } from 'react'

import { Input, InputRef } from 'antd'

import { useOutsideTouchHandle } from '../../../Hooks/useOutsideTouchHandle'

import { InputProps } from './InputProps'

import { CommonInputWrapper } from './styles'

const SearchInput: FunctionComponent<InputProps> = (props) => {
  const { autoFocus, withborder, isfaq } = props
  const inputRef = useRef<InputRef>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef, autoFocus])

  const handleOutsideToutch = (): void => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }
  useOutsideTouchHandle({ ref: wrapperRef, callback: handleOutsideToutch })

  return (
    <CommonInputWrapper ref={wrapperRef} withborder={withborder} isfaq={isfaq}>
      <Input ref={inputRef} {...props} autoComplete='off' />
    </CommonInputWrapper>
  )
}

export default SearchInput
