import { useSelector } from 'react-redux'

import {
  relaunchCampaignListClickEvent,
  relaunchCampaignInDetailsClickEvent,
} from 'src/Helpers/TagManager'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { getAmountOfCampaigns } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { RelaunchInvokePlacement } from 'src/Types'

interface Hint {
  handleRelaunchEvent: (params: {
    placement?: RelaunchInvokePlacement.DETAILS | RelaunchInvokePlacement.LIST
    campaignId: string | number
  }) => void
}

export const useRelaunchEvent = (): Hint => {
  const user = useSelector(getUserSelector)
  const amountOfCampaigns = useSelector(getAmountOfCampaigns)

  const handleRelaunchEvent = (params: {
    placement?: RelaunchInvokePlacement.DETAILS | RelaunchInvokePlacement.LIST
    campaignId: string | number
  }): void => {
    if (params.placement === RelaunchInvokePlacement.DETAILS) {
      relaunchCampaignInDetailsClickEvent({
        userId: user.id,
        campaignNumber: amountOfCampaigns,
        campaignId: params.campaignId,
      })
    }

    if (params.placement === RelaunchInvokePlacement.LIST) {
      relaunchCampaignListClickEvent({
        userId: user.id,
        campaignNumber: amountOfCampaigns,
        campaignId: params.campaignId,
      })
    }
  }

  return {
    handleRelaunchEvent,
  }
}
