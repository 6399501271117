import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { RootState } from 'src/Redux'
import { ApiError, AppDispatch } from 'src/Redux/types'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type AppSelector<T> = (state: RootState) => T

export const setApiError = (
  action: PayloadAction<unknown, string, never, SerializedError>,
): ApiError => {
  if (action.payload) {
    return action.payload as ApiError
  }
  return {
    message: action?.error.message || '',
    code: Number(action?.error.code),
  }
}
