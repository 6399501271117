import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { BonusStatus } from 'src/Types'

import { BonusStatusTextProps } from './BonusStatusTextProps'

import { SuccessText } from './styles'

export const BonusStatusText: FC<BonusStatusTextProps> = ({ status }) => {
  const { t } = useTranslation()

  const getText = (bonusStatus: BonusStatus): string | null => {
    switch (bonusStatus) {
      case BonusStatus.OPENED:
        return t('createCampaignPage.thisLinkHasAlreadyBeenOpened')
      case BonusStatus.EXPIRED:
        return t('createCampaignPage.thisLinkHasAlreadyExpired')
      default:
        return null
    }
  }
  const statusText = getText(status)

  return <SuccessText>{statusText}</SuccessText>
}
