import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

import { GenresParameters } from '../GenresParameters/GenresParameters'
import { CommentParameters } from '../CommentParameters/CommentParameters'

const TiktokTrackParamsContainer = styled.div`
  display: flex;
  height: fit-content;
  max-width: 720px;
  padding: 24px;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  @media ${device.tabletMin} {
    display: grid;
    align-items: center;
  }
`

const TiktokTrackParamsInner = styled.div`
  display: grid;
  gap: 16px;
  & > button {
    margin-left: 16px;
  }
`

const Title = styled.h4`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  padding: 0px;
`

export const TiktokTrackParams: FC = () => {
  const { t } = useTranslation()
  return (
    <TiktokTrackParamsContainer>
      <TiktokTrackParamsInner>
        <Title>{t('scheduleCampaignPage.chooseTrackParameters')}</Title>

        <GenresParameters />
      </TiktokTrackParamsInner>

      <TiktokTrackParamsInner>
        <Title>{t('scheduleCampaignPage.shareYourExpectations')}</Title>

        <CommentParameters />
      </TiktokTrackParamsInner>
    </TiktokTrackParamsContainer>
  )
}
