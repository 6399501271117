import { FC, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ArtistProtection } from 'src/Components/ArtistProtection'
import { device } from 'src/Styled/variables'
import { useAppDispatch } from 'src/Hooks/redux'
import { getBillingAgreement } from 'src/Redux/billingAgreement-process/get-billingAgrreement/api-actions'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { LoadingSpinner } from 'src/Containers/ui'
import { subscription } from 'src/Router/routes'

import { SubscriptionPageWrapper } from '../styles'
import { HeaderTitle } from '../components/HeaderTitle'

import { PlanToPay } from './PlanToPay'
import { Payment } from './Payment'
import { useRedirectFromEmail } from './useRedirectFromEmail'

export const PageContainer = styled.div`
  padding: 16px;
  padding-top: 64px;
  padding-bottom: 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 600px;
  height: fit-content;

  @media ${device.mobileMax} {
    padding: 16px;
  }
`
const StyledHeaderTitle = styled(HeaderTitle)`
  margin-bottom: 0px;
`

const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
`

const PaySubscription: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const autopay = search.get('autopay') === 'true'
  useRedirectFromEmail()

  const handleClose = (): void => {
    navigate(subscription)
  }
  useEffect(() => {
    void dispatch(getBillingAgreement())
  }, [dispatch])

  return (
    <SubscriptionPageWrapper>
      {autopay && (
        <StyledLoadingSpinner fullscreen color='green' size='large' />
      )}
      <PageContainer>
        <StyledHeaderTitle onClose={handleClose}>
          {t('getPremiumPage.order')}
        </StyledHeaderTitle>
        <PlanToPay />
        <Payment />
        <ArtistProtection>{t('getPremiumPage.protection')}</ArtistProtection>
      </PageContainer>
    </SubscriptionPageWrapper>
  )
}
export default PaySubscription
