import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RootState } from 'src/Redux'

import { ga } from './api'

export const rtkApi = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState, endpoint }) => {
      const isCookies = ga && !endpoint?.startsWith('https://api.spotify.com')
      const { AccessToken } = (getState() as RootState).authentication
        .credentials
      if (AccessToken) {
        headers.set('authorization', `Bearer ${AccessToken}`)
      }
      if (isCookies) {
        headers.set('Cookies', ga)
      }
      return headers
    },
  }),
  tagTypes: [],
  endpoints: () => ({}),
})
