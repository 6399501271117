import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'

import { Title } from '../styles'

import { ScheduleLanguages } from './ScheduleLanguages'
import { ScheduleGenres } from './ScheduleGenres'

import { ChooseTrackParametersContainer } from './styles'

export const TrackParameters: FC = () => {
  const { t } = useTranslation()
  const campaignDetails = useAppSelector(getCampaignToSchedule)

  const isGenresActive = !campaignDetails.genres.length

  return (
    <ChooseTrackParametersContainer>
      <Title>{t('scheduleCampaignPage.chooseTrackParameters')}</Title>
      <ScheduleGenres genres={campaignDetails.genres} />
      <ScheduleLanguages
        isAddButtonDisabled={isGenresActive}
        languages={campaignDetails.languages}
      />
    </ChooseTrackParametersContainer>
  )
}
