import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  signupErrorToLogin,
  signupToLogin,
} from 'src/Helpers/TagManager/AuthEvents'
import { useQuickLoginUTMParameters } from 'src/Hooks/useQuickLoginUTMParameters'
import { login } from 'src/Router/routes'
import { colors, variables } from 'src/Styled/variables'

const LoginTextBlock = styled.div`
  text-decoration: none;
  text-align: center;
  line-height: 21px;
`
export const LoginText = styled.span`
  text-decoration: none;
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
`
export const RegisterLogin = styled.span`
  color: ${colors.green};
  border-bottom: 1px dotted ${colors.green};
  line-height: 21px;
`

interface Props {
  emailExist: boolean
}
export const UserRegisterFormLoginLink: FC<Props> = ({ emailExist }) => {
  const { t } = useTranslation()
  const utmParameters = useQuickLoginUTMParameters()
  const params = new URLSearchParams(utmParameters).toString()
  const loginWithParams = `${login}?${params}`

  const handleClicked = (): void => {
    if (emailExist) {
      signupErrorToLogin()
      return
    }
    signupToLogin()
  }
  return (
    <LoginTextBlock>
      <Link onClick={handleClicked} to={loginWithParams}>
        <LoginText>{t('login.IAlreadyHaveAnAccount')}&#8194;</LoginText>
        <RegisterLogin>{t('login.login')}</RegisterLogin>
      </Link>
    </LoginTextBlock>
  )
}
