import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { ErrorCode, NameSpace } from 'src/Redux/types'
import { getPremium } from 'src/Router/routes'
import { subscribeEvent } from 'src/Helpers/TagManager'

import { redirectToRoute } from '../RedirectProcess'
import { addFeatureForUser } from '../A-BFeatures-process/api-actions'
import { RootState } from '../index'

export const GET_PREMIUM_FEATURE_NAME = 'subscription_wait_list_joined'

export type PlanTypes = 'lite150' | 'regular250' | 'super500'

export const subscribeForGetPremiumWebHook = createAsyncThunk<
  void,
  { planName: PlanTypes; email: string }
>(
  `${NameSpace.Payment}/subscribeForGetPremiumWebHook`,
  async (requestData, { rejectWithValue }) => {
    try {
      const hookUrl =
        'https://hook.eu1.make.com/40xwws7c7q0e71gwp7gtlcce54pr3thm'

      const { data } = await axios.post<void>(hookUrl, requestData)
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const subscribeForGetPremium = createAsyncThunk<
  null,
  { planName: PlanTypes }
>(
  `${NameSpace.GET_PREMIUM}/subscribeForGetPremium`,
  async ({ planName }, { rejectWithValue, dispatch, getState }) => {
    try {
      const store = getState() as RootState

      const { email, id, curatorId } = store.authentication.user.user

      const res = await dispatch(
        addFeatureForUser({
          featureName: GET_PREMIUM_FEATURE_NAME,
          variation: 1,
        }),
      )

      unwrapResult(res)

      await dispatch(subscribeForGetPremiumWebHook({ planName, email }))

      subscribeEvent({
        userId: id || curatorId,
        planName,
      })

      dispatch(redirectToRoute(`${getPremium}?success=true`))

      return null
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>

      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
