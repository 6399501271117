import styled from 'styled-components'
import { Progress } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'
import DefaultCover from 'src/Assets/Svg/defaultCover.svg'

interface Props {
  refund?: boolean
}

export const StyledDetailsCard = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: repeat(3, minmax(0, auto));
  gap: 16px;
  align-items: start;
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 4px;
  margin-bottom: 8px;
  @media ${device.smallScreen} {
    grid-template-columns: 80px 310px 1fr 150px;
    grid-template-rows: none;
    align-items: center;
    margin-bottom: 16px;
    padding: 24px;
  }
`
export const StyledIcon = styled.div`
  display: grid;
  gap: 16px;
  justify-items: center;
  align-items: center;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row: 1 / -1;
  @media ${device.smallScreen} {
    grid-auto-flow: column;
  }
`

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-top: -8px;
  @media ${device.smallScreen} {
    grid-column-start: 1;
    margin-top: 0;
  }
`
export const ImgWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: url(${DefaultCover});
  background-color: ${colors.greyTextBlackBg};
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  & img {
    border-radius: 4px;
  }
`

export const NamesWrapper = styled.div`
  display: grid;
  gap: 0px;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`

export const Name = styled.div`
  line-height: 150%;
`

export const Artist = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 150%;
`

export const DaysInfo = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 150%;
  padding: 4px;
  background-color: ${colors.greyBg};
  border-radius: 4px;
`

export const DateInfo = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 150%;
  padding-top: 4px;
  color: ${colors.mainText} !important;
`

export const BudgetWrapper = styled.div`
  display: grid;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
  @media ${device.smallScreen} {
    gap: 0px;
  }
`
export const Row = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 4px;
`

export const Key = styled.div`
  color: ${colors.greyTextWhiteBg};
`

export const Value = styled.div`
  color: ${(props: Props) => (props.refund ? colors.green : colors.mainText)};
`
export const TikTokDetailsCampaignStatusWrapper = styled.div`
  display: grid;
  @media ${device.mobileMin} {
    width: 150px;
  }
`

interface Props {
  status?: string
}

export const TikTokDetailsCampaignStatus = styled.div`
  color: ${(props: Props) =>
    props.status === 'running'
      ? `${colors.mainText}`
      : `${colors.messegesRed}`};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const TikTokDetailsCampaignStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledProgress = styled(Progress)`
  padding-top: 6px;
  width: 100%;

  .ant-progress-steps-outer {
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-column-gap: 1px;
  }
  .ant-progress-steps-item {
    width: auto !important;
    margin-right: 0px;
    height: 4px !important;
  }
  & .ant-progress-steps-item:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  & .ant-progress-steps-item:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`
