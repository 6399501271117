import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const CustomModal = styled.div`
  z-index: 300;
  margin-top: 60px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SuccessBlock = styled.div`
  display: grid;
  gap: 40px;
  justify-items: center;
`

export const SuccessBlockBody = styled.div`
  display: grid;
  justify-items: center;
  line-height: 150%;
  gap: 16px;
`

export const SuccessText = styled.div`
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
`

export const SuccessButtonBlock = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media ${device.tabletMax} {
    flex-direction: column;
  }
`

export const ButtonWrapper = styled.div`
  width: 278px;

  @media ${device.tabletMin} {
    width: 232px;
  }
`

export const SuccessCircle = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  line-height: 150%;
`
export const GreenText = styled.span`
  color: ${colors.green};
`
