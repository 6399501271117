import TagManager from 'react-gtm-module'

import {
  CampaignPlatformTypeEnum,
  CampaignTypeEnum,
  FlowType,
  ID,
} from 'src/Types'
import { TagManagerEnum } from 'src/Constants/enums'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const languageAddedEvent = ({
  campaignType,
  languageId,
  languageName,
  platformName,
  userId,
  campaignId,
  languageOptions,
}: {
  campaignType: string | CampaignTypeEnum
  languageId?: string
  languageName?: string
  platformName?: string
  userId?: string
  campaignId?: number | string
  languageOptions?: boolean
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.LanguagesAdded,
      event_name: EventNameEnum.LanguagesAdded,
      flow: FlowType.scheduling,
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'track-platform': platformName,
      'campaign-id': campaignId,
      userID: userId,
      'language-name': languageName,
      'language-id': languageId,
      ...(platformName === CampaignPlatformTypeEnum.SPOTIFY
        ? {
            language_options: languageOptions
              ? TagManagerEnum.WIDER_LANGUAGES_MATCHING
              : TagManagerEnum.SELECTED_LANGUAGES_ONLY,
          }
        : {}),
    },
  })
}

export const languagesHintOpenedEvent = ({
  isFeed,
  isFirstCampaign,
  campaignNumber,
  campaignPlatform,
  userId,
  campaignId,
  isAnyLanguage,
}: {
  isFeed: boolean
  isFirstCampaign: boolean
  campaignNumber?: number
  campaignPlatform: CampaignPlatformTypeEnum
  userId?: ID | null
  campaignId: string | number
  isAnyLanguage?: boolean
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: 'hint_opened',
        flow: FlowType.scheduling,
        is_feed: isFeed,
        campaign_platform: campaignPlatform,
        track_platform: campaignPlatform,
        campaign_type:
          (isFirstCampaign && campaignNumber === 1) || !userId
            ? CampaignTypeEnum.FIRST
            : CampaignTypeEnum.NEW,
        campaign_number: campaignNumber || 1,
        campaign_id: campaignId,
        ...(isAnyLanguage
          ? {
              language_options: isAnyLanguage
                ? TagManagerEnum.WIDER_LANGUAGES_MATCHING
                : TagManagerEnum.SELECTED_LANGUAGES_ONLY,
            }
          : {}),
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const languagesHintClosedEvent = ({
  isFeed,
  isFirstCampaign,
  campaignNumber,
  campaignPlatform,
  userId,
  campaignId,
  isAnyLanguage,
}: {
  isFeed: boolean
  isFirstCampaign: boolean
  campaignNumber?: number
  campaignPlatform: CampaignPlatformTypeEnum
  userId?: ID | null
  campaignId: string | number
  isAnyLanguage?: boolean
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.HintToggleEvent,
      event_params: {
        event_name: 'hint_closed',
        flow: FlowType.scheduling,
        is_feed: isFeed,
        campaign_platform: campaignPlatform,
        track_platform: campaignPlatform,
        campaign_type:
          (isFirstCampaign && campaignNumber === 1) || !userId
            ? CampaignTypeEnum.FIRST
            : CampaignTypeEnum.NEW,
        campaign_number: campaignNumber || 1,
        campaign_id: campaignId,
        ...(isAnyLanguage
          ? {
              language_options: isAnyLanguage
                ? TagManagerEnum.WIDER_LANGUAGES_MATCHING
                : TagManagerEnum.SELECTED_LANGUAGES_ONLY,
            }
          : {}),
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const changedLanguageOptionsEvent = ({
  isFeed,
  isFirstCampaign,
  campaignNumber,
  userId,
  campaignId,
  languageOptions,
}: {
  isFeed: boolean
  isFirstCampaign: boolean
  campaignNumber?: number
  userId?: ID | null
  campaignId: string | number
  languageOptions: boolean
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.ChangedLanguageOptions,
        flow: FlowType.scheduling,
        is_feed: isFeed,
        campaign_platform: CampaignPlatformTypeEnum.SPOTIFY,
        track_platform: CampaignPlatformTypeEnum.SPOTIFY,
        campaign_type:
          (isFirstCampaign && campaignNumber === 1) || !userId
            ? CampaignTypeEnum.FIRST
            : CampaignTypeEnum.NEW,
        campaign_number: campaignNumber || 1,
        campaign_id: campaignId,
        language_options: languageOptions
          ? TagManagerEnum.WIDER_LANGUAGES_MATCHING
          : TagManagerEnum.SELECTED_LANGUAGES_ONLY,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}
