import { FC, ReactNode, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getPlaylist } from 'src/Redux/playlists-process/selectors'
import { useAppDispatch } from 'src/Hooks/redux'
import { getPlaylists } from 'src/Redux/playlists-process/api-actions'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'
import { Playlist } from 'src/Types'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { pageViewEvent } from 'src/Helpers/TagManager'

import PlayList from './components/PlayList'
import PlayListComponentEmptyPage from './components/PlayListComponentEmptyPage'
import { LanguagePreferenceAlert } from './components/LanguagePreferenceAlert'
import { LanguageSetModal } from './components/LanguageSetModal'

import { Container, PageTitle, PlaylistContainer } from './styles'

const PlayListComponent: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const playlists = useSelector(getPlaylist)
  const user = useSelector(getUser)

  useEffect(() => {
    void dispatch(getPlaylists())
  }, [dispatch])

  useEffect(() => {
    user && pageViewEvent(user, pageInfo.playlists)
  }, [user])

  const checkEmptiness = (list: Array<Playlist>): ReactNode => {
    if (list.length) {
      return <PlayList />
    }
    return <PlayListComponentEmptyPage />
  }

  return (
    <PlaylistContainer>
      <PageTitle>{t('playlistsPage.pageTitle')}</PageTitle>
      <LanguagePreferenceAlert />
      <Container>{checkEmptiness(playlists)}</Container>
      <LanguageSetModal />
    </PlaylistContainer>
  )
}

export default PlayListComponent
