import React, { FC, PropsWithChildren } from 'react'

import styled from 'styled-components'
import ReactLoading from 'react-loading'

import PauseIcon from '/src/Assets/Svg/pause-button.svg?react'

import PlayIcon from 'src/Assets/Svg/play-button.svg?react'

const CoverWrapper = styled.div`
  position: relative;
  margin-right: 16px;
  cursor: pointer;
`

const StyledPauseIcon = styled(PauseIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const StyledPlayIcon = styled(PlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const Loader = styled(ReactLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

type TiktokTrackPlayerProps = PropsWithChildren & {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  loading: boolean
  isPlaying: boolean
  loaderSize?: string
  iconSize?: string
  className?: string
}

export const TrackCoverWrapper: FC<TiktokTrackPlayerProps> = ({
  children,
  loading,
  isPlaying,
  onClick,
  loaderSize = '30px',
  iconSize = '16px',
  className,
}) => (
  <CoverWrapper className={className} onClick={onClick}>
    {children}

    {loading && <Loader type='spin' width={loaderSize} height={loaderSize} />}

    {/* Adjusted Logic for Displaying Play/Pause Icons */}
    {isPlaying ? (
      <StyledPauseIcon width={iconSize} height={iconSize} />
    ) : (
      <StyledPlayIcon width={iconSize} height={iconSize} />
    )}
  </CoverWrapper>
)
