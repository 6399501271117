import React, { FunctionComponent, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { campaignDuration } from 'src/Constants/numeric'
import { calculateDaysLeft } from 'src/Constants/functions'

import { ProgressBarProps } from './props'

import {
  ProgressBar,
  ProgressContainer,
  ProgressInfo,
  ProgressInfoItem,
  ProgressSection,
  StartDay,
} from './styles'

export const ProgressBarComponent: FunctionComponent<ProgressBarProps> = ({
  endDate,
}) => {
  const { t } = useTranslation()

  const totalDays = Array.from({ length: campaignDuration })
  totalDays.forEach((_, id) => {
    totalDays[id] = id
  })

  const daysLeftMemo = useMemo(() => calculateDaysLeft(endDate), [endDate])

  const daysLeftLabel =
    daysLeftMemo > 1 ? t('reviewsPage.daysLeft') : t('reviewsPage.dayLeft')

  const startDayMemo = useMemo(() => campaignDuration - daysLeftMemo, [endDate])

  const startDayLabel = useMemo(() => {
    if (startDayMemo > 1) {
      return 'days ago'
    }
    if (startDayMemo === 1) {
      return 'day ago'
    }
    return 'Today'
  }, [startDayMemo])

  return (
    <ProgressContainer>
      <>
        <ProgressInfo>
          <StartDay>{`${startDayMemo || ''} ${startDayLabel}`}</StartDay>
          <ProgressInfoItem>{`${daysLeftMemo} ${daysLeftLabel}`}</ProgressInfoItem>
        </ProgressInfo>
        <ProgressBar>
          {totalDays.map((el, id) => (
            <ProgressSection
              key={`${el}`}
              first={!id}
              last={id === totalDays.length - 1}
              passed={id >= daysLeftMemo}
              yellow={daysLeftMemo <= 5}
              red={daysLeftMemo <= 2}
            />
          ))}
        </ProgressBar>
      </>
    </ProgressContainer>
  )
}
