import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { SmartImage } from 'src/Components/SmartImage'

interface StyledComponentsProps {
  isMenuOpened: boolean
}

export const UserInfoWrapper = styled.div<StyledComponentsProps>`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  transform: ${({ isMenuOpened }) =>
    isMenuOpened ? 'translateX(0)' : 'translateX(-200px)'};
  justify-content: flex-start;
  align-items: center;
  transition: all 1s ease-out;
  @media (min-width: 576px) {
    padding-top: 40px;
  }
`

export const SidebarAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 222px;
`

export const SidebarImg = styled(SmartImage)`
  border-radius: 50%;
  margin-bottom: 16px;
  min-height: 120px;
  width: 120px;
  height: 120px;
  object-fit: cover;
`

export const SidebarTitleName = styled.div`
  margin-bottom: 4px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 21px;
  color: ${colors.mainText};
  text-transform: capitalize;
`

export const SidebarTitleRole = styled.div`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
  text-transform: capitalize;
`

export const DeactivatedLabel = styled.span`
  font-size: ${variables.fontSize14};
  color: ${colors.messegesRed};
`
