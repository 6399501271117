import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import ReactStars from 'react-rating-stars-component'
import { useNavigate, useParams } from 'react-router-dom'

import dayjs from 'src/Helpers/dayjs'
import { CampaignVideo } from 'src/Types'
import Button from 'src/Components/Buttons/Button'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { formatDate } from 'src/Constants/formatDate'
import { colors } from 'src/Styled/variables'
import { campaignsList, details } from 'src/Router/routes'
import { VideoStatus } from 'src/Constants/enums'
import avatar from 'src/Assets/Png/ava.png'
import { SmartImage } from 'src/Components/SmartImage'

import { StatusBadge } from '../StatusBadge'

import {
  ButtonWrapper,
  VideoItemCardContainer,
  ParamTitle,
  StatusWrapper,
  UserInfoWrapper,
  UserParamWrapper,
  ViewsParamWrapper,
  UserPicContainer,
  Name,
} from './styles'

interface VideoItemProps {
  video: CampaignVideo
}

export const VideoItem: FC<VideoItemProps> = (props) => {
  const navigate = useNavigate()
  const { campaignID } = useParams<keyof { campaignID: string }>()
  const { video } = props
  const isMobile = useMediaQuery({ maxWidth: 576 })

  const { t } = useTranslation()

  const formattedFollowers = addSpaceToNumber(video.followerCount, ',')

  const formattedViews =
    video.status !== VideoStatus.DECLINED
      ? addSpaceToNumber(video.viewsCount || 0, ',')
      : '-'

  const formattedDate = dayjs(video.videoUploadDate).format(
    formatDate.formatDateFirst2,
  )
  const isRateButtonVisible = video.status === VideoStatus.AWAITING_REVIEW
  const isDetailsButtonVisible = [
    VideoStatus.APPROVED_AUTOMATICALLY,
    VideoStatus.DECLINED,
    VideoStatus.APPROVED_BY_ARTIST,
    VideoStatus.APPROVED_BY_ADMIN,
    VideoStatus.AWAITING_MODERATOR_REVIEW,
  ].includes(video.status)

  const onDetailsOrRateClick = (): void => {
    if (campaignID && video.id) {
      navigate(`${campaignsList}${details}/${campaignID}/${video.id}`)
    }
  }

  return (
    <VideoItemCardContainer>
      <UserPicContainer>
        <SmartImage
          alt='userpic'
          src={video.userPhotoUrl}
          fallbackSrc={avatar}
        />
      </UserPicContainer>

      <UserInfoWrapper>
        <Name>{video.nickname}</Name>
        <span>
          {formattedFollowers} {t('campaignResultsPage.followers')}
        </span>
      </UserInfoWrapper>

      <UserParamWrapper>
        {isMobile && (
          <ParamTitle>{t('campaignResultsPage.submissionDate')}</ParamTitle>
        )}

        <span>{formattedDate}</span>
      </UserParamWrapper>

      <ViewsParamWrapper>
        <ParamTitle>{t('campaignResultsPage.views')}</ParamTitle>
        <span>{formattedViews}</span>
      </ViewsParamWrapper>

      <StatusWrapper>
        <StatusBadge videoStatus={video.status} />
        {video.stars && video.stars > 0 ? (
          <ReactStars
            count={5}
            edit={false}
            value={video.stars}
            size={16}
            color={colors.greyTextBlackBg}
            activeColor={colors.messegesYellow1}
          />
        ) : null}
      </StatusWrapper>

      <ButtonWrapper>
        {isRateButtonVisible && (
          <Button type={'green'} onClick={onDetailsOrRateClick}>
            {t('campaignResultsPage.rate')}
          </Button>
        )}
        {isDetailsButtonVisible && (
          <Button type={'whiteWithGreenBorder'} onClick={onDetailsOrRateClick}>
            {t('campaignResultsPage.details')}
          </Button>
        )}
      </ButtonWrapper>
    </VideoItemCardContainer>
  )
}
