import React, { FC, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getHasAwaiting,
  getIsRecipient,
} from 'src/Redux/transactions-process/selectors'
import Button from 'src/Components/Buttons/Button'
import {
  getArtistBalance,
  getUserId,
  getUserSelector,
} from 'src/Redux/auth-process/userSlice/selectors'
import {
  payoutRequestedEvent,
  payoutRequestEvent,
} from 'src/Helpers/TagManager'
import { resetUserBalance } from 'src/Redux/auth-process/userSlice'
import { CustomEmbedRef } from 'src/Types'
import { getAllTransactions } from 'src/Redux/transactions-process/curatorBalanceHistorySlice/api-actions'
import { getIsPayoutLoading } from 'src/Redux/transactions-process/orderPayoutSlice/selectors'
import { orderPayout } from 'src/Redux/transactions-process/orderPayoutSlice/api-actions'
import { getTransactionsDetails } from 'src/Redux/transactions-process/api-actions'

import { CuratorSurveyAfterPayoutRequest } from '../../CuratorSurveyAfterPayoutRequest'

import { ButtonWrapper, SubHead } from './styles'

const DELAY_5000 = 5000
const FIXED_BY = 2
const MIN_BALANCE = 20

export const RequestButton: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const loading = useAppSelector(getIsPayoutLoading)
  const hasAwaiting = useAppSelector(getHasAwaiting)
  const recipient = useAppSelector(getIsRecipient)
  const currentBalance = useAppSelector(getArtistBalance)
  const user = useAppSelector(getUserSelector)
  const typeformRef = useRef<CustomEmbedRef | null>(null)
  const userId = useAppSelector(getUserId)

  const isDisabled =
    hasAwaiting || Number(currentBalance) < MIN_BALANCE || !recipient

  const openSliderButton = (): void => {
    setTimeout(() => {
      typeformRef.current?.open()
    }, DELAY_5000)
  }

  const handlePayoutRequest = (): void => {
    if (!currentBalance) {
      return
    }
    void dispatch(orderPayout(currentBalance))
      .unwrap()
      .then((response) => {
        void dispatch(getAllTransactions(1))
        void dispatch(getTransactionsDetails())
        // Responce is empty, so we can't get transaction id
        userId && payoutRequestedEvent(currentBalance, 0, String(userId))
        openSliderButton()
        dispatch(resetUserBalance())
        payoutRequestEvent(user, currentBalance)
        return response
      })
  }

  const buttonText = hasAwaiting
    ? t('balancePage.payoutOnApproval')
    : t('balancePage.requestPayout')
  return (
    <>
      <ButtonWrapper>
        <Button
          loading={loading}
          type='green'
          disabled={isDisabled}
          onClick={handlePayoutRequest}
        >
          {!loading ? buttonText : ''}
        </Button>
      </ButtonWrapper>
      <SubHead>{`${t('balancePage.minimumRequest')} $${MIN_BALANCE.toFixed(
        FIXED_BY,
      )}`}</SubHead>
      <CuratorSurveyAfterPayoutRequest typeformRef={typeformRef} />
    </>
  )
}
