import { FC, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { setIsAlertMode } from 'src/Redux/sidebar-process'
import { TracksLevelsAlert } from 'src/Components/TracksLevelsAlert'
import { useAppDispatch } from 'src/Hooks/redux'
import { levels, root, tracks } from 'src/Router/routes'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { Roles } from 'src/Constants/enums'

const layoutAlerts = [
  {
    component: <TracksLevelsAlert />,
    pages: [levels, tracks],
    rootPageRoles: [Roles.curator],
  },
]

export const TopBarAlert: FC = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const role = useSelector(getUserRole)

  const showingAlert = layoutAlerts.find((alert) => {
    const isRootPage = pathname === root
    const isMatchedByPathname = alert.pages.includes(pathname)
    const isMatchedByRole =
      role && alert.rootPageRoles.includes(role) && isRootPage

    if (isMatchedByRole || isMatchedByPathname) {
      return alert.component
    }
    return null
  })

  useEffect(() => {
    dispatch(setIsAlertMode(Boolean(showingAlert)))
  }, [showingAlert, dispatch])

  return showingAlert?.component || null
}
