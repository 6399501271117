import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { NameSpace, SliceState, SliceStatus } from '../../types'
import { RootState } from '../..'
import { errorCreateBillingAgreementSelector } from '../create-billingAgreement'

import { getBillingAgreement } from './api-actions'

interface BillingAgreementProcessState extends SliceState {
  data: {
    paypalEmail?: string
    id?: number
  }
}

const initialState: BillingAgreementProcessState = {
  data: {},
  status: SliceStatus.Idle,
  error: null,
}

export const getBillingAgreementSlice = createSlice({
  initialState,
  name: NameSpace.BILLING_AGREEMENT,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBillingAgreement.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getBillingAgreement.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.data = action.payload
      state.error = null
    })
    builder.addCase(getBillingAgreement.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})

const getState = (state: RootState): BillingAgreementProcessState =>
  state.billingAgreement.getBillingAgreement

export const hasBillingAgreementSelector = createDraftSafeSelector(
  getState,
  ({ data }) => Boolean(data?.id),
)
export const getBillingAgreementEmailSelector = createDraftSafeSelector(
  getState,
  ({ data }) => data?.paypalEmail,
)

export const getBillingAgreementIdSelector = createDraftSafeSelector(
  getState,
  ({ data }) => data?.id,
)
export const errorGetBillingAgreementSelector = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)

export const billingAgreementErrorSelector = createDraftSafeSelector(
  (state: RootState) => ({
    errorGet: errorGetBillingAgreementSelector(state),
    errorCreate: errorCreateBillingAgreementSelector(state),
  }),
  ({ errorCreate, errorGet }) => errorCreate || errorGet,
)
