import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Mobile } from 'src/Components/Responsive'
import { Playlist } from 'src/Types'

import { DataNotReceivedContainer, DataNotReceivedLabel } from '../../../styles'
import { StreamsListenersStats } from '../StreamsListenersStats/StreamsListenersStats'
import {
  MatchesAndResultCol,
  ReviewMainSectionLabel,
  MatchesAndResultRow,
  ReviewSubSectionLabel,
  OpenOnSpotify,
  OpenOnSpotifyLink,
} from '../styles'

interface MatchesAndResultProps {
  isSFAConnected: boolean
  playlist: Playlist
}

export const MatchesAndResult: FC<MatchesAndResultProps> = ({
  isSFAConnected,
  playlist,
}) => {
  const { t } = useTranslation()
  return (
    <MatchesAndResultCol>
      <ReviewMainSectionLabel>
        {t('campaignResultsPage.result')}
      </ReviewMainSectionLabel>
      <MatchesAndResultRow>
        <ReviewSubSectionLabel>
          {`${t('campaignResultsPage.streamsListeners')}:`}
        </ReviewSubSectionLabel>

        {!isSFAConnected && (
          <DataNotReceivedContainer>
            <DataNotReceivedLabel>
              {t('campaignResultsPage.dataNotReceived')}
            </DataNotReceivedLabel>
          </DataNotReceivedContainer>
        )}

        {isSFAConnected && (
          <StreamsListenersStats
            noDataText={t('campaignResultsPage.collectingData')}
            streams={playlist.streams_count}
            listeners={playlist.listeners_count}
          />
        )}
      </MatchesAndResultRow>
      <Mobile>
        <OpenOnSpotify>
          {t('campaignResultsPage.openPlaylistOn')}
          <OpenOnSpotifyLink href={playlist.playlist_url}>
            {t('campaignResultsPage.spotify')}
          </OpenOnSpotifyLink>
        </OpenOnSpotify>
      </Mobile>
    </MatchesAndResultCol>
  )
}
