import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { InfluencerEventStatus } from 'src/Types'

import { RootState } from '..'
import { SliceStatus } from '../types'

import { CampaignVideosState } from '.'

const getState = (state: RootState): CampaignVideosState => state.campaignVideos

export const getCampaignVideos = createDraftSafeSelector(
  getState,
  ({ allVideos }) => allVideos,
)

const LoadingStatuses = [SliceStatus.Loading, SliceStatus.Idle]
export const getCampaignVideosStatusIsLoading = createDraftSafeSelector(
  getState,
  ({ status }) => LoadingStatuses.includes(status),
)

export const getAwaitingVideos = createDraftSafeSelector(
  getState,
  ({ awaitingReviewVideos }) => awaitingReviewVideos,
)

export const getCampaignVideosCounters = createDraftSafeSelector(
  getState,
  ({ videosCount }) => videosCount,
)

export const getVideosCountByStatuses =
  (statuses: Array<InfluencerEventStatus>) =>
  (state: RootState): number =>
    getCampaignVideosCounters(state).reduce((acc, counterItem) => {
      if (statuses.includes(counterItem.status)) {
        return acc + counterItem.count
      }
      return acc
    }, 0)
