import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const MainValue = styled.div<{ color?: string }>`
  color: ${({ color }) => (color ? color : colors.mainText)};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  white-space: nowrap;
`

export const SubValue = styled(MainValue)`
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
`

export const ValueTooltip = styled.div<{
  position: number
  color: string
  right?: boolean
  left?: boolean
}>`
  position: absolute;
  top: -10px;
  left: ${({ position }) => `${position >= 100 ? 100 : position}%`};
  ${({ right, left }) => {
    if (right) {
      return 'transform: translate(-100%, -100%);'
    }
    if (left) {
      return 'transform: translate(-0%, -100%);'
    }
    return 'transform: translate(-50%, -100%);'
  }}
  background-color: ${({ color }) => color};
  width: 80px;
  padding: 4px;
  border-radius: 4px;
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    ${({ right, left }) => {
      if (right) {
        return 'left: 90%;'
      }
      if (left) {
        return 'left: 10%;'
      }
      return 'left: 50%;'
    }}
    transform: translateX(-50%);
    border: 4px solid transparent;
    border-top-color: ${({ color }) => color};
  }
`
interface ProgressProps {
  position: number
  hasLeftRadius?: boolean
  hasRightRadius?: boolean
  completed?: boolean
}

export const ProgressBar = styled.div<ProgressProps>`
  position: relative;
  width: 100%;
  height: 8px;
  background-color: ${colors.progressMain};
  background: ${({ position, completed }) => `linear-gradient(
    90deg,
    ${completed ? colors.greyTextWhiteBg : colors.progressActive} ${position}%,
    ${completed ? colors.greyStroke : colors.progressMain} ${position}%
  )`};
  ${({ hasLeftRadius }) =>
    hasLeftRadius &&
    'border-top-left-radius: 4px; border-bottom-left-radius: 4px;'}
  ${({ hasRightRadius }) =>
    hasRightRadius &&
    'border-top-right-radius: 4px; border-bottom-right-radius: 4px;'}
  ${({ hasRightRadius, hasLeftRadius }) => {
    if (hasLeftRadius) {
      return 'width: 40px;'
    }
    if (hasRightRadius) {
      return 'width: 40px;'
    }
    return 'width: 100%;'
  }}
`

export const Mark = styled.div<{ color: string }>`
  width: 2px;
  height: 8px;
  z-index: 1;
  background: ${({ color }) => color} !important;
  position: relative;
`
export const MarkLabel = styled.div`
  width: fit-content;
  position: absolute;
  top: 10px;
  left: 0px;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
`
