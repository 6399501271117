import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'

import { Checkbox } from '../../../../../ui'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

export const MainTitle = styled.h1`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  align-self: stretch;
`

export const RulesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`
export const ContentWrapper = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const StyledButton = styled(Button)`
  width: 130px;
`
export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: -22px;
`
