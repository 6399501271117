import React, { FunctionComponent } from 'react'

import { CreateCampaignLayout } from 'src/Components/CreateCampaignLayout'

import { useCreateCampaignWithoutSignupInit } from './useCreateCampaignWithoutSignupInit'

const CreateCampaignWithoutSignup: FunctionComponent = () => {
  useCreateCampaignWithoutSignupInit()

  return <CreateCampaignLayout />
}

export default CreateCampaignWithoutSignup
