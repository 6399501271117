import { FC } from 'react'

import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'
import { useTranslation } from 'react-i18next'

import AlertComponent from 'src/Components/Alert'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { useAppSelector } from 'src/Hooks/redux'
import { getIsBlockedCreatorSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { colors, variables } from 'src/Styled/variables'

const Title = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const Description = styled(Title)`
  font-weight: ${variables.fontWeight400};
`

export const InfluencerBlockedError: FC = () => {
  const { t } = useTranslation()
  const isBlocked = useAppSelector(getIsBlockedCreatorSelector)
  const { show } = useIntercom()

  if (!isBlocked) {
    return null
  }

  const handleClickSupport = (): void => {
    show()
  }
  return (
    <AlertComponent unclosable icon color='danger'>
      <Title>{t('balancePage.accountBlocked')}</Title>
      <Description>{t('balancePage.blockedDesc')}</Description>
      <DotedButton onClick={handleClickSupport} color={colors.mainText}>
        {t('balancePage.contactSupport')}
      </DotedButton>
    </AlertComponent>
  )
}
