import { useEffect } from 'react'

import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

export const useCheckSpotifyCheckoutStepIsAllowed = (): void => {
  const { search, setSearch } = useCustomSearchParams()

  const step = search.get('step')
  const paymentStatus = search.get('paymentStatus')
  const successPaymentStatus = paymentStatus === 'success'

  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)

  useEffect(() => {
    const isSpotifyCampaignIsNotValid =
      (!spotifyCampaignDetails?.id || spotifyCampaignDetails?.id === 0) &&
      spotifyCampaignDetails.price === 0

    if (
      step === ScheduleSteps.CHECKOUT &&
      isSpotifyCampaignIsNotValid &&
      !successPaymentStatus
    ) {
      setSearch({ step: ScheduleSteps.SETTINGS })
    }
  }, [
    spotifyCampaignDetails.price,
    successPaymentStatus,
    step,
    setSearch,
    spotifyCampaignDetails?.id,
  ])
}
