import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const PageContainer = styled.div`
  box-sizing: content-box;
  padding: 0;
  margin: 0 auto;
`

export const PageSubTitle = styled.h2`
  margin: 0 0 16px;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  @media ${device.mobileMin} {
    margin-top: 8px;
    line-height: 30px;
  }
`

export const InfoCardsWrapper = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const PageContentContainer = styled.div`
  box-sizing: content-box;
  background-color: ${colors.white};
  padding: 24px 32px 24px 24px;
  margin-bottom: 16px;
  position: relative;
  margin-top: 16px;

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-tab {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ant-tabs-tab-btn {
    color: ${colors.greyTextWhiteBg};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.greyTextWhiteBg};
  }

  .ant-tabs-nav-list {
    border-bottom: 2px solid ${colors.greyStroke};
  }

  .ant-tabs-nav {
    height: 35px;
    margin-bottom: 24px;

    .ant-tabs-ink-bar {
      background: ${colors.green};
      height: 2px;
      border-radius: 1px;
      bottom: -1px;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-table-column-title {
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
    line-height: 150%;
    color: ${colors.mainText};
  }

  .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
    line-height: 150%;
    color: ${colors.mainText};
  }

  .ant-table-cell {
    background: none !important;
    border-bottom: none !important;

    &:before {
      display: none;
    }
  }

  .ant-table-row {
    background: #f9f9f9;
    margin-bottom: 2px;
    min-height: 53px;
  }

  .ant-table-content table {
    border-spacing: 0 2px;
  }

  .ant-pagination {
    display: none;
  }
`
// Table styled

export const HeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
`
export const MobileHeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
  display: grid;
  grid-template-columns: 14.4px 1fr;
  column-gap: 8px;
  & svg {
    width: 14.4px;
    height: 16px;
    margin-top: 4px;
  }
`

export const HeaderTitleSelectedNumber = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.green};
  margin-left: 4px;
`

export const SortIconWrapper = styled.span`
  margin-left: 13px;
  padding: 5px;
`

export const ResetButtonWrapper = styled.div`
  position: absolute;
  top: 29px;
  right: 25px;
`

export const Amount = styled.span<{ green?: boolean }>`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
`

export const TrackCardInfoWrapper = styled.span`
  display: flex;
  flex-direction: column;
`
export const IconWrapper = styled.div`
  margin-right: 8px;
  img {
    width: 16px;
    height: 16px;
  }
`
export const InfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
`
export const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const MobileCard = styled.div`
  background-color: ${colors.white};
  padding: 16px 16px 16px 40px;
  border-radius: 4px;
`

export const MobileCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const MobileCardInfoWrapper = styled.div``

export const MobileCardFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-between;
`

export const MobileParamTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize10};
  line-height: 150%;
  color: ${colors.mainText};
`
export const MobileParamItem = styled.div`
  display: flex;
  flex-direction: column;
`
export const MobileItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const MobileInfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
  margin-top: 20px;
  text-align: center;
`

export const MobileLoadingWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;

  @media ${device.mobileMax} {
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${colors.white};
    box-shadow: 0 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row-reverse;
    padding: 12px 16px;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
  }
`

export const RequestButtonWrapper = styled.div`
  margin-bottom: 5px;
  width: 100%;
  min-width: 120px;
  max-width: 176px;

  @media ${device.mobileMax} {
    margin-bottom: 0;
  }
`

export const WidgetsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media ${device.mobileMax} {
    flex-direction: row-reverse;
  }
`

export const MobileNoItemsTextWrapper = styled.div`
  display: block;
  text-align: center;
  margin-top: 16px;
`

export const MinRequestText = styled.div`
  @media ${device.mobileMax} {
    min-width: 78px;
    margin-right: 34px;
  }
`

export const BlockOfCards = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media ${device.mobileMin} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${device.smallScreen} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`
