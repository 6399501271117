import { combineReducers } from '@reduxjs/toolkit'

import { rtkApi } from 'src/Services/rtkApi'

import { campaignProcess } from './campaign-process'
import { genresProcess } from './genres-process'
import { languagesProcess } from './languages-process'
import { spotifyTokenProcess } from './spotifyToken-process'
import { trackProcess } from './track-process'
import { sidebarProcess } from './sidebar-process'
import { playlistsProcess } from './playlists-process'
import { artistsProcess } from './artists-process'
import { feedProcess } from './feed-process'
import { wiseProcess } from './wise-process'
import { reviewsProcess } from './reviews-process'
import { influencerProcess } from './influencer-process'
import { spotifyTracksProcess } from './spotifyTracks-process'
import { settingsProcess } from './settings-process'
import { notificationProcess } from './notification-process'
import { artistBalanceProcess } from './artistBalanceProcess'
import { adminPanelProcess } from './adminPanel-process'
import { sessionNotificationsProcess } from './sessionNotification-process'
import { scheduleProcess } from './schedule-process'
import { authProcess } from './auth-process'
import { alertProcess } from './alert-process'
import { transactions } from './transactions-process/reducer'
import { paymentProcess } from './payment-process'
import { featuresSlice } from './A-BFeatures-process'
import { paypalTransactionsProcess } from './paypalTransactions-process'
import { sourceBusterProcess } from './sourcebuster-process'
import { campaignVideosProcess } from './campaignVideos-process'
import { campaignVideoProcess } from './campaignVideo-process'
import { analyticsProcess } from './analytics-process'
import { canceledAlerts } from './canceledAlerts'
import { curatorTracks } from './curatorTracks-process'
import { levelsProcess } from './levels-procces'
import { getPremium } from './getPremiumProcess'
import { referral } from './referral-process'
import { searchPlaylistProcess } from './searchPlaylist-process'
import { similarGenresProcess } from './similarGenres-process'
import { subscriptionsProcess } from './subscription-process'
import { creatorRegister } from './creatorRegister-process'
import { rateProcess } from './rate-process'
import { typeformProcess } from './typeform-process'
import { tikTokTrackProcess } from './tiktokTrack-process'
import { billingAgreementProcess } from './billingAgreement-process'
import { checkoutTimer } from './checkoutCountdown-process'

const rootReducer = combineReducers({
  transactions,
  referral,
  [rtkApi.reducerPath]: rtkApi.reducer,
  subscriptions: subscriptionsProcess,
  aBFeatures: featuresSlice.reducer,
  authentication: authProcess,
  campaignVideos: campaignVideosProcess.reducer,
  campaignVideo: campaignVideoProcess,
  scheduling: scheduleProcess,
  notifications: notificationProcess.reducer,
  artistBalance: artistBalanceProcess,
  adminPanel: adminPanelProcess,
  sessionNotifications: sessionNotificationsProcess.reducer,
  campaigns: campaignProcess,
  artists: artistsProcess.reducer,
  spotifyTracks: spotifyTracksProcess.reducer,
  tikTokTrack: tikTokTrackProcess.reducer,
  influencer: influencerProcess.reducer,
  alerts: alertProcess.reducer,
  settings: settingsProcess,
  transferwise: wiseProcess.reducer,
  reviews: reviewsProcess.reducer,
  feed: feedProcess.reducer,
  playlists: playlistsProcess.reducer,
  sidebar: sidebarProcess.reducer,
  spotifyTrack: trackProcess.reducer,
  spotifyToken: spotifyTokenProcess.reducer,
  genres: genresProcess.reducer,
  languages: languagesProcess.reducer,
  payment: paymentProcess.reducer,
  paypalTransactions: paypalTransactionsProcess.reducer,
  sourcebuster: sourceBusterProcess.reducer,
  analytics: analyticsProcess.reducer,
  canceledAlerts: canceledAlerts.reducer,
  getPremium: getPremium.reducer,
  curatorTracks: curatorTracks.reducer,
  levels: levelsProcess.reducer,
  searchPlaylist: searchPlaylistProcess.reducer,
  similarGenres: similarGenresProcess.reducer,
  creatorRegister: creatorRegister.reducer,
  rate: rateProcess.reducer,
  typeform: typeformProcess.reducer,
  billingAgreement: billingAgreementProcess,
  checkoutTimer: checkoutTimer.reducer,
})

export default rootReducer
