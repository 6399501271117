import React, { FunctionComponent } from 'react'

import { TrackMatchesProps } from './props'

import { Container, Title, MatchedGenres, MatchedRate } from './styles'

const EIGHTY = 80

const Component: FunctionComponent<TrackMatchesProps> = ({
  matchedList,
  matchedRate,
  isGenres,
}) => {
  const genresRow = (): string => {
    let result = ''
    matchedList.forEach((el, id) => {
      result += el
      if (id !== matchedList.length - 1) {
        result += ', '
      }
      if (result.length > EIGHTY) {
        result = `${result.substring(0, EIGHTY)} ...`
      }
    })
    return result
  }

  return (
    <Container>
      <Title>
        {isGenres ? 'Genre matches: ' : 'Playlist matches: '}
        <MatchedRate
          isGenres={Boolean(isGenres)}
          matchedRate={matchedRate}
        >{`${matchedRate}${isGenres ? '%' : ''}`}</MatchedRate>
      </Title>
      <MatchedGenres>{genresRow()}</MatchedGenres>
    </Container>
  )
}

export const TrackMatches = React.memo(Component)
