import { FC } from 'react'

import { ProgressBar, ProgressSection } from './styles'

interface ProgressByBudgetProps {
  spent: number
  budget: number
}

const PERCENT = 100
const STEP_PERCENT = 10
const totalSteps = Array.from({ length: STEP_PERCENT }).map((_, indx) => ({
  from: (indx + 1) * STEP_PERCENT - STEP_PERCENT,
  to: (indx + 1) * STEP_PERCENT,
}))
export const ProgressByBudget: FC<ProgressByBudgetProps> = ({
  spent,
  budget,
}) => {
  const percentageGraph = (spent * PERCENT) / budget
  return (
    <ProgressBar>
      {totalSteps.map(({ from, to }) => (
        <ProgressSection
          key={to}
          to={to}
          from={from}
          percent={percentageGraph}
        />
      ))}
    </ProgressBar>
  )
}
