import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { ArtistSpotify, Genre, RateVideo } from 'src/Types'
import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../types'
import { RootState } from '../index'

interface SpotifyArtistBody {
  searchString: string
}

interface SpotifyArtistResponse {
  artists: {
    items: ArtistSpotify[]
  }
}

interface SpotifyArtistGenresBody {
  artistId: string
}

interface SpotifyArtistGenreResponse {
  artist_genres: Array<{
    id: number
    genre_name: string
    PlaylistGenres: Array<Genre>
  }>
}

export interface ArtistConnection {
  artist_id: number
  artist_spotify_id: string
  confirmation_time: string
  id: number
  is_connected: boolean
  sfa_account_id: number
}

interface ArtistSpotifyConnectionsResponse {
  connections: Array<ArtistConnection>
  sfa_email: string
}

interface SetSpotifyConnectionBody {
  artist_spotify_id: string
}

interface SetSpotifyConnectionResponse {
  connection: ArtistConnection
}

interface TiktokCampaignRejectBody {
  tiktokCampaignEventId: number
  feedback: string
}

interface TiktokCampaignRateBody {
  tiktokCampaignEventId: number
  feedback: string
  stars: number
}

export const getSpotifyArtists = createAsyncThunk<
  ArtistSpotify[],
  SpotifyArtistBody
>(
  `${NameSpace.Artists}/getSpotifyArtists`,
  async ({ searchString }, { rejectWithValue, getState }) => {
    try {
      const searchUrl = `https://api.spotify.com/v1/search?q=${searchString}&type=artist`
      const state = getState() as RootState
      const authToken = state.spotifyToken.accessToken || ''

      const { data } = await axios.get<SpotifyArtistResponse>(searchUrl, {
        headers: { Authorization: `Bearer ${authToken}` },
      })

      return data.artists.items
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getArtistGenres = createAsyncThunk<
  SpotifyArtistGenreResponse,
  SpotifyArtistGenresBody
>(
  `${NameSpace.Artists}/getArtistGenres`,
  async ({ artistId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<SpotifyArtistGenreResponse>(
        `${APP.SERVER}/campaign/spotify-artist-genres/${artistId}/with_playlists`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const removeTikTokAlert = createAsyncThunk(
  `${NameSpace.Artists}/removeTikTokAlert`,
  async (_, { rejectWithValue }) => {
    try {
      await api.put(`${APP.SERVER}/artist/remove-tiktok-alert`)

      return true
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getArtistSpotifyConnections =
  createAsyncThunk<ArtistSpotifyConnectionsResponse>(
    `${NameSpace.Artists}/getArtistSpotifyConnections`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await api.get<ArtistSpotifyConnectionsResponse>(
          `${APP.SERVER}/artist/spotify-connections`,
        )
        return data
      } catch (error) {
        const { message, response } = error as AxiosError
        return rejectWithValue({
          message,
          code: response?.status || ErrorCode.InternalServerError,
        })
      }
    },
  )

export const setSpotifyConnectionForArtist = createAsyncThunk<
  SetSpotifyConnectionResponse,
  SetSpotifyConnectionBody
>(
  `${NameSpace.Artists}/setSpotifyConnectionForArtist`,
  async ({ artist_spotify_id }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<SetSpotifyConnectionResponse>(
        `${APP.SERVER}/artist/spotify-connection`,
        {
          artist_spotify_id,
        },
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const postTiktokCampaignReject = createAsyncThunk<
  void,
  TiktokCampaignRejectBody
>(
  `${NameSpace.Artists}/postTiktokCampaignReject`,
  async ({ tiktokCampaignEventId, feedback }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<void>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/reject-creator/${tiktokCampaignEventId}`,
        {
          feedback,
        },
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const putTiktokCampaignRate = createAsyncThunk<
  RateVideo,
  TiktokCampaignRateBody
>(
  `${NameSpace.Artists}/putTiktokCampaignRate`,
  async ({ tiktokCampaignEventId, feedback, stars }, { rejectWithValue }) => {
    try {
      const { data } = await api.put<RateVideo>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/rate-video/${tiktokCampaignEventId}`,
        {
          feedback,
          stars,
        },
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
