import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const Wrapper = styled.div`
  padding: 10px 15px 15px 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
`
export const ImageWrapper = styled.div`
  max-width: 42px;
  padding-top: 10px;
  margin-right: 15px;
  flex: 1 1;

  img {
    border-radius: 50%;
    height: 42px;
    width: 42px;
    object-fit: cover;
  }
`
export const Message = styled.div`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 16px;
  color: #787985;
  flex: 4 4;
`
export const HeaderMessage = styled.div`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  font-weight: bold;
  display: block;
  a {
    color: ${colors.badgeGreen};
  }
`
export const TextName = styled.p`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
  padding-top: 5px;
`
export const DateWrapper = styled.p`
  position: absolute;
  bottom: 0px;
  margin-bottom: 8px;
  right: 8px;
  font-size: ${variables.fontSize14};
  color: #787985;
  @media screen and (min-width: 992px) {
    bottom: 16px;
    right: 34px;
    margin-bottom: 0;
  }
`
