import { FC, useState, memo } from 'react'

import { useTranslation } from 'react-i18next'
import ReactStars from 'react-rating-stars-component'

import { colors } from 'src/Styled/variables'
import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { NOT_FIRST_ITEM } from 'src/Constants/constants'
import TrustPilot from 'src/Assets/Svg/logo-trustpilot.svg?react'

import { whatArtistsThinkItems } from './list'

import {
  WhatArtistsThinkHeader,
  WhatArtistsThinkList,
  WhatArtistsThinkItem,
  WhatArtistsThinkAvatarWrapper,
  WhatArtistsThinkAvatar,
  WhatArtistsThinkTitle,
  WhatArtistsThinkText,
  WhatArtistsThinkShowFullButton,
  WhatArtistsThinkReadOnTrustPilot,
  TrustPilotRead,
  TrustPilotImg,
  CloseButton,
  StarWrapper,
  WhatArtistsThinkShowFullButtonWrapper,
} from './styles'

export const WhatArtistsThink: FC = memo(() => {
  const { t } = useTranslation()
  const [activeItemId, setActiveItemId] = useState<null | number>(null)

  const toggleActiveItem = (id: number): void => {
    setActiveItemId((prevId) => (prevId === id ? null : id))
  }

  const content = whatArtistsThinkItems.map((item) => {
    const isActive = item.id === activeItemId
    const isNotActive = item.id !== activeItemId && Boolean(activeItemId)

    return (
      <WhatArtistsThinkItem
        key={item.id}
        isActive={isActive}
        className={isNotActive ? 'nonactive' : ''}
        {...(isNotActive && {
          style: {
            transform:
              !isActive && item.id >= NOT_FIRST_ITEM
                ? 'translateX(1000px)'
                : 'translateX(-1000px)',
          },
        })}
      >
        <WhatArtistsThinkAvatarWrapper>
          <WhatArtistsThinkAvatar src={item.avatar} alt={`artist${item.id}`} />
          {isActive && (
            <CloseButton onClick={() => toggleActiveItem(item.id)}>
              <CloseCross height={10} />
            </CloseButton>
          )}
        </WhatArtistsThinkAvatarWrapper>

        <WhatArtistsThinkTitle>{t(item.name)}</WhatArtistsThinkTitle>

        <StarWrapper>
          <ReactStars
            count={5}
            edit={false}
            value={item.stars}
            size={16}
            color={colors.greyTextBlackBg}
            activeColor={colors.messegesYellow1}
          />
        </StarWrapper>

        <WhatArtistsThinkText isActive={isActive}>
          <div>{t(item.text)}</div>
          <div>{item.text2 && t(item.text2)}</div>
        </WhatArtistsThinkText>
        <WhatArtistsThinkShowFullButtonWrapper>
          <WhatArtistsThinkShowFullButton
            type='button'
            onClick={() => toggleActiveItem(item.id)}
          >
            {isActive
              ? t('whatArtistsThink.hideFullReview')
              : t('whatArtistsThink.showFullReview')}
          </WhatArtistsThinkShowFullButton>
        </WhatArtistsThinkShowFullButtonWrapper>

        <WhatArtistsThinkReadOnTrustPilot
          href={item.link}
          target='_blank'
          rel='noopener noreferrer'
        >
          <TrustPilotRead>{t('whatArtistsThink.readOn')}</TrustPilotRead>
          <TrustPilotImg>
            <TrustPilot />
          </TrustPilotImg>
        </WhatArtistsThinkReadOnTrustPilot>
      </WhatArtistsThinkItem>
    )
  })

  return (
    <>
      <WhatArtistsThinkHeader>
        {t('whatArtistsThink.whatArtistsThink')}
      </WhatArtistsThinkHeader>

      <WhatArtistsThinkList isActive={Boolean(activeItemId)}>
        {content}
      </WhatArtistsThinkList>
    </>
  )
})
WhatArtistsThink.displayName = 'WhatArtistsThink'
