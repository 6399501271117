import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import {
  CampaignPlatformTypeEnum,
  SpotifyCampaign,
  TiktokCampaign,
} from 'src/Types'

import { CampaignStopped } from '../CampaignStopped'

import { NotStarted } from './styles'

interface TiktokCanceledProps {
  campaign: SpotifyCampaign | TiktokCampaign
}

const getIsTiktokStopped = (
  campaign: SpotifyCampaign | TiktokCampaign,
): boolean =>
  campaign.platformType === CampaignPlatformTypeEnum.TIKTOK &&
  'influencersAcceptedAmount' in campaign &&
  campaign.influencersAcceptedAmount === 0 &&
  campaign.submittedVideosAmount === 0
export const TiktokCanceled: FC<TiktokCanceledProps> = ({ campaign }) => {
  const { t } = useTranslation()
  const isTiktokStopped = getIsTiktokStopped(campaign)
  if (campaign.startDate || isTiktokStopped) {
    return (
      <CampaignStopped
        type={campaign.type}
        startDate={campaign.startDate}
        endDate={campaign.endDate}
      />
    )
  }
  return <NotStarted>{t('campaignsPage.notStarted')}</NotStarted>
}
