import styled, { css } from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

interface Props {
  isFAQ?: boolean
  isHiddenOnMobile?: boolean
}

export const SubHeadForNewCampaignSearch = styled.div<Props>`
  color: ${colors.mainText};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  line-height: 150%;
  margin-bottom: ${(props: Props) => (!props.isFAQ ? '8px' : '21px')};
  margin-top: 22px;

  ${({ isFAQ, isHiddenOnMobile }) => {
    const displayStyle = isHiddenOnMobile && !isFAQ ? 'none' : 'grid'

    if (isFAQ) {
      return css`
        display: ${displayStyle};
        align-items: center;
        grid-template-columns: 12px 1fr;
        grid-gap: 18.8px;
      `
    }
    return css``
  }}

  @media ${device.mobileMin} {
    padding-left: 0;
    padding-top: 0px;
    margin-bottom: 16px;
    font-size: ${variables.fontSize24};
    color: ${colors.mainText};

    ${({ isFAQ }) => {
      if (!isFAQ) {
        return css`
          display: grid;
          grid-template-columns: 12px 1fr;
          align-items: center;
          grid-gap: 18.8px;
        `
      }
      return css``
    }}

    & p {
      line-height: 150%;
      margin: 0px;
    }
  }
`

export const ArrowBackButton = styled.div`
  cursor: pointer;
  width: 12px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
