import i18n from 'src/Localization/i18n'

import { ErrorCode } from '../types'

export const getIsInvalidSearchInputValue = (
  searchInputValue: string | null,
): boolean => {
  const invalidStartCharacters = ['%', '#', '{', '}', '^', '*']
  const invalidIncludedCharacters = ['&', ';', '+', ' ', '`', '\\']

  return (
    invalidStartCharacters.some((char) => searchInputValue?.includes(char)) ||
    invalidIncludedCharacters.some((char) => searchInputValue?.startsWith(char))
  )
}

const isSpotifyUrl = (searchInputValue: string): boolean =>
  searchInputValue.includes('open.spotify.com')

export const getSpotifyApiUrl = (
  searchInputValue: string,
): {
  url: string
  type: 'trackId' | 'keyword'
} => {
  const TRACK_ID_LENGTH = 22
  const TRACK_ID_POSITION = 6

  const apiSpotify = 'https://api.spotify.com/v1'
  const index = searchInputValue.indexOf('track')
  const trackID = searchInputValue
    .substring(index)
    .slice(TRACK_ID_POSITION)
    .substring(0, TRACK_ID_LENGTH)

  if (isSpotifyUrl(searchInputValue)) {
    return {
      url: `${apiSpotify}/tracks/${trackID}`,
      type: 'trackId',
    }
  }
  return {
    url: `${apiSpotify}/search?q=${searchInputValue}&type=track`,
    type: 'keyword',
  }
}

interface ErrorMessageParams {
  search: string
  code?: number
  message?: string
  searchErrorByType?: string
}

export const getErrorMessage = ({
  search,
  code,
  message,
  searchErrorByType,
}: ErrorMessageParams): string => {
  if (
    searchErrorByType === 'tryUrl' ||
    (code === ErrorCode.NotFound && !isSpotifyUrl(search))
  ) {
    return i18n.t('createCampaignPage.trackNameSearchUnavailable')
  }
  if (
    searchErrorByType === 'tryName' ||
    (code === ErrorCode.NotFound && isSpotifyUrl(search))
  ) {
    return i18n.t('createCampaignPage.urlSearchUnavailable')
  }

  if (message && !code) {
    return message
  }
  return i18n.t('createCampaignPage.tryLater')
}
