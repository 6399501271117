import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { BudgetRangeState } from '.'

const getState = (state: RootState): BudgetRangeState =>
  state.scheduling.budgetRange
export const getBudgetRange = createDraftSafeSelector(
  getState,
  ({ budgetRange }) => budgetRange,
)

const LOAD_STATUSES = [SliceStatus.Loading, SliceStatus.Idle]

export const getBudgetRangeError = createDraftSafeSelector(
  getState,
  ({ error, budgetRange, status }) =>
    (error || !budgetRange?.max) && !LOAD_STATUSES.includes(status),
)
