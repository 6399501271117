import { FunctionComponent, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import Button from 'src/Components/Buttons/Button'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { SpotifyIFrame } from 'src/Components/SpotifyIframe'
import { colors } from 'src/Styled/variables'
import { getIsLimitReached } from 'src/Redux/feed-process/selectors'

import { ProgressBarComponent } from '../ProgressBar'
import { TrackMatches } from '../TrackMatches'

import { TrackListItemProps } from './props'

import {
  ButtonsContainer,
  ContentColumn,
  ContentContainer,
  ContentRow,
  LimitReachedPlaceholder,
  TrackListItemContainer,
  TrackPreview,
} from './styles'

const getTrackId = (url: string): string => {
  if (!url) {
    return ''
  }
  const urlParts = url.split('/')
  return urlParts[urlParts.length - 1]
}

export const TrackListItem: FunctionComponent<TrackListItemProps> = ({
  track,
  openSkipModal,
  openReviewModal,
}) => {
  const isLimitReached = useSelector(getIsLimitReached)
  const isNotLargeDesktop = useMediaQuery({ maxWidth: 1439 })
  const {
    id: campaignId,
    matchedPercent,
    matchedGenres,
    trackUrl,
    matchedPlaylists,
    startDate,
    endDate,
  } = track

  const matchedGenresCapitalized = useMemo(
    () => matchedGenres.map((el) => capitalizeFirstLetter(el)),
    [matchedGenres],
  )

  return (
    <TrackListItemContainer>
      <ContentContainer>
        <ContentRow>
          <TrackPreview>
            <SpotifyIFrame trackId={getTrackId(trackUrl)} />
          </TrackPreview>
          {!isNotLargeDesktop && (
            <ContentColumn>
              <TrackMatches
                matchedList={matchedGenresCapitalized}
                matchedRate={matchedPercent}
                isGenres
              />
              <TrackMatches
                matchedList={matchedPlaylists}
                matchedRate={matchedPlaylists?.length}
              />
            </ContentColumn>
          )}
          <ProgressBarComponent startDate={startDate} endDate={endDate} />
          <Desktop>
            <ButtonsContainer>
              {isLimitReached ? (
                <LimitReachedPlaceholder>
                  Limited for today
                </LimitReachedPlaceholder>
              ) : (
                <>
                  <Button
                    type='green'
                    onClick={() => openReviewModal({ campaign_id: campaignId })}
                  >
                    Review
                  </Button>
                  <DotedButton
                    color={colors.mainText}
                    onClick={() => openSkipModal({ campaign_id: campaignId })}
                  >
                    Skip track
                  </DotedButton>
                </>
              )}
            </ButtonsContainer>
          </Desktop>
        </ContentRow>
        <ContentRow>
          {isNotLargeDesktop && (
            <>
              <TrackMatches
                matchedList={matchedGenresCapitalized}
                matchedRate={matchedPercent}
                isGenres
              />
              <TrackMatches
                matchedList={matchedPlaylists}
                matchedRate={matchedPlaylists?.length}
              />
            </>
          )}
          <Mobile>
            <ButtonsContainer>
              {isLimitReached ? (
                <LimitReachedPlaceholder>
                  Limited for today
                </LimitReachedPlaceholder>
              ) : (
                <>
                  <Button
                    type='green'
                    onClick={() => openReviewModal({ campaign_id: campaignId })}
                  >
                    Review
                  </Button>
                  <DotedButton
                    color={colors.mainText}
                    onClick={() => openSkipModal({ campaign_id: campaignId })}
                  >
                    Skip track
                  </DotedButton>
                </>
              )}
            </ButtonsContainer>
          </Mobile>
        </ContentRow>
      </ContentContainer>
    </TrackListItemContainer>
  )
}
