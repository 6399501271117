import TagManager from 'react-gtm-module'

import { CampaignPlatformTypeEnum, CampaignTypeEnum, ID } from 'src/Types'
import { Roles } from 'src/Constants/enums'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const trackAddedEvent = ({
  trackName,
  trackDuration,
  trackId,
  artistName,
  isFirstCampaign,
  platformName,
  campaignNumber,
  userId,
}: {
  trackName: string
  trackDuration: string | number
  trackId: string | number
  artistName: string
  isFirstCampaign: boolean
  platformName: string
  campaignNumber?: number
  userId?: ID | null
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.TrackAdded,
      event_name: EventNameEnum.TrackAdded,
      'campaign-type':
        (isFirstCampaign && campaignNumber === 1) || !userId
          ? CampaignTypeEnum.FIRST
          : CampaignTypeEnum.BASIC,
      'campaign-platform': platformName,
      'track-platform': platformName,
      'campaign-number': campaignNumber || 1,
      ...(userId ? { userID: userId } : {}),
      'track-name': trackName,
      'track-length': trackDuration,
      'track-id': trackId,
      'track-artist-name': artistName,
    },
  })
}

export const startedEnterTrackEvent = ({
  campaignPlatform,
  userId,
  isFirstCampaign,
  campaignId,
  campaignNumber,
}: {
  campaignPlatform: CampaignPlatformTypeEnum
  userId?: ID | null
  isFirstCampaign: boolean
  campaignId?: string | number
  campaignNumber?: number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.StartedEnterTrack,
        campaign_platform: campaignPlatform,
        campaign_type:
          (isFirstCampaign && campaignNumber === 1) || !userId
            ? CampaignTypeEnum.FIRST
            : CampaignTypeEnum.NEW,
        campaign_number: campaignNumber || 1,
        ...(campaignId ? { campaign_id: campaignId } : {}),
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const enteredTrackUrlEvent = ({
  campaignPlatform,
  userId,
  isFirstCampaign,
  campaignId,
  campaignNumber,
}: {
  campaignPlatform: CampaignPlatformTypeEnum
  userId?: ID | null
  isFirstCampaign: boolean
  campaignId?: string | number
  campaignNumber?: number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.EnteredTrackUrl,
        campaign_platform: campaignPlatform,
        campaign_type:
          (isFirstCampaign && campaignNumber === 1) || !userId
            ? CampaignTypeEnum.FIRST
            : CampaignTypeEnum.NEW,
        campaign_number: campaignNumber || 1,
        ...(campaignId ? { campaign_id: campaignId } : {}),
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const enterTrackEvent = ({
  userId,
  campaignType,
  enteredText,
}: {
  userId?: ID | null
  campaignType: string
  enteredText: string | undefined
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.EnterTrack,
        campaign_platform: 'tiktok',
        campaign_type: campaignType,
        entered_text: enteredText,
      },

      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const searchUnavailableEvent = ({
  userId,
  isFirstCampaign,
  campaignId,
  campaignNumber,
}: {
  userId?: ID | null
  isFirstCampaign: boolean
  campaignId?: string | number
  campaignNumber?: number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.SearchUnavailable,
        campaign_platform: CampaignPlatformTypeEnum.TIKTOK,
        campaign_type:
          (isFirstCampaign && campaignNumber === 1) || !userId
            ? CampaignTypeEnum.FIRST
            : CampaignTypeEnum.NEW,
        campaign_number: campaignNumber || 1,
        ...(campaignId ? { campaign_id: campaignId } : {}),
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const defaultSearchErrorTagManagerEvent = ({
  userId,
  eventName,
  campaignPlatform,
}: {
  userId?: ID | null
  eventName: string
  campaignPlatform: CampaignPlatformTypeEnum
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: eventName,
        campaign_platform: campaignPlatform,
      },
      user_properties: {
        userID: userId,
      },
    },
  })
}

export const errorAddTrackEvent = ({
  campaignPlatform,
  userId,
  isFirstCampaign,
  campaignId,
  campaignNumber,
}: {
  campaignPlatform: CampaignPlatformTypeEnum
  userId?: ID | null
  isFirstCampaign: boolean
  campaignId?: string | number
  campaignNumber?: number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.ErrorAddTrack,
        campaign_platform: campaignPlatform,
        campaign_type:
          (isFirstCampaign && campaignNumber === 1) || !userId
            ? CampaignTypeEnum.FIRST
            : CampaignTypeEnum.NEW,
        campaign_number: campaignNumber || 1,
        ...(campaignId ? { campaign_id: campaignId } : {}),
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const onPlaySearchedTrack = (
  type: CampaignTypeEnum,
  accountType: Roles | null,
  userId?: ID | null,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.TikTokSearchedTrackPlay,
        campaign_platform: CampaignPlatformTypeEnum.TIKTOK,
        campaign_type: type,
      },
      user_properties: {
        ...(userId ? { user_ID: userId } : {}),
        account_type: accountType,
      },
    },
  })
}
export const onPlayAddedTrack = (
  type: CampaignTypeEnum,
  accountType: Roles | null,
  userId?: ID | null,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.TikTokAddedTrackPlay,
        campaign_platform: CampaignPlatformTypeEnum.TIKTOK,
        campaign_type: type,
      },
      user_properties: {
        ...(userId ? { user_ID: userId } : {}),
        account_type: accountType,
      },
    },
  })
}
