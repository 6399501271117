import { FunctionComponent } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import track from 'src/Assets/Png/track.png'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { convertTimeHandler } from 'src/Constants/functions'
import { ALBUM_IMAGES } from 'src/Constants/constants'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types/index'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { getTikTokTrackSelector } from 'src/Redux/tiktokTrack-process/selectors'
import { onPlayAddedTrack } from 'src/Helpers/TagManager/TrackEvent'

import { IconComponent } from '../IconComponent'
import { TrackAudioPlayer } from '../TrackAudioPlayer'
import { TrackCoverWrapper } from '../TrackAudioPlayer/TrackCoverWrapper'

import { AddedTrackProps } from './AddedTrackProps'

import {
  TrackBlock,
  TrackContainer,
  TrackDescription,
  TrackDuration,
  TrackImg,
  TrackText,
  TrackTitle,
  Platforms,
  TrackBlockLeft,
  CenteredWrapper,
} from './styles'

const StyledTrackCoverWrapper = styled(TrackCoverWrapper)`
  margin-right: 0;
`

export const AddedTrack: FunctionComponent<
  AddedTrackProps<CampaignPlatformTypeEnum>
> = ({ platform, changeTrackHandler, isVariantB }) => {
  const { t } = useTranslation()

  const tikTokTrack = useSelector(getTikTokTrackSelector)
  const spotifyTrack = useSelector(getSpotifyTrackSelector)

  return (
    <TrackContainer>
      {!isVariantB && (
        <Platforms>
          <IconComponent platform={platform} width={24} height={24} />
          {platform === CampaignPlatformTypeEnum.SPOTIFY
            ? t('createCampaignPage.spotifyCampaign')
            : t('createCampaignPage.tikTokCampaign')}
        </Platforms>
      )}
      {platform === CampaignPlatformTypeEnum.SPOTIFY && spotifyTrack && (
        <TrackBlock>
          <TrackBlockLeft>
            <TrackImg
              src={spotifyTrack.album?.images[ALBUM_IMAGES]?.url}
              alt={spotifyTrack?.name}
              fallbackSrc={track}
              width='64'
            />
            <TrackText>
              <TrackTitle>{spotifyTrack.name}</TrackTitle>
              <TrackDescription>
                {spotifyTrack.artists[0].name}
              </TrackDescription>
              <TrackDuration>
                {convertTimeHandler({
                  value: spotifyTrack.duration_ms,
                  type: 'ms',
                })}
              </TrackDuration>
            </TrackText>
          </TrackBlockLeft>
          <CenteredWrapper>
            <DottedBtn
              content={t('createCampaignPage.changeTrack')}
              onClick={changeTrackHandler}
            />
          </CenteredWrapper>
        </TrackBlock>
      )}

      {platform === CampaignPlatformTypeEnum.TIKTOK && tikTokTrack && (
        <TrackBlock>
          <TrackBlockLeft>
            <TrackAudioPlayer
              render={({ loading, currentTrackUrl, isPlaying, onClick }) => (
                <StyledTrackCoverWrapper
                  iconSize={'25px'}
                  loaderSize='50px'
                  isPlaying={
                    isPlaying && currentTrackUrl === tikTokTrack.trackPlayUrl
                  }
                  loading={
                    loading && currentTrackUrl === tikTokTrack.trackPlayUrl
                  }
                  onClick={(e) => {
                    onClick(e, tikTokTrack.trackPlayUrl)
                    if (!isPlaying) {
                      onPlayAddedTrack(CampaignTypeEnum.FIRST, null, null)
                    }
                  }}
                >
                  <TrackImg
                    src={tikTokTrack.trackCoverUrl}
                    alt={tikTokTrack.trackTitle}
                    fallbackSrc={track}
                    width='64'
                  />
                </StyledTrackCoverWrapper>
              )}
            />

            <TrackText>
              <TrackTitle>{tikTokTrack.trackTitle}</TrackTitle>
              <TrackDescription>{tikTokTrack.trackAuthorName}</TrackDescription>
              <TrackDuration>
                {convertTimeHandler({
                  value: tikTokTrack.trackDuration,
                  type: 's',
                })}
              </TrackDuration>
            </TrackText>
          </TrackBlockLeft>

          <CenteredWrapper>
            <DottedBtn
              content={t('createCampaignPage.changeTrack')}
              onClick={changeTrackHandler}
            />
          </CenteredWrapper>
        </TrackBlock>
      )}
    </TrackContainer>
  )
}
