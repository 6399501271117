import React from 'react'

import { BonusAlert } from 'src/Components/BonusAlert'
import { HelmetTitle } from 'src/Components/CreateCampaignLayout/components/HelmetTitle'
import { WelcomeAlert } from 'src/Components/WelcomeAlert'
import { CampaignsListBonusAlert } from 'src/Containers/CampaignsList/components/CampaignsListBonusAlert'

export interface HeaderComponentsBlockProps {
  isVariantB?: boolean
}
export const HeaderComponentsBlock: React.FC<HeaderComponentsBlockProps> = ({
  isVariantB,
}) => {
  if (!isVariantB) {
    return null
  }

  return (
    <>
      <HelmetTitle />
      <BonusAlert />
      <WelcomeAlert />
      <CampaignsListBonusAlert />
    </>
  )
}
